type RestrictionHeadingProps = {
  useMinimalCustomerAge: boolean;
  minimalCustomerAge: number;
  isBirthdayRequired: boolean;
};

const RestrictionHeading = ({
  isBirthdayRequired,
  minimalCustomerAge,
  useMinimalCustomerAge,
}: RestrictionHeadingProps) => {
  if (isBirthdayRequired) {
    return <>Please Enter Your&nbsp;Birthdate</>;
  }

  if (useMinimalCustomerAge) {
    return <>Are you {minimalCustomerAge} or older?</>;
  }

  return <>Are you 21 or older?</>;
};

export { RestrictionHeading };
