export enum ProductFilterType {
  SINGLE = 'Single',
  MULTIPLE = 'Multiple',
  RANGE = 'Range',
}

export enum ProductFilterUnit {
  PERCENT = '%',
  DOLLAR = '$',
}

export type ProductFilter<FT extends ProductFilterType, CustomFields extends Record<string, unknown> = any> = {
  key: string;
  uiLabel: string;
  type: FT;
  expandedByDefault: boolean;
  isTouched: boolean;
} & CustomFields;

export type ProductFilterOption = NamedEntity & {
  disabled: boolean;
};

export type ProductFilterTag = ProductFilter<
  ProductFilterType.SINGLE,
  {
    options: ProductFilterOption[];
    value: EntityID;
  }
>;

export type ProductFilterTagMultiple = ProductFilter<
  ProductFilterType.MULTIPLE,
  {
    options: ProductFilterOption[];
    presentation: 'Tag' | 'Checkbox';
    value: EntityID[];
  }
>;

export type ProductFilterTagValue = EntityID;
export type ProductFilterTagMultipleValue = ProductFilterTagValue[];

export type ProductFilterRange = ProductFilter<
  ProductFilterType.RANGE,
  {
    range: {
      min: number;
      max: number;
      unitAbbr: ProductFilterUnit;
    };
    step?: number;
    value: [number, number];
  }
>;

export type ProductFilterRangeValue = [number, number];

export type ProductFilterAny = ProductFilterTag | ProductFilterRange | ProductFilterTagMultiple;
export type ProductFilterAnyValue = ProductFilterTagValue | ProductFilterTagMultipleValue | ProductFilterRangeValue;

// возвращаются в порядке отображения в ручке получения продуктов
export type ProductFilters = ProductFilterAny[];

export type ProductFiltersConfigMap = Record<string, ProductFilterAny>;

// филльтры в запросе продуктов - просто мапа айдишник фильтра на его значение
export type ProductFiltersQuery = { [key: EntityID]: ProductFilterAnyValue };

export type SidebarFilters = Record<string, any>; // TODO: Make proper type

export type CatalogFilters = {
  sortingMethodId?: number;
  searchTerm?: string;
} & SidebarFilters;

export const mapFilterOptions = (options: ProductFilterOption[]) =>
  options.map((o) => ({ name: o.name, label: o.name, value: o.id, disabled: o.disabled }));

export const CommonFilters = {
  POPULAR: { quickFilter: [3] },
  DEALS: { quickFilter: [2] },
};
