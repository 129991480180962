// eslint-disable-next-line max-classes-per-file
import { ImageVerification } from 'common/entities/documents/ImageVerification';
import {
  DocumentCategory,
  DocumentVerification as DocumentVerificationType,
  VerificationStatusId,
} from 'entities/documents/documents-v2';

interface DocumentVerificationImpl<CT extends DocumentCategory = DocumentCategory> {
  readonly type: CT;

  get id(): DocumentVerificationType['id'];
  get data(): DocumentVerificationType;
  get prototypeName(): string;
  get isValid(): boolean;
  get isActionRequired(): boolean;
}

class DocumentVerification {
  protected readonly images: ImageVerification[];

  constructor(protected readonly document: DocumentVerificationType) {
    this.images = document.images.map((verification) => new ImageVerification(verification));
  }

  get id() {
    return this.document.id;
  }

  get prototypeName() {
    return this.document.verificationDocumentType.name;
  }

  get data() {
    return this.document;
  }

  get isValid() {
    return [VerificationStatusId.PendingVerification, VerificationStatusId.Verified].includes(
      this.data.verificationStatus.id,
    );
  }

  get isActionRequired() {
    return [
      VerificationStatusId.UserActionRequired,
      VerificationStatusId.UserAbandonedVerification,
      VerificationStatusId.DeclinedByManager,
    ].includes(this.data.verificationStatus.id);
  }
}

class IdentityDocument extends DocumentVerification implements DocumentVerificationImpl<DocumentCategory.Identity> {
  public readonly type = DocumentCategory.Identity;
}

class MedicalDocument extends DocumentVerification implements DocumentVerificationImpl<DocumentCategory.Medical> {
  public readonly type = DocumentCategory.Medical;
}

class AdditionalDocument extends DocumentVerification implements DocumentVerificationImpl<DocumentCategory.Additional> {
  public readonly type = DocumentCategory.Additional;
}

export { IdentityDocument, MedicalDocument, AdditionalDocument };
export type { DocumentVerificationImpl };
