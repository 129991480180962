import { merge } from '@swe/shared/utils/object';

type FormatPointsOptions = {
  signed?: boolean;
  transaction?: boolean;
};

const formatPoints = (amount: number, options?: FormatPointsOptions) => {
  const { signed, transaction } = merge({ signed: true, transaction: false }, options);

  const isNegative = amount < 0;
  const sign = signed ? (isNegative ? '−\u00A0' : transaction ? '+\u00A0' : '') : '';
  const absValue = Math.abs(amount);

  return `${sign}${absValue}\u00A0pts.`;
};

export { formatPoints };
