import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { getTokenVerticalValue } from '@swe/shared/ui-kit/theme/provider/helpers';

import cx from 'clsx';

import styles from './styles.module.scss';

import { ProductBadges } from 'common/components/product-card/components/badges';
import { ProductImage } from 'common/components/product-card/components/image';
import { ProductPrice } from 'common/components/product-card/components/price';
import { ProductTier } from 'common/components/product-card/components/product-tier';
import { PromoBadge, PromoName } from 'common/components/product-card/components/promo';
import { Properties } from 'common/components/product-card/components/properties';
import { ProductTitle } from 'common/components/product-card/components/title';
import { Wrapper } from 'common/components/product-card/components/wrapper';
import { CommonCardProps } from 'common/components/product-card/types';
import { prepareProductForCard, useBadges } from 'common/use-cases/product-card';
import { getProductImageAlt } from 'entities/common/seo';
import { getProductVariantNextTier, getProductVariantPrice } from 'entities/product/product';

type ProductCardProps = CommonCardProps & { width: number };

const ProductCard = ({
  className,
  externalId,
  product,
  variant: outerVariant,
  qtyInCart,
  totalQtyInCart,
  addToCart,
  unavailable,
  width,
  lazy,
  open,
  manyPromosTitle,
  badgePlacement = 'over-the-image',
}: ProductCardProps) => {
  const theme = useTheme();

  const { variant, isOutOfStock, images, labTests, promos, productName, hasPriceFrom } = prepareProductForCard(
    product,
    outerVariant,
  );
  const [price, oldPrice] = getProductVariantPrice(variant, qtyInCart);
  const nextTier = getProductVariantNextTier(variant, qtyInCart);
  const badges = useBadges(product, variant, false);

  return (
    <Wrapper
      open={open}
      className={cx(styles.root, className)}
      product={product}
      variant={variant}
      externalId={externalId}
      unavailable={unavailable}
    >
      <ProductImage
        images={images}
        alt={getProductImageAlt(product)}
        width={width - getTokenVerticalValue(theme.productCard.borderWidth)}
        lazy={lazy}
      >
        {badgePlacement === 'over-the-image' && (
          <ProductBadges
            className={cx(styles.badges, styles.badges_over, totalQtyInCart > 0 && styles.badges_hidden)}
            badges={badges}
          />
        )}
        {addToCart && !unavailable && <div className={styles.addToCart}>{addToCart}</div>}
        {theme.productCard.badge.promo.isVisible && (
          <PromoBadge
            promos={promos}
            className={styles.discount}
          />
        )}
      </ProductImage>
      <section className={styles.info}>
        {badgePlacement === 'under-the-image' && (
          <ProductBadges
            className={cx(styles.badges, styles.badges_under, totalQtyInCart > 0 && styles.badges_hidden)}
            badges={badges}
          />
        )}
        <ProductTitle
          name={productName}
          brand={product.brand?.name}
          category={product.category?.name}
        />
        <Properties
          variants={product.variants}
          potency={labTests}
        />
      </section>
      <section className={styles.bottom}>
        <div className={styles.amount}>
          {nextTier && <ProductTier tier={nextTier} />}
          <PromoName
            promos={promos}
            manyPromosTitle={manyPromosTitle}
          />
          <ProductPrice
            from={hasPriceFrom}
            price={price}
            oldPrice={oldPrice}
            isOutOfStock={isOutOfStock}
          />
        </div>
      </section>
    </Wrapper>
  );
};

export type { ProductCardProps };
export { ProductCard };
