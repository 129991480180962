import { usePersistState } from '@swe/shared/providers/persist-state';
import { AlertSize, Alert, AlertColor } from '@swe/shared/ui-kit/components/alert';
import { DiscountIcon } from '@swe/shared/ui-kit/components/icon';
import Text from '@swe/shared/ui-kit/components/text';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName, ComponentHasColor, ComponentHasSize } from '@swe/shared/ui-kit/types/common-props';
import { addMonths, isFuture } from '@swe/shared/utils/date';

import React, { useCallback } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import getReferralProgram from 'endpoints/profile/referral-program/get-referral-program';
import { getDiscountAmountLabel } from 'entities/profile/referral-program';

type ReferralBannerProps = ComponentHasClassName &
  ComponentHasSize<AlertSize> &
  ComponentHasColor<AlertColor> & {
    closable?: boolean;
    onClick?: () => void;
  };

const ReferralBanner = ({ closable, className, size = 'sm', color, onClick }: ReferralBannerProps) => {
  const { isReferralProgramEnabled, isReferralBannerEnabled } = useStoreConfig();
  const { data, isLoading } = getReferralProgram.useRequest();
  const navigate = useRouterNavigate();
  const [referralBannerHiddenUntil, setReferralBannerHiddenUntil] = usePersistState<string | null>(
    'local',
    'Common/isReferralBannerVisible',
    null,
  );
  const isReferralVisible = referralBannerHiddenUntil
    ? !isFuture(new Date(Date.parse(referralBannerHiddenUntil)))
    : true;

  const handleClick = useCallback(() => {
    onClick?.();
    return navigate(Routes.ProfileReferralProgram);
  }, [onClick, navigate]);

  const { isGuest } = useGuest();

  if (
    isGuest ||
    !(isReferralProgramEnabled && isReferralBannerEnabled) ||
    (closable && !isReferralVisible) ||
    isLoading
  ) {
    return null;
  }

  const { referrerDiscountType, referrerDiscountAmount } = data ?? {};

  return (
    <Alert
      color={color}
      className={className}
      size={size}
      icon={DiscountIcon}
      onClose={closable ? () => setReferralBannerHiddenUntil(addMonths(new Date(), 1).toISOString()) : undefined}
      onClick={handleClick}
    >
      <Text size="lg">{`Refer Friends - Get ${getDiscountAmountLabel(
        referrerDiscountType,
        referrerDiscountAmount,
      )} Off`}</Text>
    </Alert>
  );
};

const ThemedReferralBanner = (props: Omit<ReferralBannerProps, 'color'>) => {
  const theme = useTheme();
  return (
    <PatternThemeProvider name="header">
      <ReferralBanner
        {...props}
        color={theme.header.alert as AlertColor}
      />
    </PatternThemeProvider>
  );
};

export { ThemedReferralBanner as ReferralBanner };
export default ThemedReferralBanner;
