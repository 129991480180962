import { Handler, useDrag } from '@use-gesture/react';
import cn from 'clsx';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState, KeyboardEvent, useMemo, forwardRef } from 'react';

import { useResize } from '@swe/shared/hooks';

import { Input } from '@swe/shared/ui-kit/components/form/input';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { Text } from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { isKeyPressed } from '@swe/shared/utils/keyboard';

import styles from './styles.module.scss';

type Value = [number, number];

type Format = 'dollar' | 'number' | 'percent';

type PropsFormatDollarNumber = {
  format?: 'dollar' | 'number' | 'percent';
  min: number;
  max: number;
};

type PropsFormatPercent = {
  format?: 'percent';
  min: never;
  max: never;
};

type PropsFormat = PropsFormatDollarNumber | PropsFormatPercent;

type Pointer = 'left' | 'right';

const hasMinMax = (props: PropsFormat): props is PropsFormatDollarNumber =>
  props.format ? ['dollar', 'number'].includes(props.format) : false;

const getMask = (format: Format) => {
  switch (format) {
    case 'number':
      return 'num';
    case 'dollar':
      return '$num';
    case 'percent':
      return 'num%';
  }
};

export type RangeProps = {
  label?: string | boolean;
  step?: number;
} & PropsFormat &
  FormControl<Value> &
  ComponentHasClassName;

const Range = forwardRef<FormControlRef, RangeProps>(
  ({ onChange, value, className, label, step = 1, ...props }, outerRef) => {
    const pointerRef = useRef<HTMLDivElement>(null);
    const trackRef = useRef<HTMLDivElement>(null);
    const scaleRef = useRef<HTMLDivElement>(null);

    const [innerValue, setInnerValue] = useState(value || []);
    const [width, setWidth] = useState<number | null>(null);
    const calcWidth = useCallback(() => {
      if (trackRef.current && trackRef.current.offsetParent !== null) {
        setWidth(trackRef.current.offsetWidth);
      }
    }, []);

    useResize(calcWidth);
    useEffect(calcWidth, [calcWidth]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDebounced = useCallback(debounce(onChange || (() => {}), 500), [onChange]);

    let localMin = 0;
    let localMax = 100;

    const hasMinMaxProp = hasMinMax(props);

    if (hasMinMaxProp) {
      const { max, min } = props;
      localMin = min;
      localMax = max;
    }

    const scale = localMax - localMin;

    const steps: number[] = Array(Math.round(scale / step))
      .fill(1)
      .map((_, index) => index * step + localMin);

    steps.push(localMax);

    const findNearest = useCallback(
      (value: number) => {
        for (let i = 0; i < steps.length; i += 1) {
          if (value >= steps[i] && value <= steps[i + 1]) {
            if (value >= steps[i] + step / 2) {
              return steps[i + 1];
            }
            return steps[i];
          }
        }
        if (value <= steps[0]) {
          return steps[0];
        }
        return steps[steps.length - 1];
      },
      [step, steps],
    );

    const [leftState, setLeftState] = useState(innerValue[0] !== undefined ? innerValue[0] : localMin);
    const [rightState, setRightState] = useState(innerValue[1] !== undefined ? innerValue[1] : localMax);

    useEffect(() => {
      setLeftState(findNearest(innerValue[0] !== undefined ? innerValue[0] : localMin) - localMin);
      setRightState(findNearest(innerValue[1] !== undefined ? innerValue[1] : localMax) - localMin);
    }, [findNearest, localMax, localMin, innerValue]);

    const trimByLimits = useCallback(
      (pointer: Pointer, scaleValue: number) =>
        Math.max(Math.min(scaleValue, pointer === 'left' ? rightState : scale), pointer === 'left' ? 0 : leftState),
      [leftState, rightState, scale],
    );

    const getNormalizedValue = useCallback(
      (pointer: Pointer, scaleValue: number) => {
        const localValue = findNearest(trimByLimits(pointer, scaleValue) + localMin);
        let res: [number, number] = [localValue, rightState + localMin];

        if (pointer === 'right') {
          res = [leftState + localMin, localValue];
        }

        return res;
      },
      [findNearest, leftState, localMin, rightState, trimByLimits],
    );

    const localOnChange = useCallback(
      (pointer: Pointer, scaleValue: number) => {
        const normalizedValue = getNormalizedValue(pointer, scaleValue);
        setInnerValue(normalizedValue);
      },
      [getNormalizedValue, setInnerValue],
    );

    const [dragging, setDragging] = useState(false);
    const handleChange = useCallback(
      (pointer: Pointer, _scaleValue: number) => {
        const values = getNormalizedValue(pointer, _scaleValue);
        onChangeDebounced(values);
      },
      [getNormalizedValue, onChangeDebounced],
    );

    const cb = useCallback<(p: Pointer) => Handler<'drag'>>(
      (pointer: Pointer) => {
        return ({ dragging, movement: [x], memo, last, first }) => {
          let prevValue = memo;

          if (first && width === null) {
            calcWidth();
          }

          if (width === null) {
            return;
          }

          const state = pointer === 'left' ? leftState : rightState;

          if (prevValue === undefined) {
            prevValue = (state / scale) * width;
          }

          const scaleValue = trimByLimits(pointer, ((x + prevValue) / width) * scale);

          if (dragging) {
            localOnChange(pointer, scaleValue);
            setDragging(true);
          }

          if (last) {
            handleChange(pointer, scaleValue);
            setDragging(false);
          }
          return prevValue;
        };
      },
      [calcWidth, handleChange, leftState, localOnChange, rightState, scale, trimByLimits, width],
    );

    const leftBind = useDrag(cb('left'));
    const rightBind = useDrag(cb('right'));

    const onClickScale = useCallback(
      (e: { clientX: number }) => {
        if (width === null) {
          calcWidth();
        }

        if (scaleRef.current && pointerRef.current && width !== null) {
          const { left } = scaleRef.current.getBoundingClientRect();
          const pointerSize = pointerRef.current.offsetWidth;
          const position = Math.max(Math.min(e.clientX - left - pointerSize / 2, width), 0);
          const newValue = (position / width) * scale;

          if (
            Math.abs(newValue - leftState) < Math.abs(newValue - rightState) ||
            (newValue < leftState && newValue < rightState)
          ) {
            localOnChange('left', newValue);
            handleChange('left', newValue);
          } else {
            localOnChange('right', newValue);
            handleChange('right', newValue);
          }
        }
      },
      [calcWidth, handleChange, leftState, localOnChange, rightState, scale, width],
    );

    const pointerClick = useCallback((e: { stopPropagation(): void }) => {
      e.stopPropagation();
    }, []);

    const onChangeHandler = useCallback(
      (pointer: Pointer, scaleValue: number) => {
        if (Number.isNaN(scaleValue)) {
          return;
        }
        localOnChange(pointer, scaleValue);
        handleChange(pointer, scaleValue);
      },
      [handleChange, localOnChange],
    );

    const keyUpHandler = useCallback(
      (e: KeyboardEvent<HTMLInputElement>, p: Pointer = 'left') => {
        if (isKeyPressed(e, { key: ['ArrowUp', 'ArrowDown'] })) {
          e.preventDefault();
          const v = e.key === 'ArrowDown' ? step * -1 : step;
          if (p === 'left') {
            localOnChange(p, leftState + v);
            handleChange(p, leftState + v);
          } else {
            localOnChange(p, rightState + v);
            handleChange(p, rightState + v);
          }
        }
      },
      [handleChange, leftState, localOnChange, rightState, step],
    );

    const [inputKeys, setInputKeys] = useState([1, 1]);

    const onBlurHandler = useCallback(
      (pointer: Pointer) => {
        const prev = [...inputKeys];
        prev[pointer === 'left' ? 0 : 1]++;
        setInputKeys(prev);
      },
      [inputKeys],
    );

    const pointerPercentLeft = useMemo(() => {
      return (leftState / scale) * 100;
    }, [leftState, scale]);
    const pointerPercentRight = useMemo(() => {
      return (rightState / scale) * 100;
    }, [rightState, scale]);

    const handleLeftChange = useCallback(
      (v: string) => onChangeHandler('left', parseFloat(v) - localMin),
      [localMin, onChangeHandler],
    );
    const handleRightChange = useCallback(
      (v: string) => onChangeHandler('right', parseFloat(v) - localMin),
      [localMin, onChangeHandler],
    );
    const handleKeyUpLeft = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => keyUpHandler(e, 'left'),
      [keyUpHandler],
    );
    const handleKeyUpRight = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => keyUpHandler(e, 'right'),
      [keyUpHandler],
    );
    const handleBlurLeft = useCallback(() => onBlurHandler('left'), [onBlurHandler]);
    const handleBlurRight = useCallback(() => onBlurHandler('right'), [onBlurHandler]);

    const mask = useMemo(
      () =>
        props.format
          ? {
              mask: getMask(props.format),
              unmask: true,
              lazy: false,
              blocks: {
                num: {
                  mask: Number,
                  min: localMin,
                  max: localMax,
                },
              },
            }
          : undefined,
      [localMax, localMin, props.format],
    );

    useEffect(() => {
      if (isEqual(value, innerValue)) return;
      setInnerValue(value || []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <div className={cn(className, styles.root)}>
        {label !== false && (
          <Text
            className={styles.label}
            variant="control"
            size="lg"
          >
            {label}
          </Text>
        )}
        <div className={styles.controlPanel}>
          <Input
            ref={outerRef}
            key={`left${inputKeys[0]}`}
            className={styles.control}
            name="from"
            value={String(leftState + localMin)}
            label={false}
            size="md"
            customMask={mask}
            isClearable={false}
            onBlur={handleBlurLeft}
            onChange={handleLeftChange}
            onKeyUp={handleKeyUpLeft}
            staticNote={false}
            ariaLabel="From"
          />
          <Input
            key={`right${inputKeys[1]}`}
            className={styles.control}
            name="to"
            value={String(rightState + localMin)}
            label={false}
            size="md"
            customMask={mask}
            isClearable={false}
            onBlur={handleBlurRight}
            onChange={handleRightChange}
            onKeyUp={handleKeyUpRight}
            staticNote={false}
            ariaLabel="To"
            textAlign="right"
          />
        </div>
        <div
          className={styles.scale}
          ref={scaleRef}
          onClick={onClickScale}
        >
          <div
            className={styles.track}
            ref={trackRef}
          >
            <div
              className={cn([styles.scaleFilled, dragging && styles._noTransition])}
              style={{
                left: `${pointerPercentLeft}%`,
                width: `${pointerPercentRight - pointerPercentLeft}%`,
              }}
            />
            <div
              ref={pointerRef}
              tabIndex={0}
              className={cn([styles.pointer, dragging && styles._noTransition])}
              onClick={pointerClick}
              {...leftBind()}
              style={{
                left: `${pointerPercentLeft}%`,
              }}
            />
            <div
              tabIndex={0}
              className={cn([styles.pointer, dragging && styles._noTransition])}
              onClick={pointerClick}
              {...rightBind()}
              style={{
                left: `${pointerPercentRight}%`,
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default Range;
export { Range };
