import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Product } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = Product;
type Params = {
  saleType?: StoreSaleType;
  productId: Product['id'];
};

const NAME = '/Products/GetProduct';
const GetProductEndpoint = createModernEndpoint<Params, Data>(NAME);

export { NAME };
export default GetProductEndpoint;
export { GetProductEndpoint };
