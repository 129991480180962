import { ServerResponse } from 'http';

import { serialize, parse, CookieSerializeOptions } from 'cookie';

import { isSSR } from '@swe/shared/utils/environment';

interface DefaultOptions extends CookieSerializeOptions {
  res?: ServerResponse | Response | any;
  cookie?: string | Record<string, string | undefined>;
}

type TmpCookiesObj = { [key: string]: string } | Partial<{ [key: string]: string }>;
type CookieValueTypes = string | undefined;

const stringify = (value: any) => {
  try {
    if (typeof value === 'string') {
      return value;
    }
    const stringifiedValue = JSON.stringify(value);
    return stringifiedValue;
  } catch (e) {
    return value;
  }
};

const decode = (str: string): string => {
  if (!str) return str;

  return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
};

const getCookies = (options?: DefaultOptions): TmpCookiesObj => {
  const ssrCookie = options?.cookie;
  if (isSSR) {
    if (typeof ssrCookie === 'object') return ssrCookie;
    if (typeof ssrCookie === 'string') return parse(ssrCookie);
    return {};
  }

  const _cookies: TmpCookiesObj = {};
  const documentCookies = document.cookie ? document.cookie.split('; ') : [];

  for (let i = 0, len = documentCookies.length; i < len; i++) {
    const cookieParts = documentCookies[i].split('=');

    const _cookie = cookieParts.slice(1).join('=');
    const name = cookieParts[0];

    _cookies[name] = _cookie;
  }

  return _cookies;
};

const getCookie = (key: string, options?: DefaultOptions): CookieValueTypes => {
  const _cookies = getCookies(options);
  const value = _cookies[key];
  if (value === undefined) return undefined;
  return decode(value);
};

const setCookie = (key: string, data: any, options?: DefaultOptions): void => {
  let _cookieOptions: any;
  let _res;
  if (options) {
    const { res, ..._options } = options as DefaultOptions;
    _res = res;
    _cookieOptions = _options;
  }

  const cookieStr = serialize(key, stringify(data), { path: '/', ..._cookieOptions });
  if (isSSR) {
    if (_res) {
      let currentCookies = _res.getHeader('Set-Cookie');

      if (!Array.isArray(currentCookies)) {
        currentCookies = !currentCookies ? [] : [String(currentCookies)];
      }
      _res.setHeader('Set-Cookie', currentCookies.concat(cookieStr));
    }
  } else {
    document.cookie = cookieStr;
  }
};

const deleteCookie = (key: string, options?: DefaultOptions): void => {
  return setCookie(key, '', { ...options, maxAge: -1 });
};

const hasCookie = (key: string, options?: DefaultOptions): boolean => {
  if (!key) return false;

  const cookie = getCookies(options);
  return Object.prototype.hasOwnProperty.call(cookie, key);
};

export { getCookie, getCookies, setCookie, deleteCookie, hasCookie };
