import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { RadioGroupRadio, RadioGroupRadioProps } from '@swe/shared/ui-kit/components/form/radio-group/variants/radio';
import RadioGroupSection, {
  RadioGroupSectionProps,
} from '@swe/shared/ui-kit/components/form/radio-group/variants/section';
import RadioGroupTag, { RadioGroupTagProps } from '@swe/shared/ui-kit/components/form/radio-group/variants/tag';
import RadioGroupTagFilterable, {
  RadioGroupTagFilterableProps,
} from '@swe/shared/ui-kit/components/form/radio-group/variants/tag-filterable';
import { FormControlRef } from '@swe/shared/ui-kit/components/form/types';

type RadioGroupVariantTagProps<VT = string> = {
  variant: 'tag';
} & RadioGroupTagProps<VT>;

type RadioGroupVariantTagFilterableProps<VT = string> = {
  variant: 'tag-filterable';
} & RadioGroupTagFilterableProps<VT>;

type RadioGroupVariantSectionProps<VT = string> = {
  variant: 'section';
} & RadioGroupSectionProps<VT>;

type RadioGroupVariantRadioProps<VT = string> = {
  variant: 'radio';
} & RadioGroupRadioProps<VT>;

type RadioGroupProps<VT = string> =
  | RadioGroupVariantTagProps<VT>
  | RadioGroupVariantTagFilterableProps<VT>
  | RadioGroupVariantSectionProps<VT>
  | RadioGroupVariantRadioProps<VT>;

const _RadioGroup = <VT,>(
  { variant = 'tag', className, ...props }: RadioGroupProps<VT>,
  ref: ForwardedRef<FormControlRef>,
) => {
  const rootElRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    focus: () => rootElRef.current?.focus(),
  }));

  const renderedVariant = useMemo(() => {
    switch (variant) {
      default:
      case 'radio':
        return <RadioGroupRadio<VT | null> {...(props as RadioGroupRadioProps<VT | null>)} />;
      case 'tag':
        return <RadioGroupTag<VT | null> {...(props as RadioGroupTagProps<VT | null>)} />;
      case 'tag-filterable':
        return <RadioGroupTagFilterable<VT | null> {...(props as RadioGroupTagFilterableProps<VT | null>)} />;
      case 'section':
        return <RadioGroupSection<VT> {...(props as RadioGroupSectionProps<VT>)} />;
    }
  }, [variant, props]);

  return (
    <div
      className={className}
      ref={rootElRef}
      tabIndex={variant === 'section' ? undefined : 0}
    >
      {renderedVariant}
    </div>
  );
};

const RadioGroup = forwardRef(_RadioGroup) as <VT>(props: RadioGroupProps<VT>) => ReturnType<typeof _RadioGroup>;

export * from './types';
export type { RadioGroupProps };
export { RadioGroup };
export default RadioGroup;
