import {
  Placement,
  useFloating,
  offset as floatingOffset,
  shift,
  flip,
  hide,
  autoUpdate,
  useHover,
  useInteractions,
  useDismiss,
  safePolygon,
  useClick,
  useFocus,
  arrow as arrowMiddleware,
  FloatingArrow,
  FloatingArrowProps,
  DetectOverflowOptions,
  Strategy,
} from '@floating-ui/react';
import cx from 'clsx';
import { forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useBreakpoint } from '@swe/shared/tools/media';
import { getShiftValue } from '@swe/shared/ui-kit/components/popover/config';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName, Size } from '@swe/shared/ui-kit/types/common-props';

import { isSSR } from '@swe/shared/utils/environment';

import { TabbableContent } from './components/tabbable-content';
import styles from './styles.module.scss';

type UsePopoverProps = {
  placement?: Placement;
  offset?: Size;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  trigger?: 'click' | 'hover' | 'manual' | 'focus';
  sameWidth?: boolean;
  arrow?: Omit<FloatingArrowProps, 'context' | 'ref'>;
  hidePadding?: DetectOverflowOptions['padding'];
  shiftCrossAxis?: boolean;
  disableFlip?: boolean;
  strategy?: Strategy;
  onForceClose?: () => void;
};

const usePopover = ({
  placement,
  offset,
  isOpen: _isOpen,
  onToggle,
  trigger = 'click',
  sameWidth,
  arrow,
  hidePadding,
  shiftCrossAxis = false,
  disableFlip = false,
  strategy: _strategy = 'absolute',
  onForceClose,
}: UsePopoverProps) => {
  const theme = useTheme();
  const [isOpen, _setIsOpen] = useState(false);
  const isInnerOpen = _isOpen ?? isOpen;
  const onOpenChange = useCallback(
    (val: boolean) => {
      _setIsOpen(val);
      onToggle?.(val);
    },
    [onToggle],
  );
  const arrowRef = useRef<SVGSVGElement>(null);

  const { mobile } = useBreakpoint();

  const close = useCallback(() => onOpenChange(false), [onOpenChange]);
  const open = useCallback(() => onOpenChange(true), [onOpenChange]);

  const offsetValue =
    offset || arrow
      ? (offset ? parseInt(theme.spacing.scale[offset], 10) : 0) + (arrow ? arrow.height ?? 7 : 0)
      : undefined;

  const middleware = useMemo(
    () => [
      offsetValue ? floatingOffset(offsetValue) : undefined,
      ...(disableFlip ? [] : [flip()]),
      shift({ padding: getShiftValue(mobile), crossAxis: shiftCrossAxis }),
      arrow
        ? arrowMiddleware({
            element: arrowRef,
            padding: parseInt(theme.borderRadius.scale.md, 10),
          })
        : undefined,
      hide({ padding: hidePadding, boundary: !isSSR ? document.body : undefined }),
    ],
    [arrow, disableFlip, hidePadding, mobile, offsetValue, shiftCrossAxis, theme.borderRadius.scale.md],
  );
  const {
    x,
    y,
    refs,
    update: _update,
    context,
    strategy,
    middlewareData: { hide: hideData },
  } = useFloating({
    open: isInnerOpen,
    onOpenChange,
    placement,
    strategy: _strategy,
    middleware,
    whileElementsMounted: (...args) => {
      if (sameWidth && refs.floating.current && refs.reference.current) {
        refs.floating.current.style.width = `${(refs.reference.current as HTMLElement)?.offsetWidth}px`;
      }
      return autoUpdate(...args);
    },
  });

  useEffect(() => {
    if (hideData?.referenceHidden) {
      onForceClose?.();
      close();
    }
  }, [close, hideData?.referenceHidden, onForceClose]);

  const update = useCallback(() => {
    if (sameWidth && refs.floating.current && refs.reference.current) {
      refs.floating.current.style.width = `${(refs.reference.current as HTMLElement)?.offsetWidth}px`;
    }
    _update();
  }, [sameWidth, refs.floating, refs.reference, _update]);

  const hover = useHover(context, { enabled: trigger === 'hover', handleClose: safePolygon() });
  const click = useClick(context, { enabled: trigger === 'click' });
  const dismiss = useDismiss(context, {
    enabled: trigger === 'click',
  });
  const focus = useFocus(context, { enabled: trigger !== 'manual' && trigger !== 'click', keyboardOnly: false });

  const { getReferenceProps, getFloatingProps } = useInteractions([focus, click, dismiss, hover]);

  return {
    referenceProps: {
      ...getReferenceProps(),
      ref: refs.setReference,
    },
    popoverProps: {
      ...getFloatingProps(),
      ref: refs.setFloating,
      style: {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      },
    },
    update,
    isOpen: isInnerOpen,
    open,
    close,
    arrow: arrow ? (
      <FloatingArrow
        ref={arrowRef}
        context={context}
        {...arrow}
      />
    ) : undefined,
  };
};

type PopoverContext = Omit<ReturnType<typeof usePopover>, 'referenceProps' | 'popoverProps'>;

type PopoverProps = ComponentHasClassName &
  UsePopoverProps & {
    content: ReactNode | ((context: PopoverContext) => ReactNode);
    children: ReactNode | ((context: PopoverContext) => ReactNode);
    block?: boolean;
    tabbable?: boolean;
  };

const Popover = forwardRef<PopoverContext, PopoverProps>(
  ({ content, children, className, block, tabbable, ...props }: PopoverProps, ref) => {
    const { referenceProps, popoverProps, ...context } = usePopover(props);

    useImperativeHandle(ref, () => context, [context]);

    let popoverContent: ReactNode;

    if (context.isOpen) {
      popoverContent = (
        <div {...popoverProps}>
          {context.arrow}
          {typeof content === 'function' ? content(context) : content}
        </div>
      );
      if (tabbable) {
        popoverContent = <TabbableContent onLeave={context.close}>{popoverContent}</TabbableContent>;
      }
    }

    return (
      <div
        className={cx(className, styles.root, { [styles._block]: block })}
        {...referenceProps}
      >
        {typeof children === 'function' ? children(context) : children}
        {popoverContent && <Portal>{popoverContent}</Portal>}
      </div>
    );
  },
);

export type { PopoverContext, UsePopoverProps };
export { usePopover, Popover };
