import Button from '@swe/shared/ui-kit/components/button';
import { useControls } from '@swe/shared/ui-kit/components/carousel-v2/core/use-controls';
import { useProgress } from '@swe/shared/ui-kit/components/carousel-v2/core/use-progress';

import { ChevronLeftIcon, ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import styles from './styles.module.scss';

type ControlsProps = {
  offset: number;
};
const Controls = ({ offset }: ControlsProps) => {
  const { canScrollForward, canScrollBackward, prev, next } = useControls();
  const { scrollSnaps } = useProgress();
  const { spacing } = useTheme();
  const controlPosition = `calc(${spacing.scale.md} + ${offset}px)`;

  if (scrollSnaps.length === 1) {
    return null;
  }

  return (
    <>
      <Button
        style={{
          left: controlPosition,
        }}
        className={styles.control}
        size="sm"
        color="light"
        onClick={prev}
        icon={ChevronLeftIcon}
        disabled={!canScrollBackward}
        ariaLabel="Previous"
      />
      <Button
        style={{
          right: controlPosition,
        }}
        className={styles.control}
        size="sm"
        color="light"
        onClick={next}
        icon={ChevronRightIcon}
        disabled={!canScrollForward}
        ariaLabel="Next"
      />
    </>
  );
};

export { Controls };
