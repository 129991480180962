import { useCallback } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { ShopInfo } from 'entities/shop/info';

const useNavigateToStore = (store?: ShopInfo) => {
  const currentStore = useStoreConfig();

  const navigate = useCallback(async () => {
    if (!store?.id || store.id === currentStore.id) return;

    window.location.href = store.url;
  }, [store?.id, store?.url, currentStore.id]);

  return {
    navigate,
  };
};

export { useNavigateToStore };
