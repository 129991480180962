import { usePersistState } from '@swe/shared/providers/persist-state';
import { useMatchRoute } from '@swe/shared/providers/router/helpers';

import { useCallback } from 'react';

import { AgeRestrictionModal } from 'common/containers/age-restriction-modal';
import { useStoreConfig } from 'common/providers/config';
import { Routes } from 'common/router/constants';

export const useAgeChecked = () => {
  const { isAgeCheckRequired = true } = useStoreConfig();

  return usePersistState<boolean>('cookies', 'Common/isAgeChecked', !isAgeCheckRequired);
};

const AgeRestriction = () => {
  const [isAgeChecked, setAgeChecked] = useAgeChecked();

  const handleConfirm = useCallback(() => {
    setAgeChecked(true);
  }, [setAgeChecked]);

  const isAgreementsPage = useMatchRoute(Routes.Agreements);

  if (isAgreementsPage) {
    return null;
  }

  return (
    <AgeRestrictionModal
      visible={!isAgeChecked}
      onConfirm={handleConfirm}
    />
  );
};

export { AgeRestriction };
export default AgeRestriction;
