import {
  RouterEventsPayload,
  RouterImpl,
  RouterNavigate,
  RouterProvider as SharedRouterProvider,
  RouteTransitionOptions,
  useRouter as useSharedRouter,
  useRouterAsPath as useSharedRouterAsPath,
  useRouterEvents,
  useRouterHistory as useSharedRouterHistory,
  useRouterNavigate as useSharedRouterNavigate,
  useRouterPathname as useSharedRouterPathname,
  useRouterQuery as useSharedRouterQuery,
} from '@swe/shared/providers/router';
import { Route, RoutePath, RouteQuery } from '@swe/shared/providers/router/constants';

import { normalizeRoute as _normalizeRoute, stringifyRouteUrl } from '@swe/shared/providers/router/utils';
import { EventEmitter } from '@swe/shared/tools/event-emitter';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isEqual } from '@swe/shared/utils/object';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  matchRoutes,
  RouteObject,
  useHref,
  useInRouterContext,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Routes } from '@swe/shop-ui/common/router/constants';

import { convertReactToSharedQuery, convertReactToSharedRoute } from '@swe/shop-ui/common/router/utils';

const getRouteFromMatches = <R extends RoutePath = RoutePath>(
  routes: RouteObject[],
  pathname: RoutePath,
  notFoundRoute: R,
): R => {
  const matches = matchRoutes(routes, pathname);
  if (matches?.length) {
    const path = matches[matches.length - 1].route.path;
    if (path === '*') {
      return notFoundRoute;
    }
    return convertReactToSharedRoute(path as RoutePath) as R;
  }
  return notFoundRoute;
};

const useRouterContext = (routes: RouteObject[]): RouterImpl<Routes> => {
  if (!useInRouterContext()) {
    throw new Error('You must use Shared router implementation in React Router Context');
  }
  const _location = useLocation();
  const _params = useParams();
  const [_searchParams] = useSearchParams();
  const _navigate = useNavigate();
  const basePath = useHref('/') as RoutePath;

  const query: RouteQuery = useMemo(() => {
    return convertReactToSharedQuery(_params, _searchParams);
  }, [_searchParams, _params]);
  const pathname = useMemo(
    () => getRouteFromMatches<Routes>(routes, _location.pathname as RoutePath, Routes.NotFound),
    [routes, _location.pathname],
  );
  const asPath = `${_location.pathname}${_location.search}${_location.hash}`;

  // console.log(basePath, pathname, asPath, query);

  const normalizeRoute = useCallback(
    <R extends Routes = Routes>(route: Route<R>) => _normalizeRoute(route, pathname, basePath),
    [pathname, basePath],
  );

  const buildHref = useCallback(
    <R extends Routes = Routes>(route: Route<R>): RoutePath => stringifyRouteUrl(normalizeRoute(route)),
    [normalizeRoute],
  );

  const lastRouteResult = useRef<Promise<boolean>>(Promise.resolve(true));

  const events = useMemo(() => new EventEmitter<RouterEventsPayload>(), []);
  const { state: navigationState } = useNavigation();

  // TODO: https://github.com/remix-run/react-router/pull/11521 in v7 we'll get rid of this
  useEffect(() => {
    if (navigationState === 'idle') {
      events.fire('routeChangeComplete', undefined);
    }
  }, [events, navigationState]);

  const currentRoute = useMemo(() => ({ pathname, query, basePath }), [pathname, query, basePath]);
  const navigate: RouterNavigate<Routes> = useCallback(
    async <R extends Routes>(url: Route<R>, options: RouteTransitionOptions = {}) => {
      if (typeof url === 'number') {
        _navigate(url);
        return true;
      }
      const normalizedRoute = normalizeRoute(url);
      if (isEqual(normalizedRoute, currentRoute)) {
        return lastRouteResult.current;
      }
      events.fire('routeChangeStart', buildHref(normalizedRoute));
      const result = new Promise<boolean>((resolve) => {
        events.once('routeChangeComplete', () => resolve(true));
      });
      _navigate(stringifyRouteUrl(normalizedRoute, true), {
        replace: options.replace,
        preventScrollReset: !(options.scroll ?? true),
      });
      return result;
    },
    [normalizeRoute, currentRoute, buildHref, events, _navigate],
  );

  return useMemo(
    () => ({
      pathname,
      asPath,
      query,
      navigate,
      basePath,
      buildHref,
      normalizeRoute,
      events,
    }),
    [pathname, asPath, query, navigate, basePath, buildHref, normalizeRoute, events],
  );
};

type RouterProviderProps = ComponentHasChildren & {
  routes: RouteObject[];
};

const RouterProvider = ({ children, routes }: RouterProviderProps) => {
  const ctx = useRouterContext(routes);

  return <SharedRouterProvider router={ctx}>{children}</SharedRouterProvider>;
};

const useRouterHelpers = () => useSharedRouter<Routes>();
const useRouterNavigate = () => useSharedRouterNavigate<Routes>();
const useRouterPathname = () => useSharedRouterPathname<Routes>();
const useRouterHistory = () => useSharedRouterHistory<Routes>();

export {
  useRouterHelpers,
  useRouterNavigate,
  useRouterPathname,
  useSharedRouterQuery as useRouterQuery,
  useSharedRouterAsPath as useRouterAsPath,
  useRouterHistory,
  useRouterEvents,
};
export default RouterProvider;
