import { useOnlyUpdateEffect } from '@swe/shared/hooks/use-did-update-effect';
import usePromiseQueue from '@swe/shared/hooks/use-promise-queue';

import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { productAnalyticsMap } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import {
  CartActionsContext,
  CartContext,
  CartItemsWithAvailabilityContext,
  CartMetaContext,
  CartPriceContext,
  CartQuantityContext,
  CartResidencyContext,
  CartStashContext,
  CartStateContext,
  CartUtilsContext,
} from 'common/providers/cart/context';
import { AddToCartMeta, CartItemWithAvailability, ProductAvailability, Stash } from 'common/providers/cart/types';
import {
  AddToCartOnError,
  AddToCartOnSuccess,
  OnBeforeSetLocalItem,
  useAddToCart,
} from 'common/providers/cart/use-cases/use-add-to-cart';
import { useGetCartRequest } from 'common/providers/cart/use-cases/use-get-cart-request';
import { useNotificationAffectedItems } from 'common/providers/cart/use-cases/use-notification-affected-items';
import { useNotificationChangeQuantity } from 'common/providers/cart/use-cases/use-notification-change-quantity';
import { useNotificationMismatchSaleType } from 'common/providers/cart/use-cases/use-notification-mismatch-saletype';
import { useNotificationNewItem } from 'common/providers/cart/use-cases/use-notification-new-item';
import { useQuantityStorage } from 'common/providers/cart/use-cases/use-quantity-storage';
import { isOutOfLimits, prepareItem, UNAVAILABLE_SALE_TYPE_TO_FILTER } from 'common/providers/cart/utils';
import { useCurrentUser } from 'common/providers/user';

import { useMedicalPurchaseConfirmation } from 'common/use-cases/use-medical-purchase-confirmation';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useResidency } from 'common/use-cases/use-residency';
import { useSaleType } from 'common/use-cases/use-sale-type';

import { CartEditEndpoint, CartEditParams } from 'endpoints/cart/edit';
import { Cart, CartItemDTO } from 'entities/cart/cart';
import { Order } from 'entities/common/orders';
import { Product, ProductSaleType, ProductVariant } from 'entities/product/product';

const CartProvider = ({ children }: PropsWithChildren) => {
  const { platformOs } = usePlatformOs();
  const { applyCartState } = useQuantityStorage();
  const { add: addToQueue, instance } = usePromiseQueue(useMemo(() => ({ concurrency: 1 }), []));
  const { saleType, isMedicalMenuEnabled } = useSaleType();
  const { user, isLoaded: isLoadedUser } = useCurrentUser();
  const {
    orderType,
    deliveryAddress,
    deliveryLocation,
    isLoading: isLoadingPickupDelivery,
  } = usePickupDeliverySettings();
  const { cart, mutate, isValidating, isLoading: isLoadingCart } = useGetCartRequest(addToQueue);
  useEffect(() => {
    applyCartState(cart);
  }, [cart, applyCartState]);
  const { pushEvent } = useAnalytics();
  const [stash, setStash] = useState<Stash>({});
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const isLoading = isLoadingCart || !isLoadedUser || isLoadingPickupDelivery;
  const isEmpty = isLoading ? false : !cart?.items.length;
  const isVirtual = !!cart && !cart.order;

  const isOutOfLimit = useMemo(() => isOutOfLimits(cart?.order?.limits ?? []), [cart?.order?.limits]);
  const price = useMemo(() => {
    return {
      total: cart?.subtotalAmount ?? cart?.total ?? 0,
      totalBeforePromo: cart?.totalBeforePromo ?? 0,
    };
  }, [cart]);
  const addToStash = useCallback((variantId: ProductVariant['id'], quantity: number) => {
    setStash((prev) => ({ ...prev, [variantId]: quantity }));
  }, []);
  const removeFromStash = useCallback((variantId: ProductVariant['id']) => {
    setStash((prev) => ({ ...prev, [variantId]: undefined }));
  }, []);

  const [unavailableItems, availableItems] = useMemo((): [CartItemWithAvailability[], CartItemWithAvailability[]] => {
    const orderMap: Record<Product['variants'][number]['id'], NonNullable<Order['items']>[number]> = isVirtual
      ? {}
      : (cart?.order?.items || []).reduce((acc, { variantId, ...rest }) => {
          return {
            ...acc,
            [variantId]: rest,
          };
        }, {});

    const items = cart?.items.map((item) => prepareItem(item, isVirtual, orderMap[item.variantId] || {}, user) || {});

    if (!items) {
      return [[], []];
    }

    const missingItems: CartItemWithAvailability[] = [];
    const existItems: CartItemWithAvailability[] = [];

    items.forEach(({ product, qty, variantId, reservedQty, availableQty }) => {
      const diff = qty - (reservedQty || 0);
      if (diff > 0) {
        missingItems.push({
          product,
          availability: ProductAvailability.OutOfStock,
          qty: diff,
          variantId,
          reservedQty,
          availableQty,
        });

        const rest = qty - diff;
        if (rest > 0) {
          existItems.push({
            product,
            availability: ProductAvailability.Available,
            qty: rest,
            variantId,
            reservedQty,
            availableQty,
          });
        }

        return;
      }

      if (isMedicalMenuEnabled && UNAVAILABLE_SALE_TYPE_TO_FILTER[saleType]?.(product)) {
        missingItems.push({
          product,
          availability:
            saleType === ProductSaleType.RECREATIONAL
              ? ProductAvailability.OnlyMedical
              : ProductAvailability.OnlyRecreation,
          qty: qty - diff,
          variantId,
          reservedQty,
          availableQty,
        });
        return;
      }

      existItems.push({
        product,
        availability: ProductAvailability.Available,
        qty,
        variantId,
        reservedQty,
        availableQty,
      });
    });

    return [missingItems, existItems];
  }, [cart?.items, cart?.order?.items, isMedicalMenuEnabled, isVirtual, saleType, user]);

  const availableItemsQuantity = useMemo(
    () => availableItems?.reduce((acc, { qty }) => acc + qty, 0) || 0,
    [availableItems],
  );

  const { addToCartNotification } = useNotificationNewItem(cart?.items || []);

  const sendAnalytics = useCallback(
    (data: Cart) => {
      if (Array.isArray(data?.items)) {
        pushEvent(AEventType.CART_CHANGE, {
          items: data.items.map(({ variantId, qty }) => ({ variantId, qty })),
        });
      }
    },
    [pushEvent],
  );

  const addToCartOnSuccess = useCallback(
    ({ newCart: cart, oldCart, firstVariantsId }: AddToCartOnSuccess) => {
      const findFn = ({ variantId: _variantId }: CartItemDTO) => firstVariantsId === _variantId;
      const newCartItem = cart.items.find(findFn);
      const oldItem = oldCart?.items.find(findFn);
      if (newCartItem && !oldItem) {
        addToCartNotification(newCartItem);
      }
      sendAnalytics(cart);
    },
    [addToCartNotification, sendAnalytics],
  );

  const { confirmMedicalPurchase } = useMedicalPurchaseConfirmation();

  const addToCartOnError = useCallback(
    (data: AddToCartOnError) => {
      if (data.error.subCode === 547) {
        void confirmMedicalPurchase();
      }
    },
    [confirmMedicalPurchase],
  );

  const { applyLocalAvailableItemsQuantity, setLocalAvailableItemsQuantity, setSkipNext } =
    useNotificationChangeQuantity({
      availableItemsQuantity,
      isLoadingCart: !isLoadedUser || isLoadingPickupDelivery || isLoading,
    });

  const onBeforeSetLocalItem = useCallback(
    ({ qty }: OnBeforeSetLocalItem) => {
      applyLocalAvailableItemsQuantity(qty);
    },
    [applyLocalAvailableItemsQuantity],
  );

  const isQueueIdle = useCallback(() => {
    return instance.size === 0 && instance.pending === 0;
  }, [instance.pending, instance.size]);

  const {
    addToCart: _addToCart,
    removeFromCart: _removeFromCart,
    isLoadingAdd,
    isLoadingRemove,
  } = useAddToCart({
    addToQueue,
    isQueueIdle,
    cart,
    mutateCart: mutate,
    onSuccess: addToCartOnSuccess,
    onError: addToCartOnError,
    onBeforeSetLocalItem,
  });

  const { prepareSaleType } = useNotificationMismatchSaleType({
    isValidatingCart: isValidating,
  });

  const sendGTMEvent = useCallback(
    (event: AEventType, item: CartItemDTO, meta?: AddToCartMeta) => {
      const { product, variantId, qty } = item;
      const idx = (cart?.items || []).findIndex(({ variantId: _variantId }) => _variantId === variantId);
      pushEvent(event, [
        productAnalyticsMap({
          product,
          cart,
          index: meta && meta.idx ? meta.idx : idx === -1 ? 0 : idx + 1,
          qtyInCart: qty,
          analyticalItemListId: meta ? `${meta.originId}` : 'cart',
          analyticalItemListName: meta ? meta.originName : 'cart',
        }),
      ]);
    },
    [cart, pushEvent],
  );

  const addToCart = useCallback(
    async (item: CartItemDTO, meta?: AddToCartMeta) => {
      const res = await prepareSaleType(item);
      if (res) {
        removeFromStash(item.product.variants[0].id);
        _addToCart(item);
        sendGTMEvent(AEventType.ADD_TO_CART, item, meta);
      }
    },
    [_addToCart, prepareSaleType, removeFromStash, sendGTMEvent],
  );

  const removeFromCart = useCallback(
    (item: CartItemDTO, meta?: AddToCartMeta) => {
      _removeFromCart(item);
      sendGTMEvent(AEventType.REMOVE_FROM_CART, item, meta);
    },
    [_removeFromCart, sendGTMEvent],
  );

  const getStashQuantity = useCallback((sku: VariantId) => stash[sku], [stash]);

  const update = useCallback(
    async (data: CartEditParams) => {
      try {
        setIsLoadingUpdate(true);
        const newCart = await CartEditEndpoint.request({
          ...data,
          saleType,
          platformOs,
        });
        await mutate(newCart, { revalidate: false });
        return true;
      } catch (e) {
        return false;
      } finally {
        setIsLoadingUpdate(false);
      }
    },
    [mutate, platformOs, saleType],
  );

  useEffect(() => {
    void update({ saleType, platformOs });
  }, [saleType, platformOs, update]);

  const updateResidency = useCallback(async (isResident: boolean) => update({ isResident }), [update]);

  const residency = useResidency(!!cart?.isResident, updateResidency);

  const skipNextCartUpdate = useRef(false);

  const setSkipNextCartUpdate = useCallback((f: boolean) => {
    skipNextCartUpdate.current = f;
  }, []);

  const cartInitiated = useRef(false);

  useEffect(() => {
    if (isLoadedUser && !isLoadingPickupDelivery && !cartInitiated.current && !isLoading) {
      cartInitiated.current = true;
    }
  }, [isLoadedUser, isLoading, isLoadingPickupDelivery]);

  useOnlyUpdateEffect(() => {
    if (cartInitiated.current && !skipNextCartUpdate.current) {
      setSkipNext(true);
      void mutate();
      skipNextCartUpdate.current = false;
    }
  }, [mutate, user?.email]);

  useOnlyUpdateEffect(() => {
    if (cartInitiated.current) {
      void mutate();
    }
  }, [orderType, deliveryAddress, deliveryLocation?.lng, deliveryLocation?.lat, mutate, isLoadingPickupDelivery]);

  useOnlyUpdateEffect(() => {
    if (cart && typeof cart.isResident !== 'undefined') {
      void residency.setIsResident(cart.isResident);
    }
  }, [cart]);

  const quantity = useMemo(() => cart?.items?.reduce((acc, { qty }) => acc + qty, 0) || 0, [cart?.items]);

  const hasMedicalProducts = useMemo(() => {
    return (cart?.items || []).some(({ product }) => product.variants[0].saleType === ProductSaleType.MEDICAL);
  }, [cart?.items]);

  useNotificationAffectedItems({
    unavailableItems,
    isLoadingCart: !isLoadedUser || isLoadingPickupDelivery || isLoading,
  });

  return (
    <CartContext.Provider value={cart}>
      <CartActionsContext.Provider value={useMemo(() => ({ addToCart, removeFromCart }), [addToCart, removeFromCart])}>
        <CartUtilsContext.Provider
          value={useMemo(
            () => ({
              mutate,
              update,
              setSkipNextCartUpdate,
              setLocalAvailableItemsQuantity,
            }),
            [mutate, setLocalAvailableItemsQuantity, setSkipNextCartUpdate, update],
          )}
        >
          <CartStateContext.Provider
            value={useMemo(
              () => ({
                isLoading,
                isLoadingUpdate,
                isValidating,
                isLoadingAdd,
                isLoadingRemove,
              }),
              [isLoading, isLoadingAdd, isLoadingRemove, isLoadingUpdate, isValidating],
            )}
          >
            <CartMetaContext.Provider
              value={useMemo(
                () => ({
                  isVirtual,
                  isEmpty,
                  hasMedicalProducts,
                  isOutOfLimit,
                }),
                [hasMedicalProducts, isEmpty, isOutOfLimit, isVirtual],
              )}
            >
              <CartItemsWithAvailabilityContext.Provider
                value={useMemo(
                  () => ({
                    availableItems,
                    unavailableItems,
                  }),
                  [availableItems, unavailableItems],
                )}
              >
                <CartPriceContext.Provider value={price}>
                  <CartQuantityContext.Provider value={quantity}>
                    <CartResidencyContext.Provider value={residency}>
                      <CartStashContext.Provider
                        value={useMemo(
                          () => ({ addToStash, removeFromStash, getStashQuantity }),
                          [addToStash, getStashQuantity, removeFromStash],
                        )}
                      >
                        {children}
                      </CartStashContext.Provider>
                    </CartResidencyContext.Provider>
                  </CartQuantityContext.Provider>
                </CartPriceContext.Provider>
              </CartItemsWithAvailabilityContext.Provider>
            </CartMetaContext.Provider>
          </CartStateContext.Provider>
        </CartUtilsContext.Provider>
      </CartActionsContext.Provider>
    </CartContext.Provider>
  );
};

export { isOutOfLimits } from './utils';
export {
  useCartQuantity,
  useCartState,
  useCartMeta,
  useCartUtils,
  useCart,
  useCartItemsWithAvailability,
  useCartActions,
  useCartPrice,
  useCartResidency,
  useCartStash,
} from './context';
export * from './types';
export { CartProvider };
