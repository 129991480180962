import { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

import Button, { ButtonProps } from '@swe/shared/ui-kit/components/button';
import Modal, { ModalProps } from '@swe/shared/ui-kit/components/modal';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type PromptProps = {
  visible?: boolean;
  size?: ModalProps['size'];
  heading?: ReactNode;
  content?: ReactNode;
  confirmLabel?: string;
  confirmColor?: ButtonProps['color'];
  cancelLabel?: string;
  cancelColor?: ButtonProps['color'];
  hideCancel?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onResolve?: (result: boolean) => void;
  ariaLabel: ModalProps['ariaLabel'];
};

const Prompt = ({
  visible,
  heading,
  content,
  size,
  confirmLabel = 'Confirm',
  confirmColor = 'primary',
  cancelLabel = 'Cancel',
  cancelColor = 'light',
  hideCancel,
  onConfirm,
  onCancel,
  onResolve,
  ariaLabel,
}: PromptProps) => {
  const result = useRef<boolean | undefined>();
  const cancelHandler = useCallback(() => {
    onCancel?.();
    result.current = false;
  }, [onCancel]);

  const confirmHandler = useCallback(() => {
    onConfirm?.();
    result.current = true;
  }, [onConfirm]);

  const resolveHandler = useCallback(() => {
    onResolve?.(result.current!);
    result.current = undefined;
  }, [onResolve]);

  return (
    <Modal
      heading={heading}
      visible={visible}
      size={size}
      onClose={cancelHandler}
      onCloseAnimationEnd={resolveHandler}
      showCloseButton={false}
      ariaLabel={ariaLabel}
      footer={
        <div className={styles.controls}>
          {!hideCancel && (
            <Button
              className={styles.button}
              color={cancelColor}
              onClick={cancelHandler}
              name="cancel"
            >
              {cancelLabel}
            </Button>
          )}
          <Button
            className={styles.button}
            onClick={confirmHandler}
            name="confirm"
            color={confirmColor}
          >
            {confirmLabel}
          </Button>
        </div>
      }
    >
      {content}
    </Modal>
  );
};

type ConfirmParams = Omit<PromptProps, 'visible' | 'onCancel' | 'onConfirm' | 'onResolve'>;
const promptContext = createContext<{ confirm: (params: ConfirmParams) => Promise<boolean> }>(null!);
const PromptContextProvider = promptContext.Provider;
const usePrompt = () => useContext(promptContext);

const PromptProvider = ({ children }: ComponentHasChildren) => {
  const [visible, setVisible] = useState(false);
  const [promptProps, setPromptProps] = useState<PromptProps>({ ariaLabel: '' });
  const [resolveHandler, setResolveHandler] = useState<PromptProps['onResolve']>(() => undefined);
  const promiseQueue = useRef(Promise.resolve(true));

  const closePrompt = useCallback(() => {
    setVisible(false);
  }, []);
  const confirm = useCallback((params: ConfirmParams) => {
    promiseQueue.current = promiseQueue.current.then(() => {
      setPromptProps(params);
      setVisible(true);
      return new Promise<boolean>((resolve) => {
        setResolveHandler(() => (result: boolean) => {
          resolve(result);
        });
      });
    });

    return promiseQueue.current;
  }, []);

  return (
    <PromptContextProvider value={useMemo(() => ({ confirm }), [confirm])}>
      {children}
      <Prompt
        {...promptProps}
        visible={visible}
        onCancel={closePrompt}
        onConfirm={closePrompt}
        onResolve={resolveHandler}
      />
    </PromptContextProvider>
  );
};

export { Prompt, PromptProvider, usePrompt };
export type { PromptProps, ConfirmParams };
export default Prompt;
