import { SnackbarService } from '@swe/shared/providers/snackbar';
import { Button } from '@swe/shared/ui-kit/components/button';
import { CheckboxGroupDefault } from '@swe/shared/ui-kit/components/form/checkbox-group/variants/check';
import { InfoIcon } from '@swe/shared/ui-kit/components/icon';
import { Prompt } from '@swe/shared/ui-kit/components/promt';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import { Tooltip } from '@swe/shared/ui-kit/components/tooltip';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { useCallback, useMemo, useState } from 'react';

import { useCurrentUser } from 'common/providers/user';
import { ToggleMarketingNotificationEndpoint } from 'endpoints/profile/notifications/toggle-marketing-notifications';
import { AddReminders } from 'endpoints/profile/reminders/add-reminders';
import { ProductVariant, ReminderStatus } from 'entities/product/product';
import { NotificationMethod } from 'entities/profile/notifications';

type ReminderProps = {
  variant?: ProductVariant;
  onUpdate?: () => void;
} & ComponentHasChildren;

const Reminder = ({ variant, children, onUpdate }: ReminderProps) => {
  const { user } = useCurrentUser();

  const add = useCallback(async () => {
    if (!variant) {
      SnackbarService.push({
        type: 'warning',
        message: 'variant id is empty',
      });
      return;
    }
    try {
      await AddReminders.request({ variantId: variant?.id });
      onUpdate?.();
      SnackbarService.push({
        type: 'success',
        message: 'Reminder has been set',
      });
    } catch (e) {
      console.error(e);
    }
  }, [onUpdate, variant]);

  const necessaryNotifications = useMemo(
    () =>
      user?.notificationSettings?.promotions.filter(({ notificationMethod }) =>
        [NotificationMethod.Push, NotificationMethod.Email].includes(notificationMethod),
      ) || [],
    [user?.notificationSettings?.promotions],
  );

  const showModal = useMemo(() => {
    return necessaryNotifications.every(({ enabled }) => !enabled);
  }, [necessaryNotifications]);

  const [visible, setVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<NotificationMethod[]>([
    NotificationMethod.Push,
    NotificationMethod.Email,
  ]);

  const onConfirm = useCallback(async () => {
    setVisible(false);
    try {
      await Promise.all(
        necessaryNotifications.map(({ notificationMethod }) =>
          ToggleMarketingNotificationEndpoint.request({
            notificationMethod,
            enabled: selectedValues.includes(notificationMethod),
          }),
        ),
      );
    } catch (e) {
      console.error(e);
    }
    await add();
  }, [add, necessaryNotifications, selectedValues]);

  const onCancel = useCallback(async () => {
    setVisible(false);
  }, []);

  const onClickHandler = useCallback(async () => {
    if (showModal) {
      setVisible(true);
      return;
    }
    await add();
  }, [add, showModal]);

  const info =
    'If you want to know when this product will be available, then click on Set reminder button and we will definitely notify you. It is only available to authorized users.';

  const showReminder = variant && variant.reminderStatus !== null && (variant.availableQty || 0) <= 0;

  return !showReminder ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <>
      <Stack
        direction="row"
        wrap={false}
      >
        <Tooltip
          content={info}
          trigger="click"
        >
          <Button
            type="button"
            color="light"
            icon={InfoIcon}
            ariaLabel={info}
          />
        </Tooltip>
        <Button
          block
          name="reminder"
          {...(variant?.reminderStatus === ReminderStatus.AlreadySet
            ? {
                color: 'light',
                children: 'Reminder has been set',
                disabled: true,
              }
            : {
                children: 'Set reminder',
                onClick: onClickHandler,
              })}
        />
      </Stack>
      <Prompt
        heading="Select reminder channel so that we can notify you when the product is available"
        ariaLabel="Select reminder channel so that we can notify you when the product is available"
        visible={visible}
        content={
          <CheckboxGroupDefault
            options={necessaryNotifications.map(({ name, notificationMethod }) => ({
              label: name,
              name,
              value: notificationMethod,
            }))}
            name="reminder"
            value={selectedValues}
            onChange={setSelectedValues}
          />
        }
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  );
};

export { Reminder };
