import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { range } from '@swe/shared/utils/array';

import { Fragment, useMemo } from 'react';

import { SkeletonSectionHeader } from 'domains/product/components/product-details/common/skeleteon-section-header';

let prevPosition = 56;

const HEIGHT_TEXT = 24;
const TOP_PADDING = 12;
const BOTTOM_PADDING = 16;

const ROWS_POSITION = [prevPosition];
range(3).forEach(() => {
  prevPosition += TOP_PADDING + HEIGHT_TEXT + BOTTOM_PADDING;
  ROWS_POSITION.push(prevPosition);
});

const ProductTierPricingSkeleton = () => {
  const rows = useMemo(
    () =>
      ROWS_POSITION.map((position) => (
        <Fragment key={`row${position}`}>
          <Bone
            x={0}
            y={position}
            width="100%"
            height={1}
          />
          <Bone
            x={0}
            y={position + TOP_PADDING}
            width="40%"
            height={HEIGHT_TEXT}
          />
          <Bone
            x="75%"
            y={position + TOP_PADDING}
            width="25%"
            height={HEIGHT_TEXT}
          />
        </Fragment>
      )),
    [],
  );

  return (
    <Skeleton
      uniqueKey="product_tier_pricing"
      height={prevPosition}
    >
      <SkeletonSectionHeader />
      {rows}
    </Skeleton>
  );
};

export { ProductTierPricingSkeleton };
