import { useEffect, useState } from 'react';

import { state } from './local-state';

import { Prototypes } from 'common/entities/documents';
import GetDocumentTypes from 'endpoints/profile/documents/get-document-types';

const useDocumentsPrototypes = (isActive = true) => {
  const { data, isLoading, mutate } = GetDocumentTypes.useRequest(isActive ? undefined : null);
  const [manager, setManager] = useState<Prototypes | undefined>(state.prototypes);

  useEffect(() => {
    if (data) {
      state.prototypes = new Prototypes(data);
      setManager(state.prototypes);
    }
  }, [data]);

  return {
    prototypes: manager,
    data,
    isLoading,
    mutate,
  };
};

export { useDocumentsPrototypes };
