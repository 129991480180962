import { createContext, useContext, useMemo, ElementType } from 'react';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type HeadType = ElementType;

const context = createContext<{ head: HeadType }>(null!);

const HeadCtxProvider = context.Provider;

type HeadProviderProps = {
  head?: HeadType;
} & ComponentHasChildren;

const HeadProvider = ({ head = 'div', children }: HeadProviderProps) => {
  const ctx = useMemo(
    () => ({
      head,
    }),
    [head],
  );
  return <HeadCtxProvider value={ctx}>{children}</HeadCtxProvider>;
};

const useHead = () => {
  const { head } = useContext(context);
  return head ?? 'div';
};

export { HeadProvider, useHead };
export type { HeadProviderProps };
