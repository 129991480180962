import { DocumentPrototype as DocumentPrototypeType } from 'entities/documents/documents-v2';

class DocumentPrototype {
  constructor(private readonly prototype: DocumentPrototypeType) {}

  get id() {
    return this.prototype.id;
  }

  get name() {
    return this.prototype.name;
  }

  get imagesRequired(): [min: number, max: number] {
    return [this.prototype.minImagesCount, this.prototype.maxImagesCount];
  }

  get data() {
    return this.prototype;
  }
}

const createPrototype = (prototype: DocumentPrototypeType) => new DocumentPrototype(prototype);

export { createPrototype, DocumentPrototype };
