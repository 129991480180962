import cn from 'clsx';
import { ComponentType, ForwardedRef, forwardRef, ReactNode, ComponentProps } from 'react';

import { usePending } from '@swe/shared/hooks/use-pending';
import Link from '@swe/shared/providers/router/link';
import { Badge, BadgeColor, BadgeProps } from '@swe/shared/ui-kit/components/badge';
import { IconProps } from '@swe/shared/ui-kit/components/icon';
import { Indicator, IndicatorColor } from '@swe/shared/ui-kit/components/indicator';
import Loader from '@swe/shared/ui-kit/components/loader';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import {
  Colors,
  ComponentHasColor,
  ComponentHasSize,
  ComponentHasStyling,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type ButtonSize = Sizes<'lg' | 'sm' | 'xs'>;

// TODO: Button Link doesn't have ghost color - so describe dts
// https://github.com/microsoft/TypeScript/issues/46457
/* type ButtonColorProps =
  | {
      variant?: 'solid';
      color?: ButtonColor;
    }
  | {
      variant?: 'link';
      color?: Exclude<ButtonColor, 'ghost'>;
    }; */

export type ButtonColor = Colors<
  'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'neutral' | 'light' | 'ghost'
>;

export type ButtonVariant = 'solid' | 'link';

type Accessibility =
  | {
      children: ReactNode;
      ariaLabel?: string;
    }
  | { children?: never; ariaLabel: string };

type Name =
  | {
      ariaLabel?: never;
      name: string;
    }
  | { name?: string; ariaLabel: string };

type BaseProps = ComponentHasStyling &
  ComponentHasColor<ButtonColor> &
  ComponentHasSize<ButtonSize> &
  Accessibility &
  Name & {
    badge?: BadgeProps['children'];
    block?: boolean;
    border?: 'none';
    endIcon?: ComponentType<IconProps> | false;
    focused?: boolean;
    hoverable?: boolean;
    hovered?: boolean;
    icon?: ComponentType<IconProps> | false;
    indicator?: IndicatorColor;
    onFocus?: () => void;
    pending?: boolean;
    tabIndex?: HTMLElement['tabIndex'];
    variant?: ButtonVariant;
  };

type ButtonButtonProps = BaseProps & {
  type?: 'submit' | 'button';
  form?: string;
  disabled?: boolean | 'fake';
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
};

type LinkButtonProps = BaseProps & Omit<ComponentProps<typeof Link>, 'color' | 'size' | 'ref'>;

export type ButtonProps = ButtonButtonProps | LinkButtonProps;

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<any>) => {
  const {
    ariaLabel,
    badge,
    block,
    border,
    children,
    className,
    color = 'primary',
    endIcon: EndIcon,
    focused,
    hoverable = true,
    hovered,
    icon: Icon,
    indicator,
    name,
    onClick,
    onFocus,
    pending: _pending,
    size = 'lg',
    style,
    tabIndex,
    variant = 'solid',
  } = props;

  const [wrappedHandler, innerPending] = usePending(onClick);
  const pending = _pending ?? innerPending;

  const disabled = props.disabled === true || pending;

  const { button } = useTheme();

  let badgeColor: BadgeColor | undefined;

  if (variant === 'link') {
    if (color === 'ghost') {
      badgeColor = undefined;
    } else {
      badgeColor = button[variant][color]?.idle.badgeType as BadgeColor;
    }
  } else {
    badgeColor = button[variant][color]?.idle.badgeType as BadgeColor;
  }

  const content = (
    <>
      {pending ? <Loader className={cn(styles.icon, styles.icon_loader)} /> : Icon && <Icon className={styles.icon} />}
      {children && (
        <div className={styles.text}>
          <div>{children}</div>
        </div>
      )}
      {badge && (
        <Badge
          className={styles.badge}
          size="sm"
          color={badgeColor}
        >
          {badge}
        </Badge>
      )}
      {indicator && (
        <Indicator
          className={styles.indicator}
          color={indicator}
          size="md"
        />
      )}
      {EndIcon && <EndIcon className={styles.icon} />}
    </>
  );

  const commonProps = {
    ref,
    style,
    locatorType: 'button',
    locatorName: (name || ariaLabel) as string,
    className: cn(
      styles.root,
      styles[`_variant_${variant}`],
      styles[`_size_${size}`],
      styles[`_color_${color}`],
      border && styles[`_border_${border}`],
      {
        [styles._disabled]: disabled,
        [styles._pending]: pending,
        [styles._block]: block,
        [styles._hovered]: hovered,
        [styles._focused]: focused,
        [styles._hoverable]: hoverable,
      },
      className,
    ),
    tabIndex,
    'aria-label': ariaLabel,
    onFocus,
    onClick: wrappedHandler,
  } as const;

  if ('href' in props) {
    return (
      <Locator
        {...commonProps}
        as={Link}
        href={props.href}
        target={props.target}
        replace={props.replace}
        scroll={props.scroll}
        id={props.id}
        color="none"
      >
        {content}
      </Locator>
    );
  }

  return (
    <Locator
      {...commonProps}
      as="button"
      disabled={disabled}
      form={props.form}
      type={props.type ?? 'button'}
    >
      {content}
    </Locator>
  );
});

export default Button;
