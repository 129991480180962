import { loadExternalGlobalScript } from '@swe/shared/tools/script';

import { useStoreConfig } from 'common/providers/config';
import { AeroPayPaymentContext } from 'domains/checkout/types/aeropay';

const usePaymentWithAeroPay = () => {
  const { aeroPayLocationId, aeroPayMerchantId, aeroPayEnv } = useStoreConfig();
  return {
    process: async (totalAmount: number) => {
      const aeroPay = await loadExternalGlobalScript<typeof window.AeroPay>(
        'https://sdk.aeropay.com/ap/aeropay.js',
        'AeroPay',
      );
      return new Promise<AeroPayPaymentContext>((resolve) => {
        aeroPay.init({ env: aeroPayEnv! });
        const apButton = aeroPay.button({
          locationId: aeroPayLocationId!,
          merchantId: aeroPayMerchantId!,
          type: 'userAuthorize',
          onSuccess: (paymentContext) => resolve(paymentContext),
          onEvent: (msg) => {
            const data = JSON.parse(msg.data);
            if (data.msgType === 'AeroPayClose') {
              resolve(null);
            }
          },
        });
        apButton.launch(totalAmount.toString());
      });
    },
  };
};

export { usePaymentWithAeroPay };
