/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import cx from 'clsx';
import { useCallback, ReactNode } from 'react';

import { BaseCheckboxGroupProps } from '@swe/shared/ui-kit/components/form/checkbox-group/types';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';
import InlineScrollableWrapper, {
  InlineScrollableWrapperProps,
} from '@swe/shared/ui-kit/components/form/wrapper/inline-scrollable';
import { Tag } from '@swe/shared/ui-kit/components/tag';

import styles from './styles.module.scss';

type CheckboxGroupTagProps<VT extends any = string> = BaseCheckboxGroupProps<VT> & {
  inline?: boolean;
  controls?: InlineScrollableWrapperProps['controls'];
  hideDisabled?: boolean;
  start?: ReactNode;
};

const CheckboxGroupTag = <VT extends any = string>({
  value = [],
  name,
  label,
  error,
  disabled,
  onChange,
  onBlur,
  options,
  inline,
  controls = 'all',
  hideDisabled,
  start,
}: CheckboxGroupTagProps<VT>) => {
  const handleOptionClick = useCallback(
    ({ value: optionValue, disabled, active }: { value: VT; disabled: boolean; active: boolean }) => {
      if (disabled || !optionValue) return;

      let newValue = [...value];
      if (active) {
        newValue = newValue.filter((v) => v !== optionValue);
      } else {
        newValue = newValue.concat(optionValue);
      }

      onChange?.(newValue);
    },
    [onChange, value],
  );

  const filteredOptions = hideDisabled ? options.filter((option) => !option.disabled) : options;

  const renderedOptions = (
    <div
      id={name}
      className={cx(styles.root, inline && styles._noWrap)}
      onBlur={onBlur}
    >
      {start}
      {filteredOptions.length > 0 ? (
        filteredOptions.map((option, optionIdx) => (
          <Tag
            key={`cv-${optionIdx}`}
            active={value.includes(option.value)}
            disabled={disabled || option.disabled}
            name={name}
            onToggle={() =>
              handleOptionClick({
                value: option.value,
                disabled: !!option.disabled,
                active: value.includes(option.value),
              })
            }
          >
            {option.label}
          </Tag>
        ))
      ) : (
        <Tag
          name={name}
          className={styles.empty}
        >
          Empty
        </Tag>
      )}
    </div>
  );

  return (
    <CommonWrapper
      label={label}
      error={error}
    >
      {inline ? (
        <InlineScrollableWrapper controls={controls}>{renderedOptions}</InlineScrollableWrapper>
      ) : (
        renderedOptions
      )}
    </CommonWrapper>
  );
};

export type { CheckboxGroupTagProps };
export { CheckboxGroupTag };
export default CheckboxGroupTag;
