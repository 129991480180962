import { useBreakpoint } from '@swe/shared/tools/media';
import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { useId } from 'react';

type ProductCardSkeletonProps = ComponentHasClassName & {
  uniqueId?: string;
  width?: string;
};

const ProductCardSkeleton = ({ className, uniqueId, width }: ProductCardSkeletonProps) => {
  const theme = useTheme();
  const { mobile } = useBreakpoint();
  const id = useId();

  return (
    <Skeleton
      className={className}
      uniqueKey={`productCardSkeleton`.concat(uniqueId ?? id)}
      width={width}
      height={mobile ? 350 : 445}
    >
      <Bone
        x="0"
        y="0"
        rx={theme.productCard.borderRadius}
        ry={theme.productCard.borderRadius}
        width="100%"
        height="100%"
      />
    </Skeleton>
  );
};

export { ProductCardSkeleton };
