import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { FulfilmentDay } from 'entities/shop/info';

const NAME = '/Orders/GetIntervalsV2';
const GetOrderIntervalsV2Endpoint = createModernEndpoint<
  {
    orderId: number;
  },
  FulfilmentDay[]
>(NAME);

export { GetOrderIntervalsV2Endpoint };
