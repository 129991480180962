import { ReactNode } from 'react';

import { PublicCarouselOptions, Gap } from '@swe/shared/ui-kit/components/carousel-v2/core';
import { ImgProps } from '@swe/shared/ui-kit/components/image';

type ControllableProps = {
  current?: number;
  onChange?: (index: number) => void;
};

type Image = ImgProps & {
  type: 'image';
  render?: (element: ReactNode) => ReactNode;
};

type CarouselItemT = Image | ReactNode;

const isImage = (props: CarouselItemT): props is Image => {
  return !!(props && typeof props === 'object' && 'type' in props && props.type === 'image');
};

type CommonProps = {
  items: CarouselItemT[];
  stepper?: 'dot' | 'dash' | false;
  thumbs?: boolean;
  controls?: boolean;
  extendToView?: boolean;
  gap: 'equal' | Gap;
  className?: string;
  slideClassName?: string;
  thumbClassName?: string;
};

type CarouselProps = PublicCarouselOptions & CommonProps & ControllableProps;

export type { CarouselProps, ControllableProps, CommonProps, CarouselItemT };
export { isImage };
