import { useToggleable } from '@swe/shared/hooks/use-toggleable';

import { useCallback, useEffect, useState } from 'react';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate, useRouterPathname, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';

import ReferralCongratulationsModal from 'domains/profile/containers/referral-program/containers/referral-congratulations-modal';
import AddReferralDataEndpoint from 'endpoints/profile/referral-program/add-referral-data';
import AttachReferralByDataEndpoint from 'endpoints/profile/referral-program/attach-referral-by-data';

const ReferralProgramSubscriber = () => {
  const query = useRouterQuery();
  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();
  const { user, isRegistrationComplete, revalidate: revalidateUser } = useCurrentUser();
  const [isOpened, openSuccessAttachModal, closeSuccessAttachModal] = useToggleable();
  const [isAttached, setIsAttached] = useState<boolean>();

  const clearReferralDataQuery = useCallback(() => {
    const newQuery = { ...query };
    delete query.referralData;
    void navigate({ query: newQuery });
  }, [navigate, query]);

  const [isLoading, setIsLoading] = useState(false);

  const attachToReferralProgram = useCallback(
    async (referralData: string) => {
      setIsLoading(true);
      try {
        await AttachReferralByDataEndpoint.request({ referralData });
        setIsAttached(true);
        clearReferralDataQuery();
        openSuccessAttachModal();
        void revalidateUser();
      } finally {
        setIsLoading(false);
      }
    },
    [clearReferralDataQuery, openSuccessAttachModal, revalidateUser],
  );

  useEffect(() => {
    if (isAttached || isLoading) {
      return;
    }

    const queryReferralData = query.referralData;
    const isUserAuthorized = !!user && isRegistrationComplete;
    const savedReferralData = user?.referralData;

    if (pathname === Routes.Home) {
      if (queryReferralData && !Array.isArray(queryReferralData)) {
        if (isUserAuthorized) {
          void attachToReferralProgram(queryReferralData);
        } else {
          AddReferralDataEndpoint.request({ referralData: queryReferralData });
          void navigate(
            {
              pathname: Routes.SignUp,
              query,
            },
            { replace: true },
          );
        }
      } else if (isUserAuthorized && savedReferralData) {
        void attachToReferralProgram(savedReferralData);
      }
    }
  }, [
    attachToReferralProgram,
    isAttached,
    isLoading,
    isRegistrationComplete,
    navigate,
    pathname,
    revalidateUser,
    query,
    user,
  ]);

  return (
    <ReferralCongratulationsModal
      visible={isOpened}
      onClose={closeSuccessAttachModal}
    />
  );
};

export { ReferralProgramSubscriber };
