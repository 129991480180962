import { useEffect, useRef } from 'react';

import { debounce } from '@swe/shared/utils/func';

const FROZEN_CLASSNAME = '_body_frozen';

let freezeCount = 0;

const check = debounce(() => {
  if (freezeCount > 0) {
    document.documentElement.classList.add(FROZEN_CLASSNAME);
  } else {
    document.documentElement.classList.remove(FROZEN_CLASSNAME);
  }
}, 100);

const lockScroll = () => {
  freezeCount += 1;
  check();
};

const releaseScroll = () => {
  freezeCount = Math.max(0, freezeCount - 1);
  check();
};

const useScrollLocker = (lock?: boolean) => {
  const wasLockedRef = useRef(false);
  useEffect(() => {
    if (lock) {
      lockScroll();
      wasLockedRef.current = true;
    }

    return () => {
      if (wasLockedRef.current) {
        releaseScroll();
        wasLockedRef.current = false;
      }
    };
  }, [lock]);
};

export { useScrollLocker };
