import { EventEmitter } from '@swe/shared/tools/event-emitter';

import { Cart } from 'entities/cart/cart';
import { ProductVariant } from 'entities/product/product';

type CartEventTypes = {
  onQuantityChange: { productId: ProductId; variantId: VariantId; qty: number };
};

class CartEvents extends EventEmitter<CartEventTypes> {}

const cartEvents = new CartEvents();

type ProductQuantityStorage = Map<VariantId, number>;
type Quantities = {
  map: Record<VariantId, number>;
  array: number[];
  total: number;
};

const QuantityStorage = new Map<ProductId, ProductQuantityStorage>();

const toArray = (map: ProductQuantityStorage) => Array.from(map.values());
const toObject = (map: ProductQuantityStorage) => Object.fromEntries(map.entries());
const getTotal = (map: ProductQuantityStorage) => Array.from(map.values()).reduce((acc, qty) => acc + qty, 0);

const useQuantityStorage = () => {
  const getQuantity = (productId: ProductId, variantId: VariantId) => {
    const productQuantity = QuantityStorage.get(productId);
    return productQuantity?.get(variantId) || 0;
  };

  const getQuantities = (productId: ProductId): Quantities => {
    const storage = QuantityStorage.get(productId) || new Map<ProductVariant['id'], number>();

    return {
      map: toObject(storage),
      array: toArray(storage),
      total: getTotal(storage),
    };
  };
  const setQuantity = (productId: ProductId, variantId: VariantId, qty: number) => {
    let productQuantity = QuantityStorage.get(productId);
    if (!productQuantity) {
      productQuantity = new Map<ProductVariant['id'], number>();
      QuantityStorage.set(productId, productQuantity);
    }

    productQuantity.set(variantId, qty);
    cartEvents.fire('onQuantityChange', { productId, variantId, qty });
  };

  const applyCartState = (cart?: Cart) => {
    if (!cart || !cart.items.length) {
      QuantityStorage.clear();
      return;
    }

    cart.items.forEach(({ product, variantId, qty }) => {
      setQuantity(product.id, variantId, qty);
    });
  };

  const incrementQuantity = (productId: ProductId, variantId: VariantId, quantity = 1) => {
    const qty = getQuantity(productId, variantId) + quantity;
    setQuantity(productId, variantId, qty);
  };

  const decrementQuantity = (productId: ProductId, variantId: VariantId, quantity = 1) => {
    const qty = Math.max(getQuantity(productId, variantId) - quantity, 0);
    setQuantity(productId, variantId, qty);
  };

  const isEmpty = () => {
    return QuantityStorage.size === 0;
  };

  return {
    cartEvents,
    applyCartState,
    getQuantity,
    getQuantities,
    setQuantity,
    incrementQuantity,
    decrementQuantity,
    isEmpty,
  };
};

export type { Quantities };
export { useQuantityStorage };
