import hash from 'object-hash';
import { unstable_serialize } from 'swr';

const createSWRKey = <EndpointURL = string, Params = any>(endpoint: EndpointURL, params: Params) => {
  try {
    return unstable_serialize([endpoint, params ?? {}]);
  } catch (e) {
    return hash([endpoint, params ?? {}]);
  }
};

export { createSWRKey };
