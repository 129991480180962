import { DEFAULT_THEME } from '@swe/shared/ui-kit/theme/provider/constants';
import { Theme, ThemePattern, THEMES_VERSION } from '@swe/shared/ui-kit/theme/provider/themes';
import { hashSum } from '@swe/shared/utils/object';

const hashThemeName = (themeName?: Theme): Theme =>
  themeName && themeName !== DEFAULT_THEME ? (hashSum(themeName) as Theme) : DEFAULT_THEME;

const applyPattern = (themeName: string | undefined, patternName: ThemePattern) => {
  return `${themeName}-${patternName}`;
};

const processThemeName = (themeName: string, patternName?: ThemePattern) => {
  if (!patternName) return themeName;

  return applyPattern(themeName, patternName);
};

const buildAssetUrl = (themeName: string, assetType: 'css' | 'mjs', assetPrefix?: AbsoluteURL) => {
  const themeVersion = THEMES_VERSION[themeName as keyof typeof THEMES_VERSION] ?? THEMES_VERSION.default;
  return `${assetPrefix ?? ''}/_themes/${themeName}/index.${assetType}?v=${themeVersion}`;
};

export { processThemeName, buildAssetUrl, hashThemeName };
