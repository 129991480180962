import { useCallback, useEffect, useState } from 'react';

import { useCarouselContext } from '@swe/shared/ui-kit/components/carousel-v2/core/context';

const useControls = () => {
  const { subscribe, unsubscribe, getCanScrollBackward, getCanScrollForward, next, prev } = useCarouselContext();
  const [canScrollBackward, setCanScrollBackward] = useState(getCanScrollBackward?.() ?? false);
  const [canScrollForward, setCanScrollForward] = useState(getCanScrollForward?.() ?? false);

  const setup = useCallback(() => {
    setCanScrollBackward(getCanScrollBackward!());
    setCanScrollForward(getCanScrollForward!());
  }, [getCanScrollBackward, getCanScrollForward]);
  const handleNext = useCallback(() => {
    next?.();
  }, [next]);
  const handlePrev = useCallback(() => {
    prev?.();
  }, [prev]);

  useEffect(() => {
    if (subscribe) {
      setup();
      subscribe('reInit', setup);
      subscribe('select', setup);

      return () => {
        setup();
        unsubscribe!('reInit', setup);
        unsubscribe!('select', setup);
      };
    }
  }, [setup, subscribe, unsubscribe]);

  return {
    canScrollBackward,
    canScrollForward,
    next: handleNext,
    prev: handlePrev,
  };
};

export { useControls };
