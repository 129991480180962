import { BadgeColor, ThemedBadgeProps } from '@swe/shared/ui-kit/components/badge';

import {
  IconComponent,
  MedicalIcon,
  PercentIcon,
  StrainPrevalenceHybridIcon,
  StrainPrevalenceIndicaIcon,
  StrainPrevalenceSativaIcon,
} from '@swe/shared/ui-kit/components/icon';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';

import {
  formatLabTestsInOneLine,
  getFirstImage,
  getImages,
  getProductName,
  getProductVariant,
  isOutOfStock,
  Product,
  ProductSaleType,
  ProductVariant,
} from 'entities/product/product';

const STRAIN_PREVALENCE_BADGE_COLOR: Record<string, BadgeColor> = {
  Sativa: 'warning',
  'Sativa Dominant': 'warning',
  Indica: 'secondary',
  'Indica Dominant': 'secondary',
  Hybrid: 'success',
  CBD: 'neutral',
};

const STRAIN_PREVALENCE_BADGE_ICON: Record<string, IconComponent | undefined> = {
  Sativa: StrainPrevalenceSativaIcon,
  'Sativa Dominant': StrainPrevalenceSativaIcon,
  Indica: StrainPrevalenceIndicaIcon,
  'Indica Dominant': StrainPrevalenceIndicaIcon,
  Hybrid: StrainPrevalenceHybridIcon,
  CBD: undefined,
};

const getBadges = (product: Product, variant: ProductVariant, hasIcon = false, showPromo = true) => {
  return [
    showPromo &&
      variant.promos &&
      variant.promos.length > 0 && {
        color: 'danger',
        icon: PercentIcon,
        themePattern: 'product-deal' as ThemePattern,
      },
    variant.saleType === ProductSaleType.MEDICAL && {
      color: 'success',
      icon: MedicalIcon,
      tooltip: 'Medical use only',
      themePattern: 'product-medical' as ThemePattern,
    },
    product.strain && {
      color: STRAIN_PREVALENCE_BADGE_COLOR[product.strain.prevalence.name],
      icon: hasIcon ? STRAIN_PREVALENCE_BADGE_ICON[product.strain.prevalence.name] : undefined,
      children: product.strain.prevalence.name,
      themePattern: 'product-strain-prevalence' as ThemePattern,
    },
    product.qualityLine && {
      color: 'light',
      children: product.qualityLine.name,
      themePattern: 'product-quality-line' as ThemePattern,
    },
  ].filter((badge) => !!badge) as ThemedBadgeProps[];
};

const useBadges = (product: Product, variant: ProductVariant, showPromo: boolean) => {
  const theme = useTheme();

  const badges: ThemedBadgeProps[] = getBadges(product, variant, theme.productCard.badge.strain.hasIcon, showPromo);

  return badges;
};

const prepareProductForCard = (product: Product, _variant?: ProductVariant | ProductVariant['id']) => {
  const variant = getProductVariant(product, _variant);
  const promos = variant.promos ?? [];

  const images = getImages(product, variant.id);
  const image = getFirstImage(product, variant.id);

  const labTests: string[] = formatLabTestsInOneLine(variant.labTests);

  const hasPriceFrom = promos.length > 1 || promos[0]?.isConditional;

  return {
    variant,
    productName: getProductName(product),
    isOutOfStock: isOutOfStock(variant),
    image,
    images,
    labTests,
    promos,
    hasPriceFrom,
  };
};

export { useBadges, prepareProductForCard };
