import { useDrag } from '@use-gesture/react';
import cx from 'clsx';
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { a, useSpring } from 'react-spring';

import { useScrollLocker } from '@swe/shared/tools/window';
import Backdrop from '@swe/shared/ui-kit/components/backdrop';
import Button from '@swe/shared/ui-kit/components/button';
import { Container, ContainerProps } from '@swe/shared/ui-kit/components/container';
import { FocusTrap } from '@swe/shared/ui-kit/components/focus-trap';
import { ChevronLeftIcon, CloseIcon } from '@swe/shared/ui-kit/components/icon';
import { SPRING_ANIMATION_CONFIG } from '@swe/shared/ui-kit/components/modal/config';
import { ModalMobileBaseProps } from '@swe/shared/ui-kit/components/modal/mobile/types';
import { Portal } from '@swe/shared/ui-kit/components/portal';

import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';

import { SectionHeading, SectionHeadingProps } from '@swe/shared/ui-kit/components/section-heading';

import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';
import { ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';

import styles from './styles.module.scss';

type ModalMobileFullscreenProps = ModalMobileBaseProps & {
  subHeading?: ReactNode;
  sidebar?: ReactNode;
  side?: 'left' | 'right';
  sectionHeading?: SectionHeadingProps;
  width?: 'full' | 'side';
  maxWidth?: ContainerProps['maxWidth'];
  canBeSwiped?: boolean;
  isHeaderEnabled?: boolean;
  closeButtonPosition?: 'left' | 'right';
  hasGap?: boolean;
  headerHeight?: number;
  themePattern?: ThemePattern;
};

const ModalMobileFullscreen = ({
  visible,
  heading,
  subHeading,
  isHeaderEnabled = true,
  footer,
  sidebar,
  children,
  background,
  onOpen,
  onClose,
  onCloseAnimationEnd,
  isStacked,
  side = 'right',
  sectionHeading,
  width = 'side',
  canBeSwiped = true,
  closeButtonPosition = 'left',
  maxWidth,
  hasGap = true,
  headerHeight,
  ariaLabel,
  themePattern,
}: ModalMobileFullscreenProps) => {
  useScrollLocker(visible);
  const holderRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const stopperRef = useRef<HTMLDivElement>(null);
  const widthRef = useRef<number | null>(null);
  const [localVisible, setLocalVisible] = useState(false);
  const [isActiveTrap, setIsActiveTrap] = useState(false);
  const [{ x }, api] = useSpring(() => ({
    x: side === 'left' ? 9999 : -9999,
    config: SPRING_ANIMATION_CONFIG,
    immediate: true,
  }));

  const calcWidth = useCallback(() => {
    const width = rootRef.current?.offsetWidth;
    if (width) {
      widthRef.current = width;
    }
  }, []);

  const open = useCallback(() => {
    onOpen?.();
    api.start({
      x: 0,
      immediate: false,
      onResolve: () => {
        setIsActiveTrap(true);
        calcWidth();
      },
    });
  }, [api, calcWidth, onOpen]);

  const close = useCallback(() => {
    setIsActiveTrap(false);
    onClose?.();
    api.start({
      x: side === 'right' ? widthRef.current : -1 * widthRef.current!,
      immediate: false,
      onResolve: () => {
        setLocalVisible(false);
        onCloseAnimationEnd?.();
      },
    });
  }, [api, onClose, onCloseAnimationEnd, side]);

  useEffect(() => {
    const root = rootRef.current;
    const holder = stopperRef.current ?? holderRef.current;
    const touchHandler = (event: TouchEvent) => {
      event.preventDefault();
    };

    root?.addEventListener('transitionend', calcWidth);
    root?.addEventListener('animationend', calcWidth);
    holder?.addEventListener('touchstart', touchHandler);

    return () => {
      holder?.removeEventListener('touchstart', touchHandler);
      root?.removeEventListener('transitionend', calcWidth);
      root?.removeEventListener('animationend', calcWidth);
    };
  }, [calcWidth, localVisible]);

  useEffect(() => {
    if (localVisible) {
      calcWidth();
      if (widthRef.current) {
        x.set(side === 'right' ? widthRef.current : -1 * widthRef.current!);
        open();
      }
    }
  }, [localVisible, calcWidth, open, x, side]);

  useEffect(() => {
    if (!visible && localVisible) {
      close();
    }

    if (visible && !localVisible) {
      setLocalVisible(true);
    }
  }, [visible, localVisible, close]);

  const bind = useDrag(
    ({ event, last, velocity: [vx], movement: [mx], cancel, tap }) => {
      if (!onClose || tap || widthRef.current === null) {
        return;
      }

      event.preventDefault();

      if (side === 'left' ? mx > 30 : mx < -30) {
        cancel();
      }

      if (last) {
        if (Math.abs(vx) > 2 || Math.abs(mx) > widthRef.current * 0.3) {
          close();
        } else {
          open();
        }
      } else {
        api.start({ x: mx, immediate: true });
      }
    },
    { enabled: canBeSwiped, axis: 'x', filterTaps: true },
  );

  return (
    <Portal>
      {localVisible && (
        <>
          <PatternThemeProvider name={themePattern}>
            <FocusTrap isActive={isActiveTrap}>
              <a.div
                aria-modal="true"
                role="dialog"
                aria-label={ariaLabel}
                ref={rootRef}
                style={{ x, '--sw-header-height': headerHeight ? `${headerHeight}px` : '0' } as CSSProperties}
                className={cx(
                  styles.root,
                  background && styles._withBackground,
                  footer && styles._withFooter,
                  styles[`_side_${side}`],
                  width !== 'side' && styles[`_width_${width}`],
                  !hasGap && styles._noGap,
                )}
              >
                {isHeaderEnabled !== false &&
                  (sectionHeading ? (
                    <Container
                      className={styles.headerWithSection}
                      maxWidth={maxWidth}
                    >
                      <SectionHeading
                        size="xl"
                        level={1}
                        {...sectionHeading}
                      />
                      {subHeading}
                    </Container>
                  ) : (
                    <Container
                      className={styles.header}
                      maxWidth={maxWidth}
                    >
                      <div
                        className={cx(
                          styles.headerContent,
                          closeButtonPosition === 'right' && styles.headerContent_reversed,
                        )}
                      >
                        <Button
                          color="ghost"
                          size="sm"
                          icon={onClose ? (isStacked ? ChevronLeftIcon : CloseIcon) : undefined}
                          onClick={onClose}
                          ariaLabel="Close"
                        />
                        <SectionHeading
                          className={styles.heading}
                          noPadding
                          level={1}
                        >
                          {heading}
                        </SectionHeading>
                      </div>
                      {subHeading}
                    </Container>
                  ))}
                {background && <div className={styles.background}>{background}</div>}
                <Container
                  className={cx(styles.content, !background && styles.content_noPadding)}
                  maxWidth={maxWidth}
                >
                  {background ? (
                    <>
                      {sidebar}
                      {children}
                    </>
                  ) : (
                    <Scrollable
                      className={styles.scrollable}
                      fade={false}
                    >
                      {sidebar}
                      {children}
                    </Scrollable>
                  )}
                </Container>
                {footer && (
                  <Container
                    className={styles.footer}
                    maxWidth={maxWidth}
                  >
                    {footer}
                  </Container>
                )}
                <div
                  ref={holderRef}
                  className={styles.holder}
                  {...bind()}
                />
                {side === 'left' && (
                  <div
                    ref={stopperRef}
                    className={styles.stopper}
                  />
                )}
              </a.div>
            </FocusTrap>
          </PatternThemeProvider>
          <Backdrop
            visible={visible}
            onClick={onClose}
            className={styles.backdrop}
            style={{ '--sw-header-height': headerHeight ? `${headerHeight}px` : '0' } as CSSProperties}
          />
        </>
      )}
    </Portal>
  );
};

export type { ModalMobileFullscreenProps };
export { ModalMobileFullscreen };
export default ModalMobileFullscreen;
