import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { CreateAccountPayload } from 'entities/authorization/sign-up';

type Params = CreateAccountPayload;
type Data = null;
type Error = APIErrorExtended;

const NAME = '/Registration/ConfirmEasySignUp';
const SignupConfirmEasySignUpEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default SignupConfirmEasySignUpEndpoint;
