import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useIsomorphicLayoutEffect } from '@swe/shared/hooks/use-isomorphic-layout-effect';

import { PortalCounter } from '@swe/shared/ui-kit/components/portal/utils';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { Portals } from './types';

const DEFAULT_PORTAL = 'common' as const;

type PortalContextType = {
  registerTarget?(name: Portals, node: Element): void;
  onTargetReady?(name: Portals, cb: (el: Element) => void): void;
  onChildrenStatusChange?(name: Portals, cb: (f: boolean) => void): void;
  updateChildrenStatus?(name: Portals, f: boolean): void;
  unRegisterTarget?(name: Portals): void;
};

export const PortalContext = createContext<PortalContextType>({});

type PortalProviderProps = {
  children: ReactNode;
};

type PortalContainer = Partial<Record<Portals, [Element | null, ((el: Element) => void)[]]>>;
const container: PortalContainer = {};
const registerChildren: Partial<Record<Portals, (f: boolean) => void>> = {};

export const PortalProvider = ({ children }: PortalProviderProps) => {
  const registerTarget = useCallback((name: Portals, node: Element) => {
    if (!container[name]) {
      container[name] = [null, []];
    }

    const item = container[name];
    if (item) {
      item[0] = node;
      item[1].forEach((cb) => cb(node));
    }
  }, []);

  const unRegisterTarget = useCallback((name: Portals) => {
    delete container[name];
  }, []);

  const onTargetReady = useCallback((name: Portals, cb: (e: Element) => void) => {
    if (!container[name]) {
      container[name] = [null, []];
    }

    const item = container[name];
    if (item && item[0] !== null) {
      cb(item[0]);
    } else if (item) {
      item[1].push(cb);
    }
  }, []);

  const updateChildrenStatus = useCallback((name: Portals, f: boolean) => {
    const record = registerChildren[name];
    if (record) {
      record(f);
    }
  }, []);

  const onChildrenStatusChange = useCallback((name: Portals, cb: (f: boolean) => void) => {
    registerChildren[name] = cb;
  }, []);

  const value: PortalContextType = useMemo(
    () => ({
      registerTarget,
      onTargetReady,
      onChildrenStatusChange,
      updateChildrenStatus,
      unRegisterTarget,
    }),
    [onChildrenStatusChange, onTargetReady, registerTarget, unRegisterTarget, updateChildrenStatus],
  );

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>;
};

type PortalTargetProps = {
  name?: Portals;
  level?: number;
};

export const PortalTarget = ({ name = DEFAULT_PORTAL, level }: PortalTargetProps) => {
  const { registerTarget, unRegisterTarget } = useContext(PortalContext);
  const ref = useRef(null);

  useEffect(() => {
    if (registerTarget && ref.current) {
      registerTarget(name, ref.current);
    }
    return () => unRegisterTarget?.(name);
  }, [name, registerTarget, unRegisterTarget]);

  return (
    <div
      style={{ ...(typeof level !== 'undefined' ? { zIndex: level } : {}), position: 'relative' }}
      ref={ref}
      id={`portal-${name}`}
    />
  );
};

type PortalProps = {
  name?: Portals;
} & ComponentHasChildren;

export const Portal = ({ children, name = DEFAULT_PORTAL }: PortalProps) => {
  const [node, setNode] = useState<Element | null>(null);
  const { onTargetReady, updateChildrenStatus } = useContext(PortalContext);

  useIsomorphicLayoutEffect(() => {
    PortalCounter.increment(name);
    const hasChildren = !!children;
    updateChildrenStatus?.(name, hasChildren);

    return () => {
      const nextCount = PortalCounter.decrement(name);
      if (nextCount === 0) {
        updateChildrenStatus?.(name, false);
      }
    };
  }, [children]);

  if (node) {
    return createPortal(children, node);
  }

  if (onTargetReady) {
    onTargetReady(name, setNode);
  }

  return null;
};

const PORTALS = {
  Common: () => <PortalTarget level={1} />,
  FixedFooter: () => <PortalTarget name="fixedFooter" />,
  FixedFooterTop: () => <PortalTarget name="fixedFooterTop" />,
};

export { PORTALS };
