import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { omit } from '@swe/shared/utils/object';

import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState, useEffect } from 'react';

import { useRouterNavigate, useRouterPathname, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { SignInByTokenGuestPhoneEndpoint } from 'endpoints/authorization/guest/signIn-by-token';

const context = createContext<{
  isLoading: boolean;
  isGuest: boolean | undefined;
  setIsGuestUser: Dispatch<SetStateAction<boolean | undefined>>;
}>(null!);
const GuestContextProvider = context.Provider;

type GuestProviderProps = ComponentHasChildren;

const GuestProvider = ({ children }: GuestProviderProps) => {
  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>();

  const query = useRouterQuery();
  const isGuestAuth = query.tokenType === 'guest' && query.token;

  const { isLoading } = SignInByTokenGuestPhoneEndpoint.useRequest(
    isGuestAuth
      ? {
          token: String(query.token),
        }
      : null,
    {
      notifyWithSnackbar: false,
    },
  );

  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();

  useEffect(() => {
    if (isGuestAuth && !isLoading) {
      void navigate(
        {
          pathname: Routes.ProfileOrderDetails,
          query: omit(query, ['tokenType', 'token']),
        },
        { replace: true },
      );
    }
  }, [isGuestAuth, isLoading, pathname, query, navigate]);

  const value = useMemo(
    () => ({
      isLoading: isGuestUser === undefined,
      isGuest: isGuestUser !== undefined && isGuestUser,
      setIsGuestUser,
    }),
    [isGuestUser],
  );

  return isGuestAuth && isLoading ? null : <GuestContextProvider value={value}>{children}</GuestContextProvider>;
};

const useGuest = () => useContext(context);

export { GuestProvider, useGuest };
