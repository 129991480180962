import { useCallback, useEffect, useState } from 'react';

import { GoogleJSApiLoader } from '@swe/shared/tools/google/core/loader';
import { isSSR } from '@swe/shared/utils/environment';

const useGoogleJsApi = (apiKey: string, loadOnMount = false) => {
  const [api, setApi] = useState<typeof google | null>(isSSR ? null : window.google ?? null);
  const [isLoading, setLoading] = useState(!api);
  const [isReady, setReady] = useState(!!api);
  const [error, setError] = useState<string | null>(null);

  const load = useCallback(
    () =>
      new Promise<typeof google>((resolve, reject) => {
        if (isReady && api) resolve(api);
        if (error) reject(error);

        new GoogleJSApiLoader(apiKey)
          .load()
          .then((api) => {
            setApi(api);
            setReady(true);
            resolve(api);
          })
          .catch((e) => {
            setReady(false);
            setError(e.toString());
            reject(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }),
    [api, apiKey, error, isReady],
  );

  useEffect(() => {
    if (apiKey && loadOnMount) {
      void load();
    }
  }, [apiKey, load, loadOnMount]);

  return {
    api,
    load,
    error,
    isLoading,
    isReady,
  };
};

export { useGoogleJsApi };
