/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import cx from 'clsx';

import { useCallback } from 'react';

import { BaseRadioGroupProps } from '@swe/shared/ui-kit/components/form/radio-group/types';

import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';

import InlineScrollableWrapper, {
  InlineScrollableWrapperProps,
} from '@swe/shared/ui-kit/components/form/wrapper/inline-scrollable';

import Tag, { TagProps } from '@swe/shared/ui-kit/components/tag';

import styles from './styles.module.scss';

type RadioGroupTagProps<VT = string> = BaseRadioGroupProps<VT> & {
  inline?: boolean;
  controls?: InlineScrollableWrapperProps['controls'];
  hideDisabled?: boolean;
  size?: TagProps['size'];
};

const RadioGroupTag = <VT extends any = string>({
  value,
  name,
  label,
  error,
  disabled,
  onChange,
  onBlur,
  options,
  inline,
  controls = 'all',
  hideDisabled,
  size,
}: RadioGroupTagProps<VT | null>) => {
  const handleOptionClick = useCallback(
    ({ value: optionValue, disabled, active }: { value: VT | null; disabled: boolean; active: boolean }) => {
      if (disabled || !optionValue) return;

      onChange?.(active ? null : optionValue);
    },
    [onChange],
  );

  const filteredOptions = hideDisabled ? options.filter((option) => !option.disabled) : options;

  const renderedOptions = (
    <div
      id={name}
      className={cx(styles.tagsGroup, inline && styles.tagsGroup_noWrap)}
      onBlur={onBlur}
    >
      {filteredOptions.map((option, optionIdx) => (
        <Tag
          key={`cv-${optionIdx}`}
          className={styles.tag}
          active={value === option.value}
          disabled={disabled || option.disabled}
          name={name}
          icon={option.icon}
          size={size}
          onToggle={() =>
            handleOptionClick({
              value: option.value,
              disabled: !!option.disabled,
              active: value === option.value,
            })
          }
        >
          {option.label}
        </Tag>
      ))}
    </div>
  );

  return (
    <CommonWrapper
      label={label}
      error={error}
    >
      {inline ? (
        <InlineScrollableWrapper controls={controls}>{renderedOptions}</InlineScrollableWrapper>
      ) : (
        renderedOptions
      )}
    </CommonWrapper>
  );
};

export type { RadioGroupTagProps };
export { RadioGroupTag };
export default RadioGroupTag;
