import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';

import { Chips } from '@swe/shared/ui-kit/components/chips';
import { FilterableList, FilterableListPropsCommon } from '@swe/shared/ui-kit/components/filterable-list';
import { Option } from '@swe/shared/ui-kit/components/form/checkbox-group/types';
import {
  CheckboxGroupVariants,
  CheckboxGroupVariantsProps,
} from '@swe/shared/ui-kit/components/form/checkbox-group/variants';
import { FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type CheckboxGroupProps<VT = string> = ComponentHasClassName &
  FilterableListPropsCommon<Option<VT>> & {
    placeholder?: string;
    maxListHeight?: string;
    showSelectedTags?: boolean;
  } & CheckboxGroupVariantsProps<VT>;

const _CheckboxGroup = <VT,>(
  {
    className,

    searchable,
    search,
    searchQuery,
    searchPlaceholder,
    onSearch,

    expandable,
    expanded,
    collapsedOptionsVisible,
    onToggle,
    maxListHeight,
    showSelectedTags,
    ...checkboxGroupProps
  }: CheckboxGroupProps<VT>,
  ref: ForwardedRef<FormControlRef>,
) => {
  useImperativeHandle(ref, () => ({
    focus: () => {},
  }));

  const optionsMap = (checkboxGroupProps.options || []).reduce<Record<string, Option['label']>>(
    (acc, { label, value }) => {
      return {
        ...acc,
        [String(value)]: label,
      };
    },
    {},
  );
  const selectedOptions = useMemo(() => {
    const res = (checkboxGroupProps.value || []).map((value) => ({
      label: optionsMap[String(value)],
      value,
      name: String(optionsMap[String(value)]),
    }));

    res.sort((a, b) => a.name.localeCompare(b.name));

    return res;
  }, [checkboxGroupProps.value, optionsMap]);

  const removeFromSelected = useCallback(
    (value: VT) => {
      const newValue = [...checkboxGroupProps.value].filter((v) => value !== v);

      checkboxGroupProps.onChange?.(newValue);
    },
    [checkboxGroupProps],
  );

  return checkboxGroupProps.inline ? (
    <CheckboxGroupVariants {...checkboxGroupProps} />
  ) : (
    <FilterableList
      className={className}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      searchQuery={searchQuery}
      search={search}
      expandable={expandable}
      expanded={expanded}
      collapsedOptionsVisible={collapsedOptionsVisible}
      onSearch={onSearch}
      onToggle={onToggle}
      options={checkboxGroupProps.options}
    >
      {(options) =>
        options.length > 0 ? (
          <Scrollable
            fade
            style={{ maxHeight: maxListHeight }}
          >
            <Stack className={styles.groupContent}>
              {showSelectedTags && (
                <Stack
                  spacing="xxs"
                  direction="row"
                  wrap
                >
                  {selectedOptions.map(({ label, value }, idx) => (
                    <Chips
                      key={idx}
                      size="md"
                      onClose={() => removeFromSelected(value)}
                    >
                      {label}
                    </Chips>
                  ))}
                </Stack>
              )}
              <CheckboxGroupVariants
                {...checkboxGroupProps}
                options={options}
              />
            </Stack>
          </Scrollable>
        ) : (
          <Text className={styles.nothingFound}>Nothing found</Text>
        )
      }
    </FilterableList>
  );
};

const CheckboxGroup = forwardRef(_CheckboxGroup) as <VT>(
  props: CheckboxGroupProps<VT>,
) => ReturnType<typeof _CheckboxGroup>;

export * from './types';
export type { CheckboxGroupProps };
export { CheckboxGroup };
export default CheckboxGroup;
