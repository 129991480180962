import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { OAuthProvider } from 'entities/shop/config';

type Params = {
  externalServiceToken: string;
  externalAuthenticationProvider: OAuthProvider;
  redirectUrl?: string;
  firstName?: string;
  lastName?: string;
};
type Data = { success: boolean };

const NAME = '/Account/SignInExternal';
const SignInEndpointExternal = createModernEndpoint<Params, Data>(NAME);

export default SignInEndpointExternal;
