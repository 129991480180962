import { createReactContext } from '@swe/shared/tools/react/context';
import { MAP_DEFAULT_OPTIONS } from '@swe/shared/ui-kit/components/google-map/config';
import { asyncNoop, noop } from '@swe/shared/utils/func';

type GoogleMapContext = {
  map: google.maps.Map | null;
  options: google.maps.MapOptions;
  panToMe: () => Promise<void>;
  zoomIn: () => void;
  zoomOut: () => void;
  fitPoints: (points: google.maps.LatLngLiteral[]) => void;
};

const defaultContext: GoogleMapContext = {
  map: null,
  options: MAP_DEFAULT_OPTIONS,
  panToMe: asyncNoop,
  zoomIn: noop,
  zoomOut: noop,
  fitPoints: noop,
};

const { useGoogleMapContext, GoogleMapContextProvider } = createReactContext('GoogleMap', defaultContext);

export type { GoogleMapContext };
export { useGoogleMapContext, GoogleMapContextProvider };
