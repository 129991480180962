import { createContext, useContext } from 'react';

import { ScrollableMethods } from '@swe/shared/ui-kit/components/scrollable';
import { noop } from '@swe/shared/utils/func';

type ModalContextType = {
  scrollRef?: ScrollableMethods | null;
  headerOffsetBlock?: HTMLElement | null;
  setHeaderOffsetBlock: (el: HTMLElement | null) => void;
};

const ModalContext = createContext<ModalContextType>({
  scrollRef: undefined,
  headerOffsetBlock: undefined,
  setHeaderOffsetBlock: noop,
});

const ModalContextProvider = ModalContext.Provider;

const useModalContext = () => useContext(ModalContext);

export type { ModalContextType };
export { ModalContextProvider, useModalContext };
