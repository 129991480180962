import { useDrag } from '@use-gesture/react';
import cx from 'clsx';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useSpring, a } from 'react-spring';

import { useScrollLocker } from '@swe/shared/tools/window';
import Backdrop from '@swe/shared/ui-kit/components/backdrop';
import { Button, ButtonProps } from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';
import { FocusTrap } from '@swe/shared/ui-kit/components/focus-trap';
import { CloseIcon } from '@swe/shared/ui-kit/components/icon';

import { SPRING_ANIMATION_CONFIG } from '@swe/shared/ui-kit/components/modal/config';
import { ModalMobileBaseProps } from '@swe/shared/ui-kit/components/modal/mobile/types';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import { useAnimationSettings } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ModalMobileDefaultProps = ModalMobileBaseProps &
  ComponentHasChildren & {
    paddings?: boolean;
    fillHeight?: boolean;
    renderBackButton?: (props: Pick<ButtonProps, 'className' | 'size'>) => ReactNode;
    inPortal?: boolean;
  };

const ModalMobileDefault = ({
  visible,
  heading,
  children,
  subHeading,
  footer,
  paddings = true,
  fillHeight,
  showCloseButton = true,
  renderBackButton,
  onOpen,
  onClose,
  onCloseAnimationEnd,
  ariaLabel,
  headingTrail,
  inPortal = true,
}: ModalMobileDefaultProps) => {
  useScrollLocker(visible);
  // const isIOS = useIsIOS();
  const rootRef = useRef<HTMLDivElement>(null);
  const stopperRef = useRef<HTMLDivElement>(null);
  const holderRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<number | null>(null);
  const willBeClosedRef = useRef(false);
  const [isActiveTrap, setIsActiveTrap] = useState(false);
  const { enabled: isAnimationEnabled } = useAnimationSettings();
  const [localVisible, setLocalVisible] = useState(isAnimationEnabled ? false : visible);
  // const [isBodySwipeActive, setBodySwipe] = useState(false);
  const [{ y }, api] = useSpring(() => ({
    y: isAnimationEnabled ? -2000 : 0,
    immediate: true,
    config: SPRING_ANIMATION_CONFIG,
  }));

  const updateModalHeight = useCallback(() => {
    const h = rootRef.current && rootRef.current.offsetHeight;
    if (h !== null) {
      heightRef.current = h;
    }
  }, []);
  const triggerCloseAnimation = useCallback(() => {
    setIsActiveTrap(false);
    willBeClosedRef.current = true;
    api.start({
      y: heightRef.current,
      immediate: false,
      onResolve: () => {
        setLocalVisible(false);
        onCloseAnimationEnd?.();
        willBeClosedRef.current = false;
      },
    });
  }, [api, onCloseAnimationEnd]);
  const triggerOpenAnimation = useCallback(() => {
    onOpen?.();
    api.start({
      y: 0,
      immediate: false,
      onResolve: () => {
        setIsActiveTrap(true);
      },
    });
  }, [api, onOpen]);
  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);
  const handleBackdropClick = useCallback(() => {
    if (!willBeClosedRef.current) {
      close();
    }
  }, [close]);

  useEffect(() => {
    const holder = stopperRef.current;
    const touchHandler = (e: TouchEvent) => {
      e.preventDefault();
    };

    holder?.addEventListener('touchstart', touchHandler);

    return () => {
      holder?.removeEventListener('touchstart', touchHandler);
    };
  }, [updateModalHeight, localVisible]);

  useEffect(() => {
    if (localVisible) {
      updateModalHeight();
      if (heightRef.current) {
        y.set(heightRef.current);
        triggerOpenAnimation();
      }
    }
  }, [localVisible, updateModalHeight, triggerOpenAnimation, y]);

  useEffect(() => {
    if (!visible && localVisible) {
      triggerCloseAnimation();
    }

    if (visible && !localVisible) {
      setLocalVisible(true);
    }
  }, [visible, localVisible, triggerCloseAnimation]);

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleScroll = useCallback(
  //   debounce(({ scrollTop }: OnScrollPayload) => {
  //     if (isIOS) return;
  //     setBodySwipe(scrollTop === 0);
  //   }, 250),
  //   [isIOS],
  // );

  useDrag(
    ({ last, direction: [, dirY], velocity: [, vy], movement: [, my], cancel, tap }) => {
      if (!onClose || tap || heightRef.current === null) {
        return;
      }

      if (my < -5) {
        cancel();
      }

      if (last) {
        if ((vy > 2 && dirY !== -1) || my > heightRef.current * 0.3) {
          close();
        } else {
          api.start({
            y: 0,
          });
        }
      } else {
        api.start({
          y: my,
          immediate: true,
        });
      }
    },
    { enabled: true, target: holderRef, axis: 'y', filterTaps: true },
  );
  // useDrag(
  //   ({ last, direction: [, dirY], velocity: [, vy], movement: [, my], cancel, tap, event }) => {
  //     if (!onClose || tap || heightRef.current === null) {
  //       return;
  //     }
  //
  //     if (my < -5 || !isBodySwipeActive) {
  //       return cancel();
  //     }
  //
  //     if (last) {
  //       if (my > 0 && !isBodySwipeActive) {
  //         return cancel();
  //       }
  //
  //       if ((vy > 2 && dirY !== -1) || my > heightRef.current * 0.3) {
  //         close();
  //       } else {
  //         event.preventDefault();
  //         api.start({ y: 0 });
  //       }
  //     } else {
  //       api.start({ y: my, immediate: true });
  //     }
  //   },
  //   {
  //     enabled: isBodySwipeActive && !isIOS,
  //     target: rootRef,
  //     axis: 'y',
  //     filterTaps: true,
  //     pointer: { touch: true },
  //     eventOptions: { passive: false },
  //   },
  // );

  const content = localVisible ? (
    <>
      <Backdrop
        visible={visible}
        onClick={handleBackdropClick}
      />
      <FocusTrap isActive={isActiveTrap}>
        <a.div
          aria-modal="true"
          role="dialog"
          aria-label={ariaLabel}
          className={cx(styles.root, fillHeight && styles.root_fillHeight, paddings === false && styles._noPaddings)}
          style={{ y }}
          ref={rootRef}
        >
          {onClose && showCloseButton && (
            <Button
              className={styles.close}
              color="light"
              size="sm"
              icon={CloseIcon}
              onClick={close}
              ariaLabel="Close"
            />
          )}
          {renderBackButton?.({ className: styles.backBtn, size: 'sm' })}
          {(heading || subHeading) && (
            <Container className={styles.header}>
              <SectionHeading
                level={1}
                size="lg"
                subHeading={subHeading}
                trailBlock={headingTrail}
              >
                {heading}
              </SectionHeading>
            </Container>
          )}
          <Scrollable
            className={styles.scroll}
            fade={false}
          >
            <Container className={styles.content}>{children}</Container>
          </Scrollable>
          {footer && <Container className={styles.footer}>{footer}</Container>}
          {onClose && (
            <div
              ref={holderRef}
              className={styles.holder}
            />
          )}
          <div
            ref={stopperRef}
            className={styles.stopper}
          />
        </a.div>
      </FocusTrap>
    </>
  ) : null;

  return inPortal ? <Portal>{content}</Portal> : content;
};

export type { ModalMobileDefaultProps };
export { ModalMobileDefault };
export default ModalMobileDefault;
