import Text from '@swe/shared/ui-kit/components/text';

import { AgeFormProps } from 'common/containers/age-restriction-modal/components/age-form';

type AgeFormRestrictionTextProps = Pick<AgeFormProps, 'minimalCustomerAge'> & {
  useMinimalCustomerAge: boolean;
};

const TEXT_DEFAULT = 'Please confirm you are 21+ years old or a valid medical marijuana patient.';
const TEXT_21_PLUS = 'You must be at least 21 years old to view cannabis content.';
const TEXT_18_PLUS = 'You must be at least 18 years old with a medical card or 21 years old to view cannabis content.';

const AgeFormRestrictionText = ({ minimalCustomerAge, useMinimalCustomerAge }: AgeFormRestrictionTextProps) => {
  const text = useMinimalCustomerAge ? (minimalCustomerAge >= 21 ? TEXT_21_PLUS : TEXT_18_PLUS) : TEXT_DEFAULT;

  return <Text size="lg">{text}</Text>;
};

export { AgeFormRestrictionText };
