import { Error500 } from '@swe/shared/ui-kit/components/error-500';

import { useEffect } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Cont404 } from '@swe/shop-ui/common/containers/404';

const is404Error = (error: unknown) => isRouteErrorResponse(error) && error.status === 404;

const isChankLoadingError = (error: unknown) =>
  error && typeof error === 'object' && 'name' in error && error.name.includes('ChunkLoadError');

const ErrorPage = () => {
  const _error = useRouteError();
  useEffect(() => console.error(_error), [_error]);

  if (is404Error(_error)) {
    return <Cont404 />;
  }

  const error = isChankLoadingError(_error) ? 'A new version of the page is available' : _error;

  return <Error500 error={error} />;
};

export default ErrorPage;
