import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Params = { dateOfBirth?: DateISOString; firstName: string; lastName: string; medicalId: string };

const NAME = '/Registration/ConfirmExternalData';
const ConfirmExternalDataEndpoint = createModernEndpoint<Params, undefined>(NAME);

export default ConfirmExternalDataEndpoint;
export { ConfirmExternalDataEndpoint };
export type { Params };
