import { useCallback } from 'react';

import {
  ProgressContinuous,
  ProgressContinuousProps,
} from '@swe/shared/ui-kit/components/progress/variants/continuous';
import { ProgressDiscrete, ProgressDiscreteProps } from '@swe/shared/ui-kit/components/progress/variants/discrete';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';

type ProgressProps = {
  bottomLeftLabel?: string;
  bottomRightLabel?: string;
  topLeftLabel?: string;
  topRightLabel?: string;
} & (({ variant: 'continuous' } & ProgressContinuousProps) | ({ variant: 'discrete' } & ProgressDiscreteProps));

const Progress = (props: ProgressProps) => {
  let progressVariant;
  const { variant, topLeftLabel, bottomLeftLabel, bottomRightLabel, topRightLabel } = props;

  // eslint-disable-next-line react/destructuring-assignment
  switch (variant) {
    default:
    case 'continuous':
      progressVariant = <ProgressContinuous {...props} />;
      break;
    case 'discrete':
      progressVariant = <ProgressDiscrete {...props} />;
      break;
  }

  const getTextRowElement = useCallback(
    (leftLabel?: string, rightLabel?: string) =>
      (leftLabel || rightLabel) && (
        <Stack
          direction="row"
          className={styles.textRow}
        >
          <div className={styles.textBoxLeft}>
            {leftLabel && (
              <Text
                variant="control"
                size="lg"
              >
                {leftLabel}
              </Text>
            )}
          </div>
          <div className={styles.textBoxRight}>
            {rightLabel && (
              <Text
                variant="control"
                size="lg"
              >
                {rightLabel}
              </Text>
            )}
          </div>
        </Stack>
      ),
    [],
  );

  return (
    <Stack className={styles.root}>
      {getTextRowElement(topLeftLabel, topRightLabel)}
      {progressVariant}
      {getTextRowElement(bottomLeftLabel, bottomRightLabel)}
    </Stack>
  );
};

export type { ProgressProps };
export { Progress };
export default Progress;
