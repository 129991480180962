import { Skeleton } from '@swe/shared/ui-kit/components/skeleton';

import { SkeletonSectionHeader } from 'domains/product/components/product-details/common/skeleteon-section-header';

const DiscountSkeleton = () => {
  return (
    <Skeleton
      uniqueKey="product_description"
      height={50}
    >
      <SkeletonSectionHeader />
    </Skeleton>
  );
};

export { DiscountSkeleton };
