const convertLetterToNumber = (input: string) => {
  const map = ['', '', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz'];
  const res = input.split('').map((l: string) => {
    const index = map.findIndex((i) => i.includes(l.toLocaleLowerCase()));
    return index > 1 ? `${index}` : l;
  });
  return res.join('');
};

const hasLetters = (inp: string) => {
  return /[A-z]/.test(inp);
};

const formatPhone = (input: string, tryConvertLetter = false) => {
  const cleaned = (tryConvertLetter ? convertLetterToNumber(input) : input).replace(/[^A-z0-9]/g, '');
  const match = cleaned.match(/^(1|)?([A-z0-9]{3})([A-z0-9]{3})([A-z0-9]{4})$/);
  if (match) {
    const match2 = match[2][0] === '1' ? match[2].substr(1) : match[2];
    return ['+1 ', '(', match2, ') ', match[3], '-', match[4]].join('');
  }
  return '';
};

export { formatPhone, convertLetterToNumber, hasLetters };
