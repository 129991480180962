import Tag, { TagProps } from '@swe/shared/ui-kit/components/tag';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { ProductVariant } from 'entities/product/product';

type VariantProps = {
  name: string;
  isPacked: boolean;
} & Pick<TagProps, 'active' | 'className'>;

const Variant = ({ className, name, active, isPacked }: VariantProps) => {
  return (
    <Tag
      className={className}
      name={name}
      active={active}
      size="sm"
      type="alt"
    >
      {name}
      {!isPacked && ' · by weight'}
    </Tag>
  );
};

type VariantsProps = {
  variants: ProductVariant[];
} & ComponentHasClassName;

const Variants = ({ className, variants }: VariantsProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.variants}>
        {variants.map((variant, idx) => (
          <Variant
            key={variant.id}
            name={variant.name}
            active={variants.length > 1 && idx === 0}
            isPacked={variant.isPacked}
          />
        ))}
      </div>
      {/*
      // TODO: in more optimal way
      <div
        className={cx(
          styles.ellipsis,
          isOverflowing && styles.ellipsis_visible,
          shouldRenderMark && styles.ellipsis_added,
        )}
      >
        <MoreIcon size="sm" />
      </div>
*/}
    </div>
  );
};

export type { VariantsProps, VariantProps };
export { Variants, Variant };
