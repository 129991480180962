import throttle from 'lodash/throttle';
import { useEffect } from 'react';

import { EventEmitter } from '@swe/shared/tools/event-emitter';

class Resizer extends EventEmitter<{ onResize: undefined }> {}
const R = new Resizer();
const resizeDispatcher = () => R.fire('onResize', undefined);

const useResize = (callback?: () => void, wait = 250) => {
  useEffect(() => {
    if (callback) {
      callback();

      const throttledCallback = throttle(callback, wait);

      if (!R.listeners.onResize || R.listeners.onResize.size === 0) {
        window.addEventListener('resize', resizeDispatcher);
      }

      R.on('onResize', throttledCallback);

      return () => {
        R.off('onResize', throttledCallback);

        if (!R.listeners.onResize || R.listeners.onResize.size === 0) {
          window.removeEventListener('resize', resizeDispatcher);
        }
      };
    }
  }, [callback, wait]);
};

export { useResize };
