import yup from '@swe/shared/tools/yup';

import { Checkbox, CheckboxProps } from '@swe/shared/ui-kit/components/form/checkbox';
import CheckboxGroup, { CheckboxGroupProps } from '@swe/shared/ui-kit/components/form/checkbox-group';
import { Builder, SubmitButton, Button, useFormContext } from '@swe/shared/ui-kit/components/form/core';
import withFormField from '@swe/shared/ui-kit/components/form/core/field';
import File, { InputFileProps, InputFileValue } from '@swe/shared/ui-kit/components/form/file';
import { Input, InputProps } from '@swe/shared/ui-kit/components/form/input';
import InputDate, { InputDateProps } from '@swe/shared/ui-kit/components/form/input-date';
import RadioGroup, { RadioGroupProps } from '@swe/shared/ui-kit/components/form/radio-group';
import { RangeProps, Range } from '@swe/shared/ui-kit/components/form/range';
import Select, { SelectProps } from '@swe/shared/ui-kit/components/form/select';
import Textarea, { TextareaProps } from '@swe/shared/ui-kit/components/form/textarea';

export { useWatch } from 'react-hook-form';

const FormInput = withFormField<string, InputProps>(Input);
const FormTextarea = withFormField<string, TextareaProps>(Textarea);
const FormCheckbox = withFormField<boolean, CheckboxProps>(Checkbox);
const FormSelect = withFormField<string, SelectProps>(Select);
const FormRange = withFormField<[number, number], RangeProps>(Range);
const FormInputDate = withFormField<DateISOString, InputDateProps>(InputDate);
/**
 * TODO: Fix typescript error
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const FormFile = withFormField<InputFileValue, InputFileProps>(File);
/**
 * TODO: Support FormControlRef after CommonWrapper refactoring
 */
const FormRadioGroup = withFormField<string | null, RadioGroupProps<string | null>>(RadioGroup);
const FormCheckboxGroup = withFormField<any, CheckboxGroupProps>(CheckboxGroup);

const Form = {
  Builder,
  SubmitButton,
  Button,
  Input: FormInput,
  InputDate: FormInputDate,
  Checkbox: FormCheckbox,
  CheckboxGroup: FormCheckboxGroup as <VT = string>(
    props: Omit<CheckboxGroupProps<VT>, 'value'>,
  ) => ReturnType<typeof FormCheckboxGroup>,
  Select: FormSelect as <VT = string>(props: Omit<SelectProps<VT>, 'value'>) => ReturnType<typeof FormSelect>,
  RadioGroup: FormRadioGroup as <VT = string | null>(
    props: Omit<RadioGroupProps<VT>, 'value'>,
  ) => ReturnType<typeof FormRadioGroup>,
  Range: FormRange,
  Textarea: FormTextarea,
  File: FormFile,
};

export { useFormContext, yup, Form };
export default Form;
