import { useGeolocationContext } from '@swe/shared/providers/geolocation';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigateToStore } from 'common/containers/header/containers/sub-header/use-cases/use-navigate-to-store';
import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useStoresFiltered } from 'common/use-cases/use-stores';
import { FullShopFulfillmentType } from 'entities/shop/info';

type UsePickupArguments = {
  isEnabled?: boolean;
};

const usePickup = ({ isEnabled }: UsePickupArguments) => {
  const { currentStoreId, saveFulfillmentPreferences } = usePickupDeliverySettings();
  const { position: location, acquire } = useGeolocationContext();
  const { stores, getById, isLoading } = useStoresFiltered({ location });
  const [selectedStoreId, setSelectedStoreId] = useState<EntityID<number> | undefined>();

  const selectedStore = useMemo(() => getById(selectedStoreId), [getById, selectedStoreId]);
  const { navigate } = useNavigateToStore(selectedStore);

  const { platformOs } = usePlatformOs();

  const save = useCallback(async () => {
    await saveFulfillmentPreferences({
      fulfillmentType: FullShopFulfillmentType.InStorePickup,
      preferencesStoreId: parseInt(String(selectedStoreId ?? stores.current.id), 10),
      platformOs,
    });
    if (selectedStoreId && selectedStoreId !== currentStoreId) {
      await navigate();
    }
  }, [currentStoreId, navigate, platformOs, saveFulfillmentPreferences, selectedStoreId, stores]);
  const clear = useCallback(() => {
    setSelectedStoreId(undefined);
  }, []);

  useEffect(() => {
    if (isEnabled) {
      void acquire();
    }
  }, [acquire, isEnabled]);
  return {
    stores: stores.withMileage.length === 0 ? stores.withPickup : stores.withMileage,
    nearestStores: stores.nearest,
    selectedStoreId,
    currentStore: stores.current,
    selectedStore,
    isLoading,
    setSelectedStoreId,
    getById,
    hasStoresWithDelivery: stores.withDelivery.length > 0,
    save,
    clear,
  };
};

export type { UsePickupArguments };
export { usePickup };
