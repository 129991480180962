import { SnackbarService } from '@swe/shared/providers/snackbar';
import { useIntl } from '@swe/shared/tools/intl';

import { useCallback, useEffect, useRef } from 'react';

import { useRouterPathname, useRouterNavigate } from 'common/router';

import { Routes } from 'common/router/constants';
import { useSaleType } from 'common/use-cases/use-sale-type';

type UseNotificationChangeQuantityParams = {
  availableItemsQuantity: number | undefined;
  isLoadingCart: boolean;
};

const useNotificationChangeQuantity = ({
  availableItemsQuantity,
  isLoadingCart,
}: UseNotificationChangeQuantityParams) => {
  const isReady = useRef(false);
  const localAvailableItemsQuantity = useRef(availableItemsQuantity ?? 0);

  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();
  const { saleType } = useSaleType();
  const prevSaleType = useRef(saleType);
  const skipNext = useRef(false);
  const { intl } = useIntl();
  const message = intl.t('cart.changeQtyMsg', undefined, 'Sorry, stock availability has changed; we updated your cart');

  useEffect(() => {
    if (!isLoadingCart && !isReady.current) {
      isReady.current = !isLoadingCart;
      localAvailableItemsQuantity.current = availableItemsQuantity ?? 0;
    }
    if (
      isReady.current &&
      localAvailableItemsQuantity.current !== availableItemsQuantity &&
      prevSaleType.current === saleType &&
      pathname !== Routes.Cart
    ) {
      if (!skipNext.current) {
        SnackbarService.push({
          type: 'warning',
          message,
          onClick: () => navigate(Routes.Cart),
          autoHideDuration: 2000,
        });
      }
      skipNext.current = false;
    }

    localAvailableItemsQuantity.current = availableItemsQuantity ?? 0;
    prevSaleType.current = saleType;
  }, [availableItemsQuantity, isLoadingCart, message, navigate, pathname, saleType]);

  const applyLocalAvailableItemsQuantity = useCallback((v: number) => {
    localAvailableItemsQuantity.current += v;
  }, []);

  const setLocalAvailableItemsQuantity = useCallback((v: number) => {
    localAvailableItemsQuantity.current = v;
  }, []);

  const setSkipNext = useCallback((v: boolean) => {
    skipNext.current = v;
  }, []);

  return {
    applyLocalAvailableItemsQuantity,
    setLocalAvailableItemsQuantity,
    setSkipNext,
  };
};

export { useNotificationChangeQuantity };
