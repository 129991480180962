import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { Cart } from 'entities/cart/cart';
import { StoreSaleType } from 'entities/shop/sale-type';

type RemoveFromCartManyEndpointParams = {
  platformOs: PlatformOs;
  saleType?: StoreSaleType;
  variants: {
    variantId: EntityID;
    qty: number;
  }[];
};

const NAME = '/Cart/RemoveProducts';
const RemoveFromCartManyEndpoint = createEndpoint<RemoveFromCartManyEndpointParams, Cart>(NAME);

export type { RemoveFromCartManyEndpointParams };
export { RemoveFromCartManyEndpoint };
export default RemoveFromCartManyEndpoint;
