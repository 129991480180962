import cn from 'clsx';
import { CSSProperties, ForwardedRef, forwardRef, KeyboardEvent, ReactElement } from 'react';

import { useCurrentBreakpoint } from '@swe/shared/tools/media';
import { CellProps } from '@swe/shared/ui-kit/components/grid/components/cell';
import { BothSpacings, Columns, HAlign, VAlign } from '@swe/shared/ui-kit/components/grid/types';
import {
  PropValueByMediaWithShorthand,
  useViewportBreakpointToValueMap,
} from '@swe/shared/ui-kit/components/media/utils';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName, Spacing } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

const getProperSpacing = (spacing: Spacing | BothSpacings, spacingIndex: 0 | 1) =>
  Array.isArray(spacing) ? spacing[spacingIndex] : spacing;

const HALIGN_TO_JUSTIFY_CONTENT: Record<HAlign, string> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  around: 'space-around',
  between: 'space-between',
};
const toJustifyContentValue = (hAlign: HAlign) => HALIGN_TO_JUSTIFY_CONTENT[hAlign];

const VALIGN_TO_ALIGN_ITEMS: Record<VAlign, string> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
  baseline: 'baseline',
};
const toAlignItemsValue = (vAlign: VAlign) => VALIGN_TO_ALIGN_ITEMS[vAlign];

type EmptyElement = false | '' | 0 | null | undefined;

type GridProps = ComponentHasClassName & {
  columns?: Columns;
  spacing?: PropValueByMediaWithShorthand<Spacing | BothSpacings>;
  vAlign?: PropValueByMediaWithShorthand<VAlign>;
  hAlign?: PropValueByMediaWithShorthand<HAlign>;
  children?: EmptyElement | ReactElement<CellProps> | (EmptyElement | ReactElement<CellProps>)[];
  wrap?: boolean;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  tabIndex?: number;
};

export const _Row = (
  { columns = 12, spacing, hAlign, vAlign, children, wrap = true, className, onClick, tabIndex, onKeyDown }: GridProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const theme = useTheme();
  const bp = useCurrentBreakpoint();

  const spacingsByViewportSize = useViewportBreakpointToValueMap<Spacing | BothSpacings>(spacing, 'sm');
  const vAlignByViewportSize = useViewportBreakpointToValueMap<VAlign>(vAlign, 'stretch');
  const hAlignByViewportSize = useViewportBreakpointToValueMap<HAlign>(hAlign, 'start');
  const spacingVal = spacingsByViewportSize[bp];
  const vAlignVal = vAlignByViewportSize[bp];
  const hAlignVal = hAlignByViewportSize[bp];

  const cssVars = {
    '--sw-grid-columns': columns,
    '--sw-grid-column-spacing': theme.spacing.scale[getProperSpacing(spacingVal, 0)],
    '--sw-grid-row-spacing': theme.spacing.scale[getProperSpacing(spacingVal, 1)],
    '--sw-grid-row-v-align': toAlignItemsValue(vAlignVal),
    '--sw-grid-row-h-align': toJustifyContentValue(hAlignVal),
  } as CSSProperties;

  return (
    <div
      ref={ref}
      className={cn(className, !wrap && styles._noWrap)}
      tabIndex={tabIndex}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        style={cssVars}
        className={styles.row}
      >
        {children}
      </div>
    </div>
  );
};

const Row = forwardRef<HTMLDivElement, GridProps>(_Row);

export type { GridProps };
export default Row;
