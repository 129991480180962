import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import { ProductTierPricingSkeleton } from './skeleton';

import { ProductSection } from 'common/components/product-section';

import ProductTierPricingTable from 'common/components/tier-pricing';
import { ProductVariant } from 'entities/product/product';

type ProductTierPricingProps = {
  variant: ProductVariant;
};

const ProductTierPricing = ({ variant }: ProductTierPricingProps) => {
  if (variant.tierPricing?.length === 0) {
    return null;
  }

  return (
    <ProductSection
      title="Tier Price"
      expandable={false}
    >
      <ProductTierPricingTable variant={variant} />
    </ProductSection>
  );
};

const ProductTierPricingSkeletonized = withSkeleton(ProductTierPricing, ProductTierPricingSkeleton, ['variant']);

export type { ProductTierPricingProps };
export { ProductTierPricing, ProductTierPricingSkeletonized };
export default ProductTierPricing;
