import cn from 'clsx';
import { ElementType, SyntheticEvent } from 'react';

import { Override } from '@swe/shared/types/utility';
import Box, { BoxProps } from '@swe/shared/ui-kit/components/box';
import { ComponentHasAlignment, ComponentHasSize, Sizes, Alignment } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type TextBaseSize = Sizes<'sm' | 'md' | 'lg' | 'xl'>;
export type TextHeadingSize = Sizes<TextBaseSize | 'xs' | 'xxs'>;

type TextBaseProps<T extends ElementType> = BoxProps<T> &
  ComponentHasSize<TextBaseSize> &
  ComponentHasAlignment<Alignment> & {
    variant?: 'body' | 'control';
    uppercase?: boolean;
    lineThrough?: boolean;
    noWrap?: boolean;
    onClick?: (e: SyntheticEvent) => void;
    adaptive?: boolean;
  };

type TextHeadlineProps<T extends ElementType> = Override<
  TextBaseProps<T>,
  ComponentHasAlignment<Alignment> & {
    variant: 'headline';
    size?: TextHeadingSize;
  }
>;

export type TextProps<T extends ElementType> = TextHeadlineProps<T> | TextBaseProps<T>;

export const Text = <T extends ElementType = 'div'>({
  className,
  size = 'md',
  variant = 'body',
  uppercase,
  lineThrough,
  noWrap,
  adaptive = false,
  align = 'left',
  ...boxProps
}: TextProps<T>) => {
  return (
    <Box
      {...boxProps}
      className={cn(
        styles.root,
        styles[`_variant_${variant}`],
        styles[`_size_${size}`],
        styles[`_align_${align}`],
        uppercase && styles._uppercase,
        lineThrough && styles._lineThrough,
        noWrap && styles._noWrap,
        adaptive && styles._adaptive,
        className,
      )}
    />
  );
};

export default Text;
