import { ComponentType, useMemo } from 'react';

import BoxRatio from '@swe/shared/ui-kit/components/box-ratio';
import SVG from '@swe/shared/ui-kit/components/svg';

import { useThemeGetter } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

export type IllustrationProps = ComponentHasClassName & {
  onLoad?: () => void;
};
export const makeIllustration = (name: string): ComponentType<IllustrationProps> => {
  return function Illustration({ className, onLoad }: IllustrationProps) {
    const { getIllustrationSrc } = useThemeGetter();
    const assetSrs = useMemo(() => getIllustrationSrc({ illustrationName: name }), [getIllustrationSrc]);

    return (
      <BoxRatio
        className={className}
        ratio={1}
      >
        <SVG
          src={assetSrs}
          width="100%"
          height="100%"
          role="presentation"
          onLoad={onLoad}
        />
      </BoxRatio>
    );
  };
};

export const CalendarIllustration = makeIllustration('calendar');
export const CartIllustration = makeIllustration('cart');
export const ChatIllustration = makeIllustration('chat');
export const CreateNewPasswordIllustration = makeIllustration('create-new-password');
export const EmailCodeIllustration = makeIllustration('email-code');
export const Error404Illustration = makeIllustration('error-404');
export const Error500Illustration = makeIllustration('error-500');
export const IdIllustration = makeIllustration('id');
export const NoDiscountsIllustration = makeIllustration('no-discounts');
export const NoProductsIllustration = makeIllustration('no-products');
export const NoPromoCodesIllustration = makeIllustration('no-promo-codes');
export const PasswordRecoveryIllustration = makeIllustration('password-recovery');
export const PhoneCodeIllustration = makeIllustration('phone-code');
export const PhoneNumberIllustration = makeIllustration('phone-number');
export const ReferralIllustration = makeIllustration('referral');
