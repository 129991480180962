import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { Product, ProductVariant } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = Product;
type Params = {
  saleType?: StoreSaleType;
  variantId: ProductVariant['id'];
};
type GetProductError = APIErrorExtended;

const NAME = '/Products/GetProductByVariantId';
const GetProductByVariantIdEndpoint = createModernEndpoint<Params, Data, GetProductError>(NAME);

export type { GetProductError };
export default GetProductByVariantIdEndpoint;
