import { Error404 } from '@swe/shared/ui-kit/components/error-404';

import React, { useCallback } from 'react';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

const Cont404 = () => {
  const navigate = useRouterNavigate();

  const goToHome = useCallback(() => {
    void navigate(Routes.Home);
  }, [navigate]);

  return <Error404 actions={[{ children: 'Go to homepage', name: 'go-home', size: 'lg', onClick: goToHome }]} />;
};

export { Cont404 };
