import { ReactNode } from 'react';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type RenderWithChildren = (props: ComponentHasChildren & { href: any }) => ReactNode;

type BreadcrumbProps = ComponentHasChildren & {
  renderLink?: RenderWithChildren;
  href?: any;
  isLast?: boolean;
};

const Breadcrumb = ({ children, renderLink, href, isLast }: BreadcrumbProps) => {
  const content = renderLink && !isLast && href ? renderLink({ children, href }) : children;
  return <div>{content}</div>;
};

export type { BreadcrumbProps };
export { Breadcrumb };
