import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

const NAME = '/Orders/GetCourierNumber';
const GetCourierNumberEndpoint = createModernEndpoint<
  {
    id: number;
  },
  string
>(NAME);

export { GetCourierNumberEndpoint };
export default GetCourierNumberEndpoint;
