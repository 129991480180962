import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { Notification } from 'entities/profile/notifications';

type Params = undefined;
type Data = {
  promotions: Notification[];
  transactions: Notification[];
};
type Error = APIErrorExtended;

const NAME = '/Notifications/Get';
const GetProfileNotificationsEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default GetProfileNotificationsEndpoint;
