import cx from 'clsx';
import { useCallback, useEffect } from 'react';

import { useCarousel } from '@swe/shared/ui-kit/components/carousel-v2/core';
import { useCarouselContext } from '@swe/shared/ui-kit/components/carousel-v2/core/context';
import { useProgress } from '@swe/shared/ui-kit/components/carousel-v2/core/use-progress';
import { CarouselItemT, CarouselProps, isImage } from '@swe/shared/ui-kit/components/carousel-v2/types';

import Image from '@swe/shared/ui-kit/components/image';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { omit } from '@swe/shared/utils/object';

import styles from './styles.module.scss';

type ThumbsProps = ComponentHasClassName & Pick<CarouselProps, 'items' | 'thumbClassName'>;

const Thumbs = ({ className, thumbClassName, items }: ThumbsProps) => {
  const { Root, Slide, api } = useCarousel({
    infinite: false,
    autoplay: false,
    options: {
      containScroll: 'keepSnaps',
      dragFree: true,
    },
  });
  const { selectedIndex } = useProgress();
  const { set } = useCarouselContext();

  const renderItem = useCallback(
    (item: CarouselItemT, itemIdx: number) => {
      if (isImage(item)) {
        return (
          <div className={cx(styles.thumbImageWrapper, thumbClassName)}>
            <Image
              className={styles.thumbImage}
              {...omit(item, ['className'])}
              ratio={1}
              width={64}
            />
          </div>
        );
      }
      return <div>{itemIdx}</div>;
    },
    [thumbClassName],
  );

  useEffect(() => {
    api.set?.(selectedIndex);
  }, [api, selectedIndex]);

  if (items?.length === 1) return null;

  return (
    <Root classNames={{ root: className }}>
      {items.map((item, idx) => (
        <Slide
          key={idx}
          className={cx(styles.thumb, idx === selectedIndex && styles.thumb_selected)}
          onClick={() => set?.(idx)}
        >
          {renderItem(item, idx + 1)}
        </Slide>
      ))}
    </Root>
  );
};

export type { ThumbsProps };
export { Thumbs };
