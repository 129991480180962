import cx from 'clsx';
import React from 'react';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type BoxRatioProps = ComponentHasClassName & {
  children?: React.ReactNode;
  ratio?: number;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
};

const BoxRatio = ({ className, children, onClick, ratio = 1 }: BoxRatioProps) => {
  return (
    <div
      className={cx(className, styles.root)}
      style={{
        aspectRatio: String(ratio),
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export { BoxRatio };
export type { BoxRatioProps };
export default BoxRatio;
