import { RouterNavigate } from '@swe/shared/providers/router';
import { Route } from '@swe/shared/providers/router/constants';
import { invert } from '@swe/shared/utils/object';

enum Routes {
  Home = '/',
  Catalog = '/menu/[[...all]]',
  Discounts = '/menu/discounts',
  StoreAbout = '/about-us',
  Cart = '/cart',
  Checkout = '/cart/checkout',
  CheckoutDocuments = '/cart/checkout/documents',
  CheckoutDocumentsCategory = '/cart/checkout/documents/[category]',
  SignIn = '/log-in',
  SignUp = '/sign-up',
  PasswordRecovery = '/recovery/password',
  Profile = '/profile',
  ProfileOrders = '/profile/orders',
  ProfileOrderDetails = '/profile/orders/[id]',
  ProfileOrderChat = '/profile/orders/[id]/chat/[chatId]',
  ProfileContacts = '/profile/contact-information',
  ProfilePhoneUpdate = '/profile/contact-information/update-phone',
  ProfileBirthdayUpdate = '/profile/contact-information/update-date-of-birth',
  ProfileDocuments = '/profile/documents',
  ProfileDocument = '/profile/documents/[category]/[verificationId]',
  ProfileAddDocuments = '/profile/documents/[category]/add',
  ProfilePrivacy = '/profile/privacy-&-security',
  ProfilePasswordChange = '/profile/privacy-&-security/password-change',
  ProfilePersonalInformation = '/profile/privacy-&-security/personal-information',
  ProfileNotifications = '/profile/notifications',
  ProfilePromo = '/profile/promo-codes',
  ProfileLoyalty = '/profile/loyalty',
  ProfileReferralProgram = '/profile/referral-program',
  Agreements = '/policies',
  AgreementsTermsOfService = '/policies/terms-of-service',
  AgreementsPrivacyPolicy = '/policies/privacy-policy',
  AgreementsReferrerTermsOfUse = '/policies/referrer-terms-of-use',
  AgreementsReferralTermsOfUse = '/policies/referral-terms-of-use',
  MarketingSubscription = '/marketing/subscribe',
  MarketingSubscriptionSuccess = '/marketing/subscribe/success',
  MarketingSubscriptionEmailCheck = '/marketing/subscribe/email-check',
  MarketingUnsubscribe = '/unsubscribe',
  Guest = '/guest',
  NotFound = '/404',
  Reminders = '/profile/reminders',
}

const RouteToRouteNameMap = invert(Routes);

enum ModalRoutes {
  Deals = 'deals',
  Locations = 'locations',
  ProductDetails = 'product-details',
}

const isCheckoutRoute = (pathname: Routes) =>
  [Routes.Checkout, Routes.CheckoutDocuments, Routes.CheckoutDocumentsCategory].includes(pathname);

const BreadcrumbTitles: Record<Routes, string> = {
  [Routes.SignIn]: 'Sign In',
  [Routes.SignUp]: 'Sign Up',
  [Routes.Guest]: 'Guest Sign In',
  [Routes.PasswordRecovery]: 'Password Recovery',
  [Routes.Home]: 'Home',
  [Routes.Discounts]: 'Discounts',
  [Routes.Catalog]: 'Menu',
  [Routes.StoreAbout]: 'Store About',
  [Routes.Cart]: 'Cart',
  [Routes.Profile]: 'Profile',
  [Routes.ProfileOrders]: 'Order History',
  [Routes.ProfileOrderDetails]: 'Order Details',
  [Routes.ProfileOrderChat]: 'Store Chat',
  [Routes.ProfileContacts]: 'Contact Information',
  [Routes.ProfilePhoneUpdate]: 'Date of Birth',
  [Routes.ProfileBirthdayUpdate]: 'Phone Number ',
  [Routes.ProfileDocuments]: 'Documents',
  [Routes.ProfileDocument]: 'Document Details',
  [Routes.ProfileAddDocuments]: 'Add Documents',
  [Routes.ProfilePrivacy]: 'Privacy & Security',
  [Routes.ProfilePasswordChange]: 'Change Password',
  [Routes.ProfilePersonalInformation]: 'Personal Information',
  [Routes.ProfileNotifications]: 'Notification',
  [Routes.ProfilePromo]: 'Promo Codes',
  [Routes.ProfileLoyalty]: 'Loyalty',
  [Routes.ProfileReferralProgram]: 'Referral Program',
  [Routes.Agreements]: 'Agreements',
  [Routes.Checkout]: 'Checkout',
  [Routes.CheckoutDocuments]: 'Add Documents',
  [Routes.CheckoutDocumentsCategory]: 'Add Documents',
  [Routes.AgreementsTermsOfService]: 'Terms of Service',
  [Routes.AgreementsPrivacyPolicy]: 'Privacy Policy',
  [Routes.AgreementsReferrerTermsOfUse]: 'Referrer Terms of Use',
  [Routes.AgreementsReferralTermsOfUse]: 'Referral Terms of Use',
  [Routes.NotFound]: 'Not Found',
  [Routes.Reminders]: 'Back In Stock',
  [Routes.MarketingSubscription]: 'Marketing subscription',
  [Routes.MarketingSubscriptionSuccess]: 'Marketing subscription',
  [Routes.MarketingSubscriptionEmailCheck]: 'Marketing subscription email check',
  [Routes.MarketingUnsubscribe]: 'Marketing unsubscribe',
};

type ShopRoute = Route<Routes>;
type ShopRouterNavigate = RouterNavigate<Routes>;
const getBreadcrumbTitle = (route: Routes | string) => BreadcrumbTitles[route as Routes];

export { getBreadcrumbTitle, isCheckoutRoute, ModalRoutes, Routes, RouteToRouteNameMap };
export type { ShopRoute, ShopRouterNavigate };
