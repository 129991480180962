type EventEmitterImpl<Events extends object> = {
  on: <EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) => void;
  once: <EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) => void;
  off: <EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) => void;
  fire: <EventName extends keyof Events>(eventName: EventName, event: Events[EventName]) => void;
};

class EventEmitter<Events extends object> implements EventEmitterImpl<Events> {
  readonly __EVENTS__!: Events;

  readonly listeners: Partial<{
    [EventName in keyof Events]: Set<(event: Events[EventName]) => void>;
  }> = {};

  on<EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) {
    if (this.listeners[eventName]) {
      this.listeners[eventName]?.add(listener);
    } else {
      this.listeners[eventName] = new Set([listener]);
    }

    return () => this.off(eventName, listener);
  }

  off<EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) {
    if (this.listeners[eventName]) {
      this.listeners[eventName]?.delete(listener);

      if (this.listeners[eventName]?.size === 0) {
        delete this.listeners[eventName];
      }
    }
  }

  once<EventName extends keyof Events>(eventName: EventName, listener: (event: Events[EventName]) => void) {
    const cleaner = () => {
      this.off(eventName, listener);
      this.off(eventName, cleaner);
    };

    this.on(eventName, listener);
    this.on(eventName, cleaner);

    return cleaner;
  }

  fire<EventName extends keyof Events>(eventName: EventName, event: Events[EventName]) {
    const listeners = this.listeners[eventName];
    listeners?.forEach((listener) => listener(event));
  }
}

export type { EventEmitterImpl };
export { EventEmitter };
