import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = undefined;
type Data = {
  success: boolean;
};
const NAME = '/Registration/SkipRegistrationStep';
const SkipRegistrationStepEndpoint = createModernEndpoint<Params, Data, APIErrorExtended>(NAME);

export default SkipRegistrationStepEndpoint;
