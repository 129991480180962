import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

const TitleSkeleton = () => {
  const tokens = useTheme();

  return (
    <Skeleton
      uniqueKey="product_title"
      height={150}
    >
      <Bone
        x="0"
        y="0"
        rx={tokens.badge.md.borderRadius}
        ry={tokens.badge.md.borderRadius}
        width="70"
        height="24"
      />
      <Bone
        x="78"
        y="0"
        rx={tokens.badge.md.borderRadius}
        ry={tokens.badge.md.borderRadius}
        width="70"
        height="24"
      />
      <Bone
        x="156"
        y="0"
        rx={tokens.badge.md.borderRadius}
        ry={tokens.badge.md.borderRadius}
        width="70"
        height="24"
      />
      <Bone
        x="0"
        y="48"
        width="200"
        height="20"
      />
      <Bone
        x="0"
        y="76"
        width="300"
        height="42"
      />
    </Skeleton>
  );
};

export { TitleSkeleton };
