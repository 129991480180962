import Button from '@swe/shared/ui-kit/components/button';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { formatOrdinalNumber } from '@swe/shared/utils/number';
import { formatIntegerPrice } from '@swe/shared/utils/price';

import { useMemo } from 'react';

import GetReferralProgramEndpoint from 'endpoints/profile/referral-program/get-referral-program';
import { getDiscountAmountLabel } from 'entities/profile/referral-program';

type ReferralCongratulationsModalProps = {
  onClose?: () => void;
  visible: boolean;
};

const ReferralCongratulationsModal = ({ onClose, visible }: ReferralCongratulationsModalProps) => {
  const { data: referralProgram, isLoading } = GetReferralProgramEndpoint.useRequest();
  const {
    referralMinInvoiceCount = 0,
    referralMinInvoiceAmount = 0,
    referralPromocodeMinApplyAmount = 0,
    referralDiscountAmount = 0,
    referralDiscountType,
  } = referralProgram ?? {};

  const isPlacedOrdersNeeded: boolean = useMemo(() => referralMinInvoiceCount > 0, [referralMinInvoiceCount]);

  const referralDiscountLabel = useMemo(
    () => getDiscountAmountLabel(referralDiscountType, referralDiscountAmount),
    [referralDiscountAmount, referralDiscountType],
  );

  return (
    <Modal
      ariaLabel="Refferal"
      visible={visible && !isLoading}
      footer={
        !isLoading && (
          <Button
            block
            color="primary"
            name="continue"
            onClick={onClose}
          >
            Continue
          </Button>
        )
      }
    >
      <Stack spacing="sm">
        <Text
          mt="xs"
          variant="headline"
          size="md"
        >
          {isPlacedOrdersNeeded
            ? `Enjoy your ${referralDiscountLabel} off for your ${formatOrdinalNumber(
                referralMinInvoiceCount + 1,
              )} order!`
            : `Enjoy your ${referralDiscountLabel} off for the next order!`}
        </Text>
        <Text size="lg">
          {isPlacedOrdersNeeded
            ? [
                `You've successfully redeemed the referral code. Place ${referralMinInvoiceCount.toFixed()} orders (${formatIntegerPrice(
                  referralMinInvoiceAmount,
                )} each) to get a ${referralDiscountLabel} off discount code.`,
                referralPromocodeMinApplyAmount > 0 &&
                  `You can use it with any order above ${formatIntegerPrice(referralPromocodeMinApplyAmount)}.`,
              ]
                .filter(Boolean)
                .join(' ')
            : [
                "You've successfully redeemed the referral code. You can find your bonus in the profile",
                referralPromocodeMinApplyAmount > 0 &&
                  `and apply it to any order above ${formatIntegerPrice(referralPromocodeMinApplyAmount)}`,
              ]
                .filter(Boolean)
                .join(' ')}
        </Text>
      </Stack>
    </Modal>
  );
};

export { ReferralCongratulationsModal };
export default ReferralCongratulationsModal;
