import { FieldPath, FieldValues, useFormContext as useRHFFormContext, useWatch } from 'react-hook-form';

import { createReactContext } from '@swe/shared/tools/react/context';
import { AugmentedFormContext, CustomFormContext } from '@swe/shared/ui-kit/components/form/core/types';

const CONTEXT: CustomFormContext<any> = {
  validationSchema: undefined,
  initialValues: undefined,
  formId: '',
};

const { useCustomFormContext, CustomFormContextProvider } = createReactContext('CustomForm', CONTEXT);

const useFormContext = <FormValues extends FieldValues>(): AugmentedFormContext<FormValues> => {
  const formContext = useRHFFormContext<FormValues>();
  const customContext: CustomFormContext<FormValues> = useCustomFormContext();

  return {
    ...formContext,
    ...customContext,
  };
};

const useField = <FV extends FieldValues = FieldValues, FN extends FieldPath<FV> = FieldPath<FV>>(name: FN) =>
  useWatch<FV, FN>({ name });

const useFields = <FV extends FieldValues = FieldValues, FN extends FieldPath<FV>[] = FieldPath<FV>[]>(name: FN) =>
  useWatch<FV, FN>({ name });

const useFormValues = <FV extends FieldValues>() => useWatch() as FV;

export type { FieldPath };
export { useCustomFormContext, useFormContext, useField, useFields, useFormValues, CustomFormContextProvider };
