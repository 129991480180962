import { Wrapper, WrapperProps } from '@googlemaps/react-wrapper';
import { Handler, useWheel } from '@use-gesture/react';
import cx from 'clsx';
import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { usePreviousAny } from '@swe/shared/hooks';
import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';
import { acquireGeolocation, useGeolocation } from '@swe/shared/hooks/use-geolocation';
import { useGoogleApiContext } from '@swe/shared/providers/google-api';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { LOADER_OPTIONS } from '@swe/shared/tools/google/config';
import { Override } from '@swe/shared/types/utility';
import { UserMarker } from '@swe/shared/ui-kit/components/google-map/components';
import { FullscreenControl } from '@swe/shared/ui-kit/components/google-map/components/fullscreen';
import { GeolocationControl } from '@swe/shared/ui-kit/components/google-map/components/geolocation';
import { ZoomControl } from '@swe/shared/ui-kit/components/google-map/components/zoom';
import { MAP_DEFAULT_OPTIONS } from '@swe/shared/ui-kit/components/google-map/config';
import { GoogleMapContext, GoogleMapContextProvider } from '@swe/shared/ui-kit/components/google-map/context';
import { useMapListener } from '@swe/shared/ui-kit/components/google-map/hooks';
import { MapProps } from '@swe/shared/ui-kit/components/google-map/types';
import { geolocationToLatLng, getBoundsZoomLevel } from '@swe/shared/ui-kit/components/google-map/utils';

import Modal from '@swe/shared/ui-kit/components/modal';

import styles from './styles.module.scss';

const Map = forwardRef<GoogleMapContext, MapProps>(
  (
    {
      className,
      children,
      user,
      controls = ['zoom', 'geo', 'fullscreen'],
      watchLocation,
      centerPin,
      useLocationAsCenter = false,
      disableInteractions,
      fitPointsBounds,
      onUserLocationFound,
      onPanToMe,
      onDragEnd,
      onDragStart,
      onClick,
      ...options
    },
    ref,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const [isFullScreen, setFullscreen] = useState(false);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const previousUserCoords = usePreviousAny(user);
    const { position: realLocation } = useGeolocation({
      isEnabled: useLocationAsCenter,
      watch: watchLocation,
    });

    const handleZoomIn = useCallback(() => {
      const currentZoom = map?.getZoom() ?? 16;
      map?.setZoom(currentZoom + 1);
    }, [map]);

    const handleZoomOut = useCallback(() => {
      const currentZoom = map?.getZoom() ?? 4;
      map?.setZoom(currentZoom - 1);
    }, [map]);

    const handlePanToMe = useCallback(async () => {
      try {
        const geo = await acquireGeolocation();
        const location = geolocationToLatLng(geo);
        map?.panTo(location);
        map?.setZoom(16);
        onPanToMe?.(location);
      } catch (e: any) {
        SnackbarService.push({
          type: 'danger',
          heading: 'Location disabled',
          message: e.message,
        });
      }
    }, [map, onPanToMe]);

    const mergedOptions = useMemo(
      () =>
        merge(
          {},
          MAP_DEFAULT_OPTIONS,
          options,
          useLocationAsCenter ? { center: undefined } : {},
          disableInteractions
            ? { draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true }
            : {},
        ),
      [disableInteractions, options, useLocationAsCenter],
    );

    useEffect(() => {
      if (!map && mapRef.current) {
        setMap(new window.google.maps.Map(mapRef.current, mergedOptions));
      }
    }, [disableInteractions, map, mergedOptions, options, useLocationAsCenter]);

    const dragTimeoutRef = useRef<any>(null);
    const handleDragStart = useCallback(() => {
      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }

      onDragStart?.();
    }, [onDragStart]);
    const handleDragEnd = useCallback(() => {
      dragTimeoutRef.current = setTimeout(() => {
        const center = map?.getCenter()?.toJSON();
        onDragEnd?.(center);
      }, 400);
    }, [map, onDragEnd]);
    const handleClick = useCallback(
      (ev: google.maps.MapMouseEvent) => {
        if (ev.latLng) {
          map?.panTo(ev.latLng);
        }
        onClick?.(ev.latLng?.toJSON());
      },
      [map, onClick],
    );
    useMapListener(map, 'dragstart', disableInteractions ? undefined : handleDragStart);
    useMapListener(map, 'dragend', disableInteractions ? undefined : handleDragEnd);
    useMapListener(map, 'click', disableInteractions ? undefined : handleClick);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const wheelHandler = useCallback(
      throttle<Handler<'wheel'>>(({ first, velocity: [, vy], direction }) => {
        const [, yDir] = direction;
        if (vy > 0.25 || first) {
          if (yDir < 0) {
            handleZoomIn();
          } else {
            handleZoomOut();
          }
        }
      }, 250),
      [handleZoomIn, handleZoomOut],
    );
    const bindWheel = useWheel(wheelHandler);

    useDeepCompareEffect(() => {
      map?.setOptions(
        merge(
          {},
          MAP_DEFAULT_OPTIONS,
          options,
          useLocationAsCenter ? { center: undefined } : {},
          disableInteractions
            ? { draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true }
            : {},
        ),
      );
    }, [options, useLocationAsCenter]);

    useEffect(() => {
      if (useLocationAsCenter && realLocation && !user) {
        map?.panTo(realLocation);
        onUserLocationFound?.(realLocation);
      }
    }, [useLocationAsCenter, map, onUserLocationFound, user, realLocation]);

    useEffect(() => {
      const shouldPanToUser = centerPin ? !previousUserCoords && !!user : !!user;
      if (shouldPanToUser) {
        map?.panTo(user!);
      }
    }, [centerPin, map, user, previousUserCoords]);

    const fitPoints = useCallback(
      (points: google.maps.LatLngLiteral[]) => {
        if (mapRef.current && map) {
          const rect = mapRef.current.getBoundingClientRect();
          const bounding = new google.maps.LatLngBounds();
          const zoomLevel = getBoundsZoomLevel(bounding, { width: rect.width, height: rect.height });

          points.forEach((point) => bounding.extend(point));
          map.setZoom(zoomLevel);
          map.fitBounds(bounding);
        }
      },
      [map],
    );

    useEffect(() => {
      if (fitPointsBounds) {
        fitPoints(fitPointsBounds);
      }
    }, [fitPoints, fitPointsBounds]);

    const ctx = useMemo<GoogleMapContext>(
      () => ({ map, options, panToMe: handlePanToMe, zoomIn: handleZoomIn, zoomOut: handleZoomOut, fitPoints }),
      [fitPoints, handlePanToMe, handleZoomIn, handleZoomOut, map, options],
    );

    useImperativeHandle(ref, () => ctx);

    const prevent = useCallback((event: WheelEvent) => event.preventDefault(), []);

    useEffect(() => {
      const element = rootRef.current;
      if (mergedOptions.gestureHandling === 'greedy') {
        element?.addEventListener('wheel', prevent);
      }
      return () => {
        element?.removeEventListener('wheel', prevent);
      };
    }, [mergedOptions.gestureHandling, prevent]);

    const moveMapInsideModal = useCallback(() => {
      setTimeout(() => {
        if (!modalContentRef.current || !rootRef.current) return;
        modalContentRef.current.appendChild(rootRef.current);
        if (fitPointsBounds) {
          fitPoints(fitPointsBounds);
        }
      }, 0);
    }, [fitPoints, fitPointsBounds]);

    const moveMapBack = useCallback(() => {
      if (!wrapperRef.current || !modalContentRef.current || !rootRef.current) return;
      if (!modalContentRef.current?.contains(rootRef.current)) return;

      wrapperRef.current?.insertBefore(rootRef.current, wrapperRef.current.firstChild);
      if (fitPointsBounds) {
        fitPoints(fitPointsBounds);
      }
    }, [fitPoints, fitPointsBounds]);

    const handleModalClose = useCallback(() => {
      moveMapBack();
      setFullscreen(false);
    }, [moveMapBack]);

    return (
      <GoogleMapContextProvider value={ctx}>
        <div
          className={cx(className, styles.wrapper)}
          ref={wrapperRef}
        >
          <div
            className={styles.root}
            ref={rootRef}
            {...(disableInteractions ? {} : bindWheel())}
          >
            <div
              ref={mapRef}
              className={styles.map}
            >
              {(user || realLocation) && !centerPin && (
                <UserMarker
                  position={user! ?? realLocation!}
                  zIndex={2}
                />
              )}
              {children}
            </div>
            {controls.length > 0 && (
              <>
                {controls.includes('fullscreen') && (
                  <div className={cx(styles.controls, styles.controls_topRight)}>
                    <FullscreenControl
                      isEnabled={isFullScreen}
                      onActivate={() => setFullscreen(true)}
                      onDeactivate={handleModalClose}
                    />
                  </div>
                )}
                {controls.includes('zoom') && (
                  <div className={cx(styles.controls, styles.controls_columned, styles.controls_bottomLeft)}>
                    <ZoomControl
                      mode="in"
                      onClick={handleZoomIn}
                    />
                    <ZoomControl
                      mode="out"
                      onClick={handleZoomOut}
                    />
                  </div>
                )}
                {controls.includes('geo') && (
                  <div className={cx(styles.controls, styles.controls_columned, styles.controls_bottomRight)}>
                    <GeolocationControl onClick={handlePanToMe} />
                  </div>
                )}
              </>
            )}
            {centerPin && <div className={styles.centerPin}>{centerPin}</div>}
          </div>
          <Modal
            size="xxl"
            visible={isFullScreen}
            paddings={false}
            showCloseButton={false}
            isHeaderEnabled={false}
            onDeviceSwitch={moveMapBack}
            onOpen={moveMapInsideModal}
            onClose={handleModalClose}
            ariaLabel="Map"
          >
            <div
              key="eternity"
              ref={modalContentRef}
              style={{ width: '100%', height: '660px', maxHeight: '85vh' }}
            />
          </Modal>
        </div>
      </GoogleMapContextProvider>
    );
  },
);
Map.displayName = 'Map';

type GoogleMapWithWrapperProps = MapProps &
  Override<
    WrapperProps,
    {
      apiKey?: string;
    }
  >;

const GoogleMap = forwardRef<GoogleMapContext, GoogleMapWithWrapperProps>(
  ({ apiKey: outerApiKey, ...mapProps }, ref) => {
    const { apiKey } = useGoogleApiContext();

    return (
      <Wrapper
        apiKey={outerApiKey ?? apiKey}
        {...LOADER_OPTIONS}
      >
        <Map
          {...mapProps}
          scrollwheel={false}
          disableDoubleClickZoom
          ref={ref}
        />
      </Wrapper>
    );
  },
);
GoogleMap.displayName = 'GoogleMapWrapper';

export * from './core/clusterer';
export * from './components';

export type { GoogleMapWithWrapperProps, MapProps };
export { GoogleMap };
export default GoogleMap;
