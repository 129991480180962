import { Link } from '@swe/shared/ui-kit/components/link';

import styles from './styles.module.scss';

const MAIN_CONTENT_ID = 'maincontent';
const FOOTER_ID = 'footer';

const LinkToContent = () => {
  return (
    <div className={styles.root}>
      <Link
        href={`#${MAIN_CONTENT_ID}`}
        color="ghost"
      >
        Skip to main content
      </Link>{' '}
      or{' '}
      <Link
        href={`#${FOOTER_ID}`}
        color="ghost"
      >
        Skip to footer
      </Link>
    </div>
  );
};

export { LinkToContent, MAIN_CONTENT_ID, FOOTER_ID };
