import cx from 'clsx';
import React, {
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useElementAnyContentChangeListener } from '@swe/shared/hooks/use-element-size';
import useThrottle from '@swe/shared/hooks/use-throttle';

import { EventEmitter } from '@swe/shared/tools/event-emitter';

import Box, { BoxProps } from '@swe/shared/ui-kit/components/box';
import { ComponentHasClassName, ComponentHasStyles } from '@swe/shared/ui-kit/types/common-props';

import { isSSR } from '@swe/shared/utils/environment';

import { isEqual } from '@swe/shared/utils/object';

import styles from './styles.module.scss';

type ScrollStateAxis = 'start' | 'end' | 'middle' | undefined;
type ScrollState = {
  x: ScrollStateAxis;
  y: ScrollStateAxis;
};

const getAxisScrollState = (pos: number | undefined): ScrollStateAxis => {
  if (pos === undefined) {
    return undefined;
  }

  switch (pos) {
    case 0:
      return 'start';
    case 1:
      return 'end';
    default:
      return 'middle';
  }
};

const getScrollState = (pos: ScrollPosition): ScrollState => ({
  x: getAxisScrollState(pos.x),
  y: getAxisScrollState(pos.y),
});
type ScrollableEvents = { scrollPosition: ScrollPosition; scrollState: ScrollState };

type ScrollPosition = {
  x: number | undefined;
  y: number | undefined;
  scrollTop: number;
  scrollLeft: number;
};

export type ScrollToOptionCustom = {
  smooth?: boolean;
};

type ScrollableMethods = {
  getPosition: () => ScrollPosition;
  getElement: () => HTMLElement;
  scroll: (options?: ScrollToOptions & ScrollToOptionCustom) => void;
  scrollTo: (el: HTMLElement, options?: ScrollToOptionCustom) => void;
  subscribe: <ET extends keyof ScrollableEvents>(ev: ET, callback: (p: ScrollableEvents[ET]) => void) => void;
  unsubscribe: <ET extends keyof ScrollableEvents>(ev: ET, callback: (p: ScrollableEvents[ET]) => void) => void;
};

const ctx = React.createContext<ScrollableMethods>(null!);

const ScrollableContextProvider = ctx.Provider;
const useNearestScrollable = () => useContext(ctx);

const round = (num: number) => Math.round(num * 1e2) / 1e2;

type ScrollableProps = ComponentHasClassName & {
  hideScrollbar?: boolean;
  children?: ReactNode | ((ctx: ScrollableMethods) => React.ReactNode);
  fade?: boolean | 'container';
  direction?: 'vertical' | 'horizontal';
  onScroll?: (pos: ScrollPosition) => void;
  onScrollState?: (st: ScrollState) => void;
  isRoot?: boolean;
  stableGutter?: boolean;
} & ComponentHasStyles &
  BoxProps<'div'>;

const Scrollable = React.forwardRef<ScrollableMethods, ScrollableProps>(
  (
    {
      children,
      className,
      hideScrollbar = false,
      fade,
      direction = 'vertical',
      style,
      onScroll,
      onScrollState,
      isRoot,
      stableGutter = true,
      ...boxProps
    },
    forwardedRef,
  ) => {
    const eventEmitter = useMemo(() => new EventEmitter<ScrollableEvents>(), []);
    const rootElRef = useRef<HTMLElement>(isRoot && !isSSR ? document.documentElement : null!);
    const [hasFade, setHasFade] = useState(false);

    const ctx = useMemo(
      (): ScrollableMethods => ({
        getElement: () => {
          return rootElRef.current;
        },
        getPosition: () => {
          const el = rootElRef.current;
          if (!el) {
            return {
              x: undefined,
              y: undefined,
              scrollTop: 0,
              scrollLeft: 0,
            };
          }
          const { scrollTop, scrollLeft, scrollWidth, scrollHeight, clientWidth, clientHeight } = el;
          const hasScroll = { x: scrollWidth > clientWidth, y: scrollHeight > clientHeight };
          const x = hasScroll.x ? round(scrollLeft / (scrollWidth - clientWidth)) : undefined;
          const y = hasScroll.y ? round(scrollTop / (scrollHeight - clientHeight)) : undefined;
          return {
            x,
            y,
            scrollTop,
            scrollLeft,
          };
        },
        scroll: (options) => {
          const { smooth, ...rest } = {
            ...{ smooth: true },
            ...options,
          };
          setTimeout(() => {
            rootElRef.current?.scroll({
              ...rest,
              ...(smooth && { behavior: 'smooth' }),
            });
          });
        },
        scrollTo: (elm, options = {}) => {
          const { smooth } = options;

          if (rootElRef.current) {
            rootElRef.current?.scroll({
              top: elm.offsetTop - (rootElRef.current.offsetHeight / 2 - elm.offsetHeight / 2),
              left: elm.offsetLeft - (rootElRef.current.offsetWidth / 2 - elm.offsetWidth / 2),
              ...(smooth && { behavior: 'smooth' }),
            });
          }
        },
        subscribe: <ET extends keyof ScrollableEvents>(ev: ET, callback: (p: ScrollableEvents[ET]) => void) =>
          eventEmitter.on(ev, callback),
        unsubscribe: <ET extends keyof ScrollableEvents>(ev: ET, callback: (p: ScrollableEvents[ET]) => void) =>
          eventEmitter.off(ev, callback),
      }),
      [eventEmitter],
    );

    useImperativeHandle(forwardedRef, () => ctx, [ctx]);

    useEffect(() => {
      if (onScroll) {
        eventEmitter.on('scrollPosition', onScroll);

        return () => eventEmitter.off('scrollPosition', onScroll);
      }
    }, [onScroll, eventEmitter]);

    useEffect(() => {
      if (onScrollState) {
        eventEmitter.on('scrollState', onScrollState);

        return () => eventEmitter.off('scrollState', onScrollState);
      }
    }, [onScrollState, eventEmitter]);

    useEffect(() => {
      const checkFade = (st: ScrollState) =>
        setHasFade(
          !!fade &&
            ((direction === 'horizontal' && st.x !== undefined) || (direction === 'vertical' && st.y !== undefined)),
        );
      ctx.subscribe('scrollState', checkFade);
      checkFade(getScrollState(ctx.getPosition()));

      return () => ctx.unsubscribe('scrollState', checkFade);
    }, [ctx, direction, fade]);

    const [scrollPosition, setScrollPosition] = useState<ScrollPosition | null>(null);
    const scrollAxisPosition = scrollPosition && scrollPosition[direction === 'horizontal' ? 'x' : 'y'];
    const fadePosition =
      hasFade && scrollAxisPosition !== undefined && scrollAxisPosition !== null
        ? `${scrollAxisPosition * 100 * 0.15}%`
        : null;
    useEffect(() => {
      if (hasFade) {
        ctx.subscribe('scrollPosition', setScrollPosition);
        setScrollPosition(ctx.getPosition());

        return () => {
          ctx.unsubscribe('scrollPosition', setScrollPosition);
          setScrollPosition(null);
        };
      }
    }, [ctx, hasFade]);

    const lastScrollPosition = useRef<ScrollPosition>({ x: undefined, y: undefined, scrollLeft: 0, scrollTop: 0 });
    const lastScrollState = useRef<ScrollState>({ x: undefined, y: undefined });

    const contentChangeHandler = useThrottle(
      useCallback(() => {
        const pos = ctx.getPosition();
        const st = getScrollState(pos);

        if (!isEqual(lastScrollPosition.current, pos)) {
          lastScrollPosition.current = pos;
          eventEmitter.fire('scrollPosition', pos);
        }

        if (!isEqual(lastScrollState.current, st)) {
          lastScrollState.current = st;
          eventEmitter.fire('scrollState', st);
        }
      }, [ctx, eventEmitter]),
      200,
    );

    useEffect(() => {
      contentChangeHandler();
    }, [contentChangeHandler]);

    useElementAnyContentChangeListener(rootElRef, contentChangeHandler);

    const content = (
      <ScrollableContextProvider value={ctx}>
        {typeof children === 'function' ? children(ctx) : children}
      </ScrollableContextProvider>
    );

    return isRoot ? (
      content
    ) : (
      <Box
        ref={rootElRef as RefObject<HTMLDivElement>}
        className={cx(
          styles.root,
          className,
          styles[`_direction_${direction}`],
          hasFade && styles._fade,
          hideScrollbar && styles._hideScrollbar,
          stableGutter && styles._stableGutter,
        )}
        style={{ ...style, '--fade-position': fadePosition }}
        {...boxProps}
      >
        {content}
      </Box>
    );
  },
);

export type { ScrollableProps, ScrollableEvents, ScrollableMethods, ScrollPosition, ScrollState };
export { Scrollable, useNearestScrollable };
export default Scrollable;
