import { AnalyticsMeta } from 'common/entities/product-analitycs';
import { Routes } from 'common/router/constants';
import { buildNamedId } from 'common/router/utils';
import { Product, ProductVariant } from 'entities/product/product';

const createProductDetailsRoute = (
  { category, subcategory, productType, name, strain }: Product,
  { id, name: variantName }: ProductVariant,
  analyticsData?: AnalyticsMeta,
) => {
  const parts = [subcategory?.name, strain?.prevalence?.name, name, productType?.name, variantName].filter(
    Boolean,
  ) as string[];

  return {
    pathname: Routes.Catalog,
    query: {
      all: [buildNamedId(category.name, category.id), buildNamedId(parts, id)],
      ...(analyticsData
        ? Object.keys(analyticsData).reduce(
            (acc, key) => ({ ...acc, [key]: `${analyticsData[key as keyof typeof analyticsData]}` }),
            {},
          )
        : {}),
    },
  };
};

export { createProductDetailsRoute };
