enum NotificationType {
  Transactional = 'Transactional',
  Marketing = 'Marketing',
}

enum NotificationMethod {
  Sms = 'Sms',
  Email = 'Email',
  Push = 'Push',
}

type Notification = {
  notificationMethod: NotificationMethod;
  name: string;
  enabled: boolean;
  suspendUntilDate?: DateISOString;
};

export type { Notification };
export { NotificationType, NotificationMethod };
