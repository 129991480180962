import Container from '@swe/shared/ui-kit/components/container';
import Toggle from '@swe/shared/ui-kit/components/form/toggle';
import { MedicalIcon } from '@swe/shared/ui-kit/components/icon';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useMemo } from 'react';

import styles from './index.module.scss';

import { useAccountFulfillmentPreferences } from './use-cases/use-account-fulfillment-preferences';

import { SignupSubheader } from 'common/containers/header/containers/signup-subheader';
import SelectedLocation from 'common/containers/header/containers/sub-header/components/selected-location';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { usePickupDeliveryCtx } from 'common/providers/pickup-delivery';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { ProductSaleType } from 'entities/product/product';

type SubHeaderProps = ComponentHasClassName;

const SubHeader = ({ className }: SubHeaderProps) => {
  const { saleType, setSaleType, isMedicalMenuEnabled } = useSaleType();
  const { open } = usePickupDeliveryCtx();
  const { orderType, isPickupDeliveryAvailable, submittedAddress, storeInfo, isLoading } =
    useAccountFulfillmentPreferences();
  const { isGuest, isLoading: isLoadingGuest } = useGuest();
  const availableFulfillmentType = useMemo(
    () => storeInfo.fulfillmentTypes.find((type) => type === orderType) || storeInfo.fulfillmentTypes[0],
    [storeInfo.fulfillmentTypes, orderType],
  );
  const { stickyLoginBarEnabled } = useStoreConfig();

  return (
    <>
      {!stickyLoginBarEnabled && <SignupSubheader />}
      <div className={cx(styles.root, className)}>
        <Container>
          <div className={styles.row}>
            <SelectedLocation
              isReady={!isLoading && isPickupDeliveryAvailable}
              className={styles.fulfillment}
              type={availableFulfillmentType}
              storeName={storeInfo.name}
              address={submittedAddress}
              onClick={open}
            />
            {isMedicalMenuEnabled && !isLoadingGuest && !isGuest && (
              <Toggle
                className={styles.toggle}
                size="lg"
                color="success"
                name="saleType"
                value={saleType === ProductSaleType.MEDICAL}
                onChange={(val) => setSaleType(val ? ProductSaleType.MEDICAL : ProductSaleType.RECREATIONAL)}
                valueLabels={[
                  <>Adult Use</>,
                  <>
                    <MedicalIcon className={styles.medicalIcon} />
                    &nbsp;Medical
                  </>,
                ]}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export type { SubHeaderProps };
export { SubHeader };
export default SubHeader;
