import { UploadedDocumentsMap } from 'common/entities/documents/common';
import {
  AdditionalDocument,
  DocumentVerificationImpl,
  IdentityDocument,
  MedicalDocument,
} from 'common/entities/documents/DocumentVerification';
import { DocumentCategory, DocumentVerificationId, UploadedDocuments } from 'entities/documents/documents-v2';

class Verifications {
  readonly verifications: UploadedDocumentsMap;

  constructor(docs: UploadedDocuments) {
    this.verifications = {
      [DocumentCategory.Identity]: docs.identities.map((v) => new IdentityDocument(v)),
      [DocumentCategory.Medical]: docs.medicalPermits.map((v) => new MedicalDocument(v)),
      [DocumentCategory.Additional]: docs.additionalPermits.map((v) => new AdditionalDocument(v)),
    };
  }

  getList() {
    return Object.values(this.verifications).flat();
  }

  getVerificationsByCategory(category: DocumentCategory): DocumentVerificationImpl[] {
    return this.verifications[category];
  }

  getVerificationByPrototype(category: DocumentCategory, prototypeId: number) {
    const verifications = this.getVerificationsByCategory(category);

    return verifications.filter((verification) => verification.data.verificationDocumentType.id === prototypeId);
  }

  getVerification(category: DocumentCategory, verificationId: DocumentVerificationId) {
    return (this.verifications[category] as DocumentVerificationImpl[]).find((d) => d.data.id === verificationId);
  }
}

export { Verifications };
