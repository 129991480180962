import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';

import styles from './styles.module.scss';

const AddToCartSkeleton = () => (
  <Skeleton
    className={styles.skeleton}
    uniqueKey="product_add_to_cart"
    height={62}
  >
    <Bone
      x="0"
      y="0"
      rx="28"
      ry="28"
      width="30%"
      height="56"
    />
    <Bone
      x="33%"
      y="0"
      rx="28"
      ry="28"
      width="67%"
      height="56"
    />
  </Skeleton>
);

export { AddToCartSkeleton };
