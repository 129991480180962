import { prepareItem } from 'common/providers/cart/utils';
import { Cart, CartItemDTO } from 'entities/cart/cart';

import { getProductName, getProductVariantPrice, Product } from 'entities/product/product';

const getCouponByVariantId = (cart: Cart | undefined, _variantId: CartItemDTO['variantId']) => {
  const item = cart?.items.find(({ variantId }) => _variantId === variantId);
  return item?.promoCodes?.map(({ name }) => name).join('|');
};

const productAnalyticsMap = ({
  product,
  index,
  analyticalItemListName,
  analyticalItemListId,
  qtyInCart,
  cart,
  variantId,
}: {
  product: Product;
  index: number;
  analyticalItemListName?: string;
  analyticalItemListId?: string | number;
  qtyInCart?: number;
  cart?: Cart;
  variantId?: VariantId;
}) => {
  const variant = !variantId
    ? product.variants[0]
    : product.variants.find(({ id }) => `${variantId}` === `${id}`) || product.variants[0];
  const quantity = qtyInCart || 0;
  const [price, oldPrice] = getProductVariantPrice(variant, quantity || 1);

  return {
    itemId: `${variant.id}`,
    itemName: getProductName(product),
    itemBrand: product.brand?.name || '',
    price,
    index: index + 1,
    itemCategory: product.category.name,
    itemCategory2: product.subcategory?.name || '',
    itemListId: `${analyticalItemListId || ''}`,
    itemListName: analyticalItemListName || '',
    itemVariant: `${variant.name}`,
    quantity,
    discount: oldPrice ? Math.round((oldPrice - price) * 100) / 100 : 0,
    coupon: getCouponByVariantId(cart, variant.id) || '',
  };
};

const getPrepAnalyticsCartItems = (cart: Cart, user: any) =>
  (cart?.items || [])
    .map((item) => {
      const isVirtual = !!cart && !cart.order;
      const orderItem = cart?.order?.items?.find(({ variantId }) => item.variantId === variantId);
      return prepareItem(item, isVirtual, orderItem, user);
    })
    .map(({ product, qty }, index) =>
      productAnalyticsMap({
        product,
        cart,
        index,
        qtyInCart: qty,
        analyticalItemListId: 'cart',
        analyticalItemListName: 'cart',
      }),
    );

type AnalyticsMeta = { originName: string; originId: string | number; idx?: number };

export type { AnalyticsMeta };
export { getCouponByVariantId, productAnalyticsMap, getPrepAnalyticsCartItems };
