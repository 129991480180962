import cx from 'clsx';
import { useMemo } from 'react';

import { ProgressCommonProps } from '@swe/shared/ui-kit/components/progress/types';

import styles from './styles.module.scss';

type ProgressContinuousProps = ProgressCommonProps & {
  errored?: boolean;
};

enum Boundaries {
  Min = 0,
  Max = 100,
}

const ProgressContinuous = ({ style, className, value, errored }: ProgressContinuousProps) => {
  const normalizedProgress = useMemo(() => Math.min(Math.max(Boundaries.Min, value), Boundaries.Max), [value]);

  return (
    <div
      style={style}
      className={cx(styles.root, className, errored && styles._errored)}
    >
      <div
        className={styles.bar}
        style={{ width: `${normalizedProgress}%` }}
      />
    </div>
  );
};

export type { ProgressContinuousProps };
export { ProgressContinuous };
