import { formatDate, updateDateTZ } from '@swe/shared/utils/date';

import { useCallback, useMemo } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { intervalFormat } from 'entities/common/schedule';
import { FulfilmentDay } from 'entities/shop/info';

const dateOptionFormat = (value: string, shopOffset: number) => ({
  label: formatDate(updateDateTZ(value, shopOffset)),
  value: new Date(value).toISOString(),
});

const useIntervals = (_intervals: FulfilmentDay[]) => {
  const { timezoneShiftInHrs } = useStoreConfig();
  const dateOptions = useMemo(
    () => _intervals.map(({ date: value }) => dateOptionFormat(value, timezoneShiftInHrs)),
    [_intervals, timezoneShiftInHrs],
  );

  const intervals = useMemo(() => {
    return _intervals.map(({ date, intervals }) => ({
      date: new Date(date).toISOString(),
      intervals,
    }));
  }, [_intervals]);

  const getIntervalOptions = useCallback(
    (_date?: string) => {
      if (!_date) {
        return [];
      }
      const item = intervals.find(({ date }) => date === _date);
      if (item) {
        return item.intervals
          .filter(({ id, isAsap }) => id !== null || isAsap)
          .map(({ id, interval, isAsap }) => ({
            value: id,
            label: isAsap ? 'ASAP' : intervalFormat(interval, false),
          }));
      }
      return [];
    },
    [intervals],
  );

  return { dateOptions, intervals, getIntervalOptions };
};

export { useIntervals };
export default useIntervals;
