import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  applicationId: string;
  deviceToken: string;
};
type Data = undefined;
type Error = APIErrorExtended;

const NAME = '/Notifications/SaveDeviceToken';
const SaveDeviceTokenEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default SaveDeviceTokenEndpoint;
