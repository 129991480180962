import { ImageVerification as ImageVerificationType, ImageVerificationStatusId } from 'entities/documents/documents-v2';

class ImageVerification {
  constructor(protected readonly image: ImageVerificationType) {}

  get data() {
    return this.image;
  }

  get isValid() {
    return this.image.status ? this.image.status.id === ImageVerificationStatusId.Good : true;
  }
}

export { ImageVerification };
