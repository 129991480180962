import isPlainObject from 'lodash/isPlainObject';
import { useMemo } from 'react';

import {
  Device,
  DEVICE_TO_BREAKPOINT,
  DEVICES,
  useCurrentBreakpoint,
  VIEWPORT_BREAKPOINTS,
  ViewportBreakpoint,
} from '@swe/shared/tools/media';

export type PossiblePrimitiveValues = string | number | boolean | undefined;
export type PossibleValues = PossiblePrimitiveValues | PossiblePrimitiveValues[];

export type PropValueByViewportBreakpoint<V extends PossibleValues> = Partial<Record<ViewportBreakpoint, V>>;
export type PropValueByDevice<V extends PossibleValues> = Partial<Record<Device, V>>;
export type PropValueByMedia<V extends PossibleValues> = PropValueByViewportBreakpoint<V> | PropValueByDevice<V>;
export type PropValueByMediaWithShorthand<V extends PossibleValues> = PropValueByMedia<V> | V;

const isByMediaProp = <V extends PossibleValues>(
  prop?: PropValueByMediaWithShorthand<V>,
): prop is PropValueByMedia<V> => {
  return isPlainObject(prop);
};

const isByDeviceProps = <V extends PossibleValues>(props: PropValueByMedia<V>): props is PropValueByDevice<V> => {
  return DEVICES.some((deviceName) => deviceName in props);
};

const byDevicePropConvertToByBreakpoint = <V extends PossibleValues>(
  prop: PropValueByDevice<V>,
  defaultValue: V,
): PropValueByViewportBreakpoint<V> => {
  return DEVICES.reduce<PropValueByViewportBreakpoint<V>>((acc, deviceName) => {
    return {
      ...acc,
      [DEVICE_TO_BREAKPOINT[deviceName as Device]]: prop[deviceName] || defaultValue,
    };
  }, {});
};

export const createBreakpointToValueFullMap = <V extends PossibleValues>(
  propValue: PropValueByMediaWithShorthand<V> | undefined,
  defaultValue: V,
): Required<PropValueByViewportBreakpoint<V>> => {
  let breakpointToValuePartialMap: PropValueByViewportBreakpoint<V>;

  if (propValue === undefined) {
    breakpointToValuePartialMap = {
      [VIEWPORT_BREAKPOINTS[0]]: defaultValue,
    };
  } else if (isByMediaProp(propValue)) {
    breakpointToValuePartialMap = {
      [VIEWPORT_BREAKPOINTS[0]]: defaultValue,
      ...(isByDeviceProps(propValue) ? byDevicePropConvertToByBreakpoint(propValue, defaultValue) : propValue),
    };
  } else {
    breakpointToValuePartialMap = {
      [VIEWPORT_BREAKPOINTS[0]]: propValue,
    };
  }

  let lastValue = defaultValue;

  return VIEWPORT_BREAKPOINTS.reduce<PropValueByViewportBreakpoint<V>>((acc, breakpoint) => {
    lastValue = breakpointToValuePartialMap[breakpoint] ?? lastValue;
    return {
      ...acc,
      [breakpoint]: lastValue,
    };
  }, {}) as Required<PropValueByViewportBreakpoint<V>>;
};

export const withUseMemo = <T extends (...args: any) => any>(func: T): T =>
  ((...args) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => func(...args), args)) as T;

export const useViewportBreakpointToValueMap = withUseMemo(createBreakpointToValueFullMap);

export const useBreakpointMapValue = <V extends PossibleValues>(
  map?: Required<PropValueByViewportBreakpoint<V>> | false,
) => {
  const currentBreakpoint = useCurrentBreakpoint();
  return map && currentBreakpoint ? map[currentBreakpoint] : undefined;
};

export const useBreakpointValue = <V extends PossibleValues>(
  propValue: PropValueByMediaWithShorthand<V> | undefined,
  defaultValue: V,
) => {
  const map = useViewportBreakpointToValueMap(propValue, defaultValue);
  return useBreakpointMapValue(map) as V;
};
