import { loadExternalGlobalScript } from '@swe/shared/tools/script';
import { waitFor } from '@swe/shared/utils/async';

import { useMemo } from 'react';

import { useStoreConfig } from 'common/providers/config';

const useMedChat = () => {
  const { medChatApiKey } = useStoreConfig();
  const open = useMemo(
    () =>
      medChatApiKey
        ? async () => {
            const chatApp = await loadExternalGlobalScript(
              `https://medchatapp.com/widget/widget.js?api-key=${medChatApiKey}&expand=initial&hide-launcher=true`,
              'medchatapp',
              async () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const frameStates = await waitFor(() => window.medchatapp?.frameStates);
                frameStates.app.appMode = true;
                return new Promise((resolve) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.medchatapp.onInit = resolve;
                });
              },
            );

            chatApp.expand();
          }
        : undefined,
    [medChatApiKey],
  );

  return { open };
};

export default useMedChat;
