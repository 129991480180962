import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { useEffect, useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { ObjectSchema } from 'yup';

import { useFormContext } from '@swe/shared/ui-kit/components/form/core/context';
import { pick } from '@swe/shared/utils/object';

type FormChangeListenerProps<ValuesT> = {
  onChange: (values: ValuesT) => void;
  validationSchema: ObjectSchema<any>;
  alwaysEmitOnChange?: boolean;
};

const FormChangeListener = <FormValues extends FieldValues>({
  onChange,
  validationSchema,
  alwaysEmitOnChange,
}: FormChangeListenerProps<FormValues>) => {
  const {
    watch,
    formState: { isValid },
    getValues,
  } = useFormContext<FormValues>();

  const prevValues = useRef<FormValues | null>(cloneDeep(getValues()));
  const isErrorCaughtRef = useRef(false);

  useEffect(() => {
    const subscription = watch(async () => {
      if (!isValid && !alwaysEmitOnChange) return;
      const values = getValues();
      if (isErrorCaughtRef.current && isEqual(values, prevValues.current)) {
        return;
      }

      try {
        isErrorCaughtRef.current = false;
        const validValues = await validationSchema.validate(values);
        if (isEqual(validValues, pick(prevValues.current, Object.keys(validValues)))) {
          return;
        }
        prevValues.current = cloneDeep(validValues);
        onChange(validValues);
      } catch (e: unknown) {
        if (alwaysEmitOnChange) {
          onChange(values);
          prevValues.current = values;
          isErrorCaughtRef.current = true;
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [alwaysEmitOnChange, getValues, isValid, onChange, validationSchema, watch]);

  return null;
};

export { FormChangeListener };
