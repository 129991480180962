import cx from 'clsx';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type DividerProps = ComponentHasClassName & {
  caption?: string;
};

const Divider = ({ className, caption }: DividerProps) => {
  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.hr} />
      {caption && <div className={styles.caption}>{caption}</div>}
      <div className={styles.hr} />
    </div>
  );
};

export { Divider };
export default Divider;
