import { MaskElement } from 'imask';
import { forwardRef, HTMLProps, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { ReactMaskProps, useIMask } from 'react-imask';

import { AnyMask } from '@swe/shared/ui-kit/components/form/input/types';

type MaskedInputProps = {
  value: string;
  onChange?: (value: string) => void;
  mask: AnyMask;
} & Omit<HTMLProps<HTMLInputElement>, 'value' | 'onChange'>;

const MaskedInput = forwardRef<HTMLInputElement | null, MaskedInputProps>(
  ({ value: outerValue, onChange, mask, ...props }, outerRef) => {
    const lastInnerValueRef = useRef(outerValue);

    // TODO: react-imask@7.0.1 hook do not respect unmask prop
    const handleChange = useCallback<Exclude<ReactMaskProps<MaskElement, any>['onAccept'], undefined>>(
      (val: string, maskRef) => {
        const nextValue = mask.unmask ? maskRef.unmaskedValue : maskRef.value;
        lastInnerValueRef.current = nextValue;
        onChange?.(nextValue);
      },
      [mask.unmask, onChange],
    );
    const { ref, maskRef, setValue, setUnmaskedValue } = useIMask(mask, {
      onAccept: handleChange,
    });

    useEffect(() => {
      if (maskRef.current) {
        maskRef.current.value = outerValue;
        maskRef.current.updateValue();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (outerValue !== lastInnerValueRef.current) {
        if (mask.unmask) {
          setUnmaskedValue(outerValue);
        } else {
          setValue(outerValue);
        }
      }
    }, [mask.unmask, maskRef, outerValue, setUnmaskedValue, setValue]);

    useImperativeHandle(outerRef, () => ref.current as HTMLInputElement);

    return (
      <input
        {...props}
        // TODO: Typo bug in react-imask@7.0.1
        ref={ref as any}
      />
    );
  },
);

export type { MaskedInputProps };
export { MaskedInput };
