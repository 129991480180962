import Skeleton from '@swe/shared/ui-kit/components/skeleton';

import { SkeletonSectionHeader } from 'domains/product/components/product-details/common/skeleteon-section-header';

const SectionSkeleton = () => (
  <Skeleton
    uniqueKey="product_description"
    height={50}
  >
    <SkeletonSectionHeader />
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="56" */}
    {/*  rx="4" */}
    {/*  ry="4" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="140" */}
    {/*  y="56" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="56" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="90" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="380" */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="90" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="100" */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="60" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="170" */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="200" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="380" */}
    {/*  y="78" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="100" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="145" */}
    {/*  y="100" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="120" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="275" */}
    {/*  y="100" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="95" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="380" */}
    {/*  y="100" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="122" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="145" */}
    {/*  y="122" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="225" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="122" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="90" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="380" */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="90" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="100" */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="60" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="170" */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="200" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="380" */}
    {/*  y="144" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="166" */}
    {/*  rx="4" */}
    {/*  ry="4" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="140" */}
    {/*  y="166" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="166" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x={0} */}
    {/*  y="188" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="130" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="145" */}
    {/*  y="188" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="225" */}
    {/*  height="10" */}
    {/* /> */}
    {/* <Bone */}
    {/*  x="280" */}
    {/*  y="188" */}
    {/*  rx="3" */}
    {/*  ry="3" */}
    {/*  width="100%" */}
    {/*  height="10" */}
    {/* /> */}
  </Skeleton>
);

export { SectionSkeleton };
