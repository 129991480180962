import { CarouselScrollable } from '@swe/shared/ui-kit/components/carousel';
import { range } from '@swe/shared/utils/array';

import styles from './styles.module.scss';

import { CarouselProductsProps } from './types';

import { ProductCardSkeleton } from 'common/components/product-card/vertical/skeleton';

const Skeleton = ({ name }: CarouselProductsProps) => {
  return (
    <CarouselScrollable
      title={name}
      spacing={{ xs: 'xxxs', sm: 'sm' }}
    >
      <div>
        <CarouselScrollable>
          {range(0, 8).map((idx) => (
            <ProductCardSkeleton
              key={idx}
              className={styles.cardSkeleton}
              width="242px"
              uniqueId={String(idx)}
            />
          ))}
        </CarouselScrollable>
      </div>
    </CarouselScrollable>
  );
};

export { Skeleton };
