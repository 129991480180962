import { useStoreConfig } from 'common/providers/config';

const useTimeOffset = () => {
  const { timezoneShiftInHrs, timezoneName } = useStoreConfig();

  const date = new Date();
  const user = (date.getTimezoneOffset() / 60) * -1;

  return {
    diffHours: user - timezoneShiftInHrs,
    timezoneName,
  };
};

export { useTimeOffset };
export default useTimeOffset;
