import cn from 'clsx';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { a, useSpring } from 'react-spring';

import { useResize } from '@swe/shared/hooks';
import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useScrollLocker } from '@swe/shared/tools/window';
import Backdrop from '@swe/shared/ui-kit/components/backdrop';
import Button, { ButtonProps } from '@swe/shared/ui-kit/components/button';
import { FocusTrap } from '@swe/shared/ui-kit/components/focus-trap';
import { CloseIcon } from '@swe/shared/ui-kit/components/icon';

import { SPRING_ANIMATION_CONFIG } from '@swe/shared/ui-kit/components/modal/config';
import { ModalContextProvider, ModalContextType } from '@swe/shared/ui-kit/components/modal/context';
import Sidebar from '@swe/shared/ui-kit/components/modal/desktop/common/components/sidebar';
import { ModalDesktopBaseProps } from '@swe/shared/ui-kit/components/modal/desktop/types';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { Scrollable, ScrollableMethods, ScrollPosition } from '@swe/shared/ui-kit/components/scrollable';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import { useAnimationSettings, useTheme } from '@swe/shared/ui-kit/theme/provider';
import { Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ModalDesktopDefaultProps = ModalDesktopBaseProps & {
  size?: Sizes<'xxxl' | 'xxl' | 'xl' | 'lg' | 'md'>;
  fillHeight?: boolean;
  floatingHeader?: ReactNode;
  floatingHeaderOffset?: number;
  renderBackButton?: (props: Pick<ButtonProps, 'className' | 'size'>) => ReactNode;
  paddings?: boolean;
  returnFocusOnDeactivate?: boolean;
  inPortal?: boolean;
};

const ModalDesktopDefault = ({
  children,
  visible,
  onOpen,
  onClose,
  onCloseAnimationEnd,
  floatingHeader,
  floatingHeaderOffset = 80,
  heading,
  subHeading,
  sidebar,
  background,
  footer,
  fillHeight,
  size = 'md',
  paddings = true,
  withoutFocusTrap,
  showCloseButton = true,
  renderBackButton,
  returnFocusOnDeactivate,
  ariaLabel,
  headingTrail,
  inPortal = true,
}: ModalDesktopDefaultProps) => {
  useScrollLocker(visible);
  const rootRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<ScrollableMethods>(null!);
  const { spacing } = useTheme();
  const MODAL_PADDING = useMemo(() => {
    try {
      // Hardcode from styles. In the future it should be replaced with a custom token for modal padding;
      return parseInt(spacing.scale.xl, 10);
    } catch (e) {
      return 0;
    }
  }, [spacing.scale.xl]);

  const [isHeaderVisible, showHeader, hideHeader] = useToggleable();
  const { enabled: isAnimationEnabled } = useAnimationSettings();
  const [{ opacity }, api] = useSpring(() => ({
    opacity: isAnimationEnabled ? 0 : 1,
    immediate: true,
    config: SPRING_ANIMATION_CONFIG,
  }));
  const [isActiveTrap, setIsActiveTrap] = useState(!withoutFocusTrap);
  const [localVisible, setLocalVisible] = useState(isAnimationEnabled ? false : visible);

  const open = useCallback(() => {
    setLocalVisible(true);
    onOpen?.();
    api.start({
      opacity: 1,
      immediate: false,
      onResolve: () => {
        if (!withoutFocusTrap) {
          setIsActiveTrap(true);
        }
      },
    });
  }, [api, onOpen, withoutFocusTrap]);

  const handleInnerClose = useCallback(() => {
    setIsActiveTrap(false);
    api.start({
      opacity: 0,
      immediate: false,
      onResolve: () => {
        hideHeader();
        setLocalVisible(false);
        onCloseAnimationEnd?.();
      },
    });
  }, [api, hideHeader, onCloseAnimationEnd]);
  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (!visible && localVisible) {
      handleInnerClose();
    }

    if (visible && !localVisible) {
      open();
    }
  }, [visible, close, localVisible, open, handleInnerClose]);

  const handleEscapeDeactivation = useCallback(() => {
    onClose?.();

    return false;
  }, [onClose]);

  const blockHeaderOffsetElement = useRef<HTMLElement | null>(null);
  const blockHeaderOffsetPx = useRef<number | null>(null);
  const calculateBlockHeaderOffset = useCallback(() => {
    const { y: offset, height } = blockHeaderOffsetElement.current?.getBoundingClientRect() ?? {};
    const scrollTop = scrollableRef.current?.getElement().scrollTop ?? 0;
    blockHeaderOffsetPx.current = offset && height ? Math.abs(offset + scrollTop - MODAL_PADDING) : null;
  }, [MODAL_PADDING]);
  const setBlockHeaderOffsetElement = useCallback(
    (element: HTMLElement | null) => {
      blockHeaderOffsetElement.current = element;
      calculateBlockHeaderOffset();
    },
    [calculateBlockHeaderOffset],
  );
  const modalContext = useMemo<ModalContextType>(
    () => ({
      scrollRef: scrollableRef.current,
      setHeaderOffsetBlock: setBlockHeaderOffsetElement,
      headerOffsetBlock: blockHeaderOffsetElement.current,
    }),
    [setBlockHeaderOffsetElement],
  );

  useEffect(() => {
    if (localVisible) {
      const r = scrollableRef.current;
      const scrollHandler = ({ scrollTop }: ScrollPosition) => {
        if (scrollTop > (blockHeaderOffsetPx.current || floatingHeaderOffset)) {
          showHeader();
        } else {
          hideHeader();
        }
      };

      r?.subscribe('scrollPosition', scrollHandler);

      return () => {
        r?.unsubscribe('scrollPosition', scrollHandler);
      };
    }
  }, [floatingHeaderOffset, hideHeader, localVisible, showHeader]);

  useResize(calculateBlockHeaderOffset, 250);

  const content = localVisible && (
    <div className={styles.wrapper}>
      <Backdrop
        onClick={close}
        visible={visible}
      />
      <FocusTrap
        isActive={isActiveTrap}
        onEscapeDeactivate={handleEscapeDeactivation}
        returnFocusOnDeactivate={returnFocusOnDeactivate}
      >
        <a.div
          aria-modal="true"
          role="dialog"
          aria-label={ariaLabel}
          ref={rootRef}
          style={{ opacity }}
          className={cn(
            styles.root,
            styles[`_size_${size}`],
            fillHeight && styles._fillHeight,
            paddings === false && styles._noPaddings,
          )}
        >
          {renderBackButton?.({ className: styles.backBtn })}
          {onClose && showCloseButton && (
            <Button
              color="light"
              className={styles.close}
              icon={CloseIcon}
              size="lg"
              onClick={close}
              ariaLabel="Close"
            />
          )}
          {sidebar}
          <div className={styles.common}>
            {(heading || subHeading) && (
              <SectionHeading
                className={styles.header}
                level={1}
                size="lg"
                subHeading={subHeading}
                trailBlock={headingTrail}
              >
                {heading}
              </SectionHeading>
            )}
            {background ? (
              <div className={styles.background}>{background}</div>
            ) : (
              <div className={styles.mainContent}>
                <Scrollable
                  ref={scrollableRef}
                  className={styles.scrollable}
                  fade={false}
                >
                  <div className={styles.scrollableContent}>{children}</div>
                </Scrollable>
                {footer && <div className={styles.footer}>{footer}</div>}
              </div>
            )}
            {floatingHeader && (
              <div className={styles.floatingHeaderWrapper}>
                <div className={cn(styles.floatingHeader, isHeaderVisible && styles.floatingHeader_visible)}>
                  {floatingHeader}
                </div>
              </div>
            )}
          </div>
        </a.div>
      </FocusTrap>
    </div>
  );

  return (
    <ModalContextProvider value={modalContext}>{inPortal ? <Portal>{content}</Portal> : content}</ModalContextProvider>
  );
};

export type { ModalDesktopDefaultProps };
export { ModalDesktopDefault, Sidebar };
export default ModalDesktopDefault;
