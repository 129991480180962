import cx from 'clsx';

import { Head } from '@swe/shared/ui-kit/components/datatable/head';
import { TableProps } from '@swe/shared/ui-kit/components/datatable/types';

import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';

import { Row } from './row';
import styles from './styles.module.scss';

const Datatable = <DataItem,>({ className, source, columns, header, onRowClick }: TableProps<DataItem>) => {
  return (
    <Scrollable direction="horizontal">
      <div className={cx(className, styles.root)}>
        <table className={styles.table}>
          <Head
            columns={columns}
            header={header}
          />
          <tbody className={styles.tableBody}>
            {source.map((row, rowIdx) => (
              <Row<DataItem>
                key={rowIdx}
                rowIdx={rowIdx}
                row={row}
                columns={columns}
                onRowClick={onRowClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Scrollable>
  );
};

export type { TableProps };
export { Datatable };
