import cn from 'clsx';

import { ComponentType, forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';

import { useKeyPress } from '@swe/shared/hooks/use-key-press';
import Button, { ButtonSize, ButtonVariant } from '@swe/shared/ui-kit/components/button';
import { CloseIcon, CheckIcon, IconProps, AlertIcon } from '@swe/shared/ui-kit/components/icon';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import {
  Colors,
  ComponentHasChildren,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type AlertColor = Colors<'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'neutral'>;
type AlertSize = Sizes<'md' | 'sm'>;

const ALERT_TO_BUTTON_SIZE: Record<AlertSize, ButtonSize> = {
  md: 'lg',
  sm: 'sm',
};

type AlertProps = ComponentHasClassName &
  ComponentHasChildren &
  ComponentHasColor<AlertColor> &
  ComponentHasSize<AlertSize> & {
    title?: ReactNode;
    icon?: ComponentType<IconProps> | false;
    truncate?: number;
    onClick?: () => void;
    onClose?: () => void;
  };

const COLOR_TO_ICON: Record<AlertColor, ComponentType<IconProps> | undefined> = {
  primary: undefined,
  secondary: undefined,
  neutral: undefined,
  success: CheckIcon,
  warning: AlertIcon,
  danger: AlertIcon,
};

const useShake = () => {
  const [cls, setCls] = useState('');

  const deactivate = useCallback(() => {
    setCls('');
  }, []);
  const activate = useCallback(() => {
    setCls(styles.shake);
    setTimeout(() => {
      deactivate();
    }, 500);
  }, [deactivate]);

  return {
    cls,
    activate,
    deactivate,
  };
};

type AlertRef = {
  shake: () => void;
};

const Alert = forwardRef<AlertRef, AlertProps>(
  (
    {
      className,
      children,
      title,
      color = 'primary',
      size = 'md',
      truncate,
      icon: Icon = COLOR_TO_ICON[color],
      onClose,
      onClick,
    },
    ref,
  ) => {
    const hasTruncatedText = truncate && truncate > 0;
    const tokens = useTheme();
    const { cls, activate } = useShake();

    useImperativeHandle(ref, () => ({
      shake: activate,
    }));

    const bind = useKeyPress<HTMLDivElement>({
      key: ' ',
      onKeyUp: onClick,
    });

    return (
      <div
        className={cn(
          styles.root,
          className,
          cls,
          styles[`_color_${color}`],
          styles[`_size_${size}`],
          onClick && styles._clickable,
        )}
      >
        {Icon && (
          <div
            {...bind}
            tabIndex={onClick ? 0 : undefined}
            className={styles.iconBlock}
            onClick={onClick}
          >
            <Icon className={styles.icon} />
          </div>
        )}
        <div
          {...bind}
          tabIndex={onClick ? 0 : undefined}
          className={styles.content}
          onClick={onClick}
        >
          {title && <div className={styles.title}>{title}</div>}
          <div
            className={cn(styles.text, hasTruncatedText && styles.text_truncated)}
            style={hasTruncatedText ? { WebkitLineClamp: truncate } : undefined}
          >
            {children}
          </div>
        </div>
        {onClose && (
          <div className={styles.trail}>
            <Button
              size={ALERT_TO_BUTTON_SIZE[size]}
              onClick={onClose}
              variant={tokens.alert.button.type.switch as ButtonVariant}
              color={color}
              icon={CloseIcon}
              className={styles.action}
              ariaLabel="Close"
              border="none"
            />
          </div>
        )}
      </div>
    );
  },
);
Alert.displayName = 'Alert';

export type { AlertProps, AlertRef, AlertColor, AlertSize };
export { Alert };
export default Alert;
