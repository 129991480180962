import { usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback, useEffect, useRef } from 'react';

import { useSaleType } from 'common/use-cases/use-sale-type';
import { CartItemDTO } from 'entities/cart/cart';
import { ProductSaleType } from 'entities/product/product';

type UseNotificationSaleTypeParams = {
  isValidatingCart: boolean;
};

const useNotificationMismatchSaleType = ({ isValidatingCart }: UseNotificationSaleTypeParams) => {
  const { saleType, setSaleType } = useSaleType();
  const { confirm } = usePrompt();

  const promiseResolve = useRef<((v: boolean) => void) | null>(null);
  const prevIsValidatingCart = useRef(isValidatingCart);

  useEffect(() => {
    if (promiseResolve.current && prevIsValidatingCart.current && !isValidatingCart) {
      promiseResolve.current(true);
      promiseResolve.current = null;
    }
    prevIsValidatingCart.current = isValidatingCart;
  }, [isValidatingCart]);

  const prepareSaleType = useCallback(
    async (item: CartItemDTO) => {
      const {
        product: { variants },
      } = item;
      const variant = variants[0];
      if (!saleType || variant.saleType === ProductSaleType.BOTH || variant.saleType === saleType) {
        return true;
      }

      const res = await confirm({
        heading: 'Purchase Type',
        ariaLabel: 'Purchase Type',
        content: `Looks like you're adding a ${(
          variant.saleType || ''
        ).toLocaleLowerCase()} item, but your store is set for ${
          saleType === ProductSaleType.RECREATIONAL ? 'adult' : 'medical'
        } use. Need to switch?`,
        confirmLabel: `Switch to ${variant.saleType}`,
        cancelLabel: `Stay with ${saleType} Use`,
      });
      if (res) {
        await setSaleType?.(variant.saleType);
      }
      await new Promise((resolve) => {
        promiseResolve.current = resolve;
      });
    },
    [confirm, saleType, setSaleType],
  );
  return {
    prepareSaleType,
  };
};

export { useNotificationMismatchSaleType };
