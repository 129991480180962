import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ProductVariant } from 'entities/product/product';

type Params = {
  variantId: ProductVariant['id'];
};

type Data = any;

const NAME = '/Reminders/Add';
const AddReminders = createModernEndpoint<Params, Data>(NAME);

export { AddReminders };
