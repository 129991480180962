import PQueue from 'p-queue';
import { useMemo } from 'react';

const usePromiseQueue = (params: ConstructorParameters<typeof PQueue>[0]) => {
  const instance = useMemo(() => new PQueue(params), [params]);

  const add = useMemo(() => instance.add.bind(instance), [instance]);
  const clear = useMemo(() => instance.clear.bind(instance), [instance]);

  return {
    add,
    clear,
    instance,
  };
};

export { usePromiseQueue };
export default usePromiseQueue;
