import { isSSR } from '@swe/shared/utils/environment';

import { SWRConfigWithCacheProvider, SWRConfig } from './transport.types';

export const DEFAULT_SWR_CONFIG: SWRConfig = {
  shouldRetryOnError: false,
  keyParams: (params) => params,
};

export const SWR_CLIENT_CACHE = isSSR ? null : new Map<string, any>();

export const DEFAULT_SWR_CONFIGURATION: SWRConfigWithCacheProvider = {
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  focusThrottleInterval: 20000,
};
