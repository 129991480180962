import Button from '@swe/shared/ui-kit/components/button';
import { CloseIcon, ExpandIcon } from '@swe/shared/ui-kit/components/icon';

type FullscreenControlProps = {
  isEnabled?: boolean;
  onActivate: () => void;
  onDeactivate: () => void;
};

const FullscreenControl = ({ isEnabled, onActivate, onDeactivate }: FullscreenControlProps) => (
  <Button
    size="sm"
    ariaLabel="Open map in fullscreen"
    color="light"
    icon={isEnabled ? CloseIcon : ExpandIcon}
    onClick={isEnabled ? onDeactivate : onActivate}
  />
);

export type { FullscreenControlProps };
export { FullscreenControl };
