import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { ChatIcon } from '@swe/shared/ui-kit/components/icon';

import { useCallback, useRef } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { useCurrentUser, useSession } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import useMainHub from 'endpoints/hubs/main';
import { ChatMessage, ChatMessageStatus } from 'entities/profile/orders';

const ChatMessageNotifier = () => {
  const isChatRoute = useMatchRoute(Routes.ProfileOrderChat);
  const { user, isRegistrationComplete } = useCurrentUser();
  const { id } = useStoreConfig();
  const navigate = useRouterNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;

  const onReceive = useCallback((message: ChatMessage) => {
    if (message.status === ChatMessageStatus.Received && !message.sender.isClient) {
      SnackbarService.push({
        type: 'success',
        icon: ChatIcon,
        autoHideDuration: null,
        heading: `New message. Order #${message.orderNumber}`,
        message: message.text,
        truncate: 2,
        onClick: () =>
          navigateRef.current({
            pathname: Routes.ProfileOrderChat,
            query: { id: String(message.orderId), chatId: message.chatId },
          }),
      });
    }
  }, []);

  const hasCompleteUser = !!user && isRegistrationComplete;
  const { session } = useSession();

  useMainHub({
    type: 'messageStateChanged',
    onReceive,
    params: {
      storeId: id.toString(),
      ...session,
    },
    isActive: isChatRoute ? false : hasCompleteUser,
  });

  return null;
};

export { ChatMessageNotifier };
