import { Price } from '@swe/shared/types/price';

const formatPrice = (price: Price, options?: { maximumFractionDigits: number }) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: options?.maximumFractionDigits || 2,
  }).format(price);
};

const formatIntegerPrice = (price: Price) => formatPrice(price, { maximumFractionDigits: 0 });

export { formatPrice, formatIntegerPrice };
