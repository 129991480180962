import { createContext, ReactNode, useContext } from 'react';
import {
  ErrorBoundary as ErrorBoundaryExternal,
  FallbackProps,
  ErrorBoundaryProps as ErrorBoundaryExternalProps,
} from 'react-error-boundary';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type ErrorBoundaryProps = ComponentHasChildren & {
  fallback?: ReactNode | ((args: FallbackProps) => ReactNode);
  onError?: ErrorBoundaryExternalProps['onError'];
};

type ErrorBoundaryContext = {
  defaultFallback?: ErrorBoundaryProps['fallback'];
  defaultOnError?: ErrorBoundaryProps['onError'];
};
const errorBoundaryContext = createContext<ErrorBoundaryContext>({});
const ErrorBoundaryContextProvider = errorBoundaryContext.Provider;
const useErrorBoundaryDefaults = () => useContext(errorBoundaryContext);

const ErrorBoundary = ({ children, fallback, onError }: ErrorBoundaryProps) => {
  const { defaultOnError, defaultFallback } = useErrorBoundaryDefaults();
  const errorHandler: ErrorBoundaryExternalProps['onError'] = (...args) => {
    defaultOnError?.(...args);
    onError?.(...args);
  };

  const finalFallback = fallback ?? defaultFallback;

  const props = (
    typeof finalFallback === 'function'
      ? { fallbackRender: finalFallback, onError: errorHandler, children }
      : { fallback: finalFallback, onError: errorHandler, children }
  ) as ErrorBoundaryExternalProps;

  return <ErrorBoundaryExternal {...props} />;
};

type ErrorBoundaryProviderProps = ComponentHasChildren & ErrorBoundaryContext;
const ErrorBoundaryProvider = ({ defaultFallback, defaultOnError, children }: ErrorBoundaryProviderProps) => {
  return (
    <ErrorBoundaryContextProvider value={{ defaultOnError, defaultFallback }}>{children}</ErrorBoundaryContextProvider>
  );
};

export { ErrorBoundary, ErrorBoundaryProvider };
export type { ErrorBoundaryProps };
