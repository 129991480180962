import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, RouteObject, ScrollRestoration, useMatches } from 'react-router-dom';

import { useAppHydrationData } from '@swe/shop-ui/app/hydration-data-provider';
import { PageLayoutProps } from '@swe/shop-ui/app/types';
import AppLayout from '@swe/shop-ui/common/layouts/app';
import RouterProvider from '@swe/shop-ui/common/router';

const useLoaderEndpointsFallback = (initialFallback = {}) => {
  const matches = useMatches();
  return useMemo(
    () =>
      matches.reduce(
        (acc, match) => ({
          ...acc,
          ...match.data?.endpointsCache,
        }),
        initialFallback,
      ),
    [initialFallback, matches],
  );
};

const usePageLayoutProps = (): PageLayoutProps | undefined => {
  const matches = useMatches();
  return useMemo(() => matches.findLast((match) => !!match.data?.mainLayoutProps)?.data.mainLayoutProps, [matches]);
};

type LayoutProps = ComponentHasChildren & { pages: RouteObject[] };

const Layout = ({ pages, children }: LayoutProps) => {
  const { endpointsFallback: _endpointsFallback, ...appSettings } = useAppHydrationData();
  const endpointsFallback = useLoaderEndpointsFallback(_endpointsFallback);
  const layoutProps = usePageLayoutProps();

  return (
    <RouterProvider routes={pages}>
      <AppLayout
        {...appSettings}
        endpointsFallback={endpointsFallback}
        layoutProps={layoutProps}
        Head={Helmet}
      >
        {children ?? <Outlet />}
      </AppLayout>
      <ScrollRestoration />
    </RouterProvider>
  );
};

export { Layout };
