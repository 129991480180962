import { usePromiseQueue } from '@swe/shared/hooks/use-promise-queue';
import { useScrollLocker } from '@swe/shared/tools/window';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { debounce } from '@swe/shared/utils/func';

import { useCallback, useMemo, useState } from 'react';

import { Search, SearchProps } from 'common/containers/header/containers/search/components/search';

import { useRouterNavigate } from 'common/router';
import { ShopRoute } from 'common/router/constants';
import { usePDPControls } from 'common/use-cases/use-pdp';
import { useSaleType } from 'common/use-cases/use-sale-type';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import GetListEndpoint, { GetListResponse } from 'endpoints/product/get-list';
import { getFirstImage, getProductName, Product, ProductVariant } from 'entities/product/product';

type SearchContainerProps = ComponentHasClassName & {
  headerHeight: number;
  isExpanded: boolean;
  onExpand: () => void;
  onCollapse: () => void;
};

const PQUE_PARAMS = { concurrency: 1 };

const SearchContainer = ({ onExpand, onCollapse, isExpanded, className, headerHeight }: SearchContainerProps) => {
  const { saleType } = useSaleType();
  const { open } = usePDPControls();
  const [list, setList] = useState<SearchProps['items']>(undefined);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setSearchTerm, buildCatalogLink } = useCatalogQuery();
  useScrollLocker(isExpanded);

  const { add } = usePromiseQueue(PQUE_PARAMS);

  const onSearch = useCallback(
    async (value: string) => {
      if (value.length === 0) {
        setList(undefined);
        return;
      }
      setIsLoading(true);
      setList([]);

      const { list, searchFilters, total } = (await add(async () =>
        GetListEndpoint.request({
          saleType,
          filters: {},
          page: 1,
          pageSize: 3,
          searchTerm: value,
        }),
      )) as GetListResponse;
      setTotal(total);
      setIsLoading(false);

      const res: SearchProps['items'] = list.map((product) => {
        const { category, brand, variants } = product;
        return {
          imgSrc: getFirstImage(product),
          name: getProductName(product),
          caption: `${category?.name}${brand?.name ? ` by ${brand.name}` : ''}`,
          type: 'product',
          foo: 3,
          link: [product, variants[0]],
        };
      });

      searchFilters.forEach(({ uiLabel: name, value: caption, filter }) => {
        return res.push({
          name,
          caption,
          type: 'other',
          link: buildCatalogLink({ filters: filter, subPath: null, searchTerm: null, page: null }),
        });
      });

      setList(res);
    },
    [add, saleType, buildCatalogLink],
  );

  const debouncedOnSearch = useMemo(() => debounce(onSearch, 300), [onSearch]);

  const onDeactivate = useCallback(() => {
    setList(undefined);
    onCollapse?.();
  }, [onCollapse]);

  const navigate = useRouterNavigate();

  const handleItemClick = useCallback<SearchProps['onClickItem']>(
    ({ type, link }) => {
      if (type === 'product') {
        // return open(...(link as [Product, ProductVariant]));
        return open({
          product: (link as [Product, ProductVariant])[0],
          variant: (link as [Product, ProductVariant])[1],
        });
      }

      return navigate(link as ShopRoute);
    },
    [open, navigate],
  );

  return (
    <Search
      className={className}
      isExpanded={isExpanded}
      isLoading={isLoading}
      items={list}
      total={total}
      headerHeight={headerHeight}
      onActivate={onExpand}
      onDeactivate={onDeactivate}
      onSearch={debouncedOnSearch}
      onClickAll={setSearchTerm}
      onClickItem={handleItemClick}
    />
  );
};

export { SearchContainer };
export default SearchContainer;
