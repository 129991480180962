import { Button, ButtonProps } from '@swe/shared/ui-kit/components/button';

import { useFormContext } from '@swe/shared/ui-kit/components/form/core/context';

type SubmitButtonProps = Omit<ButtonProps, 'type'>;

const SubmitButton = ({ disabled, pending, children, ...props }: SubmitButtonProps) => {
  const {
    formState: { isValid, isSubmitting, isValidating, isSubmitted },
    disabled: formDisabled,
    formId,
  } = useFormContext();

  return (
    <Button
      {...props}
      type="submit"
      disabled={formDisabled || disabled || (isSubmitted && !isValid && 'fake')}
      form={formId}
      pending={pending ?? (isSubmitting || isValidating)}
      ariaLabel="Submit"
    >
      {children}
    </Button>
  );
};

export { SubmitButton };
export default SubmitButton;
