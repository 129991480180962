import cn from 'clsx';
import { ReactNode, useCallback } from 'react';

import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import {
  Colors,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type ToggleSize = Sizes<'sm' | 'md' | 'lg'>;
export type ToggleColor = Colors<'neutral' | 'success'>;

type ToggleProps = ComponentHasClassName &
  FormControl<boolean> & {
    label?: ReactNode;
    hovered?: boolean;
    pressed?: boolean;
    focused?: boolean;
    valueLabels?: [ReactNode, ReactNode];
  } & ComponentHasSize<ToggleSize> &
  ComponentHasColor<ToggleColor>;

const Toggle = ({
  name,
  label,
  onChange,
  value = false,
  onBlur,
  error,
  disabled,
  className,
  hovered,
  pressed,
  focused,
  size = 'md',
  color = 'neutral',
  valueLabels,
}: ToggleProps) => {
  const onChangeHandler = useCallback(() => onChange?.(!value), [onChange, value]);

  return (
    <CommonWrapper
      error={error}
      className={cn(
        className,
        {
          [styles._checked]: value,
          [styles._disabled]: disabled,
          [styles._hovered]: hovered,
          [styles._focused]: focused,
          [styles._pressed]: pressed,
          [styles._color_success]: color === 'success',
        },
        styles[`_size_${size}`],
      )}
    >
      <label
        className={styles.label}
        htmlFor={name}
        tabIndex={0}
      >
        <div className={styles.checkbox}>
          <span className={styles.baseline}>{name}</span>
          <Locator
            as="input"
            locatorName={name}
            locatorType="toggle"
            className={styles.input}
            type="checkbox"
            checked={value}
            name={name}
            id={name}
            disabled={disabled}
            onChange={onChangeHandler}
            onBlur={onBlur}
          />
          <div className={styles.handle} />
          {valueLabels && valueLabels.length > 0 && (
            <div className={styles.valueLabel}>{valueLabels[Number(value)]}</div>
          )}
        </div>
        {label && <div>{label}</div>}
      </label>
    </CommonWrapper>
  );
};

export type { ToggleProps };
export { Toggle };
export default Toggle;
