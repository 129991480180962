import { KeyedMutator } from '@swe/shared/network/transport/swr/transport.types';
import { has } from '@swe/shared/utils/object';

import { useCallback, useRef, useState } from 'react';

import { useQuantityStorage } from 'common/providers/cart/use-cases/use-quantity-storage';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { AddToCartManyEndpoint, Error } from 'endpoints/cart/add-to-cart-many';
import { RemoveFromCartManyEndpoint } from 'endpoints/cart/remove-from-cart-many';
import { Cart, CartItemDTO } from 'entities/cart/cart';

type VariantId = number;
type AddToCartOnSuccess = { newCart: Cart; oldCart?: Cart; firstVariantsId: VariantId };

type AddToCartOnError = { error: Error };
type OnBeforeSetLocalItem = { qty: number };

type UseAddToCart = {
  mutateCart: KeyedMutator<Cart>;
  cart?: Cart;
  addToQueue: any;
  isQueueIdle: any;
  onSuccess?: (data: AddToCartOnSuccess) => void;
  onError?: (data: AddToCartOnError) => void;
  onBeforeSetLocalItem?: (data: OnBeforeSetLocalItem) => void;
};

const DELAY = 500;

enum Action {
  Add = 'add',
  Remove = 'remove',
}

const useAddToCart = ({
  mutateCart,
  addToQueue,
  cart,
  onSuccess,
  onError,
  onBeforeSetLocalItem,
  isQueueIdle,
}: UseAddToCart) => {
  const { saleType } = useSaleType();
  const { incrementQuantity, decrementQuantity, applyCartState } = useQuantityStorage();
  const debounceQueue = useRef<VariantId[]>([]);
  const queueMap = useRef<
    Record<
      VariantId,
      {
        qty: number;
        item: CartItemDTO;
      }
    >
  >({});

  const addToDebounceQueue = useCallback((variantId: number) => {
    const index = debounceQueue.current.indexOf(variantId);
    if (index !== -1) {
      debounceQueue.current.splice(index, 1);
    }
    debounceQueue.current.push(variantId);
  }, []);

  const removeFromDebounceQueue = useCallback((variantId: number) => {
    const index = debounceQueue.current.indexOf(variantId);
    if (index !== -1) {
      debounceQueue.current.splice(index, 1);
    }
  }, []);

  const toQueue = useCallback(
    (item: CartItemDTO, action: Action) => {
      const variantId = parseInt(item.product.variants[0].id as string, 10);
      const { qty } = item;

      if (has(queueMap.current, variantId)) {
        if (action === Action.Add) {
          queueMap.current[variantId].qty += qty;
        } else {
          queueMap.current[variantId].qty -= qty;
        }

        if (queueMap.current[variantId].qty === 0) {
          delete queueMap.current[variantId];
          removeFromDebounceQueue(variantId);
          return;
        }
      } else {
        queueMap.current[variantId] = {
          qty: action === Action.Add ? qty : qty * -1,
          item,
        };
      }

      addToDebounceQueue(variantId);
    },
    [addToDebounceQueue, removeFromDebounceQueue],
  );

  type NextReturn = {
    hasNext: boolean;
    variants: { variantId: VariantId; qty: number }[];
    firstVariantsId: VariantId;
    action: Action;
  } | null;

  const getNextData = useCallback<() => NextReturn>(() => {
    const variantId = debounceQueue.current.shift();
    if (!variantId) {
      return null;
    }

    const { qty: groupQty } = queueMap.current[variantId];
    const variants: NonNullable<NextReturn>['variants'] = [];
    const groupedBySign = Object.values(queueMap.current).filter(({ qty }) => qty > 0 === groupQty > 0);

    groupedBySign.forEach(({ item: { variantId }, qty }) => {
      const _variantId = parseInt(String(variantId), 10);
      variants.push({ variantId: _variantId, qty: Math.abs(qty) });
      delete queueMap.current[_variantId];
      removeFromDebounceQueue(_variantId);
    });

    return {
      action: groupQty > 0 ? Action.Add : Action.Remove,
      hasNext: true,
      firstVariantsId: variantId,
      variants,
    };
  }, [removeFromDebounceQueue]);

  const addLocalItem = useCallback(
    (item: CartItemDTO) => {
      const { qty = 1 } = item;
      const variantId = item.product.variants[0].id;
      const newCartItem = {
        product: item.product,
        variantId: item.product.variants[0].id,
        qty,
        reservedQty: qty,
        availableQty: qty + 1,
      };

      const cartItems = cart?.items || [];

      const itemIndex = cartItems.findIndex(({ product }) => product.variants[0].id === variantId);
      const cartItemsCopy = [...cartItems];

      if (itemIndex === -1) {
        cartItemsCopy.splice(0, 0, newCartItem);
      } else {
        const item = cartItemsCopy[itemIndex];
        cartItemsCopy.splice(itemIndex, 1, {
          ...item,
          qty: item.qty + qty,
          reservedQty: item.reservedQty + qty,
          availableQty: item.availableQty + qty + 1,
        });
      }

      const newCart = {
        ...cart,
        items: cartItemsCopy,
      };

      onBeforeSetLocalItem?.({
        qty,
      });

      void mutateCart(newCart, { revalidate: false });
    },
    [cart, mutateCart, onBeforeSetLocalItem],
  );

  const lastSuccessCartUpdate = useRef(cart);

  const finishedUpdateCart = useCallback(
    (newCart?: Cart) => {
      if (isQueueIdle() && debounceQueue.current.length === 0) {
        void mutateCart(newCart, { revalidate: false });
      }
    },
    [isQueueIdle, mutateCart],
  );

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const stop = useCallback(() => {
    clearTimeout(timer.current);
    debounceQueue.current = [];
    queueMap.current = {};
  }, []);

  type ReqMethodsParams = {
    variantId: EntityID<number>;
    qty: number;
  }[];

  const saveLastCartUpdate = useCallback(() => {
    if (lastSuccessCartUpdate.current === undefined) {
      lastSuccessCartUpdate.current = JSON.parse(JSON.stringify(cart));
    }
  }, [cart]);

  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  const nextAdd = useCallback(
    async (variants: ReqMethodsParams, firstVariantsId: VariantId) => {
      try {
        setIsLoadingAdd(true);
        const data = await addToQueue(() =>
          AddToCartManyEndpoint.request({
            saleType,
            variants,
          }),
        );
        onSuccess?.({
          oldCart: lastSuccessCartUpdate.current,
          newCart: data,
          firstVariantsId,
        });
        finishedUpdateCart(data);
        lastSuccessCartUpdate.current = undefined;
      } catch (error) {
        onError?.({
          error: error as Error,
        });
        stop();
        void mutateCart(lastSuccessCartUpdate.current, { revalidate: false });
        applyCartState(lastSuccessCartUpdate.current);
      } finally {
        setIsLoadingAdd(false);
      }
    },
    [addToQueue, onSuccess, finishedUpdateCart, saleType, onError, stop, mutateCart, applyCartState],
  );
  const { platformOs } = usePlatformOs();

  const [isLoadingRemove, setIsLoadingRemove] = useState(false);

  const nextRemove = useCallback(
    async (variants: ReqMethodsParams) => {
      try {
        setIsLoadingRemove(true);
        const data = await addToQueue(() =>
          RemoveFromCartManyEndpoint.request({
            saleType,
            variants,
            platformOs,
          }),
        );
        saveLastCartUpdate();
        finishedUpdateCart(data);
      } catch (e) {
        onError?.({
          error: e as Error,
        });
        stop();
        void mutateCart(lastSuccessCartUpdate.current, { revalidate: false });
        applyCartState(lastSuccessCartUpdate.current);
      } finally {
        setIsLoadingRemove(false);
      }
    },
    [
      addToQueue,
      saveLastCartUpdate,
      finishedUpdateCart,
      saleType,
      platformOs,
      onError,
      stop,
      mutateCart,
      applyCartState,
    ],
  );

  const next = useCallback(async () => {
    const nextData = getNextData();
    if (!nextData) {
      return;
    }
    const { action, variants, firstVariantsId } = nextData;
    if (action === Action.Add) {
      await nextAdd(variants, firstVariantsId);
    } else if (action === Action.Remove) {
      await nextRemove(variants);
    }
    setTimeout(next, DELAY);
  }, [nextAdd, nextRemove, getNextData]);

  const addToCart = useCallback(
    (item: CartItemDTO) => {
      saveLastCartUpdate();
      addLocalItem(item);
      incrementQuantity(item.product.id, item.variantId, item.qty);
      toQueue(item, Action.Add);
      if (timer) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(next, DELAY);
    },
    [saveLastCartUpdate, addLocalItem, incrementQuantity, toQueue, next],
  );

  type Param = Pick<CartItemDTO, 'product' | 'variantId' | 'qty'>;

  const removeLocalItem = useCallback(
    (param: Param) => {
      const items = [...(cart?.items || [])];
      const itemIndex = items.findIndex(({ product }) => product.variants[0].id === param.product.variants[0].id);
      if (itemIndex === -1) {
        return;
      }

      const newItem =
        items[itemIndex].qty > 1 && items[itemIndex].qty > param.qty
          ? [
              {
                ...items[itemIndex],
                qty: items[itemIndex].qty - (param.qty ?? 1),
              },
            ]
          : [];
      items.splice(itemIndex, 1, ...newItem);

      onBeforeSetLocalItem?.({
        qty: (param.qty ?? 1) * -1,
      });
      void mutateCart(
        {
          ...cart,
          items,
        },
        { revalidate: false },
      );
    },
    [cart, mutateCart, onBeforeSetLocalItem],
  );

  const removeFromCart = useCallback(
    (item: CartItemDTO) => {
      saveLastCartUpdate();
      removeLocalItem(item);
      decrementQuantity(item.product.id, item.variantId, item.qty);
      toQueue(item, Action.Remove);
      if (timer) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(next, DELAY);
    },
    [saveLastCartUpdate, removeLocalItem, decrementQuantity, toQueue, next],
  );

  return {
    addToCart,
    removeFromCart,
    isLoadingAdd,
    isLoadingRemove,
  };
};

export { useAddToCart };
export type { AddToCartOnSuccess, AddToCartOnError, OnBeforeSetLocalItem };
