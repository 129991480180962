import { CartItemDTO } from 'entities/cart/cart';
import { Order, PurchaseLimit } from 'entities/common/orders';
import { Product, ProductSaleType, ProductVariant } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

const isOutOfLimits = (limits: PurchaseLimit[]) => limits.some((l) => l.limitAmount > l.maxAmount);

const check =
  (arr: ProductSaleType[]) =>
  ({ variants }: Product) =>
    !!variants[0].saleType && arr.includes(variants[0].saleType);

const UNAVAILABLE_SALE_TYPE_TO_FILTER: Record<Exclude<StoreSaleType, undefined>, (ci: Product) => boolean> = {
  [ProductSaleType.MEDICAL]: check([ProductSaleType.RECREATIONAL]),
  [ProductSaleType.RECREATIONAL]: check([ProductSaleType.MEDICAL]),
};

const prepareItem = (
  item: CartItemDTO,
  isVirtual: boolean,
  orderItem: NonNullable<Order['items']>[number] | undefined,
  user: any,
) => {
  return isVirtual
    ? item
    : {
        ...item,
        product: {
          ...item.product,
          variants: [
            {
              ...item.product.variants[0],
              price: orderItem ? orderItem.price / orderItem.qty : 0,
              promoPrice: orderItem ? orderItem.discountedPrice / orderItem.qty : 0,
              promos: [
                ...(orderItem && orderItem.promoName
                  ? [
                      {
                        name: orderItem.promoName,
                        shortName: orderItem.promoName,
                      },
                    ]
                  : (!user && item.product.variants[0].promos) || []),
              ] as ProductVariant['promos'],
              tierPricing: item.product.variants[0].tierPricing?.map((tier) => ({
                ...tier,
                promoPrice:
                  tier.qty <= item.qty ? (orderItem ? orderItem.discountedPrice / orderItem.qty : 0) : tier.promoPrice,
                price: tier.qty <= item.qty ? (orderItem ? orderItem.price / orderItem.qty : 0) : tier.price,
              })),
            },
          ],
        },
      };
};

export { prepareItem, isOutOfLimits, UNAVAILABLE_SALE_TYPE_TO_FILTER };
