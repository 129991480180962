import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { RequiredDocuments } from 'entities/documents/documents';
import { FullShopFulfillmentType } from 'entities/shop/info';

type Params = {
  fulfillmentType: FullShopFulfillmentType;
};
type Data = RequiredDocuments;
type Error = APIErrorExtended;

const NAME = '/Documents/GetRequired';
const GetRequiredDocumentsEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export type { Data };
export default GetRequiredDocumentsEndpoint;
export { GetRequiredDocumentsEndpoint };
