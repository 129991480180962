import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import partialLeft from 'lodash/partial';
import partialRight from 'lodash/partialRight';
import throttle from 'lodash/throttle';

const asyncNoop = <T = any>() => Promise.resolve() as Promise<T>;

export { partialLeft, partialRight, noop, asyncNoop, debounce, throttle, memoize };
