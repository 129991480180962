import cn from 'clsx';
import { ReactNode, useMemo } from 'react';

import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type BaseCaptionProps = {
  children: ReactNode;
  variant: 'error' | 'note';
} & ComponentHasClassName;

type DefaultCaptionProps = BaseCaptionProps & { animated?: undefined };
type AnimatedCaptionProps = BaseCaptionProps & {
  animated: true;
  visible: boolean;
};
type CaptionProps = DefaultCaptionProps | AnimatedCaptionProps;

const Caption = ({ children, variant, className, ...rest }: CaptionProps) => {
  const DefinedText = useMemo(
    () => (
      <Text
        size="md"
        className={cn(styles.caption, styles[`_variant_${variant}`])}
        as="div"
      >
        {children}
      </Text>
    ),
    [children, variant],
  );

  return rest.animated ? (
    <AnimatedHeight
      className={className}
      expanded={rest.visible}
    >
      {DefinedText}
    </AnimatedHeight>
  ) : children ? (
    DefinedText
  ) : null;
};

export type { CaptionProps };
export { Caption };
export default Caption;
