import { useCallback, useEffect, useState } from 'react';

import { Quantities, useQuantityStorage } from 'common/providers/cart/use-cases/use-quantity-storage';

const useQuantities = (productId: ProductId) => {
  const { cartEvents, getQuantities } = useQuantityStorage();
  const [quantities, setQuantities] = useState<Quantities>(getQuantities(productId));

  useEffect(() => {
    const unsub = cartEvents.on('onQuantityChange', (e) => {
      if (e.productId === productId) {
        setQuantities(getQuantities(productId));
      }
    });

    return unsub;
  }, [cartEvents, getQuantities, productId]);

  return {
    quantities,
    getQuantities: useCallback(() => quantities, [quantities]),
    getQuantity: useCallback((variantId: VariantId) => quantities.map[variantId] ?? 0, [quantities]),
  };
};

export { useQuantities };
