import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { SnackbarKey } from '@swe/shared/providers/snackbar/types';

import { useEffect, useState } from 'react';

import PromoSnack from 'common/components/promo-snack';
import { useAgeChecked } from 'common/containers/age-restriction';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useStoreConfig } from 'common/providers/config';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate, useRouterPathname } from 'common/router';
import { Routes } from 'common/router/constants';

const SIGNUP_POPUP_DELAY = 30; // in seconds
const SIGNUP_POPUP_TIMEOUT = 10; // in seconds

type SignupIncentivizationProps = {
  closeOnUnmount?: boolean;
};

const RESTRICTED_PAGES = [
  Routes.SignUp,
  Routes.MarketingSubscription,
  Routes.MarketingSubscriptionEmailCheck,
  Routes.MarketingSubscriptionSuccess,
];

export const SignupIncentivization = ({ closeOnUnmount }: SignupIncentivizationProps) => {
  const [isAgeChecked] = useAgeChecked();
  const { user, isLoaded } = useCurrentUser();
  const navigate = useRouterNavigate();
  const { pushEvent } = useAnalytics();
  const pathname = useRouterPathname();
  const { isSignupPopupEnabled, signupPopupTitle, signupPopupDescription, signupPopupDelay, signupPopupTimeout } =
    useStoreConfig();
  const isRestrictedPage = useMatchRoute(RESTRICTED_PAGES);

  const popupDelay = (signupPopupDelay ?? SIGNUP_POPUP_DELAY) * 1000;
  const popupTimeout = (signupPopupTimeout ?? SIGNUP_POPUP_TIMEOUT) * 1000;

  const [isReady, setIsReady] = useState(isAgeChecked);
  useEffect(() => {
    if (isAgeChecked) {
      setTimeout(() => setIsReady(true), popupDelay);
    }
  }, [isAgeChecked, popupDelay]);

  const [snackKey, setSnackKey] = useState<SnackbarKey | undefined>(undefined);

  useEffect(() => {
    if (!isRestrictedPage && isSignupPopupEnabled && isReady && snackKey === undefined && isLoaded && !user) {
      const _snackKey = SnackbarService.push({
        type: 'ghost',
        key: 'signup_incentivization_popup',
        Component: PromoSnack,
        props: {
          color: 'brand',
          heading: signupPopupTitle,
          children: (
            <div
              dangerouslySetInnerHTML={{
                __html: signupPopupDescription ?? '',
              }}
            />
          ),
          action: {
            label: 'Sign Up',
            onClick: async () => {
              pushEvent(AEventType.SIGN_UP_POPUP_CLICKED, undefined);
              return navigate(Routes.SignUp);
            },
          },
        },
        autoHideDuration: popupTimeout,
      });
      setSnackKey(_snackKey);
    }
  }, [
    isSignupPopupEnabled,
    user,
    pathname,
    isReady,
    snackKey,
    isLoaded,
    signupPopupTitle,
    signupPopupDescription,
    popupTimeout,
    navigate,
    pushEvent,
    isRestrictedPage,
  ]);

  useEffect(() => {
    if (RESTRICTED_PAGES.includes(pathname) && snackKey) {
      SnackbarService.close(snackKey);
    }

    return () => {
      if (closeOnUnmount && snackKey) {
        SnackbarService.close(snackKey);
      }
    };
  }, [closeOnUnmount, pathname, snackKey]);

  return null;
};
