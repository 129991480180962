import { createContext, useContext } from 'react';

import { CarouselContext as CarouselContextT } from '@swe/shared/ui-kit/components/carousel-v2/core/types';

const CarouselContext = createContext<CarouselContextT>({});
const CarouselContextProvider = CarouselContext.Provider;
const useCarouselContext = () => useContext(CarouselContext);

export type { CarouselContextT };
export { CarouselContext, CarouselContextProvider, useCarouselContext };
