import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Product } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

type Data = Product[];
type Params = {
  saleType?: StoreSaleType;
  variantIds: EntityID[];
  productIds: EntityID[];
};

const NAME = '/Products/AlsoBoughtWith';
const GetAlsoBoughtWithEndpoint = createEndpoint<Params, Data>(NAME);

export default GetAlsoBoughtWithEndpoint;

export { GetAlsoBoughtWithEndpoint };
