import { SnackbarService } from '@swe/shared/providers/snackbar';

const showCode = (data: { code?: string | null }) => {
  if (typeof data === 'object' && !!data.code) {
    SnackbarService.push({
      type: 'success',
      heading: 'Verification code',
      message: `Code: ${data.code}`,
      dismissible: true,
    });
  }
  return data;
};

export { showCode };
