import { Text } from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';

const LaboratoryDataDisclaimer = () => {
  return (
    <Text
      size="md"
      className={styles.root}
    >
      Product information or packaging displayed may not be current or complete. Always refer to the physical product
      for the most accurate information and warnings. For additional information, contact the retailer or manufacturer.
      *Actual weight may vary based on seasonality and other factors. Estimated price is approximate and provided only
      for reference.
    </Text>
  );
};

export { LaboratoryDataDisclaimer };
