import { Bone } from '@swe/shared/ui-kit/components/skeleton';
import React from 'react';

export const SkeletonSectionHeader = () => {
  const squareSize = 28;
  const padding = 12;

  return (
    <>
      <Bone
        x={0}
        y={0}
        width={squareSize}
        height={squareSize}
      />
      <Bone
        x={squareSize + padding}
        y={0}
        width={`calc(100% - ${squareSize + padding}px)`}
        height={28}
      />
    </>
  );
};
