import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { useStoreConfig } from 'common/providers/config';

type DisableOrderingProps = ComponentHasChildren;

const DisableOrdering = ({ children }: DisableOrderingProps) => {
  const { isOnlineOrderingDisabled } = useStoreConfig();
  if (isOnlineOrderingDisabled) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export { DisableOrdering };
