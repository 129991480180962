import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { LoyaltySettings } from 'entities/loyalty/settings';

type Params = undefined;
type Data = LoyaltySettings;
type Error = APIErrorExtended;

const NAME = '/Loyalty/GetStoreLoyaltySettings';
const GetLoyaltySettingsEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default GetLoyaltySettingsEndpoint;
export { GetLoyaltySettingsEndpoint };
