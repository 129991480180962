import { MouseEvent, useCallback, useState } from 'react';

import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';
import { EventEmitter } from '@swe/shared/tools/event-emitter';

type MousePosition = [x: number, y: number];
type UseMousePositionArgs = {
  position: MousePosition;
  element: HTMLElement;
};

class MousePositionEmitter extends EventEmitter<{ onMove: UseMousePositionArgs }> {}

const EMITTERS: Map<HTMLElement, MousePositionEmitter | null> = new Map();

const getRelativeCoordinates = (event: MouseEvent, element: HTMLElement): MousePosition => {
  const rect = element.getBoundingClientRect();

  const position = {
    x: event.pageX,
    y: event.pageY,
  };
  const el = {
    x: rect.x,
    y: rect.y,
  };

  return [position.x - el.x, position.y - el.y];
};

const useMousePosition = <ET extends HTMLElement>(callback: (args: UseMousePositionArgs) => void, active = true) => {
  const [element, setElement] = useState<ET | null>(null);

  /**
   * Event type must be MouseEvent, but TS complaining about it with no reason
   */
  const handleMouseMove = useCallback(
    (event: any) => {
      if (!element) return;

      const MPE = EMITTERS.get(element);
      if (MPE) {
        const coordinates = getRelativeCoordinates(event, element!);
        MPE.fire('onMove', {
          position: coordinates,
          element,
        });
      }
    },
    [element],
  );

  useDeepCompareEffect(() => {
    if (!element) return;

    if (active) {
      const MPE = new MousePositionEmitter();

      element.addEventListener('mousemove', handleMouseMove);
      EMITTERS.set(element, MPE);

      return () => {
        element.removeEventListener('mousemove', handleMouseMove);
        EMITTERS.set(element, null);
      };
    }
  }, [element, handleMouseMove]);

  useDeepCompareEffect(() => {
    const MPE = EMITTERS.get(element!);
    if (!element || !MPE || !active) return;

    MPE.on('onMove', callback);

    return () => {
      MPE.off('onMove', callback);
    };
  }, [callback, element]);

  return {
    elementRef: setElement,
  };
};

export type { MousePosition, UseMousePositionArgs };
export { useMousePosition };
export default useMousePosition;
