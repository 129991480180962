import { formatDate } from '@swe/shared/utils/date';

enum ScheduleType {
  StoreHours = 'StoreHours',
  InStorePickup = 'InStorePickup',
  CurbsidePickup = 'CurbsidePickup',
  Delivery = 'Delivery',
  ExpressDelivery = 'ExpressDelivery',
  DriveThrough = 'DriveThrough',
}

type ScheduleInterval = [TimeISOString, TimeISOString];
type ScheduleIntervals = ScheduleInterval[] | null;

type ScheduleWeekDays = [
  ScheduleIntervals,
  ScheduleIntervals,
  ScheduleIntervals,
  ScheduleIntervals,
  ScheduleIntervals,
  ScheduleIntervals,
  ScheduleIntervals,
];

type Schedule = NamedEntity & {
  days: ScheduleWeekDays;
};

enum WeekDayAbbr {
  Sunday = 'Sun',
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
}

const WEEK = [
  WeekDayAbbr.Sunday,
  WeekDayAbbr.Monday,
  WeekDayAbbr.Tuesday,
  WeekDayAbbr.Wednesday,
  WeekDayAbbr.Thursday,
  WeekDayAbbr.Friday,
  WeekDayAbbr.Saturday,
];

const formatTimeInterval = (val: string) => {
  const prep = val.split(':').map((v) => parseInt(v, 10)) as [number, number];
  const d = new Date();
  d.setHours(...prep);
  return formatDate(d, 'p');
};

const sortDay = (intervals: ScheduleInterval[]) =>
  [...intervals].sort((a, b) => {
    if (parseInt(a[0].replace(':', ''), 10) > parseInt(b[0].replace(':', ''), 10)) {
      return 1;
    }
    return -1;
  });

const intervalFormat = (interval: ScheduleInterval, collapsed = true) => {
  const formatted = interval.map(formatTimeInterval);
  if (collapsed && formatted.every((i) => i === '12:00 AM')) {
    return '24h';
  }

  return `${formatted.join(' - ')}`;
};

const formatScheduleDay = (day: ScheduleIntervals) => {
  if (day) {
    return sortDay(day).map((val) => intervalFormat(val));
  }

  return ['Closed'];
};

const toWeekDayRecord = (days: ScheduleWeekDays) =>
  days.reduce<Record<WeekDayAbbr, string[]>>(
    (schedule, day, index) => ({
      ...schedule,
      [WEEK[index]]: formatScheduleDay(day),
    }),
    {} as Record<WeekDayAbbr, string[]>,
  );

const formatDayRecord = (days: string[], time: string) =>
  `${days.length === 1 ? days[0] : `${days[0]} - ${days[days.length - 1]}`}: ${time}`;

const reduceDuplicates = (schedule: Record<WeekDayAbbr, string[]>) => {
  const reducedSchedule: string[] = [];
  let previousDays: string[] = [WeekDayAbbr.Sunday];
  let previousTime = schedule[WeekDayAbbr.Sunday];
  const appendTime = () => {
    reducedSchedule.push(formatDayRecord(previousDays, previousTime[0]));
    reducedSchedule.push(...previousTime.slice(1));
  };

  WEEK.slice(1).forEach((day) => {
    const time = schedule[day];
    if (!time) {
      return;
    }

    if (String(time) === String(previousTime)) {
      previousDays.push(day);
    } else {
      appendTime();

      previousDays = [day];
      previousTime = time;
    }
  });

  appendTime();

  return reducedSchedule;
};

const formatDays = (days: ScheduleWeekDays) => reduceDuplicates(toWeekDayRecord(days));

export type { ScheduleInterval, ScheduleIntervals, ScheduleWeekDays, Schedule };
export {
  ScheduleType,
  WeekDayAbbr,
  WEEK,
  formatScheduleDay,
  formatDayRecord,
  formatDays,
  toWeekDayRecord,
  sortDay,
  intervalFormat,
  reduceDuplicates,
};
