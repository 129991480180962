import cn from 'clsx';

import { IconComponent } from '@swe/shared/ui-kit/components/icon';
import {
  Colors,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type BulletSize = Sizes<'md' | 'sm' | 'lg'>;
type BulletColor = Colors<'primary' | 'light' | 'success' | 'danger' | 'ghost'>;

type BulletProps = ComponentHasClassName & {
  value: string | IconComponent;
  disabled?: boolean;
  onClick?: () => void;
} & ComponentHasSize<BulletSize> &
  ComponentHasColor<BulletColor>;

const Bullet = ({ className, size = 'md', color = 'light', value, disabled, onClick }: BulletProps) => {
  const Icon = typeof value !== 'string' ? value : undefined;
  return (
    <div
      className={cn(
        styles.root,
        styles[`_size_${size}`],
        styles[`_color_${color}`],
        Icon && styles._icon,
        disabled && styles._disabled,
        className,
        onClick && styles._clickable,
      )}
      onClick={onClick}
    >
      {Icon ? <Icon className={styles.icon} /> : (value as string)}
    </div>
  );
};

export { Bullet };
export type { BulletColor, BulletSize, BulletProps };
export default Bullet;
