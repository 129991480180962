import cn from 'clsx';
import { ComponentType, SyntheticEvent, useCallback } from 'react';

import { IconProps } from '@swe/shared/ui-kit/components/icon';
import { Tooltip, TooltipProps } from '@swe/shared/ui-kit/components/tooltip';
import { ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';
import {
  Colors,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type BadgeSize = Sizes<'sm' | 'md'>;
export type BadgeColor = Colors<'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'neutral' | 'light'>;

export type BadgeProps = {
  icon?: ComponentType<IconProps>;
  children?: number | string;
  tooltip?: TooltipProps['content'];
  stopPropagation?: boolean;
} & ComponentHasSize<BadgeSize> &
  ComponentHasClassName &
  ComponentHasColor<BadgeColor>;

type ThemePatternType = {
  themePattern?: ThemePattern;
};

export type ThemedBadgeProps = BadgeProps & ThemePatternType;

const Badge = ({
  className,
  size = 'sm',
  color = 'primary',
  children,
  icon: Icon,
  tooltip,
  stopPropagation,
}: BadgeProps) => {
  const Root = tooltip ? Tooltip : 'div';

  const stopProp = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const stopPropHandler = stopPropagation ? stopProp : undefined;

  return (
    <Root
      className={cn(styles.root, styles[`_size_${size}`], styles[`_color_${color}`], className)}
      content={tooltip}
    >
      {Icon && (
        <Icon
          className={styles.icon}
          onClick={stopPropHandler}
        />
      )}
      {children && (
        <div
          className={styles.textContainer}
          onClick={stopPropHandler}
        >
          <div className={styles.text}>{children}</div>
        </div>
      )}
    </Root>
  );
};

export { Badge };
export default Badge;
