import { format, parse } from 'date-fns';

import { FactoryOpts } from 'imask';
import isNaN from 'lodash/isNaN';
import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useBreakpoint, useIsIOS, useIsTouchEnabled } from '@swe/shared/tools/media';
import { Calendar, CalendarProps } from '@swe/shared/ui-kit/components/calendar';
import { Input, InputProps } from '@swe/shared/ui-kit/components/form/input';
import { FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { CalendarIcon } from '@swe/shared/ui-kit/components/icon';

import { Popover, PopoverContext } from '@swe/shared/ui-kit/components/popover';
import { parseDateOnlyISO } from '@swe/shared/utils/date';

import styles from './styles.module.scss';

const TODAY = new Date();
const INNER_DATE_FORMAT = 'MM/dd/yyyy';
const TRANSFER_DATE_FORMAT = 'yyyy-MM-dd';

type InputDateProps = Omit<InputProps, 'customMask' | 'maskPreset' | 'type' | 'actions'> & {
  min?: Date;
  max?: Date;
  initialScreen?: CalendarProps['initialScreen'];
};

const tryFormat = (value: DateISOString = '') => {
  try {
    return format(parseDateOnlyISO(value), INNER_DATE_FORMAT);
  } catch (e) {
    return '';
  }
};

const InputDate = forwardRef<FormControlRef, InputDateProps>(
  ({ min, max, value, onChange, onBlur, className, initialScreen, ...inputProps }, ref) => {
    const prepDate = useCallback((nextTempValue: string) => {
      if (!nextTempValue) {
        return '';
      }
      const parsedNextDate = parse(nextTempValue, INNER_DATE_FORMAT, TODAY);
      return isNaN(parsedNextDate.valueOf()) ? '' : format(parsedNextDate, TRANSFER_DATE_FORMAT);
    }, []);

    const [tempValue, setTempValue] = useState(tryFormat(value));
    const tempValueChangeHandler = useCallback(
      (nextTempValue: string) => {
        setTempValue(nextTempValue);

        const value = prepDate(nextTempValue);
        onChange?.(value);
      },
      [onChange, prepDate],
    );

    const blurHandler = useCallback(() => {
      const parsedTempDate = parse(tempValue, INNER_DATE_FORMAT, TODAY);
      if (isNaN(parsedTempDate.valueOf())) {
        setTempValue('');
        onChange?.('');
      }
      onBlur?.();
    }, [onBlur, onChange, tempValue]);

    const popoverRef = useRef<PopoverContext | null>(null);

    const mask = useMemo<FactoryOpts>(
      () => ({
        mask: Date,
        pattern: '`m{/}`d{/}`Y',
        // TODO: Typo bug in imask@7.0.1
        format: (date: any): string => format(date, INNER_DATE_FORMAT),
        parse: (value): Date => parse(value, INNER_DATE_FORMAT, TODAY),
        min,
        max,
        autofix: false,
        overwrite: false,
        lazy: false,
        unmask: false,
      }),
      [max, min],
    );

    const onChangeCalendar = useCallback(
      (date: Date) => {
        const res = format(date, INNER_DATE_FORMAT);
        setTempValue(res);
        onChange?.(res);
      },
      [onChange],
    );

    const fieldWrapper = useCallback(
      (input: ReactNode) => {
        let calendarValue = new Date();
        try {
          calendarValue = parse(tempValue, INNER_DATE_FORMAT, TODAY);
          // eslint-disable-next-line no-empty
        } catch (err) {}

        return (
          <Popover
            ref={popoverRef}
            content={
              <div className={styles.calendar}>
                <Calendar
                  max={max}
                  min={min}
                  value={calendarValue}
                  initialScreen={initialScreen}
                  onChange={onChangeCalendar}
                  onChangeDay={popoverRef.current?.close}
                />
              </div>
            }
            placement="bottom-end"
            trigger="focus"
            block
            offset="xxs"
          >
            {input}
          </Popover>
        );
      },
      [initialScreen, max, min, onChangeCalendar, tempValue],
    );

    const { desktop } = useBreakpoint();
    const isIos = useIsIOS();
    const isTouch = useIsTouchEnabled();

    const mobileInputRef = useRef<HTMLInputElement>(null);
    const mobileInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const date = parse(e.target.value, TRANSFER_DATE_FORMAT, TODAY);
        setTempValue(format(date, INNER_DATE_FORMAT));
        onChange?.(e.target.value);
      },
      [onChange],
    );
    const inputId = useId();

    const actions = useMemo(
      () => [
        {
          icon: CalendarIcon,
          onClick: (e: SyntheticEvent) => {
            e.stopPropagation();
            mobileInputRef.current?.showPicker();
          },
          ...(isIos
            ? {
                node: (
                  <label
                    htmlFor={inputId}
                    style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                  />
                ),
              }
            : {}),
        },
      ],
      [inputId, isIos],
    );

    if (!desktop && isTouch) {
      return (
        <>
          <input
            id={inputId}
            style={{
              position: 'absolute',
              zIndex: -1,
              pointerEvents: 'none',
              opacity: 0,
            }}
            value={prepDate(tempValue)}
            type="date"
            ref={mobileInputRef}
            onChange={mobileInputChange}
            max={max ? format(max, TRANSFER_DATE_FORMAT) : ''}
            inputMode="decimal"
          />
          <Input
            {...inputProps}
            type="text"
            ref={ref}
            label={inputProps.label as string}
            ariaLabel={inputProps.ariaLabel as string}
            value={tempValue}
            onChange={tempValueChangeHandler}
            actions={actions}
            customMask={mask}
            inputMode="decimal"
          />
        </>
      );
    }

    return (
      <Input
        {...inputProps}
        ref={ref}
        label={inputProps.label as string}
        ariaLabel={inputProps.ariaLabel as string}
        value={tempValue}
        onChange={tempValueChangeHandler}
        onBlur={blurHandler}
        actions={actions}
        customMask={mask}
        fieldWrapper={fieldWrapper}
        onFocus={popoverRef.current?.open}
        onClear={popoverRef.current?.close}
        onClick={popoverRef.current?.open}
      />
    );
  },
);

export type { InputDateProps };
export { InputDate };
export default InputDate;
