/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { FilterableList, FilterableListPropsCommon } from '@swe/shared/ui-kit/components/filterable-list';
import { Option } from '@swe/shared/ui-kit/components/form/radio-group/types';
import { RadioGroupTag, RadioGroupTagProps } from '@swe/shared/ui-kit/components/form/radio-group/variants/tag';
import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type RadioGroupTagFilterableProps<VT = string> = ComponentHasClassName &
  FilterableListPropsCommon<Option<VT>> &
  RadioGroupTagProps<VT>;

const RadioGroupTagFilterable = <VT extends any = string>({
  className,

  searchable,
  search,
  searchQuery,
  searchPlaceholder,
  onSearch,
  expandable,
  expanded,
  collapsedOptionsVisible,

  onToggle,
  ...radioGroupProps
}: RadioGroupTagFilterableProps<VT | null>) => {
  return (
    <FilterableList
      className={className}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      searchQuery={searchQuery}
      search={search}
      expandable={expandable}
      expanded={expanded}
      collapsedOptionsVisible={collapsedOptionsVisible}
      onSearch={onSearch}
      onToggle={onToggle}
      options={radioGroupProps.options}
    >
      {(options) =>
        options.length > 0 ? (
          radioGroupProps.inline ? (
            <RadioGroupTag
              {...radioGroupProps}
              options={options}
            />
          ) : (
            <Scrollable fade>
              <div className={styles.groupContent}>
                <RadioGroupTag
                  {...radioGroupProps}
                  options={options}
                />
              </div>
            </Scrollable>
          )
        ) : (
          <Text className={styles.nothingFound}>Nothing found</Text>
        )
      }
    </FilterableList>
  );
};

export type { RadioGroupTagFilterableProps };
export { RadioGroupTagFilterable };
export default RadioGroupTagFilterable;
