import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ReferralProgram } from 'entities/profile/referral-program';

type Data = ReferralProgram;
type Params = undefined;

const NAME = '/Referral/Get';
const GetReferralProgramEndpoint = createModernEndpoint<Params, Data>(NAME);

export default GetReferralProgramEndpoint;
export { GetReferralProgramEndpoint };
