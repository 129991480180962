import { isValidElement, ReactElement } from 'react';

import { useIntl } from '@swe/shared/tools/intl';

import { Values } from '@swe/shared/tools/intl/core/i18n';

import { formatElements } from './format';

type TransProps = {
  id: string;
  defaultMessage: string;
  values?: Values;
};

const Trans = ({ id, defaultMessage, values: _values }: TransProps): ReactElement<any, any> | null => {
  const { intl } = useIntl();
  const values: Record<string, any> = { ..._values };
  const components: Record<string, any> = {};

  if (_values) {
    Object.keys(values).forEach((key) => {
      const value = values[key];
      const valueIsReactEl = isValidElement(value) || (Array.isArray(value) && value.every(isValidElement));
      if (!valueIsReactEl) return;

      const index = Object.keys(components).length;

      components[index] = value;
      values[key] = `<${index}/>`;
    });
  }

  const translation: string = intl.t(id, values, defaultMessage);
  const formattedTranslation = formatElements(translation, components);

  return formattedTranslation as unknown as ReactElement<any, any>;
};

export type { TransProps };
export { Trans };
