import { useCallback, useRef, useState } from 'react';

type Value = boolean;

type UseResidencyType = {
  isResident: Value;
  updateResident: (value: Value) => Promise<void>;
  isLoading: boolean;
  setIsResident: (value: Value) => void;
};

const useResidency = (value: Value, cb: (value: Value) => Promise<boolean>): UseResidencyType => {
  const [isResident, _setIsResident] = useState(value);
  const [isLoading, setIsLoading] = useState(false);
  const prev = useRef(value);

  const updateResident = useCallback(
    async (value: boolean) => {
      setIsLoading(true);
      _setIsResident(value);
      const res = await cb(value);
      if (res) {
        prev.current = value;
      } else {
        _setIsResident(prev.current);
      }
      setIsLoading(false);
    },
    [cb],
  );

  return {
    isResident,
    updateResident,
    isLoading,
    setIsResident: _setIsResident,
  };
};

export type { UseResidencyType };
export { useResidency };
