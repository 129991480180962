import { useBreakpoint } from '@swe/shared/tools/media';
import RadioGroup from '@swe/shared/ui-kit/components/form/radio-group';
import Grid from '@swe/shared/ui-kit/components/grid';

import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useCallback, useState } from 'react';

import { LaboratoryDataBlock } from 'domains/product/components/laboratory-data/components/laboratory-data-block';
import { ProductLaboratoryData } from 'entities/product/product';

enum Section {
  THC = 'thc',
  CBD = 'cbd',
  Terpenes = 'terpenes',
}

enum Heading {
  THC = 'THC',
  CBD = 'CBD',
  Terpenes = 'Terpenes',
}

const MOBILE_SECTION_OPTIONS = [
  { label: Heading.THC, value: Section.THC },
  { label: Heading.CBD, value: Section.CBD },
  { label: Heading.Terpenes, value: Section.Terpenes },
];

type LaboratoryDataProps = ComponentHasClassName & {
  data: ProductLaboratoryData;
};

const LaboratoryDataMobile = ({ data }: LaboratoryDataProps) => {
  const [section, setSection] = useState(Section.THC);

  const renderSection = useCallback(
    (section: Section) => {
      switch (section) {
        case Section.THC:
          return (
            <LaboratoryDataBlock
              heading={Heading.THC}
              data={data.thc.values}
              unitAbbr={data.thc.unitAbbr}
              header={false}
            />
          );
        case Section.CBD:
          return (
            <LaboratoryDataBlock
              heading={Heading.CBD}
              data={data.cbd.values}
              unitAbbr={data.cbd.unitAbbr}
              header={false}
            />
          );
        case Section.Terpenes:
          return (
            <LaboratoryDataBlock
              heading={Heading.Terpenes}
              data={data.terpenes.values}
              unitAbbr={data.terpenes.unitAbbr}
              header={false}
            />
          );
      }
    },
    [
      data.cbd.unitAbbr,
      data.cbd.values,
      data.terpenes.unitAbbr,
      data.terpenes.values,
      data.thc.unitAbbr,
      data.thc.values,
    ],
  );

  return (
    <Stack spacing="sm">
      <RadioGroup
        variant="section"
        name="table-selector"
        value={section}
        options={MOBILE_SECTION_OPTIONS}
        onChange={setSection}
      />
      {renderSection(section)}
    </Stack>
  );
};

const LaboratoryData = ({ data }: LaboratoryDataProps) => {
  const { mobile } = useBreakpoint();
  if (mobile) {
    return <LaboratoryDataMobile data={data} />;
  }

  return (
    <Grid.Row spacing="lg">
      <Grid.Cell cols={4}>
        <LaboratoryDataBlock
          heading={Heading.THC}
          data={data.thc.values}
          unitAbbr={data.thc.unitAbbr}
        />
      </Grid.Cell>
      <Grid.Cell cols={4}>
        <LaboratoryDataBlock
          heading={Heading.CBD}
          data={data.cbd.values}
          unitAbbr={data.cbd.unitAbbr}
        />
      </Grid.Cell>
      <Grid.Cell cols={4}>
        <LaboratoryDataBlock
          heading={Heading.Terpenes}
          data={data.terpenes.values}
          unitAbbr={data.terpenes.unitAbbr}
        />
      </Grid.Cell>
    </Grid.Row>
  );
};

export type { LaboratoryDataProps };
export { LaboratoryData };
