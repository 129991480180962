class QueueBuffer<ItemT = any> {
  constructor(private items: ItemT[] = []) {}

  push(item: ItemT) {
    this.items.push(item);
  }

  clear() {
    const { items } = this;
    this.items = [];
    return items;
  }

  get length() {
    return this.items.length;
  }
}

export { QueueBuffer };
