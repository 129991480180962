class SimpleInMemoryCache {
  private readonly storage = new Map();

  private readonly asyncTaskIds: Set<number> = new Set();

  pushAsyncTaskId(id: any) {
    this.asyncTaskIds.add(id);
  }

  clearAsyncTaskId(id: any) {
    this.asyncTaskIds.delete(id);
  }

  set<T>(key: string, value: T, timeout: number = 1000 * 60 * 10) {
    this.storage.set(key, value);

    const timeoutId = setTimeout(() => {
      this.delete(key);
      this.clearAsyncTaskId(timeoutId);
    }, timeout) as unknown as number;
    this.pushAsyncTaskId(timeoutId);
  }

  get<T>(key: string) {
    return (this.storage.get(key) ?? null) as T | null;
  }

  delete(key: string) {
    if (this.storage.has(key)) {
      this.storage.delete(key);
    }
  }
}

export default new SimpleInMemoryCache();
