import FocusTrapReact, { Props } from 'focus-trap-react';

import { useMemo } from 'react';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type FocusTrapProps = ComponentHasChildren & {
  isActive?: boolean;
  onEscapeDeactivate?: () => boolean;
  returnFocusOnDeactivate?: boolean;
};

const getFakeElement = () => (<div tabIndex={-1} />) as unknown as HTMLElement;

const FocusTrap = ({ isActive, onEscapeDeactivate, children, returnFocusOnDeactivate }: FocusTrapProps) => {
  const options = useMemo<Props['focusTrapOptions']>(
    () => ({
      initialFocus: false,
      allowOutsideClick: true,
      fallbackFocus: getFakeElement,
      escapeDeactivates: onEscapeDeactivate,
      returnFocusOnDeactivate,
    }),
    [onEscapeDeactivate, returnFocusOnDeactivate],
  );

  return (
    <FocusTrapReact
      active={isActive}
      focusTrapOptions={options}
    >
      {children}
    </FocusTrapReact>
  );
};

export type { FocusTrapProps };
export { FocusTrap };
