import { useRef } from 'react';

import { useBreakpoint } from '@swe/shared/tools/media';
import { ModalProps } from '@swe/shared/ui-kit/components/modal/types';

import { ModalDesktop, ModalDesktopProps } from './desktop';
import { ModalMobile } from './mobile';

const Modal = ({ mobile = 'default', desktop = 'default', onDeviceSwitch, ...props }: ModalProps) => {
  const { mobile: isMobile } = useBreakpoint();
  const lastValue = useRef(isMobile);

  if (isMobile !== lastValue.current) {
    lastValue.current = isMobile;
    onDeviceSwitch?.();
  }

  return isMobile ? (
    <ModalMobile
      {...props}
      variant={mobile}
    />
  ) : (
    <ModalDesktop
      {...(props as ModalDesktopProps)}
      variant={desktop}
    />
  );
};

export type { ModalProps };
export { Modal };
export default Modal;
