import { Device } from '@swe/shared/tools/media';

const LAST_STORE_COOKIE_NAME = 'last_store';

const CLIENT_WIDTH_BY_DEVICE_TYPE: Record<Device, number> = {
  mobile: 360,
  tablet: 768,
  desktop: 1440,
};

export { LAST_STORE_COOKIE_NAME, CLIENT_WIDTH_BY_DEVICE_TYPE };
