import cn from 'clsx';
import { ComponentType, SyntheticEvent, useMemo } from 'react';

import SVG from '@swe/shared/ui-kit/components/svg';
import { IconSrcResolver, ThemeIconSrcSize, useThemeGetter } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName, Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type IconSemanticSize = Sizes<'md' | 'sm' | 'xs' | 'lg' | 'xxs' | 'xl'>;
export type IconSize = IconSemanticSize | number;

export type IconProps = ComponentHasClassName & {
  size?: IconSize;
  active?: boolean;
  onClick?: (e: SyntheticEvent) => void;
};

export const makeIcon = (iconSrc: string): ComponentType<IconProps> => {
  const Icon = ({ className, size = 'md', active, onClick }: IconProps) => {
    return (
      <div
        className={cn(
          styles.root,
          onClick && styles._interactive,
          active === true && styles._active,
          active === false && styles._disabled,
          typeof size !== 'number' && styles[`_size_${size}`],
          className,
        )}
        style={typeof size === 'number' ? { width: size, height: size } : undefined}
        onClick={onClick}
      >
        <SVG
          src={iconSrc}
          className={styles.icon}
          role="presentation"
        />
      </div>
    );
  };
  return Icon;
};

const AVAILABLE_SRC_SIZES: ThemeIconSrcSize[] = [16, 24];
const ICON_SEMANTIC_TO_SRC_SIZE: Record<IconSemanticSize, ThemeIconSrcSize> = {
  xxs: 16,
  xs: 16,
  sm: 16,
  md: 24,
  lg: 24,
  xl: 24,
};

const findNearestSrcSize = (size: number): ThemeIconSrcSize =>
  AVAILABLE_SRC_SIZES.reduce((prev, curr) => (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev));

const buildIconModuleResolver = (name: string, size: IconSize, srcResolver: IconSrcResolver): string => {
  const normalizedSize = typeof size === 'number' ? findNearestSrcSize(size) : ICON_SEMANTIC_TO_SRC_SIZE[size];
  return srcResolver({ iconName: name, iconSize: normalizedSize });
};

export const makeIcon2 = (name: string): ComponentType<IconProps> => {
  const Icon = ({ className, size = 'md', active, onClick }: IconProps) => {
    const { getIconSrc } = useThemeGetter();
    const iconSrc = useMemo(() => buildIconModuleResolver(name, size, getIconSrc), [getIconSrc, size]);

    return (
      <div
        className={cn(
          styles.root,
          onClick && styles._interactive,
          active === true && styles._active,
          active === false && styles._disabled,
          typeof size !== 'number' && styles[`_size_${size}`],
          className,
        )}
        style={typeof size === 'number' ? { width: size, height: size } : undefined}
        onClick={onClick}
      >
        <SVG
          src={iconSrc}
          className={styles.icon}
          role="presentation"
        />
      </div>
    );
  };

  return Icon;
};

export default makeIcon;
