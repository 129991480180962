import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ShopConfigDTO } from 'entities/shop/config';

type Data = ShopConfigDTO;
type Params = {
  domain?: string;
  routeName?: string;
};

const NAME = '/Store/GetStoreConfig';
const GetShopConfigEndpoint = createModernEndpoint<Params, Data>(NAME, undefined, {
  keyParams: () => ({}),
  dedupingInterval: 60 * 1000,
  revalidateIfStale: true,
});

export default GetShopConfigEndpoint;
export { GetShopConfigEndpoint };
