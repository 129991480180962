import { Store } from 'common/entities/map/Store';
import { ShopInfo } from 'entities/shop/info';

class Delivery {
  constructor(private readonly _stores: ShopInfo[]) {}

  public get stores() {
    return this._stores.map((store) => new Store(store));
  }

  public get storesLocations() {
    return this.stores.map((s) => s.location);
  }

  public get deliveryZones() {
    return this.stores.map((s) => s.deliveryZones).flat();
  }
}

export { Delivery };
