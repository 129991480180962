import { Serializable } from '@swe/shared/types/json';

import { Schedule, ScheduleIntervals } from 'entities/common/schedule';
import { ProductSaleType } from 'entities/product/product';

export type ShopAmenity = NamedEntity<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10>;

export type LocationCoords = {
  lat: number;
  lng: number;
};

enum DistanceUnit {
  M,
  K,
  N,
}

export const locationsDistance = (
  { lat: lat1, lng: lng1 }: LocationCoords,
  { lat: lat2, lng: lng2 }: LocationCoords,
  unit: DistanceUnit = DistanceUnit.M,
) => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === DistanceUnit.K) {
    dist *= 1.609344;
  }
  if (unit === DistanceUnit.N) {
    dist *= 0.8684;
  }
  return dist;
};

export type LocationRegion = NamedEntity;
export type LocationCity = NamedEntity;

export enum ShopFulfillmentType {
  Pickup = 'Pickup',
  Delivery = 'Delivery',
}

export enum FullShopFulfillmentType {
  InStorePickup = 'InStorePickup',
  InStoreOrder = 'InStoreOrder',
  Delivery = 'Delivery',
  LocalDelivery = 'LocalDelivery',
  CurbsidePickup = 'Curbside',
  DriveThrough = 'DriveThrough',
}

export const isPickup = (type: FullShopFulfillmentType) =>
  [
    FullShopFulfillmentType.CurbsidePickup,
    FullShopFulfillmentType.DriveThrough,
    FullShopFulfillmentType.InStorePickup,
  ].includes(type);

export const isDelivery = (type: FullShopFulfillmentType) =>
  [FullShopFulfillmentType.Delivery, FullShopFulfillmentType.LocalDelivery].includes(type);

type StoreDeliveryZoneSchedule = {
  date: string;
  intervals: ScheduleIntervals;
}[];

export type StoreDeliveryZone = NamedEntity & {
  storeId?: EntityID;
  fee: Price$;
  minOrderAmount: Price$;
  freeShippingPriceThreshold?: Price$;
  schedule: StoreDeliveryZoneSchedule; // на текущую неделю, не знаю но почему то у зон есть свои скедули а у магазина свой
  geometry: Serializable;
};

export type FulfillmentOption = {
  title: string;
  fulfillmentType: string;
  hasTimeIntervals: boolean;
  children: FulfillmentOption[];
};

export type StoreLocation = {
  coords: LocationCoords;
  region: LocationRegion;
  city: LocationCity;
  zipCode: string;
};

export type ShopInfo = NamedEntity<number> & {
  url: AbsoluteURL;
  description?: string;
  images: AbsoluteURL[];
  amenities: ShopAmenity[];
  isMock?: boolean;
  contacts: {
    address: string;
    email?: string;
    phone?: string;
  };
  location: StoreLocation;
  schedules: Schedule[];
  fulfillmentTypes: ShopFulfillmentType[];
  deliveryZones?: Omit<StoreDeliveryZone, 'storeId'>[];
  fulfillmentOptions?: FulfillmentOption[];
  routeName?: string;
  saleTypes: ProductSaleType[];
  isCaregiverOrdersEnabled: boolean;
};

export type FulfilmentInterval = {
  id: EntityID<number>;
  interval: [TimeISOString, TimeISOString];
  isAsap?: boolean;
};

export type FulfilmentDay = {
  date: DateISOString;
  intervals: FulfilmentInterval[];
};

export type FulfilmentScheduleInterval = {
  scheduleExists: boolean;
  intervals: FulfilmentDay[];
};
