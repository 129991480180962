import cx from 'clsx';
import { ReactNode } from 'react';

import Box from '@swe/shared/ui-kit/components/box';
import { Button, ButtonColor, ButtonProps } from '@swe/shared/ui-kit/components/button';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type HeroProps = ComponentHasClassName & {
  illustration?: ReactNode;
  title: ReactNode;
  content?: ReactNode;
  actions?: ButtonProps[];
  border?: boolean;
};

const Hero = ({ className, illustration, title, content, actions, border = true }: HeroProps) => {
  const theme = useTheme();
  return (
    <Box className={cx(styles.root, border && styles._bordered, className)}>
      <div className={styles.content}>
        {illustration && <div className={styles.illustration}>{illustration}</div>}
        <SectionHeading size="xl">{title as any}</SectionHeading>
        <Stack
          className={styles.text}
          spacing="md"
        >
          {content}
        </Stack>
        <Stack
          direction="row"
          className={styles.actions}
        >
          {actions?.map((props, idx) => (
            <Button
              key={`hero_btn_${idx}`}
              color={theme.pageState.button.style.suggest as ButtonColor}
              block
              {...props}
            />
          ))}
        </Stack>
      </div>
    </Box>
  );
};

export type { HeroProps };
export { Hero };
