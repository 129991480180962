import { useCallback, useEffect, useMemo, useRef } from 'react';

import useDebounce from '@swe/shared/hooks/use-debounce';
import { QueueBuffer } from '@swe/shared/tools/queue-buffer';

const useBrowserEventQueue = <EventT extends any>(processor: (events: EventT[]) => Promise<void>) => {
  const processorRef = useRef(processor);
  processorRef.current = processor;

  const queueBuffer = useMemo(() => new QueueBuffer<EventT>(), []);

  const process = useCallback(async () => {
    if (queueBuffer.length === 0) {
      return;
    }

    const events = queueBuffer.clear();
    await processorRef.current(events);
  }, [queueBuffer]);

  const debouncedProcess = useDebounce(process, 10000);

  const pushEvent = useCallback(
    (event: EventT) => {
      queueBuffer.push(event);
      void debouncedProcess();
    },
    [queueBuffer, debouncedProcess],
  );

  useEffect(() => {
    window.addEventListener('pagehide', process);
    return () => window.removeEventListener('pagehide', process);
  }, [process]);

  return useMemo(() => ({ pushEvent, process }), [pushEvent, process]);
};

export default useBrowserEventQueue;
