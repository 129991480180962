import { Prototypes, Requirements, Verifications, Documents } from 'common/entities/documents';

const state: Partial<{
  prototypes: Prototypes;
  verifications: Verifications;
  requirements: Requirements;
  documents: Documents;
}> = {
  prototypes: undefined,
  verifications: undefined,
  requirements: undefined,
  documents: undefined,
};

export { state };
