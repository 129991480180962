import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';

const SIZE = 4;

const QuantitySuggestionsSkeleton = () => (
  <Skeleton
    uniqueKey="quantity_suggestions"
    height={40}
  >
    {Array(SIZE)
      .fill(null)
      .map((_, idx, arr) => (
        <Bone
          x={`${(100 / 4) * idx}%`}
          y="0"
          rx="20"
          ry="20"
          width={`${100 / SIZE - (idx + 1 === arr.length ? 0 : 1)}%`}
          height="40"
        />
      ))}
  </Skeleton>
);

export { QuantitySuggestionsSkeleton };
