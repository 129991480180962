import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack';
import { ComponentType, forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { useMounted } from '@swe/shared/hooks/use-mounted';
import { useSnackbarAdditionalCtx } from '@swe/shared/providers/snackbar/additional-provider';

type ComponentProps = {
  close: () => void;
};

type GhostProps = {
  Component: ComponentType<ComponentProps>;
  props: ComponentProps;
  delay?: number | null;
} & CustomContentProps;

const Ghost = forwardRef<HTMLDivElement, GhostProps>(({ id, Component, props, delay }, ref) => {
  const [componentProps, setComponentProps] = useState(props);

  const close = useCallback(() => {
    closeSnackbar(id);
  }, [id]);

  const timerId = useRef<ReturnType<typeof setTimeout>>();

  const runTimer = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    if (delay && typeof delay !== 'undefined') {
      timerId.current = setTimeout(close, delay);
    }
  }, [delay, close]);

  useMounted(() => {
    runTimer();
  });

  const update = useCallback(
    ({ key, props }: { key: number | string; props: any }) => {
      if (key === id) {
        setComponentProps(props);
        runTimer();
      }
    },
    [id, runTimer],
  );

  const { emitter } = useSnackbarAdditionalCtx();

  useEffect(() => {
    emitter.on('update', update);
    return () => emitter.off('update', update);
  }, [emitter, id, update]);

  const mouseEnterHandler = useCallback(() => {
    clearTimeout(timerId.current);
  }, []);

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={runTimer}
    >
      <Component
        {...componentProps}
        close={close}
      />
    </SnackbarContent>
  );
});

export type { GhostProps, ComponentProps };
export { Ghost };
export default Ghost;
