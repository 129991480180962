import { useCallback } from 'react';

import { useRouterHistory, useRouterNavigate } from 'common/router';
import { Routes, ShopRoute } from 'common/router/constants';

type GoPreviousOptions = {
  defaultRoute?: ShopRoute;
  useBackHistory?: boolean;
};

const useGoPrevious = ({ defaultRoute, useBackHistory = false }: GoPreviousOptions) => {
  const { hasPrevious } = useRouterHistory();
  const navigate = useRouterNavigate();

  const goPrevious = useCallback(() => {
    if (hasPrevious && useBackHistory) {
      void navigate(-1);
    } else {
      void navigate(defaultRoute ?? Routes.Home, { replace: true });
    }
  }, [hasPrevious, useBackHistory, navigate, defaultRoute]);

  return goPrevious;
};

export { useGoPrevious };
