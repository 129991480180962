import Button from '@swe/shared/ui-kit/components/button';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';

import { AgeForm } from 'common/containers/age-restriction-modal/components/age-form';
import { RestrictionHeading } from 'common/containers/age-restriction-modal/components/restriction-heading';
import { AgeFormRestrictionText } from 'common/containers/age-restriction-modal/components/restriction-text';
import AgreementDisclaimer from 'common/containers/agreement-disclaimer';
import { STATE_TO_ABBREVIATION } from 'common/entities/get-region-abbreviation';
import { useStoreConfig } from 'common/providers/config';
import { useMatchState } from 'common/use-cases/use-match-state';

type AgeRestrictionModalProps = {
  visible: boolean;
  onConfirm: () => void;
};

const USE_MINIMAL_CUSTOMER_AGE_STATES = [STATE_TO_ABBREVIATION.Maryland, STATE_TO_ABBREVIATION.Florida];

const AgeRestrictionModal = ({ visible, onConfirm }: AgeRestrictionModalProps) => {
  const isMaryland = useMatchState(STATE_TO_ABBREVIATION.Maryland);
  const useMinimalCustomerAge = useMatchState(USE_MINIMAL_CUSTOMER_AGE_STATES);
  const { minimalCustomerAge } = useStoreConfig();

  return (
    <Modal
      visible={visible}
      heading={
        <RestrictionHeading
          isBirthdayRequired={isMaryland}
          useMinimalCustomerAge={useMinimalCustomerAge}
          minimalCustomerAge={minimalCustomerAge}
        />
      }
      ariaLabel="Age Restricttion Modal"
      inPortal={false}
    >
      <Stack spacing="md">
        <AgeFormRestrictionText
          useMinimalCustomerAge={useMinimalCustomerAge}
          minimalCustomerAge={minimalCustomerAge}
        />
        {isMaryland ? (
          <AgeForm
            minimalCustomerAge={minimalCustomerAge}
            onAgeCheckPassed={onConfirm}
          />
        ) : (
          <Button
            ariaLabel="Confirm age"
            block
            onClick={onConfirm}
          >
            Confirm
          </Button>
        )}
        <AgreementDisclaimer
          size="lg"
          align="center"
          spacer={false}
        />
      </Stack>
    </Modal>
  );
};

export type { AgeRestrictionModalProps };
export { AgeRestrictionModal };
