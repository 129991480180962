import cn from 'clsx';

import { Price as PriceT } from '@swe/shared/types/price';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPrice } from '@swe/shared/utils/price';

import styles from './styles.module.scss';

export type PriceProps = {
  value: PriceT;
  old?: boolean;
  from?: boolean;
} & ComponentHasClassName;

export const Price = ({ value, old, className, from }: PriceProps) => {
  return (
    <span
      aria-label={old ? 'Previous price' : undefined}
      className={cn(className, { [styles._old]: old })}
    >
      {from ? `from ` : ''}
      {formatPrice(value)}
    </span>
  );
};

export default Price;
