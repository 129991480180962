import { Hero, HeroProps } from '@swe/shared/ui-kit/components/hero';
import { NoProductsIllustration } from '@swe/shared/ui-kit/components/illustration';

type CatalogEmptyStateProps = Pick<HeroProps, 'actions' | 'content'>;

const EMPTY_FORM_LABEL = (
  <div>
    Darn, we can&apos;t find what you&apos;re looking for. <br /> Try clearing filters or refining your search.
  </div>
);
const EMPTY_SEARCH_LABEL = (
  <div>
    Darn, we can&apos;t find what you&apos;re looking for. <br /> Try a different search.
  </div>
);

const CatalogEmptyState = ({ content = EMPTY_SEARCH_LABEL, actions }: CatalogEmptyStateProps) => (
  <Hero
    title="No Products Found"
    illustration={<NoProductsIllustration />}
    content={content}
    border={false}
    actions={actions}
  />
);

type CatalogFormEmptyStateProps = { onReset?(): void };

const CatalogFormEmptyState = ({ onReset }: CatalogFormEmptyStateProps) => {
  return (
    <CatalogEmptyState
      content={EMPTY_FORM_LABEL}
      actions={[{ ariaLabel: 'Clear selected filters', children: 'Clear all filters', onClick: onReset }]}
    />
  );
};

export type { CatalogEmptyStateProps };
export { CatalogEmptyState, CatalogFormEmptyState };
export default CatalogEmptyState;
