import { EffectCallback, useEffect, useState } from 'react';

export const useMounted = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

export const useWasMounted = () => {
  const [wasMounted, setWasMounted] = useState(false);

  useMounted(() => setWasMounted(true));

  return wasMounted;
};
