import { SnackbarService } from '@swe/shared/providers/snackbar';
import { usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback } from 'react';

import CancelOrderEndpoint from 'endpoints/profile/orders/cancel-order';

const useOrderCancellation = (orderId?: number) => {
  const { confirm } = usePrompt();

  const confirmCancel = useCallback(
    async (oId?: number) => {
      const id = oId ?? orderId;
      if (!id) return;

      const confirmed = await confirm({
        heading: 'Cancel order',
        content: 'Are you sure you want to cancel the order?',
        confirmColor: 'danger',
        ariaLabel: 'Cancel order',
      });

      if (!confirmed) return;

      try {
        await CancelOrderEndpoint.request({ orderId: id }, { notifyWithSnackbar: false });
      } catch (e) {
        SnackbarService.push({
          type: 'danger',
          message: 'Sorry, we cannot cancel this order. Please contact the store if necessary.',
        });
      }
    },
    [confirm, orderId],
  );

  return {
    cancel: confirmCancel,
  };
};

export { useOrderCancellation };
