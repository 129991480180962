import { useMemo } from 'react';

import { useStoreConfig, useStoreInfo } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { GetAccountFulfillmentPreferencesEndpoint } from 'endpoints/profile/preferences/get-account-fulfillment';
import { isDelivery, ShopFulfillmentType } from 'entities/shop/info';

const useAccountFulfillmentPreferences = () => {
  const { data, mutate, isLoading } = GetAccountFulfillmentPreferencesEndpoint.useRequest();
  const storeConfig = useStoreConfig();
  const currentUser = useCurrentUser();
  const storeInfo = useStoreInfo();

  const orderType = useMemo(
    () => (data && isDelivery(data.fulfillmentType) ? ShopFulfillmentType.Delivery : ShopFulfillmentType.Pickup),
    [data],
  );

  const { isGuest } = useGuest();

  const availableFulfillment = useMemo(
    () =>
      (storeConfig.availableFulfillmentTypes ?? []).filter((f) => {
        return isGuest && !storeConfig.guestSettings?.isMedicalGuest
          ? f === ShopFulfillmentType.Pickup
          : f in ShopFulfillmentType;
      }),
    [isGuest, storeConfig.availableFulfillmentTypes, storeConfig.guestSettings?.isMedicalGuest],
  );

  const isSelectedMethodAvailable = useMemo(
    () => orderType && availableFulfillment.includes(orderType),
    [availableFulfillment, orderType],
  );

  const isPickupDeliveryAvailable = useMemo(
    () =>
      storeConfig.areFulfilmentSettingsEnabled !== false &&
      !(
        availableFulfillment.length === 0 ||
        (availableFulfillment.length === 1 &&
          availableFulfillment[0] === ShopFulfillmentType.Pickup &&
          storeConfig.storesCount === 1)
      ),
    [availableFulfillment, storeConfig.areFulfilmentSettingsEnabled, storeConfig.storesCount],
  );

  const submittedAddress = useMemo(() => {
    const submittedDeliveryAddress = data?.deliveryAddress ?? undefined;
    return orderType === ShopFulfillmentType.Pickup ? storeInfo.contacts.address : submittedDeliveryAddress;
  }, [storeInfo.contacts.address, data?.deliveryAddress, orderType]);

  return {
    data,
    mutate,
    orderType,
    isLoading,
    storeConfig,
    currentUser,
    storeInfo,
    availableFulfillment,
    isSelectedMethodAvailable,
    isPickupDeliveryAvailable,
    submittedAddress,
  };
};

export { useAccountFulfillmentPreferences };
