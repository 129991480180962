import { useCallback, useState } from 'react';

type UseSectionProps = {
  expandable?: boolean;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onToggle?: (expanded: boolean) => void;
};

const useSection = ({ defaultExpanded, expandable, expanded, onToggle }: UseSectionProps) => {
  const [innerExpanded, setInnerExpanded] = useState(defaultExpanded);

  const curExpanded = expandable ? (expanded !== undefined ? expanded : innerExpanded) : true;

  const handleClick = useCallback(() => {
    if (typeof expanded === 'undefined') {
      setInnerExpanded(!innerExpanded);
    }
    onToggle?.(!curExpanded);
  }, [expanded, onToggle, curExpanded, innerExpanded]);

  return {
    isExpanded: curExpanded,
    handleToggle: handleClick,
  };
};

export { useSection };
export default useSection;
