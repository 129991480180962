import { createContext, useContext, useMemo } from 'react';

import { useGoogleJsApi } from '@swe/shared/tools/google/hooks/use-google-js-api';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { asyncNoop } from '@swe/shared/utils/func';

type GoogleApiContextT = {
  apiKey: string;
  api: typeof google | null;
  isReady: boolean;
  isLoading: boolean;
  error: string | null;
  load: () => Promise<typeof google>;
};

const GoogleApiContext = createContext<GoogleApiContextT>({
  apiKey: '',
  api: null,
  isReady: false,
  isLoading: false,
  error: null,
  load: asyncNoop as unknown as GoogleApiContextT['load'],
});

type GoogleApiProviderProps = ComponentHasChildren & {
  apiKey: string;
};

const useGoogleApiContext = () => {
  return useContext(GoogleApiContext);
};

const GoogleApiProvider = ({ children, apiKey }: GoogleApiProviderProps) => {
  const jsApi = useGoogleJsApi(apiKey);
  const ctx = useMemo(() => ({ apiKey, ...jsApi }), [jsApi, apiKey]);

  return <GoogleApiContext.Provider value={ctx}>{children}</GoogleApiContext.Provider>;
};

export type { GoogleApiContextT, GoogleApiProviderProps };
export { GoogleApiProvider, useGoogleApiContext };
