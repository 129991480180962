import { ApiErrorCode, APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern/types';

const SERVER_ERROR: Pick<APIErrorExtended, 'code' | 'message'> = {
  code: ApiErrorCode.UnknownError,
  message: 'Server Error',
};

export const createCustomError = (meta: APIErrorExtended): APIErrorExtended => ({
  ...SERVER_ERROR,
  ...meta,
});
