import { DocumentPrototype } from 'common/entities/documents/DocumentPrototype';
import { DocumentVerificationImpl } from 'common/entities/documents/DocumentVerification';
import { Prototypes } from 'common/entities/documents/Prototypes';
import { DocumentCategory } from 'entities/documents/documents-v2';

class Document<CT extends DocumentCategory = DocumentCategory> {
  readonly verifications: DocumentVerificationImpl<CT>[];

  constructor(
    readonly category: CT,
    readonly prototype: DocumentPrototype,
    verifications: DocumentVerificationImpl<CT>[] = [],
  ) {
    this.verifications = verifications.filter((d) => d.data.verificationDocumentType.id === prototype.id);
  }

  get validUploads() {
    return this.verifications.filter((u) => u.isValid);
  }

  get failedUploads() {
    return this.verifications.filter((u) => u.isActionRequired);
  }

  get validUpload() {
    return this.validUploads[0];
  }

  get failedUpload() {
    return this.failedUploads[0];
  }

  get isUploadRequired() {
    return this.verifications.length === 0;
  }

  get isActionRequired() {
    return this.failedUploads.length > 0;
  }

  get isFulfilled() {
    return this.failedUploads.length === 0 && this.validUploads.length > 0;
  }

  getVerification(verificationId: number) {
    return this.verifications.find((v) => v.id === verificationId);
  }
}

const createDocument =
  <CT extends DocumentCategory>(category: CT, verifications: DocumentVerificationImpl<CT>[], dpm: Prototypes) =>
  (id: number) => {
    const prototype = dpm.getPrototype(category, id)!;

    return new Document(category, prototype, verifications);
  };

export { Document, createDocument };
