import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { NotificationMethod } from 'entities/profile/notifications';

type Params = {
  notificationMethod: NotificationMethod;
  enabled: boolean;
  suspendDays?: number;
};
type Data = {
  success: boolean;
};
type Error = APIErrorExtended;

const NAME = '/Notifications/ChangeTransactionNotificationSetting';
const ToggleTransactionNotificationEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default ToggleTransactionNotificationEndpoint;
