import { EventEmitter } from '@swe/shared/tools/event-emitter';
import { Serializable } from '@swe/shared/types/json';

const KEY_PREFIX = 'swa_';
const prefixKey = (key: string) => `${KEY_PREFIX}${key}`;
const clearKeyFromPrefix = (key: string) => key.replace(KEY_PREFIX, '');
const isOurKey = (key: string) => key.startsWith(KEY_PREFIX);

const jsonParseSafe = (val: any) => {
  if (val === null || val === undefined || val === '') {
    return null;
  }
  try {
    return JSON.parse(val) as Serializable;
  } catch (e) {
    return null;
  }
};

interface StorageI {
  getItem<V extends Serializable = Serializable>(key: string): V | null;
  setItem<V extends Serializable = Serializable>(key: string, value: V | null): void;
}

class Storage
  extends EventEmitter<{
    change: {
      key: string;
      newValue: Serializable | null;
      oldValue: Serializable | null;
    };
  }>
  implements StorageI
{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getItem<V extends Serializable = Serializable>(key: string): V | null {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setItem<V extends Serializable = Serializable>(key: string, value: V | null) {}
}

export { Storage, jsonParseSafe, prefixKey, clearKeyFromPrefix, isOurKey };
export type { StorageI };
