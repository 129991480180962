import { jsonParseSafe, prefixKey, Storage } from '@swe/shared/providers/persist-state/constants';
import { deleteCookie, getCookie, setCookie } from '@swe/shared/tools/cookie';
import { Serializable } from '@swe/shared/types/json';
import { isSSR } from '@swe/shared/utils/environment';
import { isEqual } from '@swe/shared/utils/object';

class CookieStorage extends Storage {
  constructor(private _SSRFallback: Record<string, string> = {}) {
    super();
  }

  getItem<V extends Serializable = Serializable>(key: string) {
    if (isSSR) {
      return jsonParseSafe(this._SSRFallback[prefixKey(key)]) as V;
    }
    return jsonParseSafe(getCookie(prefixKey(key))) as V;
  }

  setItem<V extends Serializable = Serializable>(key: string, newValue: V | null) {
    const oldValue = this.getItem(key);
    if (isEqual(newValue, oldValue)) {
      return;
    }
    if (newValue === null) {
      deleteCookie(prefixKey(key));
    } else {
      setCookie(prefixKey(key), JSON.stringify(newValue), { path: '/', maxAge: 34560000 });
    }
    this.fire('change', { key, newValue, oldValue });
  }
}

export default CookieStorage;
