import { ObjectSchema } from 'yup';

const getIsYupRequired = (validationSchema: ObjectSchema<any> | undefined, name: string) => {
  try {
    /**
     * COERCION ALERT
     * "exclusiveTests" is a protected field in ObjectSchema class, but we do need to access it in order to
     * gather information about field (is it required or not);
     */
    const innerSchema = validationSchema?.fields[name] as unknown as {
      exclusiveTests: ObjectSchema<any>['exclusiveTests'];
    };
    if (!innerSchema) return false;

    return Object.keys(innerSchema.exclusiveTests).some((key) => key.toLowerCase().includes('required'));
  } catch (e) {
    return false;
  }
};

export { getIsYupRequired };
