import { usePersistState } from '@swe/shared/providers/persist-state';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';

type UserChoiceResult = {
  outcome: 'accepted' | 'dismissed';
  platform: string;
};

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<UserChoiceResult>;
  prompt(): Promise<void>;
}

const COOKIE_PWA_INSTALLED_KEY = 'pwa_installed';
const COOKIE_PWA_BANNER_CLOSED_KEY = 'pwa_banner_closed';

const useInstallPwa = ({ isOpera }: { isOpera: boolean }) => {
  const { pushEvent } = useAnalytics();
  const prompt = useRef<BeforeInstallPromptEvent | null>(null);
  const [isInstalled, setInstalled] = usePersistState<boolean>('local', COOKIE_PWA_INSTALLED_KEY, false);
  const [isPromptAvailable, setPromptAvailable] = useState(false);
  const [isBannerClosed, setBannerClosed] = usePersistState<boolean>(
    'session',
    COOKIE_PWA_BANNER_CLOSED_KEY,
    isInstalled,
  );
  const isInstallable = !isInstalled && isPromptAvailable;
  const isBannerVisible = isInstallable && !isBannerClosed;

  const install = useCallback(async () => {
    await prompt.current?.prompt();
    const installation = await new Promise<UserChoiceResult>((resolve, reject) => {
      const onVisibilityChange = () => {
        resolve({ outcome: 'dismissed', platform: 'unknown' });
        document.removeEventListener('visibilitychange', onVisibilityChange);
      };
      const _resolve = (v: any) => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
        resolve(v);
      };
      const _reject = (e: any) => {
        document.removeEventListener('visibilitychange', onVisibilityChange);
        reject(e);
      };

      prompt.current?.userChoice.then(_resolve).catch(_reject);
      document.addEventListener('visibilitychange', onVisibilityChange);
    });
    const outcome = installation?.outcome;
    if (outcome === 'accepted') {
      setBannerClosed(true);
    }

    return outcome;
  }, [setBannerClosed]);
  const openBanner = useCallback(() => {
    setBannerClosed(false);
  }, [setBannerClosed]);
  const closeBanner = useCallback(() => {
    setBannerClosed(true);
  }, [setBannerClosed]);

  useEffect(() => {
    const isInstallable = 'serviceWorker' in navigator;
    const isStandalone = (navigator as any).standalone;
    if (!isInstallable || isStandalone || isOpera) return;

    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setInstalled(false);
      setPromptAvailable(true);
      prompt.current = event;
    };
    const handleInstall = () => {
      setInstalled(true);
      setBannerClosed(true);
      pushEvent(AEventType.PWA_INSTALLED, undefined);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
    window.addEventListener('appinstalled', handleInstall as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
      window.removeEventListener('appinstalled', handleInstall);
    };
  }, [isOpera, setBannerClosed, setInstalled, pushEvent]);

  // ONLY FOR TEST PURPOSES
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.$$installPwa = install;
  }, [install]);

  return {
    isInstalled,
    isInstallable,
    isBannerVisible,
    openBanner,
    closeBanner,
    install,
  };
};

export { useInstallPwa };
