import cx from 'clsx';
import { ComponentType, ReactNode, SyntheticEvent, useCallback } from 'react';

import { IconProps } from '@swe/shared/ui-kit/components/icon';
import { Colors, ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type PinProps = ComponentHasStyling & {
  color: Colors<'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'neutral'>;
  icon?: ComponentType<IconProps>;
  children?: ReactNode;
  scaleOnHover?: boolean;
  uplifted?: boolean;
  selfAligned?: boolean;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

const Pin = ({
  style,
  className,
  color = 'primary',
  icon: Icon,
  children,
  scaleOnHover,
  selfAligned = false,
  uplifted,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: PinProps) => {
  const clickHandler = useCallback(
    (ev: SyntheticEvent) => {
      // don't pan map when click on pin, need to found another way
      ev.stopPropagation();
      onClick?.();
    },
    [onClick],
  );

  const pin = (
    <div
      style={style}
      className={cx(
        styles.root,
        styles[`_color_${color}`],
        onClick && styles._interactive,
        uplifted && styles._uplifted,
        scaleOnHover && styles._scaled,
        selfAligned && styles._selfAligned,
        className,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={clickHandler}
    >
      <div className={styles.iconWrapper}>
        {Icon ? <Icon className={styles.icon} /> : <div className={styles.content}>{children}</div>}
      </div>
      <div className={styles.dot} />
    </div>
  );

  return selfAligned ? <div className={styles.wrapper}>{pin}</div> : pin;
};

export type { PinProps };
export { Pin };
export default Pin;
