import { QueryConfig } from '@swe/shared/hooks/pagination/types';

type BuildLinkConfig = QueryConfig & {
  pathname: string;
  page: number;
  size?: number;
};

const buildLink = ({ pathname, page, size, pageKey, pageSizeKey }: BuildLinkConfig) => ({
  pathname,
  query: {
    [pageKey]: page,
    [pageSizeKey]: size,
  },
});

export { buildLink };
