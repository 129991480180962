import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';

type GuestSignInParams = {
  firstName?: string;
  lastName?: string;
  email: string;
  dateOfBirth?: DateISOString;
  phone?: string;
  optIn?: boolean;
  platformOs: PlatformOs;
  isMedical?: boolean;
};
type Data =
  | undefined
  | {
      nextTryIn: number;
      code?: string;
      id: string;
    };

const NAME = '/GuestAccount/SignIn';
const GuestSignInEndpoint = createModernEndpoint<GuestSignInParams, Data>(NAME);

export { GuestSignInEndpoint };
export type { GuestSignInParams, Data as GuestSignInData };
