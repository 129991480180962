import {
  useState,
  useCallback,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  useId,
  forwardRef,
  useImperativeHandle,
  ReactNode,
  useRef,
  useMemo,
  ChangeEvent,
} from 'react';

import { ButtonColor } from '@swe/shared/ui-kit/components/button';
import { MaskedInput } from '@swe/shared/ui-kit/components/form/input/masked-input';
import { AnyMask } from '@swe/shared/ui-kit/components/form/input/types';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { InputWrapper, InputWrapperProps } from '@swe/shared/ui-kit/components/form/wrapper/input';
import { CloseIcon, EyeIcon, EyeOffIcon } from '@swe/shared/ui-kit/components/icon';
import { Locator } from '@swe/shared/ui-kit/components/locator';

type MaskPresets = 'code_4digit' | 'phone_us' | 'dollar' | 'percent';
type AutoComplete = 'one-time-code' | 'new-password' | 'email' | 'current-password';

const MaskPresetsSettings: Record<MaskPresets, AnyMask> = {
  code_4digit: {
    mask: '0 0 0 0',
    unmask: true,
  },
  phone_us: {
    mask: '{+1} (000) 000-0000',
    unmask: true,
    lazy: true,
  },
  dollar: {
    mask: '$num',
    unmask: true,
    blocks: {
      num: {
        mask: Number,
        min: 0,
        max: 9999,
      },
    },
  },
  percent: {
    mask: 'num%',
    unmask: true,
    blocks: {
      num: {
        mask: Number,
        max: 100,
        min: 0,
      },
    },
  },
};

type Mask =
  | {
      maskPreset?: MaskPresets;
      customMask?: never;
    }
  | {
      maskPreset?: never;
      customMask?: AnyMask;
    };

export type Accessibility =
  | {
      ariaLabel?: never;
      label?: ReactNode;
      placeholder: string;
    }
  | {
      ariaLabel?: never;
      label: string;
      placeholder?: string;
    }
  | {
      ariaLabel: string;
      label?: ReactNode;
      placeholder?: string;
    };

export type InputProps = FormControl<string> &
  Omit<InputWrapperProps, 'children' | 'label'> & {
    readonly?: boolean;
    isClearable?: boolean;
    type?: Extract<HTMLInputTypeAttribute, 'password' | 'text' | 'date'>;
    autofocus?: boolean;
    autocomplete?: AutoComplete;
    onFocus?: () => void;
    onClick?: () => void;
    onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
    onKeyUp?(e: KeyboardEvent<HTMLInputElement>): void;
    maxLength?: number;
    onClear?: () => void;
    fieldWrapper?: (input: ReactNode) => ReactNode;
    buttonColor?: ButtonColor;
    inputMode?: 'decimal' | 'text';
  } & Mask &
  Accessibility;

export const Input = forwardRef<FormControlRef, InputProps>(
  (
    {
      className,
      label,
      type,
      onChange,
      value,
      icon: Icon,
      error,
      note,
      isClearable = true,
      size = 'lg',
      name,
      placeholder,
      disabled,
      autofocus,
      onBlur,
      readonly,
      maskPreset,
      customMask,
      autocomplete,
      onKeyDown,
      collapsed,
      onClick,
      onFocus,
      onIconClick,
      staticNote,
      onKeyUp,
      actions = [],
      ariaLabel,
      maxLength,
      onClear: _onClear,
      fieldWrapper,
      buttonColor,
      textAlign,
      inputMode,
    },
    outerRef,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const idName = `${name}-${useId()}`;
    const [isHiddenPassword, setIsHiddenPassword] = useState(true);

    const isPassword = type === 'password';
    const localType = isPassword && !isHiddenPassword ? 'text' : type || 'text';

    const onToggleVisiblePassword = useCallback(() => {
      setIsHiddenPassword(!isHiddenPassword);
    }, [isHiddenPassword, setIsHiddenPassword]);

    const mask = useMemo(
      () => customMask ?? (maskPreset ? MaskPresetsSettings[maskPreset] : undefined),
      [customMask, maskPreset],
    );

    const onClear = useCallback(() => {
      onChange?.('');
      if (mask) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 100);
      } else {
        inputRef.current?.focus();
      }
      _onClear?.();
    }, [_onClear, mask, onChange]);

    const _ariaLabel = (typeof label === 'string' ? label : ariaLabel) ?? placeholder;

    const commonInputProps = useMemo(
      () => ({
        id: idName,
        autoFocus: autofocus,
        autoComplete: autocomplete || 'off',
        autoCorrect: 'off' as const,
        spellCheck: 'false' as const,
        name: idName,
        type: localType,
        inputMode,
        placeholder,
        readOnly: readonly,
        maxLength,
        onBlur,
        onKeyDown,
        onKeyUp,
        onClick,
        onFocus,
        'aria-label': _ariaLabel,
      }),
      [
        autocomplete,
        _ariaLabel,
        autofocus,
        idName,
        localType,
        maxLength,
        onBlur,
        onClick,
        onFocus,
        onKeyDown,
        onKeyUp,
        placeholder,
        readonly,
        inputMode,
      ],
    );
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value), [onChange]);
    const wrapperActions = useMemo(
      () => [
        { icon: CloseIcon, onClick: onClear, visible: !disabled && isClearable && value !== '', ariaLabel: 'Clear' },
        ...actions,
        {
          icon: isHiddenPassword ? EyeIcon : EyeOffIcon,
          onClick: onToggleVisiblePassword,
          visible: isPassword,
          ariaLabel: isHiddenPassword ? 'Show password' : 'Hide password',
        },
      ],
      [actions, disabled, isClearable, isHiddenPassword, isPassword, onClear, onToggleVisiblePassword, value],
    );
    const focus = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    useImperativeHandle(
      outerRef,
      () => ({
        focus,
      }),
      [focus],
    );

    return (
      <InputWrapper
        name={idName}
        size={size}
        error={error}
        icon={Icon}
        onIconClick={collapsed ? onIconClick : undefined}
        note={note}
        label={label}
        disabled={disabled}
        collapsed={collapsed}
        className={className}
        staticNote={staticNote}
        actions={wrapperActions}
        fieldWrapper={fieldWrapper}
        buttonColor={buttonColor}
        buttonAriaLabel={name}
        textAlign={textAlign}
      >
        {mask ? (
          <Locator
            locatorType="input"
            locatorName={name}
            as={MaskedInput}
            {...commonInputProps}
            mask={mask}
            value={value}
            ref={inputRef}
            onChange={onChange}
          />
        ) : (
          <Locator
            locatorType="input"
            locatorName={name}
            as="input"
            {...commonInputProps}
            ref={inputRef}
            value={value}
            onChange={handleChange}
          />
        )}
      </InputWrapper>
    );
  },
);
