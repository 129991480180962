import { useState, useEffect, RefObject, useRef, useMemo } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useMutationObserver } from '@swe/shared/hooks/use-mutation-observer';
import { isSSR } from '@swe/shared/utils/environment';

type Rect = Pick<DOMRectReadOnly, 'x' | 'y' | 'width' | 'height'>;

type Entry = {
  readonly target: Element;
  readonly contentRect: DOMRectReadOnly;
};

const createObserver = (observeCallback: (entries: Entry[]) => void) => {
  const Observer: new (callback: (entries: Entry[]) => void) => ResizeObserver =
    (window as any).ResizeObserver ?? ResizeObserver;

  return new Observer(observeCallback);
};

const DEFAULT_RECT: Rect = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

const useElementSizeListener = (
  observable: RefObject<HTMLElement> | HTMLElement | null,
  listener: (entry: Entry) => void,
  clear?: () => void,
) => {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  const clearRef = useRef(clear);
  clearRef.current = clear;

  const observer = useMemo(
    () => (isSSR ? undefined : createObserver(([entry]: Entry[]) => listenerRef.current(entry))),
    [],
  );

  useEffect(() => {
    const el: HTMLElement | null = observable && 'current' in observable ? observable.current : observable;
    if (el) {
      observer?.observe(el);
    }

    return () => {
      observer?.disconnect();
      if (clearRef.current) {
        clearRef.current();
      }
    };
  }, [observable, observer]);
};

const useElementSize = (observable: RefObject<HTMLElement> | HTMLElement | null): Rect => {
  const [rect, setRect] = useState<Rect>(DEFAULT_RECT);

  useElementSizeListener(
    observable,
    (entry) => {
      setRect(entry.contentRect);
    },
    () => setRect(DEFAULT_RECT),
  );

  return rect;
};

const useElementSizeWithRef = <ET extends HTMLElement = HTMLElement>() => {
  const refEl = useRef<ET>(null);
  const rect = useElementSize(refEl);

  return [rect, refEl] as const;
};

const OBSERVE_OPTIONS = { subtree: true, childList: true };
const useElementAnyContentChangeListener = (
  observable: RefObject<HTMLElement> | HTMLElement | null,
  listener: () => void,
) => {
  useElementSizeListener(observable, listener);
  useMutationObserver(observable, listener, OBSERVE_OPTIONS);
  useEffect(() => {
    let el: HTMLElement | null = observable && 'current' in observable ? observable.current : observable;
    if (el === document.documentElement) {
      el = window as unknown as HTMLElement;
    }
    if (el) {
      el.addEventListener('scroll', listener, { passive: true });

      return () => el!.removeEventListener('scroll', listener);
    }
  }, [listener, observable]);
};

export {
  DEFAULT_RECT,
  useElementSizeWithRef,
  useElementSize,
  useElementSizeListener,
  useElementAnyContentChangeListener,
};
export type { Rect };
