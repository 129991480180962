import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { StoreDeliveryZone } from 'entities/shop/info';

type Data = StoreDeliveryZone[];
type Params = {
  address: string;
  secondaryAddress?: string;
  coordinates: LatLng;
};

const NAME = '/Store/GetDeliveryZones';
const GetDeliveryZonesEndpoint = createModernEndpoint<Params, Data>(NAME);

export default GetDeliveryZonesEndpoint;
export { GetDeliveryZonesEndpoint };
