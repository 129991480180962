import cx from 'clsx';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';
// import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

import { CSSProperties, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { CarouselContextProvider } from '@swe/shared/ui-kit/components/carousel-v2/core/context';
import { CarouselContext, Gap, UseCarouselOptions } from '@swe/shared/ui-kit/components/carousel-v2/core/types';
import { useAnimationSettings } from '@swe/shared/ui-kit/theme/provider';
import {
  ComponentHasChildren,
  ComponentHasInteraction,
  ComponentHasStyling,
} from '@swe/shared/ui-kit/types/common-props';

import { mergeRefs } from '@swe/shared/ui-kit/utils';

import cssStyles from './styles.module.scss';

// TODO: required for correct Embla reinitialization
// hadcoded because unable to import the value from embla-carousel directly
const EMBLA_ANIMATION_DURATION_DEFAULT = 25;

const AutoplayPlugin = Autoplay({
  stopOnMouseEnter: true,
  stopOnInteraction: true,
});
// const WheelPlugin = WheelGesturesPlugin();

type RootRenderProps = ComponentHasChildren & {
  forwardRef?: RefObject<HTMLDivElement>;
  classNames?: Partial<{ root: string; container: string }>;
  styles?: Partial<{ root: CSSProperties; container: CSSProperties }>;
  arrowControl?: ReactNode;
  stepperControl?: ReactNode;
};

type SlideRenderProps = ComponentHasChildren &
  ComponentHasStyling &
  ComponentHasInteraction<HTMLDivElement> & {
    gap?: Exclude<Gap, 'lg'>;
  };

const Slide = ({ style, className, gap, children, onClick }: SlideRenderProps) => {
  return (
    <div
      className={cx(cssStyles.slide, gap && cssStyles[`slide_gap_${gap}`], className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const useCarousel = ({
  current,
  onChange,
  autoplay = false,
  infinite = false,
  overflow = true,
  options,
}: UseCarouselOptions) => {
  const prevCurrent = useRef<number | undefined>();
  const animations = useAnimationSettings();
  const [rootRef, innerApi] = useEmblaCarousel(
    {
      ...options,
      loop: infinite,
      duration: animations.enabled ? EMBLA_ANIMATION_DURATION_DEFAULT : 0,
    },
    [autoplay && AutoplayPlugin].filter(Boolean),
  );

  const api = useMemo<CarouselContext>(
    () => ({
      next: innerApi?.scrollNext,
      prev: innerApi?.scrollPrev,
      set: innerApi?.scrollTo,
      getScrollSnaps: innerApi?.scrollSnapList,
      getSelectedScrollSnap: innerApi?.selectedScrollSnap,
      getProgress: innerApi?.scrollProgress,
      getPlugins: innerApi?.plugins,
      getCanScrollForward: innerApi?.canScrollNext,
      getCanScrollBackward: innerApi?.canScrollPrev,
      reInit: innerApi?.reInit,
      subscribe: innerApi?.on,
      unsubscribe: innerApi?.off,
    }),
    [
      innerApi?.canScrollNext,
      innerApi?.canScrollPrev,
      innerApi?.off,
      innerApi?.on,
      innerApi?.plugins,
      innerApi?.reInit,
      innerApi?.scrollNext,
      innerApi?.scrollPrev,
      innerApi?.scrollProgress,
      innerApi?.scrollSnapList,
      innerApi?.scrollTo,
      innerApi?.selectedScrollSnap,
    ],
  );

  const Context = useCallback(
    ({ children }: ComponentHasChildren) => <CarouselContextProvider value={api}>{children}</CarouselContextProvider>,
    [api],
  );
  const Root = useCallback(
    ({ forwardRef, classNames, styles, children, arrowControl, stepperControl }: RootRenderProps) => {
      return (
        <>
          <div style={styles?.root}>
            <div
              ref={mergeRefs([rootRef, forwardRef])}
              className={cx(cssStyles.root, overflow === false && cssStyles.root_noOverflow, classNames?.root)}
            >
              <div
                className={cx(cssStyles.container, classNames?.container)}
                style={styles?.container}
              >
                {children}
              </div>
              {arrowControl}
            </div>
          </div>
          {stepperControl}
        </>
      );
    },
    [overflow, rootRef],
  );

  useEffect(() => {
    if (innerApi) {
      const handler: Parameters<EmblaCarouselType['on']>[1] = (api) => {
        onChange?.(api!.selectedScrollSnap());
      };

      innerApi.on('select', handler);
      innerApi.off('reInit', handler);

      return () => {
        innerApi.off('select', handler);
        innerApi.off('reInit', handler);
      };
    }
  }, [innerApi, onChange]);

  useEffect(() => {
    if (innerApi && typeof current !== 'undefined') {
      innerApi.scrollTo(current, typeof prevCurrent.current !== 'undefined');
    }
  }, [current, innerApi, onChange]);

  return {
    Root,
    Slide,
    Context,
    api,
  };
};

export { useCarousel };
