import { usePreviousDefined } from '@swe/shared/hooks';
import { useOnlyUpdateEffect } from '@swe/shared/hooks/use-did-update-effect';
import usePromiseQueue from '@swe/shared/hooks/use-promise-queue';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { PushSnackArg } from '@swe/shared/providers/snackbar/types';
import { useIntl } from '@swe/shared/tools/intl';
import { yup } from '@swe/shared/ui-kit/components/form';
import { BuilderContext } from '@swe/shared/ui-kit/components/form/core/builder';
import { AlertIcon, CheckIcon } from '@swe/shared/ui-kit/components/icon';
import { debounce } from '@swe/shared/utils/func';
import { isEqual } from '@swe/shared/utils/object';
import { formatPrice } from '@swe/shared/utils/price';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { OrderEvents } from 'common/events/order';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useCart, useCartState, useCartUtils } from 'common/providers/cart/context';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { useDocumentsRequirements } from 'common/use-cases/documents/use-documents-requirements';
import { useIntervals } from 'common/use-cases/use-intervals';
import { useLoyalty } from 'common/use-cases/use-loyalty';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { useTimeOffset } from 'common/use-cases/use-time-offset';
import { SUBCODE_SHOW_AS_WARNING } from 'domains/cart/constants';
import { ReceiptProps } from 'domains/checkout/components/receipt';
import { SummaryProps } from 'domains/checkout/components/summary';
import { AeroPayPaymentContext } from 'domains/checkout/types/aeropay';
import { usePaymentWithAeroPay } from 'domains/checkout/use-cases/use-aeropay';
import { useFulfillmentType } from 'domains/checkout/use-cases/use-fulfullment-type';
import { CheckoutEndpoint } from 'endpoints/cart/checkout';
import { ConfirmApiErrorCode, ConfirmEndpoint, ConfirmEndpointError } from 'endpoints/cart/confirm';
import { GetIntervalsEndpoint } from 'endpoints/cart/get-intervals';
import { GetPaymentMethodsEndpoint } from 'endpoints/cart/get-payment-methods';
import { GetShopInfoEndpoint } from 'endpoints/shop/get-shop-info';
import { Cart, PaymentMethodDescriptor } from 'entities/cart/cart';
import { OrderReceiptItem, PaymentMethod } from 'entities/common/orders';
import { getLoyaltyPoints, Status } from 'entities/loyalty/info';
import { FulfilmentScheduleInterval, FullShopFulfillmentType } from 'entities/shop/info';
import { StoreSaleType } from 'entities/shop/sale-type';

export type PlaceOrderForm = {
  fulfillmentType?: FullShopFulfillmentType;
  fulfillmentDate?: DateISOString;
  fulfillmentIntervalId?: EntityID<number>;
  paymentMethodType?: EntityID<string>;
  promocodes: string[];
  loyaltyPoints?: number;
  notes?: string;
  deliveryAddress?: string;
  secondaryAddress?: string;
  deliveryLocation?: {
    lat: number;
    lng: number;
  };
  saleType: StoreSaleType;
  documentsIsValid: boolean;
};

const buildFormSchema = ({
  withDate,
  withDocuments,
  withConsents,
  withPayments,
}: {
  withDate: boolean;
  withDocuments: boolean;
  withConsents: boolean;
  withPayments: boolean;
}) => {
  let schema: Record<string, yup.Schema> = {
    fulfillmentType: yup.string().required(),
    code: yup.string(),
  };

  if (withDate) {
    schema = {
      ...schema,
      fulfillmentDate: yup.string().required("You didn't provide a date"),
      fulfillmentIntervalId: yup.string().required("You didn't provide a time window"),
    };
  }

  if (withDocuments) {
    schema = {
      ...schema,
      documentsIsValid: yup.boolean().isTrue("You didn't upload a documents"),
    };
  }

  if (withConsents) {
    const msg = 'Please agree to the store’s required terms';
    schema = {
      ...schema,
      consents: yup.boolean().required(msg).isTrue(msg),
    };
  }

  if (withPayments) {
    const msg = "You didn't choose a payment method";
    schema = {
      ...schema,
      paymentMethodType: yup.string().required(msg),
    };
  }

  return yup.object(schema);
};

type UseCheckout = {
  formRef: React.RefObject<BuilderContext<PlaceOrderForm>>;
};

const processReceiptItems = (items: OrderReceiptItem[]) => {
  const res: ReceiptProps['items'] = [];
  items.forEach(({ title, titleValue, price, children }) => {
    res.push({
      title,
      value: titleValue || `${formatPrice(price)}` || '',
      variant: 'primary',
    });
    if (children && children.length) {
      children.forEach(({ title, titleValue, price }) =>
        res.push({
          title,
          value: titleValue || `${formatPrice(price)}` || '',
          variant: 'secondary',
        }),
      );
    }
  });
  return res;
};

const useCheckout = ({ formRef }: UseCheckout) => {
  const { isCheckoutConsentsEnabled, dangerouslyHidePaymentMethods } = useStoreConfig();
  const navigate = useRouterNavigate();

  const { saleType } = useSaleType();
  const _cart = useCart();
  const cart = usePreviousDefined(_cart);
  const { isLoading: isLoadingCart, isValidating } = useCartState();
  const { mutate: mutateCart, setLocalAvailableItemsQuantity } = useCartUtils();
  const { user, isLoggedIn } = useCurrentUser();

  const [afterPlaceOrder, setAfterPlaceOrder] = useState(false);

  const cartIsReady = !isLoadingCart && !isValidating && !afterPlaceOrder ? undefined : null;

  const setValue = useCallback(
    (name: keyof PlaceOrderForm, value: any) => {
      formRef.current?.setValue(name, value);
    },
    [formRef],
  );

  const { data: _shopInfo, isLoading: isLoadingShopInfo } = GetShopInfoEndpoint.useRequest(cartIsReady);

  const shopInfo = usePreviousDefined(_shopInfo);

  const [_intervals, setIntervals] = useState<FulfilmentScheduleInterval>({ intervals: [], scheduleExists: false });
  const [isLockedOrderTypeDeps, setIsLockedOrderTypeDeps] = useState<undefined | true>();
  const [errorInterval, setErrorInterval] = useState<{ code: string }>();
  const loadIntervals = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }
    try {
      const res = await GetIntervalsEndpoint.request(undefined);
      setIntervals(res);
      setIsLockedOrderTypeDeps(undefined); // need complex refactaring hook
    } catch (e) {
      setErrorInterval(e as { code: string });
    }
  }, [isLoggedIn]);

  const { dateOptions, getIntervalOptions } = useIntervals(_intervals.intervals);

  const {
    isPickupDeliveryAvailable,
    orderType,
    deliveryAddress,
    deliveryLocation,
    deliveryAddressSecondLine,
    availableFulfillment,
  } = usePickupDeliverySettings();

  const { getFulfillmentOptions } = useFulfillmentType();

  const fulfillmentOptions = useMemo(() => getFulfillmentOptions(orderType), [getFulfillmentOptions, orderType]);

  const initialValueFulfillmentDate = useMemo(() => {
    if (cart?.order?.fulfillmentDate && dateOptions.some(({ value }) => value === cart?.order?.fulfillmentDate)) {
      return cart?.order?.fulfillmentDate;
    }
    return dateOptions[0] ? dateOptions[0].value : '';
  }, [cart?.order?.fulfillmentDate, dateOptions]);

  const [fulfillmentDate, setFulfillmentDate] = useState<string | undefined>();
  const isPatientOrder = useMemo(() => {
    return !!(cart?.order?.caregiver && user?.isCaregiver);
  }, [cart, user]);

  const { isGuest, isLoading: isLoadingGuest } = useGuest();

  const { requirements: documentRequirements, isLoading: isLoadingDocuments } = useDocumentsRequirements(
    !isLoadingGuest && !isGuest && !isPatientOrder,
  ); // avoid call if caregiver creates order for patient

  const documentsIsValid = useMemo(() => {
    if (isPatientOrder) return true;
    return user ? (isGuest ? true : !!documentRequirements?.isFulfilled) : true;
  }, [documentRequirements?.isFulfilled, isGuest, isPatientOrder, user]);

  const formInitialValues: PlaceOrderForm = useMemo(
    () => ({
      fulfillmentType: cart?.order?.fulfillmentType,
      deliveryAddress,
      deliveryLocation,
      secondaryAddress: deliveryAddressSecondLine,
      saleType,
      fulfillmentDate: initialValueFulfillmentDate,
      fulfillmentIntervalId: cart?.order?.scheduleIntervalId,
      paymentMethodType: cart?.order?.paymentMethodType,
      notes: cart?.order?.notes ?? '',
      loyaltyPoints: cart?.order?.loyalty?.pointsToRedeem ?? 0,
      promocodes: cart?.order?.promocodes ?? [],
      documentsIsValid,
      consents: undefined,
    }),
    [
      cart?.order?.fulfillmentType,
      cart?.order?.scheduleIntervalId,
      cart?.order?.paymentMethodType,
      cart?.order?.notes,
      cart?.order?.loyalty?.pointsToRedeem,
      cart?.order?.promocodes,
      deliveryAddress,
      deliveryLocation,
      deliveryAddressSecondLine,
      saleType,
      initialValueFulfillmentDate,
      documentsIsValid,
    ],
  );

  const intervalOptions = useMemo(
    () => getIntervalOptions(fulfillmentDate ?? initialValueFulfillmentDate),
    [fulfillmentDate, getIntervalOptions, initialValueFulfillmentDate],
  );

  const [formErrors, setFormErrors] = useState<object>({});

  const prepareListMarker = useCallback(
    ({ fields, ...rest }: { fields: (keyof PlaceOrderForm)[] }) => {
      const hasError = fields.some((fieldName) => fieldName in formErrors);
      const hasValue = formRef.current?.getValues(fields).every((value) => !!value);

      if (hasError) {
        return {
          ...rest,
          bullet: AlertIcon,
          headerColor: 'danger',
        } as const;
      }

      if (hasValue) {
        return {
          ...rest,
          bullet: CheckIcon,
          headerColor: 'success',
        } as const;
      }

      return {
        ...rest,
        bullet: '',
        headerColor: 'light',
      } as const;
    },
    [formErrors, formRef],
  );

  const [_paymentMethods, setPaymentMethods] = useState<PaymentMethodDescriptor[]>([]);
  const isPaymentsDisabled = _paymentMethods.length === 0;

  const { platformOs } = usePlatformOs();

  const loadPaymentMethods = useCallback(async () => {
    if (!isLoggedIn) {
      return;
    }
    try {
      const res = await GetPaymentMethodsEndpoint.request({
        saleType,
        platformOs,
      });
      setPaymentMethods(res);
    } catch (e) {
      console.error(e);
    }
  }, [isLoggedIn, platformOs, saleType]);

  useEffect(() => {
    if (!isValidating && !isLoadingCart) {
      void loadIntervals();
      void loadPaymentMethods();
    }
  }, [isValidating, isLoadingCart, loadIntervals, loadPaymentMethods]);

  const paymentMethods = useMemo(() => {
    if (!_paymentMethods) return [];

    const fulfilmentType = _cart?.order?.fulfillmentType;
    const paymentMethods = _paymentMethods.map(({ type, name }) => ({
      value: type,
      label: name,
    }));
    if (dangerouslyHidePaymentMethods && fulfilmentType) {
      const hiddenMethods = dangerouslyHidePaymentMethods[fulfilmentType];
      return paymentMethods.filter((pm) => !hiddenMethods?.includes(pm.value as PaymentMethod));
    }

    return paymentMethods;
  }, [_paymentMethods, _cart, dangerouslyHidePaymentMethods]);

  const receipt = useMemo(() => {
    const receipt: SummaryProps['receipt'] = {
      total: 0,
      oldTotal: 0,
      items: [],
    };

    if (cart) {
      receipt.total = cart?.order?.receipt?.total ?? 0;
      receipt.items = [];

      if (cart?.order) {
        receipt.items = (cart.order.items || []).map(({ images, price, name, qty }) => {
          return {
            title: `${name} (x${qty})`,
            value: `${formatPrice(price)}`,
            image: images && images[0],
          };
        });
      } else {
        receipt.items = cart.items.map((item) => {
          const { variants, name, images } = item.product;
          const variant = variants[0];
          let { price } = variant;
          if (variant.tierPricing) {
            price = variant.tierPricing?.find((tier) => item.qty >= tier.qty)?.price || price;
          }
          return {
            title: `${name} ${variants[0].name} (x${item.qty})`,
            value: `${formatPrice(price * item.qty)}`,
            image: images && images[0],
          };
        });
      }

      if (cart.order?.receipt?.items) {
        const items = processReceiptItems(cart.order?.receipt?.items ?? []);
        receipt.items = receipt.items.concat(items);
      }
    }
    return receipt;
  }, [cart]);

  const {
    isEnabled: isLoyaltyEnabled,
    isEnabledAtStore: isLoyaltyEnabledAtStore,
    settings: loyaltySettings,
    loyalty,
    isLoading: isLoadingLoyalty,
    requirements,
  } = useLoyalty(cartIsReady === undefined);

  const loyaltyInfo = usePreviousDefined(loyalty);

  const isLoading = isLoadingCart || isLoadingShopInfo || isLoadingDocuments || isLoadingLoyalty;

  useEffect(() => {
    if (
      (errorInterval && errorInterval.code === 'CartNotFound') ||
      (!isLoading && !afterPlaceOrder && isLoggedIn && (!cart?.order || !cart?.items.length))
    ) {
      void navigate(Routes.Cart, { replace: true });
    }
  }, [afterPlaceOrder, cart?.items.length, cart?.order, errorInterval, isLoading, isLoggedIn, navigate, user]);

  const onChangeDate = useCallback(
    (value: string) => {
      setFulfillmentDate(value);
      setValue('fulfillmentIntervalId', undefined);
    },
    [setValue],
  );

  const cbRef = useRef<((v: Cart) => void)[]>([]);

  const onAfterSave = useCallback((cb: (cart: Cart) => void) => {
    cbRef.current.push(cb);
  }, []);

  const [fulfillmentType, setFulfillmentType] = useState(formInitialValues.fulfillmentType);

  const onChangeType = useCallback((value: FullShopFulfillmentType) => {
    setFulfillmentType(value);
  }, []);

  useOnlyUpdateEffect(() => {
    const newFulfillmentOptions = getFulfillmentOptions(orderType);
    if (newFulfillmentOptions.length) {
      setValue('fulfillmentType', newFulfillmentOptions[0].value);
      setFulfillmentType(newFulfillmentOptions[0].value);
    }
  }, [getFulfillmentOptions, orderType, setValue]);

  useOnlyUpdateEffect(() => {
    setIsLockedOrderTypeDeps(true);
    setValue('fulfillmentDate', undefined);
    setFulfillmentDate('');
    setValue('fulfillmentIntervalId', undefined);
    onAfterSave(async () => {
      try {
        await Promise.all([loadPaymentMethods(), loadIntervals()]);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLockedOrderTypeDeps(undefined);
      }
    });
  }, [fulfillmentType, loadIntervals, loadPaymentMethods, onAfterSave, setValue]);

  useOnlyUpdateEffect(() => {
    const current = formRef.current?.getValues('paymentMethodType');
    if (current && paymentMethods.every(({ value }) => value !== current)) {
      setValue('paymentMethodType', undefined);
    }
  }, [formRef, paymentMethods, setValue]);

  useEffect(() => {
    if (dateOptions && dateOptions.length && !formRef.current?.getValues('fulfillmentDate')) {
      setValue('fulfillmentDate', dateOptions[0].value);
      setFulfillmentDate(dateOptions[0].value);
    }
  }, [dateOptions, setValue, formRef]);

  // sync hidden form fields
  useEffect(() => {
    setValue('deliveryAddress', deliveryAddress);
    setValue('deliveryLocation', deliveryLocation);
    setValue('secondaryAddress', deliveryAddressSecondLine);
    setValue('documentsIsValid', documentsIsValid);
  }, [
    deliveryAddress,
    documentRequirements,
    deliveryAddressSecondLine,
    deliveryLocation,
    setValue,
    user,
    documentsIsValid,
  ]);

  const config = useMemo(() => ({ concurrency: 1 }), []);
  const { add } = usePromiseQueue(config);

  const prevPromocodes = useRef<string[]>(formInitialValues.promocodes);

  const [isSaving, setIsSaving] = useState(false);

  const saveCheckoutData = useCallback(async () => {
    try {
      if (!formRef.current || !isLoggedIn) {
        return;
      }
      setIsSaving(true);
      const {
        deliveryAddress,
        deliveryLocation,
        promocodes,
        fulfillmentType,
        paymentMethodType,
        notes,
        loyaltyPoints,
        fulfillmentDate,
        fulfillmentIntervalId,
        secondaryAddress,
      } = formRef.current.getValues();

      const data = await add<Cart>(() =>
        CheckoutEndpoint.request(
          {
            platformOs,
            saleType,
            deliveryAddress,
            secondaryAddress,
            deliveryLocation,
            promocodes,
            ...(fulfillmentType ? { fulfillmentType } : {}),
            ...(paymentMethodType ? { paymentMethodType } : {}),
            notes,
            loyaltyPoints,
            ...(fulfillmentDate ? { fulfillmentDate } : {}),
            ...(typeof fulfillmentIntervalId !== 'undefined'
              ? { fulfillmentIntervalId }
              : { fulfillmentIntervalId: null }),
          },
          {
            notifyWithSnackbar: false,
          },
        ),
      );
      if (data?.order?.promocodes) {
        setValue('promocodes', data.order.promocodes);
        const snackData: PushSnackArg = {
          type: 'success',
          message: '',
        };

        const newCode = promocodes.find((code) => !prevPromocodes.current.includes(code));
        if (newCode) {
          if (data.order.promocodes.includes(newCode)) {
            snackData.message = 'Promo code successfully added';
          } else {
            snackData.type = 'warning';
            snackData.message = 'Promo code is expired or cannot be applied to the products in the cart';
          }
        } else if ([...prevPromocodes.current].sort().toString() !== [...data.order.promocodes].sort().toString()) {
          snackData.message = 'Promo code successfully removed';
        }

        if (snackData.message) {
          SnackbarService.push(snackData);
        }
      }
      await mutateCart(data as Cart, { revalidate: false });
      cbRef.current.forEach((cb) => cb(data as Cart));
      cbRef.current = [];

      prevPromocodes.current = data?.order?.promocodes || [];
    } catch (e) {
      if ((e as { subCode: number }).subCode === 1051) {
        void navigate(Routes.Cart, { replace: true });
        return;
      }
      SnackbarService.push({
        type: SUBCODE_SHOW_AS_WARNING.includes((e as { subCode: number }).subCode) ? 'warning' : 'danger',
        message: (e as Error).message,
      });
    } finally {
      setIsLockedOrderTypeDeps(undefined);
      setIsSaving(false);
    }
  }, [add, formRef, isLoggedIn, mutateCart, navigate, platformOs, saleType, setValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveCheckoutDataDebounced = useCallback(debounce(saveCheckoutData, 300), [saveCheckoutData]);

  useOnlyUpdateEffect(() => {
    void saveCheckoutDataDebounced();
  }, [orderType, deliveryAddress, deliveryLocation?.lat, deliveryLocation?.lng, deliveryAddressSecondLine]);

  const formOnChange = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const isIdentical = isEqual(formRef.current?.getValues(), formInitialValues);
    if (isIdentical) {
      return;
    }
    void saveCheckoutDataDebounced();
  }, [formInitialValues, formRef, isLoading, saveCheckoutDataDebounced]);

  const goToDocuments = useCallback(
    (isActionRequired?: boolean) => {
      void navigate(isActionRequired ? Routes.ProfileDocuments : Routes.CheckoutDocuments);
    },
    [navigate],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { process } = usePaymentWithAeroPay();

  const { pushEvent } = useAnalytics();
  const completeOrder = useCallback(async () => {
    setIsSubmitting(true);
    setAfterPlaceOrder(true);
    await formRef.current?.trigger();
    const form = formRef.current;
    const values = form?.getValues();
    let paymentContext: AeroPayPaymentContext | undefined;
    await saveCheckoutData();
    try {
      if (values?.paymentMethodType === PaymentMethod.AeroPay) {
        paymentContext = await process(receipt.total);
      }
      if (paymentContext === null) {
        setIsSubmitting(false);
        setAfterPlaceOrder(false);
        return;
      }
      const newCart = await ConfirmEndpoint.request(
        { saleType, paymentContext, platformOs },
        {
          notifyWithSnackbar: false,
        },
      );
      if (newCart.order?.id) {
        pushEvent(AEventType.ORDER_PLACE, newCart);
        if (newCart.order.analytics?.isFirstOrderByStore && newCart.order.invoiceId) {
          pushEvent(AEventType.ORDER_FIRST, newCart.order.invoiceId);
        }

        setLocalAvailableItemsQuantity(0);
        await mutateCart(
          {
            ...cart,
            items: [],
            ...(cart && cart.order
              ? {
                  order: {
                    ...cart.order,
                    items: [],
                  },
                }
              : {}),
          },
          { revalidate: false },
        );
        await navigate(
          {
            pathname: Routes.ProfileOrderDetails,
            query: { id: String(newCart.order.id) },
          },
          { replace: true },
        );
        setTimeout(() => {
          OrderEvents.fire('orderPlaced', newCart.order!);
        }, 0);
      }
      setLocalAvailableItemsQuantity(0);
    } catch (e) {
      if (
        (e as ConfirmEndpointError).code === ConfirmApiErrorCode.OrderRecreated ||
        (e as ConfirmEndpointError).code === ConfirmApiErrorCode.ConfirmCartAndOrderItemsAreDifferent
      ) {
        await navigate(Routes.Cart, { replace: true });
      } else {
        SnackbarService.push({
          type: SUBCODE_SHOW_AS_WARNING.includes((e as { subCode: number }).subCode) ? 'warning' : 'danger',
          message: (e as ConfirmEndpointError).message,
        });
      }
      setAfterPlaceOrder(false);
    } finally {
      void mutateCart();
      setIsSubmitting(false);
    }
  }, [
    cart,
    formRef,
    mutateCart,
    navigate,
    platformOs,
    process,
    pushEvent,
    receipt.total,
    saleType,
    saveCheckoutData,
    setLocalAvailableItemsQuantity,
  ]);

  const alertMessage =
    cart && Number(cart?.amountLeftForDelivery) > 0
      ? `Add ${formatPrice(Number(cart.amountLeftForDelivery))} to get delivery`
      : '';

  const offsetAlert = useTimeOffset();

  const [isEnabledDocuments, setIsEnabledDocuments] = useState(false);

  useEffect(() => {
    if (documentsIsValid) {
      setIsEnabledDocuments(!documentsIsValid);
      return;
    }
    if (!documentRequirements || !user) return;
    const isHidden = documentRequirements.isNothingRequired || isGuest;
    setIsEnabledDocuments(!isHidden);
  }, [cart?.id, documentRequirements, documentsIsValid, isGuest, user]);

  const [isLoadingPromo, setIsLoadingPromo] = useState(false);

  const promocodeOnChange = useCallback(() => {
    setIsLoadingPromo(true);
    onAfterSave(() => {
      setIsLoadingPromo(false);
    });
  }, [onAfterSave]);

  const { intl } = useIntl();
  const loyaltyProps = useMemo(() => {
    if (user?.isCaregiver && cart?.order?.caregiver) return; // hide loyality if order for patient
    if (!loyaltySettings || !loyaltyInfo) return;
    if (isLoyaltyEnabled === false || isLoyaltyEnabledAtStore === false) return;

    const maxPointToRedeem = cart?.order?.loyalty?.maxPointToRedeem ?? 0;
    return {
      name: 'loyaltyPoints',
      type: loyaltySettings.pointsRedemptionType,
      available: getLoyaltyPoints(loyalty) ?? 0,
      presets: loyaltySettings.pointsPresets.map(({ points }) => points) ?? [],
      maxPointToRedeem,
      ratio: cart?.order?.loyalty?.bonusToMoney,
      disabled: loyaltyInfo.status !== Status.Member,
      pending: isSaving,
      exceedMaxNotify: () => {
        SnackbarService.push({
          type: 'warning',
          heading: 'Entered amount exceeds available points',
          message: intl.t(
            'domains.checkout.form.maximumPointsApplied',
            { points: maxPointToRedeem },
            'Maximum available loyalty points applied ({points} pts)',
          ),
        });
      },
      requirements,
    };
  }, [
    cart?.order?.caregiver,
    cart?.order?.loyalty?.bonusToMoney,
    cart?.order?.loyalty?.maxPointToRedeem,
    intl,
    isLoyaltyEnabled,
    isLoyaltyEnabledAtStore,
    isSaving,
    loyalty,
    loyaltyInfo,
    loyaltySettings,
    requirements,
    user?.isCaregiver,
  ]);

  return {
    isPickupDeliveryAvailable,
    fulfillmentDateProps: {
      name: 'fulfillmentDate',
      onChange: onChangeDate,
      options: dateOptions,
      disabled: !_intervals.scheduleExists || isLockedOrderTypeDeps,
    },
    fulfillmentIntervalProps: {
      name: 'fulfillmentIntervalId',
      options: intervalOptions,
      disabled: !_intervals.scheduleExists || isLockedOrderTypeDeps,
    },
    fulfillmentProps: {
      name: 'fulfillmentType',
      options: fulfillmentOptions,
      shopName: shopInfo?.name ?? '',
      shopAddress: shopInfo?.contacts.address ?? '',
      orderType,
      availableFulfillment,
      deliveryAddress,
      deliveryAddressSecondLine,
      onChange: onChangeType,
    },
    prepareListMarker,
    formInitialValues,
    paymentMethodProps: {
      name: 'paymentMethodType',
      options: paymentMethods,
      disabled: isLockedOrderTypeDeps,
    },
    promocodeProps: {
      name: 'promocodes',
      pending: isLoadingPromo,
      onChange: promocodeOnChange,
      title: `${isPatientOrder ? "Patient's" : ''} Promo codes`,
      isPatientOrder,
    },
    notesProps: {
      name: 'notes',
    },
    receipt,
    isLoading,
    orderType,
    isEnabledDeliveryDateInterval: _intervals.scheduleExists,
    isEnabledDocuments,
    formSchema: buildFormSchema({
      withDate: !!dateOptions.length,
      withDocuments: isEnabledDocuments && !isLoadingGuest && !isGuest,
      withConsents: !!isCheckoutConsentsEnabled,
      withPayments: !isPaymentsDisabled,
    }),
    formOnChange,
    completeOrder,
    loyaltyProps,
    goToDocuments,
    alertMessage,
    isDisabled: !!alertMessage,
    isPaymentsDisabled,
    isSubmitting,
    isCheckoutConsentsEnabled,
    onAfterSave,
    cart,
    offsetAlert,
    documentsProps: {
      name: 'documentsIsValid',
    },
    documentsRequiredNames: documentRequirements?.requiredDocumentsCategories,
    formIsDisabled: afterPlaceOrder,
    setFormErrors,
    user,
    amountLeftForFreeDelivery:
      cart && Number(cart?.amountLeftForFreeDelivery) > 0 ? cart?.amountLeftForFreeDelivery : null,
  };
};

export { useCheckout, processReceiptItems };
export default useCheckout;
