import Button from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';
import { CloseIcon } from '@swe/shared/ui-kit/components/icon';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import styles from './styles.module.scss';

type InstallPwaBannerProps = {
  onInstall: () => any;
  onClose: () => any;
};

const InstallPwaBanner = ({ onInstall, onClose }: InstallPwaBannerProps) => {
  const theme = useTheme();

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.content}>
          <Text
            className={styles.text}
            size="lg"
          >
            Continue in app?
          </Text>
          <Stack
            className={styles.content}
            direction="row"
            spacing="sm"
          >
            <Button
              ariaLabel="Install PWA button"
              color="light"
              size="sm"
              onClick={onInstall}
            >
              Install
            </Button>
            <Button
              ariaLabel="Close install PWA button"
              color={theme.contrast.primary.switch === 'alt' ? 'neutral' : 'light'}
              variant="link"
              size="sm"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Stack>
        </div>
      </Container>
    </div>
  );
};

export type { InstallPwaBannerProps };
export { InstallPwaBanner };
