import { useCallback, useEffect, useState } from 'react';

import { useCarouselContext } from '@swe/shared/ui-kit/components/carousel-v2/core/context';

const useProgress = () => {
  const { set, getScrollSnaps, getSelectedScrollSnap, subscribe, unsubscribe } = useCarouselContext();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const handleInit = useCallback(() => {
    setScrollSnaps(getScrollSnaps?.() ?? []);
  }, [getScrollSnaps]);
  const handleSelect = useCallback(() => {
    setSelectedIndex(getSelectedScrollSnap?.() ?? 0);
  }, [getSelectedScrollSnap]);
  const handleDotClick = useCallback(
    (index: number) => {
      set?.(index);
    },
    [set],
  );

  useEffect(() => {
    if (subscribe) {
      handleInit();
      handleSelect();
      subscribe('reInit', handleInit);
      subscribe('reInit', handleSelect);
      subscribe('select', handleSelect);

      return () => {
        handleInit();
        handleSelect();
        unsubscribe!('reInit', handleInit);
        unsubscribe!('reInit', handleSelect);
        unsubscribe!('select', handleSelect);
      };
    }
  }, [handleInit, handleSelect, subscribe, unsubscribe]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotClick: handleDotClick,
  };
};

export { useProgress };
