/* eslint-disable no-bitwise */
import { formatPoints } from '@swe/shared/utils/points';

import { LoyaltySystemId } from 'entities/loyalty/settings';

enum Status {
  NonMember = 'NonMember',
  ExMember = 'ExMember',
  Member = 'Member',
}

enum Requirement {
  Email = 1 << 0,
  Phone = 1 << 1,
  NotificationSubscription = 1 << 2,
}

type CustomerId = number;
type BalanceId = string;
type TransferId = number;
type OrderId = number;

type Balance = {
  balanceId: BalanceId;
  balance: number;
};

type Progress = {
  goal: number;
  nextLoyaltyId: LoyaltySystemId | null;
  nextGoal: number | null;
};

type TransferType = NamedEntity<TransferId>;

type Transfer = {
  transferId: TransferId;
  type: TransferType;
  amount: number;
  orderId: OrderId;
  payTime: DateISOString;
};

enum ExpirationType {
  RollingDateExpiration = 'RollingDateExpiration',
  OnDateExpiration = 'OnDateExpiration',
  NoExpiration = 'NoExpiration',
  RollingDateFromLastOrder = 'RollingDateFromLastOrder',
}

type ExpirationData = {
  lastOrderDate: DateISOString | null;
  nearestExpirationDate: DateISOString | null;
  nearestExpirationPoints: number | null;
  totalExpirationPoints: number;
  totalExpirationDate: DateISOString;
  expirationType: ExpirationType;
} | null;

type LoyaltyInfo = {
  status: Status;
  requirements: number | null;
  customerId: CustomerId;
  balance: Balance;
  progress: Progress;
  transfers: Transfer[];
  loyaltySystemId: LoyaltySystemId;
  score?: number; // REMOVE OPTIONAL AS BACKEND RELEASES PROPERTY
  pointExpirationInfo: ExpirationData | null;
};

const getLoyaltyBalance = (loyalty?: LoyaltyInfo) =>
  loyalty && loyalty.balance && loyalty.status !== Status.NonMember ? loyalty.balance : undefined;

const getLoyaltyPoints = (loyalty?: LoyaltyInfo) => {
  const balance = getLoyaltyBalance(loyalty);
  if (!balance) return undefined;

  return balance.balance;
};
const getLoyaltyPointsFormatted = (loyalty?: LoyaltyInfo) => {
  const points = getLoyaltyPoints(loyalty);
  if (!points) return undefined;

  return formatPoints(points);
};

export type {
  LoyaltySystemId,
  Balance,
  Progress,
  Transfer,
  LoyaltyInfo,
  BalanceId,
  TransferType,
  TransferId,
  OrderId,
  CustomerId,
  ExpirationData,
};
export { Requirement, Status, getLoyaltyBalance, getLoyaltyPoints, getLoyaltyPointsFormatted, ExpirationType };
