import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { AnalyticsEvent } from 'entities/analytics/event';

type Params = { events: AnalyticsEvent[] };
type Data = undefined;

const NAME = '/WebAnalytics/AddEvent';
const AddAnalyticsEventEndpoint = createModernEndpoint<Params, Data>(NAME, { notifyWithSnackbar: false });

export default AddAnalyticsEventEndpoint;
export { AddAnalyticsEventEndpoint };
