import { BaseRadioGroupProps } from '@swe/shared/ui-kit/components/form/radio-group/types';

import Radiobox from '@swe/shared/ui-kit/components/form/radiobox';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';

import { Stack, StackProps } from '@swe/shared/ui-kit/components/stack';

type RadioGroupRadioProps<VT> = BaseRadioGroupProps<VT | null> & {
  inline?: boolean;
  spacing?: StackProps<'div'>['spacing'];
};

const RadioGroupRadio = <VT extends any = string>({
  value,
  name,
  label,
  error,
  disabled,
  onChange,
  onBlur,
  options,
  className,
  inline = false,
  spacing,
}: RadioGroupRadioProps<VT | null>) => {
  return (
    <CommonWrapper
      className={className}
      label={label}
      error={error}
      inline={inline}
    >
      <Stack
        direction={inline ? 'row' : 'column'}
        spacing={spacing}
        wrap
      >
        {options.map((option) => {
          return (
            <Radiobox
              name={name}
              key={`${option.label}${option.value}`}
              label={option.label}
              value={value === option.value}
              onChange={(nextValue) => {
                if (nextValue && onChange) {
                  onChange(option.value);
                  onBlur?.();
                }
              }}
              disabled={option.disabled || disabled}
            />
          );
        })}
      </Stack>
    </CommonWrapper>
  );
};

export type { RadioGroupRadioProps };
export { RadioGroupRadio };
export default RadioGroupRadio;
