import { useCallback, useState } from 'react';

const usePending = <CB extends (...args: any[]) => any>(cb: CB | undefined) => {
  const [pending, setPending] = useState(false);
  const wrappedCb = useCallback(
    async (...args: Parameters<CB>) => {
      const maybePromise = cb?.(...args);

      if (maybePromise instanceof Promise) {
        setPending(true);
        const result = await maybePromise;
        setPending(false);
        return result;
      }

      return maybePromise;
    },
    [cb],
  );

  return [cb ? wrappedCb : undefined, pending] as const;
};

export { usePending };
