import { Loader } from '@googlemaps/js-api-loader';

import { LOADER_OPTIONS } from '@swe/shared/tools/google/config';

const LOADER_CONTAINER: Map<string, Loader> = new Map();

class GoogleJSApiLoader {
  private loader: Loader;

  protected isLoaded = false;

  constructor(apiKey: string) {
    if (LOADER_CONTAINER.has(apiKey)) {
      this.loader = LOADER_CONTAINER.get(apiKey)!;
    } else {
      const loader = new Loader({ apiKey, ...LOADER_OPTIONS });
      LOADER_CONTAINER.set(apiKey, loader);
      this.loader = loader;
    }
  }

  public async load() {
    return this.loader.load();
  }
}

export { GoogleJSApiLoader };
