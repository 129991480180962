import { useDeepCompareMemo } from '@swe/shared/hooks/use-deep-compare';
import { CommonQueryParams, RouteQuery, RouteQueryParam } from '@swe/shared/providers/router/constants';
import { Pagination } from '@swe/shared/types/wrappers/pagination';

import { isEqual, merge, omit, omitBy } from '@swe/shared/utils/object';

import { isEmpty } from '@swe/shared/utils/other';

import { useCallback, useMemo, useRef } from 'react';

import { useRouterNavigate, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { buildNamedId, isLikeCarousel, parseNamedId } from 'common/router/utils';
import {
  DEFAULT_PAGINATION,
  decodeCatalogFilters,
  encodeCatalogFilters,
  DEFAULT_CATALOG_FILTERS,
  CatalogFiltersValues,
  DEFAULT_CATALOG_SORTING_METHOD,
  CatalogSortingMethodId,
} from 'domains/catalog/constants';
import { FilterKey } from 'entities/common/catalog';
import { CompilationType } from 'entities/common/compilation';
import {
  ProductFilterAny,
  ProductFilterOption,
  ProductFilters,
  ProductFiltersConfigMap,
  ProductFilterTag,
  ProductFilterType,
} from 'entities/product/filter';

type CatalogPageParams = {
  filters?: CatalogFiltersValues | null;
  subPath?: string[] | null;
  searchTerm?: string | null;
  sortingMethod?: CatalogSortingMethodId | null;
  page?: string | null;
};

const getFilterDescriptorDefaultValue = (filter?: ProductFilterAny) => {
  if (!filter) {
    return null;
  }
  switch (filter.type) {
    case ProductFilterType.RANGE:
      return [filter.range.min, filter.range.max];
    case ProductFilterType.MULTIPLE:
      return [];
    case ProductFilterType.SINGLE:
      return null;
    default:
      return null;
  }
};

const isEmptyValueByFilter = (filter: ProductFilterAny, value: any) => {
  if (typeof value === 'undefined') {
    return true;
  }
  return isEqual(value, getFilterDescriptorDefaultValue(filter));
};

const clearEmptyFilters = (
  filtersValues: CatalogFiltersValues | null | undefined,
  filtersDescriptor: ProductFiltersConfigMap | undefined,
): CatalogFiltersValues | null => {
  if (!filtersDescriptor || !filtersValues) {
    return filtersValues ?? null;
  }
  if (isEmpty(filtersValues)) {
    return null;
  }
  return omitBy(
    filtersValues,
    (value, key) => !filtersDescriptor[key] || isEmptyValueByFilter(filtersDescriptor[key], value),
  );
};

const parsePaginationQuery = (query: RouteQueryParam) => {
  const page = parseInt(query as string, 10);
  if (String(page) === query && page > 0) {
    return page;
  }
  return 0;
};

type CatalogCommonQuery = {
  pagination: Pagination;
  searchTerm?: string;
  filters: CatalogFiltersValues;
  sortingMethod: CatalogSortingMethodId;
  subPath: string[];
};

type CatalogMainQuery = CatalogCommonQuery & {
  page: 'main';
  categoryId: -1;
};

type CatalogCategoryQuery = CatalogCommonQuery & {
  page: 'category';
  categoryId: EntityID<number>;
};

type CatalogCompilationQuery = CatalogCommonQuery & {
  page: 'compilation';
  compilationType: CompilationType;
  compilationId: EntityID<string>;
  categoryId: -1;
};

type CatalogProductQuery = CatalogCommonQuery & {
  page: 'product';
  categoryId: EntityID<number>;
  variantId: EntityID<string>;
};

type CatalogQuery = CatalogMainQuery | CatalogCompilationQuery | CatalogCategoryQuery | CatalogProductQuery;

const parseCatalogQuery = ({
  all = [],
  [CommonQueryParams.Search]: searchQuery = '',
  [CommonQueryParams.Page]: paginationQuery,
  [CommonQueryParams.Filters]: filtersQuery,
  [CommonQueryParams.Sorting]: sortingQuery,
}: RouteQuery): CatalogQuery => {
  const subPath = Array.isArray(all) ? all : [all];
  const searchTerm = typeof searchQuery === 'string' ? searchQuery : '';
  const sortingMethod = Number(sortingQuery) || DEFAULT_CATALOG_SORTING_METHOD;
  const pagination: Pagination = {
    page: parsePaginationQuery(paginationQuery) || DEFAULT_PAGINATION.page,
    pageSize: DEFAULT_PAGINATION.pageSize,
  };
  const filters = decodeCatalogFilters<CatalogFiltersValues>(filtersQuery as string, DEFAULT_CATALOG_FILTERS);

  const commonQuery = {
    subPath,
    searchTerm,
    pagination,
    filters,
    sortingMethod,
  };

  if (subPath.length === 2 && subPath[0] === CompilationType.DISCOUNT) {
    const compilationId = parseNamedId(subPath[1]);
    if (compilationId) {
      return {
        page: 'compilation',
        compilationType: CompilationType.DISCOUNT,
        compilationId,
        categoryId: -1,
        ...commonQuery,
      };
    }
  }

  if (subPath.length === 2) {
    const categoryId = parseNamedId(subPath[0], 'number');
    const variantId = parseNamedId(subPath[1]);
    if (categoryId && variantId) {
      return {
        page: 'product',
        categoryId,
        variantId,
        ...commonQuery,
      };
    }
  }

  const isPathLikeCarousel = isLikeCarousel(subPath[0]);

  if (subPath.length === 1 && isPathLikeCarousel) {
    const compilationId = parseNamedId(subPath[0]);
    if (compilationId) {
      return {
        page: 'compilation',
        compilationType: CompilationType.CAROUSEL,
        compilationId,
        categoryId: -1,
        ...commonQuery,
      };
    }
  }

  if (subPath.length === 1 && !isPathLikeCarousel) {
    const categoryId = parseNamedId(subPath[0], 'number');
    if (categoryId) {
      return {
        page: 'category',
        categoryId,
        ...commonQuery,
        filters: merge({}, commonQuery.filters, { [FilterKey.Category]: [categoryId] }),
      };
    }
  }

  return {
    page: 'main',
    categoryId: -1,
    ...commonQuery,
  };
};

const useCatalogQuery = (_productFilters?: ProductFilters, tempFilters?: CatalogFiltersValues) => {
  const _query = useRouterQuery();
  const navigate = useRouterNavigate();
  const query = useDeepCompareMemo(() => _query, [_query]);
  const catalogQuery = useMemo(() => parseCatalogQuery(query), [query]);
  const { filters: catalogQueryFilters } = catalogQuery;
  const filters = tempFilters ?? catalogQueryFilters;
  const allCategories = useRef<ProductFilterOption[]>([]);
  const productFilters: ProductFilters | undefined = useDeepCompareMemo(() => {
    return _productFilters
      ?.map((filter) => {
        const filterWithValue = {
          ...filter,
          isTouched: filters[filter.key] !== undefined,
          value: filters[filter.key] ?? getFilterDescriptorDefaultValue(filter),
        };
        if (filter.key === FilterKey.Category) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          allCategories.current = (filterWithValue as ProductFilterTag).options;
          if (catalogQuery.categoryId === -1) {
            return {
              ...filterWithValue,
              options: allCategories.current.filter(({ disabled }) => !disabled),
            };
          }
          return {
            ...filterWithValue,
            options: allCategories.current.map((item) => ({ ...item, disabled: false })),
          };
        }
        return filterWithValue;
      })
      .filter((item) => ('options' in item ? item.options.length > 0 : true));
  }, [_productFilters, catalogQuery.categoryId, filters]);
  const filtersDescriptor = useMemo(
    () => productFilters && Object.fromEntries(productFilters.map((filter) => [filter.key, filter])),
    [productFilters],
  );

  const buildCatalogLink = useCallback(
    ({ filters, subPath, searchTerm, sortingMethod, page }: CatalogPageParams = {}) => ({
      pathname: Routes.Catalog,
      query: {
        [CommonQueryParams.Filters]: filters
          ? encodeCatalogFilters<CatalogFiltersValues>(
              clearEmptyFilters(filters, filtersDescriptor),
              DEFAULT_CATALOG_FILTERS,
            )
          : filters === null
            ? undefined
            : query[CommonQueryParams.Filters],
        [CommonQueryParams.Search]: searchTerm || (searchTerm === null ? undefined : query[CommonQueryParams.Search]),
        [CommonQueryParams.Sorting]:
          sortingMethod === null || sortingMethod === DEFAULT_CATALOG_SORTING_METHOD
            ? undefined
            : sortingMethod
              ? String(sortingMethod)
              : query[CommonQueryParams.Sorting],
        [CommonQueryParams.Page]: page
          ? parsePaginationQuery(page) > 1
            ? page
            : undefined
          : page === null
            ? undefined
            : query.page,
        all: subPath ?? (subPath === null ? undefined : query.all),
      },
    }),
    [filtersDescriptor, query],
  );

  const toCatalog = useCallback(
    (params: CatalogPageParams, replace = false) =>
      navigate(
        buildCatalogLink({
          ...params,
          page: params.page ?? null,
        }),
        {
          scroll: !replace,
          replace,
        },
      ),
    [buildCatalogLink, navigate],
  );

  const clearAll = useCallback(() => toCatalog({ filters: null, searchTerm: null }, true), [toCatalog]);

  const clearField = useCallback(
    (fieldName: string) => {
      return toCatalog(
        {
          filters: omit(filters, fieldName),
          subPath: catalogQuery.page === 'category' && fieldName === FilterKey.Category ? [] : undefined,
        },
        true,
      );
    },
    [toCatalog, filters, catalogQuery.page],
  );

  const setField = useCallback(
    (fieldName: string, value: any) => {
      return toCatalog(
        {
          filters: { ...filters, [fieldName]: value },
          subPath: catalogQuery.page === 'category' && fieldName === FilterKey.Category ? [] : undefined,
        },
        true,
      );
    },
    [toCatalog, filters, catalogQuery.page],
  );

  const setSortingMethod = useCallback(
    (sortingId: CatalogSortingMethodId) => {
      return toCatalog({ sortingMethod: sortingId }, true);
    },
    [toCatalog],
  );

  const clearSearch = useCallback(() => toCatalog({ searchTerm: null }, true), [toCatalog]);

  const setSearchTerm = useCallback(
    (val: string) => toCatalog({ filters: null, subPath: null, searchTerm: val }),
    [toCatalog],
  );

  const getFilterDefaultValue = useCallback(
    (key: string) => filtersDescriptor && getFilterDescriptorDefaultValue(filtersDescriptor[key]),
    [filtersDescriptor],
  );

  const getCategoryName = useCallback(
    (categoryId: EntityID<number>) => allCategories.current.find((cat) => cat.id === categoryId)?.name,
    [],
  );

  const changeCatalogCategory = useCallback(
    async (categoryId: EntityID<number>) => {
      return toCatalog({
        subPath: categoryId === -1 ? null : [buildNamedId(getCategoryName(categoryId), categoryId)],
        filters: null,
        searchTerm: null,
      });
    },
    [getCategoryName, toCatalog],
  );

  const areFiltersTouched = useMemo(
    () =>
      !isEqual(omit(filters, catalogQuery.page === 'category' ? [FilterKey.Category] : []), DEFAULT_CATALOG_FILTERS),
    [catalogQuery.page, filters],
  );

  const clearPagination = useCallback(() => toCatalog({ page: null }, true), [toCatalog]);

  return {
    catalogQuery,
    allCategories: allCategories.current,
    getCategoryName,
    clearAll,
    toCatalog,
    setSearchTerm,
    clearSearch,
    buildCatalogLink,
    clearField,
    setField,
    getFilterDefaultValue,
    setSortingMethod,
    changeCatalogCategory,
    productFilters: productFilters || [],
    filtersDescriptor: filtersDescriptor || {},
    areFiltersTouched,
    clearPagination,
  };
};

export { parseCatalogQuery, useCatalogQuery, clearEmptyFilters };
export default useCatalogQuery;
