import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { PaymentMethodDescriptor } from 'entities/cart/cart';
import { StoreSaleType } from 'entities/shop/sale-type';

type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};

const NAME = '/Cart/GetPaymentMethodList';
export const GetPaymentMethodsEndpoint = createModernEndpoint<Params, PaymentMethodDescriptor[]>(NAME);

export default GetPaymentMethodsEndpoint;
