import { useCallback, useMemo, useRef } from 'react';

import { CommonQueryParams, RouteQuery } from '@swe/shared/providers/router/constants';

import { useRouterHistory, useRouterNavigate, useRouterQuery } from '@swe/shared/providers/router/index';
import { omit } from '@swe/shared/utils/object';

const isModalRouteOpened = <MR extends string>(query: RouteQuery, name?: MR) =>
  name ? query[CommonQueryParams.Modal] === name : !!query[CommonQueryParams.Modal];

const getModalRouteParams = (query: RouteQuery) => {
  try {
    return JSON.parse((query[CommonQueryParams.ModalParams] as string) ?? {});
  } catch (_) {
    return {};
  }
};

const useModalRoute = <
  MR extends string,
  MP extends Record<string, any> = Record<string, never>,
  MQ extends Record<string, any> = MP,
>(
  name: MR,
  querySelector?: (params: MP) => MQ,
  decorator?: ((params?: MP) => string | undefined) | string,
) => {
  const { prevState, currentState } = useRouterHistory();
  const query = useRouterQuery();
  const navigate = useRouterNavigate();

  const paramsRef = useRef({
    query,
    querySelector,
    decorator,
    name,
    navigate,
  });
  paramsRef.current = {
    query,
    querySelector,
    decorator,
    name,
    navigate,
  };

  const open = useCallback((_params?: MP) => {
    const { querySelector, query, decorator, name, navigate } = paramsRef.current;
    const params = querySelector && _params ? querySelector(_params) : _params;
    return navigate(
      {
        query: {
          ...query,
          [CommonQueryParams.Modal]: name,
          [CommonQueryParams.ModalParams]: params && JSON.stringify(params),
          [CommonQueryParams.URLDecorator]: typeof decorator === 'string' ? decorator : decorator?.(_params),
        },
      },
      { scroll: false },
    );
  }, []);
  const close = useCallback(() => {
    const { navigate, query } = paramsRef.current;
    return navigate(
      {
        query: omit(query, [CommonQueryParams.Modal, CommonQueryParams.ModalParams, CommonQueryParams.URLDecorator]),
      },
      { scroll: false },
    );
  }, []);
  const isOpened = isModalRouteOpened(query, name);
  const params = useMemo<MQ>(() => getModalRouteParams(query), [query]);

  const hasPrevious = useMemo(() => {
    if (prevState) {
      if (
        isModalRouteOpened(prevState.url?.query ?? {}, name) &&
        isModalRouteOpened(currentState.url?.query ?? {}, name)
      ) {
        return true;
      }
    }
    return false;
  }, [currentState, prevState, name]);

  return { open, close, isOpened, params, hasPrevious };
};

export { useModalRoute, isModalRouteOpened, getModalRouteParams };
