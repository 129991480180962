import { GoogleServiceImpl, Service } from '@swe/shared/tools/google/core/types';

type ServiceContainer<T extends GoogleServiceImpl> = {
  getIfExists: (apiKey: string) => T | undefined;
  getOrCreate: (apiKey: string) => Promise<T>;
};

const createContainer = <
  Implementation extends GoogleServiceImpl,
  Constructor extends Service<Implementation> = Service<Implementation>,
>(
  Service: Constructor,
) => {
  const CONTAINER: Map<string, Implementation> = new Map();

  const init = async (apiKey: string) => {
    const service = new Service(apiKey);

    await service.init();
    CONTAINER.set(apiKey, service);

    return service;
  };
  const getIfExists = (apiKey: string) => CONTAINER.get(apiKey);
  const getOrCreate = async (apiKey: string) => getIfExists(apiKey) ?? (await init(apiKey));

  return {
    getIfExists,
    getOrCreate,
  };
};

export type { ServiceContainer };
export { createContainer };
