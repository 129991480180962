import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { range } from '@swe/shared/utils/array';

const WIDTH = 155;
const MARGIN = 8;
const ITEM_WIDTH = WIDTH + MARGIN;
const HEIGHT = 90;

const COORDINATES: [x: number, y: number][] = range(6).map((_, idx) => [ITEM_WIDTH * idx, 0]);

const ProductVariantsSkeleton = () => {
  const tokens = useTheme();

  return (
    <Skeleton
      uniqueKey="product_variants"
      height={90}
    >
      {COORDINATES.map(([x, y], idx) => (
        <Bone
          key={idx}
          x={x}
          y={y}
          rx={tokens.borderRadius.scale.lg}
          ry={tokens.borderRadius.scale.lg}
          width={WIDTH}
          height={HEIGHT}
        />
      ))}
    </Skeleton>
  );
};

export { ProductVariantsSkeleton };
