import { cloneElement, createElement, Fragment, ReactElement } from 'react';

const tagRe = /<([a-zA-Z0-9]+)>(.*?)<\/\1>|<([a-zA-Z0-9]+)\/>/;
const nlRe = /(?:\r\n|\r|\n)/g;

const voidElementTags = {
  area: true,
  base: true,
  br: true,
  col: true,
  embed: true,
  hr: true,
  img: true,
  input: true,
  keygen: true,
  link: true,
  meta: true,
  param: true,
  source: true,
  track: true,
  wbr: true,
  menuitem: true,
};

const getElements = (parts: string[]): any => {
  if (!parts.length) return [];

  const [paired, children, unpaired, after] = parts.slice(0, 4);
  const triple = [paired || unpaired, children || '', after] as const;

  return [triple].concat(getElements(parts.slice(4, parts.length)));
};

const makeCounter =
  (count = 0, prefix = '') =>
  () =>
    // eslint-disable-next-line no-param-reassign
    `${prefix}_${count++}`;

function formatElements(
  value: string,
  elements: { [key: string]: ReactElement } = {},
): string | Array<ReactElement | string> {
  const uniqueId = makeCounter(0, 'intl');
  const parts = value.replace(nlRe, '').split(tagRe);

  // no inline elements, return
  if (parts.length === 1) return value;

  const tree: Array<ReactElement | string> = [];

  const before = parts.shift();
  if (before) tree.push(before);

  // eslint-disable-next-line no-restricted-syntax
  for (const [index, children, after] of getElements(parts)) {
    let element = typeof index !== 'undefined' ? elements[index] : undefined;

    if (!element || (voidElementTags[element.type as keyof typeof voidElementTags] && children)) {
      if (!element) {
        console.error(`Can't use element at index '${index}' as it is not declared in the original translation`);
      } else {
        console.error(`${element.type} is a void element tag therefore it must have no children`);
      }

      // ignore problematic element but push its children and elements after it
      element = createElement(Fragment);
    }

    if (Array.isArray(element)) {
      element = createElement(Fragment, {}, element);
    }

    tree.push(
      cloneElement(
        element,
        { key: uniqueId() },

        // format children for pair tags
        // unpaired tags might have children if it's a component passed as a variable
        children ? formatElements(children, elements) : element.props.children,
      ),
    );

    if (after) tree.push(after);
  }

  return tree;
}

export { formatElements };
