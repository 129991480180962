import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useCallback } from 'react';

import { AddToCart, AddToCartProps } from 'common/components/add-to-cart';
import { GetQuantityFunc } from 'common/components/product-card/types';
import { AddToCartMeta } from 'common/providers/cart';
import { useAddProductToCart } from 'common/use-cases/use-add-product-to-cart';
import { usePDPControls, usePDPStrategy } from 'common/use-cases/use-pdp';
import { getProductVariant, isOutOfStock, Product, ProductVariant } from 'entities/product/product';

type AddToCartContainerProps = {
  product: Product;
  variant?: ProductVariant | ProductVariant['id'];
  color?: AddToCartProps['color'];
  lastItemIconTrash?: AddToCartProps['lastItemIconTrash'];
  lockLastItem?: AddToCartProps['lockLastItem'];
  getQuantity?: GetQuantityFunc;
  cartMeta?: AddToCartMeta;
} & ComponentHasClassName;

const AddToCartContainer = ({
  product,
  variant: _variant,
  color,
  lastItemIconTrash,
  lockLastItem,
  getQuantity,
  cartMeta,
}: AddToCartContainerProps) => {
  const { open: openPDP } = usePDPControls();
  const strategy = usePDPStrategy();
  const variant = getProductVariant(product, _variant);
  const outOfStock = isOutOfStock(variant);
  const { removeFromCart, addToCart, quantity, availableQuantity, qtyStep } = useAddProductToCart(
    product,
    variant,
    getQuantity,
  );

  const onAddHandler = useCallback(async () => {
    if (product.variants.length > 1) {
      return openPDP({
        product,
        variant,
      });
    }
    const qty = quantity + qtyStep < 1 ? 1 : qtyStep;
    return addToCart(qty, cartMeta);
  }, [addToCart, cartMeta, openPDP, product, variant, qtyStep, quantity]);

  const onRemoveHandler = useCallback(async () => {
    if (product.variants.length > 1) {
      return openPDP({
        product,
        variant,
      });
    }
    const qty = quantity - qtyStep >= 1 ? qtyStep : quantity;
    return removeFromCart(qty, cartMeta);
  }, [removeFromCart, cartMeta, openPDP, product, variant, qtyStep, quantity]);

  if (quantity === 0 && outOfStock) {
    return null;
  }

  return (
    <AddToCart
      availableQuantity={availableQuantity}
      onAdd={onAddHandler}
      onRemove={onRemoveHandler}
      size="sm"
      quantity={quantity}
      lastItemIconTrash={lastItemIconTrash}
      color={color}
      preventClick={strategy === 'modal'}
      lockLastItem={lockLastItem}
    />
  );
};

export { AddToCartContainer };
