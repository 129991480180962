import { IncomingMessage } from 'http';

import { XSweedHeader } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { SWR_CLIENT_CACHE } from '@swe/shared/network/transport/swr/transport.constants';
import { RouteQuery } from '@swe/shared/providers/router/constants';
import { isSSR } from '@swe/shared/utils/environment';

import { RouteObject, redirect, generatePath } from 'react-router-dom';

import { getSaleTypeFromReq } from '@swe/shop-ui/app/app-settings';

import ErrorPage from '@swe/shop-ui/app/error-page';
import { Layout } from '@swe/shop-ui/app/layout';
import REDIRECTS from '@swe/shop-ui/app/router/redirects';
import { ApplicationPage } from '@swe/shop-ui/app/types';
import { Cont404 } from '@swe/shop-ui/common/containers/404';
import { Routes } from '@swe/shop-ui/common/router/constants';
import { convertReactToSharedQuery, convertSharedToReactRoute } from '@swe/shop-ui/common/router/utils';
import { ShopConfigDTO } from '@swe/shop-ui/entities/shop/config';

const createMigratedRoute = (
  route: Routes,
  index: boolean,
  storeConfig: Pick<ShopConfigDTO, 'id' | 'medicalMenuEnabled'>,
): RouteObject => ({
  path: convertSharedToReactRoute(route),
  // shouldRevalidate: ({ currentUrl, nextUrl }) => currentUrl.pathname !== nextUrl.pathname,
  lazy: () =>
    import(`../../pages${route === '/' ? '' : route}/index.tsx`).then((module) => {
      const Component: ApplicationPage = module.default;
      const { preload: _preload, getMainLayoutProps } = Component;
      // TODO: client missed swr cache preloading
      const preload = isSSR ? _preload : undefined;
      return {
        Component,
        loader: async ({ params, request }) => {
          const url = new URL(request.url);
          const query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
          const endpointsCache = await preload?.(
            { [XSweedHeader.StoreId]: String(storeConfig.id) },
            { query },
            {
              saleType: getSaleTypeFromReq(
                storeConfig.medicalMenuEnabled,
                isSSR ? (request as unknown as IncomingMessage) : undefined,
              ),
            },
          );
          if (SWR_CLIENT_CACHE && endpointsCache) {
            Object.entries(endpointsCache).forEach(([key, value]) => SWR_CLIENT_CACHE!.set(key, value));
          }
          const mainLayoutProps = getMainLayoutProps?.();

          return {
            endpointsCache,
            mainLayoutProps,
          };
        },
      };
    }),
  index,
});

const redirects: RouteObject[] = REDIRECTS.map(({ source, destination, permanent, queryMapper }) => {
  return {
    path: convertSharedToReactRoute(source),
    loader: async ({ params, request }) => {
      const url = new URL(request.url);
      const _query: RouteQuery = convertReactToSharedQuery(params, url.searchParams);
      const query: RouteQuery = queryMapper ? await queryMapper(_query) : _query;
      if ('all' in query && Array.isArray(query.all)) {
        query['*'] = query.all.join('/');
        delete query.all;
      }
      return redirect(generatePath(convertSharedToReactRoute(destination), query), permanent ? 308 : 307);
    },
  };
});

const createRoutesDef = (storeConfig: Pick<ShopConfigDTO, 'id' | 'medicalMenuEnabled'>): RouteObject[] => {
  const _pages: RouteObject[] = Object.values(Routes).map((route) =>
    createMigratedRoute(route, route === Routes.Home, storeConfig),
  );
  const pages = [
    ...redirects,
    ..._pages,
    {
      path: '*',
      loader: () => {
        throw new Response('Not Found', { status: 404 });
      },
    },
  ];

  if (!storeConfig) {
    return [
      {
        element: (
          <Layout pages={pages}>
            <Cont404 />
          </Layout>
        ),
      },
    ];
  }

  return [
    {
      element: <Layout pages={pages} />,
      children: [
        {
          ErrorBoundary: ErrorPage,
          children: pages,
        },
      ],
    },
  ];
};

export { createRoutesDef };
