import { SectionProps as SharedSectionProps, Section } from '@swe/shared/ui-kit/components/section';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

type SectionProps = ComponentHasClassName & SharedSectionProps & { title: string };

const ProductSection = ({ className, children, title, ...sectionProps }: SectionProps) => {
  return (
    <Section
      className={className}
      {...sectionProps}
      heading={{ size: 'md', children: title }}
      noTopPadding
    >
      {children}
    </Section>
  );
};

export type { SectionProps };
export { ProductSection };
export default ProductSection;
