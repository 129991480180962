import {
  createElement,
  ElementType,
  createContext,
  useContext,
  useMemo,
  ComponentType,
  ComponentPropsWithRef,
  forwardRef,
  ForwardedRef,
} from 'react';

import { ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';
import { kebabCase } from '@swe/shared/utils/other';

type LocatorName = string;
type LocatorType =
  | 'section'
  | 'page'
  | 'form'
  | 'button'
  | 'input'
  | 'checkbox'
  | 'toggle'
  | 'radio'
  | 'select'
  | 'textarea'
  | 'tag';
type LocatorPathEntry = {
  name: LocatorName;
  type: LocatorType;
};

class LocatorPath extends Array<LocatorPathEntry> {
  toString(): string {
    return this.map(({ name, type }) => `${type}_${name}`).join('.');
  }

  append(newEntry: LocatorPathEntry): LocatorPath {
    return new LocatorPath(...this, newEntry);
  }
}

const LocatorContext = createContext<LocatorPath>(new LocatorPath());

const useLocatorPath = () => {
  return useContext(LocatorContext);
};

type LocatorProps<T extends ElementType | ComponentType> = Omit<ComponentPropsWithRef<T>, 'as'> & {
  as?: T;
  locatorType?: LocatorType;
  locatorName: LocatorName;
} & ComponentHasStyling;

const _Locator = <T extends ElementType | ComponentType>(
  { as, children, locatorType, locatorName, ...props }: LocatorProps<T>,
  ref: ForwardedRef<any>,
) => {
  const prevPath = useLocatorPath();
  const path = useMemo(() => {
    return prevPath.append({
      type: locatorType ?? 'section',
      name: kebabCase(locatorName),
    });
  }, [locatorName, prevPath, locatorType]);

  const element = createElement(as ?? 'div', { ...props, ref, 'data-locator': path.toString() }, children);

  return <LocatorContext.Provider value={path}>{element}</LocatorContext.Provider>;
};

const Locator = forwardRef(_Locator) as <T extends ElementType | ComponentType>(
  props: LocatorProps<T>,
) => ReturnType<typeof _Locator>;

export { Locator, useLocatorPath };
