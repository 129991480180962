import { Box, BoxProps } from '@swe/shared/ui-kit/components/box';
import Container, { ContainerProps } from '@swe/shared/ui-kit/components/container';
import Divider from '@swe/shared/ui-kit/components/divider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type FixedFooterBoxProps = ComponentHasChildren & {
  container?: ContainerProps;
  box?: BoxProps<any>;
};

const withContainer = (children: any, props?: ContainerProps) =>
  props ? <Container {...props}>{children}</Container> : children;
const withBox = (children: any, props?: BoxProps<any>) => (props ? <Box {...props}>{children}</Box> : children);

const FixedFooterBox = ({
  children,
  container = { maxWidth: 'xs' },
  box = { pt: 'sm', pb: 'sm' },
}: FixedFooterBoxProps) => {
  const content = withContainer(withBox(children, box), container);

  return (
    <div className={styles.root}>
      <Divider />
      {content}
    </div>
  );
};

export { FixedFooterBox };
