import { useCallback, useRef, useState } from 'react';

const useToggleable = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const isOpenedRef = useRef(initialValue);

  const toggle = useCallback(
    () =>
      setValue((isVisible) => {
        isOpenedRef.current = !isVisible;
        return !isVisible;
      }),
    [],
  );
  const open = useCallback(() => {
    isOpenedRef.current = true;
    setValue(true);
  }, []);
  const close = useCallback(() => {
    isOpenedRef.current = false;
    setValue(false);
  }, []);

  return [value, open, close, toggle, isOpenedRef] as const;
};

export { useToggleable };
