import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cn from 'clsx';

import styles from './styles.module.scss';

type PotencyProps = ComponentHasClassName & {
  potency: string[];
};

const Potency = ({ className, potency }: PotencyProps) => {
  if (potency.length === 0) {
    return null;
  }

  return (
    <div className={cn(styles.root, className)}>
      {potency.map((value) => (
        <span
          className={styles.value}
          key={value}
        >
          {value}
        </span>
      ))}
    </div>
  );
};

export type { PotencyProps };
export { Potency };
