import cx from 'clsx';

import { useMemo } from 'react';

import { useBreakpoint } from '@swe/shared/tools/media';
import { Controls } from '@swe/shared/ui-kit/components/carousel-v2/controls';
import { Gap, useCarousel } from '@swe/shared/ui-kit/components/carousel-v2/core';
import { Dots } from '@swe/shared/ui-kit/components/carousel-v2/dots';

import { Thumbs } from '@swe/shared/ui-kit/components/carousel-v2/thumbs';
import useExtendElToContainer from '@swe/shared/ui-kit/components/container/utils/use-extend-el-to-container';
import Image from '@swe/shared/ui-kit/components/image';

import styles from './styles.module.scss';
import { CarouselItemT, CarouselProps, isImage } from './types';

const CarouselItem = ({ item }: { item: CarouselItemT }) => {
  let renderResult = item;
  if (isImage(item)) {
    const { type, render, ...imageProps } = item;
    const img = <Image {...imageProps} />;

    renderResult = render ? render(img) : img;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{renderResult}</>;
};

const getProperGapValue = (gap: Gap | 'equal', isExtendToViewActive: boolean): Gap | 'equal' => {
  if (!isExtendToViewActive && gap === 'equal') {
    return 'xs';
  }

  return gap;
};

const Carousel = ({
  className,
  slideClassName,
  thumbClassName,
  items,
  gap: _gap,
  stepper = 'dot',
  controls = true,
  thumbs = false,
  extendToView: _extendToView = false,
  ...props
}: CarouselProps) => {
  const { lessThan } = useBreakpoint();
  const extendToView = items.length > 2 ? _extendToView : false;
  const gap = getProperGapValue(_gap, extendToView);
  const { offset } = useExtendElToContainer(extendToView);
  const { Root, Slide, Context } = useCarousel({
    ...props,
    options: {
      align: 'center',
    },
  });
  const slideStyle = useMemo(
    () => ({
      flex: extendToView && offset ? `0 0 calc(100% - ${offset * 2}px)` : undefined,
      marginRight: gap === 'equal' && offset ? offset : undefined,
    }),
    [extendToView, gap, offset],
  );

  if (items.length === 1) {
    return (
      <div>
        <Slide className={slideClassName}>
          <CarouselItem item={items[0]} />
        </Slide>
      </div>
    );
  }

  return (
    <div>
      <Root
        classNames={{
          root: cx(className, styles.root, {
            [styles._horizontalFade]: extendToView && !lessThan.xxl,
          }),
        }}
        styles={{
          root:
            extendToView && offset
              ? {
                  marginLeft: -offset,
                  marginRight: -offset,
                }
              : undefined,
          container:
            extendToView && offset
              ? {
                  transform: `translate3d(${offset}px, 0, 0)`,
                }
              : undefined,
        }}
        arrowControl={<Context>{controls && <Controls offset={offset} />}</Context>}
        stepperControl={
          <Context>
            {stepper && (
              <Dots
                stepper={stepper}
                length={items.length}
              />
            )}
          </Context>
        }
      >
        {items.map((item, key) => (
          <Slide
            className={slideClassName}
            style={slideStyle}
            key={key}
            gap={gap === 'equal' || gap === 'lg' ? undefined : gap}
          >
            <CarouselItem item={item} />
          </Slide>
        ))}
      </Root>
      {thumbs && (
        <Context>
          <Thumbs
            className={styles.thumbs}
            thumbClassName={thumbClassName}
            items={items}
          />
        </Context>
      )}
    </div>
  );
};

export type { CarouselProps };
export { Carousel };
