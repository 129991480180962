import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  confirmationId: string;
};
type Data = { code: string | null; nextTryInSeconds: number };

const NAME = '/GuestAccount/ResendPhoneVerificationCode';
const ResendPhoneVerificationCodeEndpoint = createModernEndpoint<Params, Data, APIErrorExtended>(NAME);

export { ResendPhoneVerificationCodeEndpoint };
