import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Order } from 'entities/common/orders';

type Params = {
  orderId: number;
};

type Data = Order;

const NAME = '/Orders/CancelOrder';
const CancelOrderEndpoint = createModernEndpoint<Params, Data>(NAME);

export { CancelOrderEndpoint };
export default CancelOrderEndpoint;
