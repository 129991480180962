import { Children, cloneElement, ReactElement, useEffect, useRef } from 'react';

import { Breadcrumb, BreadcrumbProps } from '@swe/shared/ui-kit/components/breadcrumbs/breadcrumb';

import { Scrollable, ScrollableMethods } from '@swe/shared/ui-kit/components/scrollable';

import styles from './styles.module.scss';

const Separator = <div>/</div>;

type BreadcrumbsProps = {
  children: ReactElement<BreadcrumbProps>[];
};

const Breadcrumbs = ({ children }: BreadcrumbsProps) => {
  const childrenCount = Children.count(children);
  const scrollableRef = useRef<ScrollableMethods>(null!);

  useEffect(() => {
    scrollableRef?.current.scroll({ left: 9999 });
  }, []);

  return (
    <Scrollable
      ref={scrollableRef}
      direction="horizontal"
      hideScrollbar
    >
      <div className={styles.root}>
        {Children.map(children, (child, index) => (
          <div className={styles.crumb}>
            {cloneElement(child, { isLast: index === childrenCount - 1 })}
            {index !== childrenCount - 1 && Separator}
          </div>
        ))}
      </div>
    </Scrollable>
  );
};

export type { BreadcrumbsProps, BreadcrumbProps };
export { Breadcrumbs, Breadcrumb };
