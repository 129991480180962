import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import { SnackbarService, useSnackbarAdditionalCtx, closeSnackbar } from '@swe/shared/providers/snackbar';
import { useBreakpoint } from '@swe/shared/tools/media';
import { CartIcon } from '@swe/shared/ui-kit/components/icon';

import { useCallback, useEffect, useRef } from 'react';

import { AddToCartPopup } from 'common/components/add-to-cart-popup';

import { useRouterNavigate, useRouterPathname } from 'common/router';
import { Routes } from 'common/router/constants';
import { CartItemDTO } from 'entities/cart/cart';

const NOTIFICATION_SNACK_ID = 'add_to_cart_popup';

const useNotificationNewItem = (items: CartItemDTO[]) => {
  const { mobile } = useBreakpoint();

  const quantity = items.reduce((acc, { qty }) => acc + qty, 0);

  const isCartRoute = useMatchRoute(Routes.Cart);
  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();

  const isNotificationShown = useRef(false);

  const { emitter } = useSnackbarAdditionalCtx();

  const closeHandler = useCallback(({ key }: { key?: string | number }) => {
    if (key === NOTIFICATION_SNACK_ID) {
      isNotificationShown.current = false;
    }
  }, []);

  const enterHandler = useCallback(({ key }: { key: string | number }) => {
    if (key === NOTIFICATION_SNACK_ID) {
      isNotificationShown.current = true;
    }
  }, []);

  useEffect(() => {
    emitter.on('close', closeHandler);
    emitter.on('enter', enterHandler);
    return () => {
      emitter.off('close', closeHandler);
      emitter.off('enter', enterHandler);
    };
  }, [closeHandler, emitter, enterHandler]);

  useEffect(() => {
    if (isNotificationShown.current) {
      closeSnackbar(NOTIFICATION_SNACK_ID);
    }
  }, [pathname]);

  const addToCartNotification = useCallback(
    (item: CartItemDTO) => {
      if (mobile) {
        SnackbarService.push({
          type: 'success',
          message: 'Added to Cart',
          autoHideDuration: 2000,
          icon: CartIcon,
        });
        return;
      }

      const props = {
        quantity: quantity + item.qty,
        emitter,
        product: item.product,
        isCartRoute,
        getQuantity: (variantId: string | number) => items.find((i) => String(i.variantId) === String(variantId)),
        goToCart: () => navigate(Routes.Cart),
      };

      if (isNotificationShown.current) {
        emitter.fire('update', {
          key: NOTIFICATION_SNACK_ID,
          props,
        });
      } else {
        SnackbarService.push({
          type: 'ghost',
          key: NOTIFICATION_SNACK_ID,
          autoHideDuration: 3000,
          Component: AddToCartPopup,
          props,
        });
      }
    },
    [emitter, isCartRoute, items, mobile, navigate, quantity],
  );

  return {
    addToCartNotification,
  };
};

export { useNotificationNewItem };
