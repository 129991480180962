import Button from '@swe/shared/ui-kit/components/button';
import { MapIcon } from '@swe/shared/ui-kit/components/icon';

type GeolocationControlProps = {
  onClick?: () => void;
};

const GeolocationControl = ({ onClick }: GeolocationControlProps) => {
  return (
    <Button
      size="sm"
      color="light"
      icon={MapIcon}
      onClick={onClick}
      ariaLabel="Show Your Location"
    />
  );
};

export type { GeolocationControlProps };
export { GeolocationControl };
export default GeolocationControl;
