import { ThemeJSTokens } from '@swe/shared/ui-kit/theme/provider/constants';
import { ThemePattern } from '@swe/shared/ui-kit/theme/provider/themes';
import { camelCase } from '@swe/shared/utils/string';

import { processThemeName } from 'app/theme/utils';

const getTokensServer = (tokens: any, themeName: string, patternName?: ThemePattern) => {
  const patternedThemeName = processThemeName(themeName, patternName);
  return tokens[patternedThemeName];
};

const getTokensClient = (themeName: string, patternName?: ThemePattern): ThemeJSTokens => {
  const patternedThemeName = processThemeName(themeName, patternName);

  return window.JS_TOKENS[patternedThemeName]!;
};

const CLASSNAME_PREFIX = '_theme_';
const getClassName = (themeName: string, themePattern?: ThemePattern) => {
  return CLASSNAME_PREFIX.concat(camelCase(processThemeName(themeName, themePattern)));
};

export { getTokensClient, getTokensServer, getClassName };
