import Price from '@swe/shared/ui-kit/components/price';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { ProductPriceTier } from 'entities/product/product';

type ProductTierProps = {
  tier: ProductPriceTier;
} & ComponentHasClassName;

const ProductTier = ({ className, tier }: ProductTierProps) => {
  return (
    <div className={cx(className, styles.root)}>
      <Price
        className={styles.price}
        value={tier.promoPrice ?? tier.price}
      />{' '}
      - from {tier.qty} pcs
    </div>
  );
};

export type { ProductTierProps };
export { ProductTier };
