import {
  ProductCard as ProductCardHorizontal,
  ProductCardProps as HorizontalProductCardProps,
} from 'common/components/product-card/horizontal';
import {
  ProductCard as ProductCardVertical,
  ProductCardProps as VerticalProductCardProps,
} from 'common/components/product-card/vertical';

type ProductCardProps =
  | ({ layout: 'vertical' } & VerticalProductCardProps)
  | ({ layout: 'horizontal' } & HorizontalProductCardProps);

const ProductCard = (props: ProductCardProps) => {
  switch (props.layout) {
    case 'vertical':
      return <ProductCardVertical {...props} />;
    case 'horizontal':
      return <ProductCardHorizontal {...props} />;
  }
};

export type { ProductCardProps };
export { ProductCard };
