import Link from '@swe/shared/providers/router/link';
import { usePatternTheme } from '@swe/shared/ui-kit/theme/provider';

import cx from 'clsx';

import { ReactNode, SyntheticEvent } from 'react';

import cssStyles from './styles.module.scss';

import { CommonCardProps } from 'common/components/product-card/types';
import { createProductDetailsRoute } from 'common/containers/product-details-modal/config';
import { ProductVariant } from 'entities/product/product';

type WrapperProps = Pick<CommonCardProps, 'className' | 'product' | 'externalId' | 'unavailable' | 'open'> & {
  variant: ProductVariant;
  children: ReactNode;
};
const Wrapper = ({ className, product, variant, externalId, children, unavailable, open }: WrapperProps) => {
  const id = externalId ? `link_${externalId}` : undefined;
  const handleClick = (e: SyntheticEvent<HTMLAnchorElement>) => {
    open(product, variant);
    e.preventDefault();
  };

  const href = createProductDetailsRoute(product, variant);

  const { className: patternClassName } = usePatternTheme('product-card');

  return (
    <Link
      id={id}
      className={cx(cssStyles.root, className, patternClassName, unavailable && cssStyles._unavailable)}
      href={href}
      onClick={handleClick}
      color="ghost"
    >
      {children}
    </Link>
  );
};

export type { WrapperProps };
export { Wrapper };
