import { useCurrentBreakpoint, ViewportBreakpoint } from '@swe/shared/tools/media';
import styles from '@swe/shared/ui-kit/components/container/styles.module.scss';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';

const useContainerPadding = () => {
  const bp = useCurrentBreakpoint();
  const theme = useTheme();
  const CONTAINER_PADDING: Record<ViewportBreakpoint, number> = {
    xs: parseFloat(theme.spacing.scale.sm),
    sm: parseFloat(theme.spacing.scale.lg),
    md: parseFloat(theme.spacing.scale.lg),
    lg: parseFloat(theme.spacing.scale.xl),
    xl: parseFloat(theme.spacing.scale.xl),
    xxl: parseFloat(theme.spacing.scale.xl),
  };
  return CONTAINER_PADDING[bp];
};

type ExtendToViewMode = boolean | 'modal';

const useExtendElToContainer = (
  enable: ExtendToViewMode = false,
): { offset: number; className: string | undefined } => {
  const offset = useContainerPadding();
  if (!enable) {
    return {
      offset: 0,
      className: undefined,
    };
  }
  return { offset, className: (enable === 'modal' ? styles._extendToView_modal : styles._extendToView) as string };
};

const useContainerBPDiscreteWidth = (divider = 1, containerSize: 'md' | 'none' = 'none') => {
  const {
    media: { breakpoint },
  } = useTheme();
  const padding = useContainerPadding();
  const bp = useCurrentBreakpoint();
  const CONTAINER_SIZE = {
    md: 670,
    none: 1360,
  };
  const CLIENT_WIDTH: Record<ViewportBreakpoint, number> = {
    xs: parseFloat(breakpoint.sm),
    sm: parseFloat(breakpoint.md),
    md: parseFloat(breakpoint.lg),
    lg: parseFloat(breakpoint.xl),
    xl: parseFloat(breakpoint.xxl),
    xxl: parseFloat(breakpoint.xxl),
  };
  return (Math.min(CLIENT_WIDTH[bp], CONTAINER_SIZE[containerSize] + padding * 2) - padding * 2) / divider;
};

export { useExtendElToContainer, useContainerBPDiscreteWidth };
export type { ExtendToViewMode };
export default useExtendElToContainer;
