import { formatPhone } from '@swe/shared/utils/phone';

import { useStoreInfo } from 'common/providers/config';
import { getSaleTypeMsg, seoBuilder } from 'entities/common/seo';

const useSeo = () => {
  const {
    name,
    location,
    contacts: { address, phone },
    saleTypes,
    fulfillmentTypes,
  } = useStoreInfo();
  const { city, region } = location;
  const phoneFormatted = formatPhone(phone || '');

  const builder = seoBuilder({
    after: () => {
      builder.description.add([`Visit In-Store ${address}`, `call us ${phoneFormatted}`]);
    },
  });

  return {
    builder,
    cityName: city?.name,
    name,
    saleTypesFormatted: getSaleTypeMsg(saleTypes),
    saleTypes,
    address,
    stateName: region?.name,
    phone,
    phoneFormatted,
    fulfillmentTypesFormatted: fulfillmentTypes.map((type) => (type === 'Delivery' ? 'Free Delivery' : type)),
  };
};

export { useSeo };
