import cn from 'clsx';
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react';

import CroppedText from '@swe/shared/ui-kit/components/cropped-text';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';
import { CheckIcon, MinusIcon } from '@swe/shared/ui-kit/components/icon';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { ComponentHasClassName, ComponentHasSize, Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type CheckboxSizes = Sizes<'md' | 'sm'>;

type CheckboxProps = ComponentHasClassName &
  FormControl<boolean> & {
    indeterminate?: boolean;
    label?: ReactNode;
    hovered?: boolean;
    pressed?: boolean;
    focused?: boolean;
    truncatedLines?: number;
    idPrefix?: string;
  } & ComponentHasSize<CheckboxSizes>;

const Checkbox = forwardRef<FormControlRef, CheckboxProps>(
  (
    {
      name,
      label,
      onChange,
      value = false,
      indeterminate,
      onBlur,
      error,
      disabled,
      className,
      hovered,
      pressed,
      focused,
      size = 'md',
      truncatedLines,
      idPrefix = '',
    },
    outerRef,
  ) => {
    const ref = useRef<HTMLInputElement>(null);
    const onChangeHandler = useCallback(() => onChange?.(!value), [onChange, value]);

    useImperativeHandle(outerRef, () => ({
      focus: () => ref.current?.focus(),
    }));

    return (
      <CommonWrapper
        error={error}
        className={cn(
          className,
          {
            [styles._checked]: value || indeterminate,
            [styles._disabled]: disabled,
            [styles._hovered]: hovered,
            [styles._focused]: focused,
            [styles._pressed]: pressed,
          },
          size === 'sm' && styles._size_sm,
        )}
      >
        <label
          className={styles.label}
          htmlFor={`${idPrefix}${name}`}
        >
          <div className={styles.checkbox}>
            <span className={styles.baseline}>{name}</span>
            <Locator
              as="input"
              locatorName={name}
              locatorType="checkbox"
              ref={ref}
              className={styles.input}
              type="checkbox"
              checked={value}
              name={name}
              id={`${idPrefix}${name}`}
              disabled={disabled}
              onChange={onChangeHandler}
              onBlur={onBlur}
            />
            {indeterminate ? (
              <MinusIcon
                key="indeterminate"
                className={styles.icon}
              />
            ) : value ? (
              <CheckIcon
                key="checked"
                className={styles.icon}
              />
            ) : null}
          </div>
          {label &&
            (truncatedLines ? <CroppedText truncatedLines={truncatedLines}>{label}</CroppedText> : <div>{label}</div>)}
        </label>
      </CommonWrapper>
    );
  },
);

export type { CheckboxProps, CheckboxSizes };
export { Checkbox };
export default Checkbox;
