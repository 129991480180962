import { ErrorBoundary } from '@swe/shared/ui-kit/components/error-boundary';
import { Grid } from '@swe/shared/ui-kit/components/grid';

import { Stack } from '@swe/shared/ui-kit/components/stack';

import { forwardRef, useCallback } from 'react';

import styles from './styles.module.scss';

import { ProductImage } from 'common/components/product-card/components/image';
import { ProductPrice } from 'common/components/product-card/components/price';
import { ProductTier } from 'common/components/product-card/components/product-tier';
import { ProductTitle } from 'common/components/product-card/components/title';
import { Variants } from 'common/components/product-card/components/variants';
import { GetQuantityFunc } from 'common/components/product-card/types';
import PromoSnack from 'common/components/promo-snack';
import { prepareProductForCard } from 'common/use-cases/product-card';
import { getProductVariantNextTier, getProductVariantPrice, Product } from 'entities/product/product';

type ProductRowProps = { product: Product; getQuantity: GetQuantityFunc };

const ProductRow = ({ product, getQuantity }: ProductRowProps) => {
  const { isOutOfStock, images, productName, variant, hasPriceFrom } = prepareProductForCard(product);
  const quantity = getQuantity(variant.id);
  const nextTier = getProductVariantNextTier(variant, quantity);
  const [price, oldPrice] = getProductVariantPrice(variant, quantity);

  return (
    <Stack direction="row">
      {product && (
        <ProductImage
          width={64}
          className={styles.imageWrapper}
          images={images}
          alt={`${product.name} Image`}
        />
      )}
      <Stack spacing="xxs">
        <ProductTitle
          name={productName}
          category={product.category?.name}
          brand={product.brand?.name}
          isAddToCartPopup
        />
        <Grid.Row vAlign="baseline">
          <Grid.Cell cols="content">
            {nextTier && <ProductTier tier={nextTier} />}
            <ProductPrice
              from={hasPriceFrom}
              price={price}
              oldPrice={oldPrice}
              isOutOfStock={isOutOfStock}
              isAddToCartPopup
            />
          </Grid.Cell>
          <Grid.Cell cols="content">
            <Variants variants={product.variants} />
          </Grid.Cell>
        </Grid.Row>
      </Stack>
    </Stack>
  );
};

type AddToCartPopupProps = {
  close: () => void;
  quantity?: number;
  product?: Product;
  getQuantity?: GetQuantityFunc;
  isCartRoute?: boolean;
  goToCart?: () => void;
};
const AddToCartPopup = forwardRef<HTMLDivElement, AddToCartPopupProps>(
  ({ goToCart, getQuantity = () => 0, close, quantity = 0, isCartRoute = false, product }, ref) => {
    const toCart = useCallback(() => {
      goToCart?.();
      close();
    }, [close, goToCart]);

    return (
      <ErrorBoundary fallback={() => null}>
        <PromoSnack
          ref={ref}
          close={close}
          heading="Added to Cart"
          action={
            isCartRoute ? undefined : { label: `View Cart${quantity > 0 ? ` (${quantity})` : ''}`, onClick: toCart }
          }
        >
          {product && (
            <ProductRow
              product={product}
              getQuantity={getQuantity}
            />
          )}
        </PromoSnack>
      </ErrorBoundary>
    );
  },
);

export { AddToCartPopup };
