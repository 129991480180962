import cn from 'clsx';
import { ReactNode } from 'react';

import Caption from '@swe/shared/ui-kit/components/form/caption';

import Text from '@swe/shared/ui-kit/components/text';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type CommonWrapperProps = ComponentHasClassName & {
  children: ReactNode | ReactNode[];
  note?: ReactNode;
  error?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  inline?: boolean;
};

const CommonWrapper = ({ className, children, error, note, label, disabled, inline }: CommonWrapperProps) => {
  return (
    <div
      className={cn(styles.root, className, {
        [styles._disabled]: disabled,
        [styles._labeled]: !!label,
        [styles._inline]: inline,
      })}
    >
      {label && (
        <label className={styles.controlWrapper}>
          <Text
            size="md"
            className={styles.labelText}
            as="div"
          >
            {label}
          </Text>
        </label>
      )}
      <div className={styles.controlWrapperRow}>{children}</div>
      <Caption
        animated
        visible={!!(error || note)}
        className={styles.controlCaption}
        variant={error ? 'error' : 'note'}
      >
        {error || note}
      </Caption>
    </div>
  );
};

export type { CommonWrapperProps };
export { CommonWrapper };
export default CommonWrapper;
