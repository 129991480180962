import cx from 'clsx';
import { useMemo } from 'react';

import { Column, TableProps } from '@swe/shared/ui-kit/components/datatable/types';
import Text from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';

type HeadProps<DataItem> = {
  columns: Column<DataItem>[];
  header?: TableProps<any>['header'];
};

const Head = <DataItem,>({ columns, header }: HeadProps<DataItem>) => {
  const ColGroup = useMemo(() => {
    const minContentStyles = {
      width: '1%',
      whiteSpace: 'nowrap' as const,
    };

    return (
      <colgroup>
        {columns.map((column) => (
          <col
            key={column.name}
            style={{
              ...(column.width === 'min-content' ? minContentStyles : { width: column.width }),
              minWidth: column.minWidth && `${column.minWidth}px`,
            }}
          />
        ))}
      </colgroup>
    );
  }, [columns]);

  return (
    <>
      {ColGroup}
      {header !== false && (
        <thead>
          <tr>
            {columns.map(({ name, heading, hAlign = 'left', className }) => {
              return (
                <th
                  className={cx(styles.hCell, styles[`cell_h-align_${hAlign}`])}
                  key={name}
                >
                  <Text
                    className={cx(styles.thead, className)}
                    variant="control"
                    size="lg"
                  >
                    {heading}
                  </Text>
                </th>
              );
            })}
          </tr>
        </thead>
      )}
    </>
  );
};

export type { HeadProps };
export { Head };
