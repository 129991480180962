import cx from 'clsx';

import { ComponentPropsWithoutRef, ComponentPropsWithRef, createElement, ElementType, forwardRef } from 'react';

import { useCurrentBreakpoint } from '@swe/shared/tools/media';
import useExtendElToContainer, {
  ExtendToViewMode,
} from '@swe/shared/ui-kit/components/container/utils/use-extend-el-to-container';
import {
  PropValueByMediaWithShorthand,
  useViewportBreakpointToValueMap,
} from '@swe/shared/ui-kit/components/media/utils';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { Spacing } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type BoxProps<T extends ElementType> = ComponentPropsWithoutRef<T> & {
  as?: T;
  mt?: PropValueByMediaWithShorthand<Spacing>;
  mb?: PropValueByMediaWithShorthand<Spacing>;
  pt?: PropValueByMediaWithShorthand<Spacing>;
  pb?: PropValueByMediaWithShorthand<Spacing>;
  extendToView?: ExtendToViewMode;
};

const Box = forwardRef(
  <T extends ElementType = 'div'>(
    { as, mt, mb, pt, pb, extendToView, children, ...props }: BoxProps<T>,
    forwardedRef: ComponentPropsWithRef<T>['ref'],
  ) => {
    const theme = useTheme();
    const bp = useCurrentBreakpoint();

    const mtMap = useViewportBreakpointToValueMap(mt, 'none');
    const mtVal = theme.sizing.scale[mtMap[bp]];

    const mbMap = useViewportBreakpointToValueMap(mb, 'none');
    const mbVal = theme.sizing.scale[mbMap[bp]];

    const ptMap = useViewportBreakpointToValueMap(pt, 'none');
    const ptVal = theme.sizing.scale[ptMap[bp]];

    const pbMap = useViewportBreakpointToValueMap(pb, 'none');
    const pbVal = theme.sizing.scale[pbMap[bp]];

    const { className: extendToViewClassName } = useExtendElToContainer(extendToView);

    return createElement(
      as ?? 'div',
      {
        ...props,
        ref: forwardedRef,
        className: cx(styles.root, props.className, extendToViewClassName),
        style: {
          ...props.style,
          '--sw-box-mt': mtVal,
          '--sw-box-mb': mbVal,
          '--sw-box-pt': ptVal,
          '--sw-box-pb': pbVal,
        },
      },
      children,
    );
  },
);
export { Box };
export default Box;
