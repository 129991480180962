const getTokenValue = (val: string) => parseFloat(val);

const getTokenLeftValue = (val: string) => {
  const vals = val.split(' ');
  let leftVal: string;
  switch (vals.length) {
    case 2:
      leftVal = vals[1];
      break;
    case 3:
      leftVal = vals[1];
      break;
    case 4:
      leftVal = vals[3];
      break;
    default:
      leftVal = vals[0];
      break;
  }
  return getTokenValue(leftVal);
};

const getTokenRightValue = (val: string) => {
  const vals = val.split(' ');
  let rightVal: string;
  switch (vals.length) {
    case 2:
      rightVal = vals[1];
      break;
    case 3:
      rightVal = vals[1];
      break;
    case 4:
      rightVal = vals[1];
      break;
    default:
      rightVal = vals[0];
      break;
  }
  return getTokenValue(rightVal);
};

const getTokenVerticalValue = (val: string) => getTokenLeftValue(val) + getTokenRightValue(val);

export { getTokenLeftValue, getTokenValue, getTokenVerticalValue };
