import { useEffect, useState, useRef, useCallback } from 'react';

import Cache from '@swe/shared/tools/cache';
import { EventEmitter } from '@swe/shared/tools/event-emitter';
import { geolocationToLatLng } from '@swe/shared/ui-kit/components/google-map/utils';
import { useAddress } from '@swe/shared/use-cases/use-address';

const GEO_CACHE_KEY = 'Geo/Location/Last';
const GEO_PERMISSION_DENIED_KEY = 'Geo/Permission/IsDenied';

type Geolocation = GeolocationCoordinates & {
  timestamp: number;
};

type UseGeolocationArg = {
  enableHighAccuracy?: boolean;
  maximumAge?: number;
  timeout?: number;
  isEnabled?: boolean;
  watch?: boolean;
  acquireAddress?: boolean;
  onAcquire?: (geo: Geolocation) => void;
};

const GeolocationBus = new EventEmitter<{
  onAcquire: Geolocation;
  onUpdate: Geolocation;
  onError: GeolocationPositionError;
}>();

const GEOLOCATION_ERRORS = {
  1: 'Seems like location is disabled. Enable it or enter the address manually',
  2: 'Position unavailable at the moment. Try later.',
  3: 'Position acquiring timed out. Try later.',
};

const coordinatesToGeolocation = (
  { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed }: GeolocationCoordinates,
  timestamp: number,
): Geolocation => ({
  accuracy,
  altitude,
  altitudeAccuracy,
  heading,
  latitude,
  longitude,
  speed,
  timestamp,
});
const acquireGeolocation = (): Promise<Geolocation> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords, timestamp }) => {
        const geo = coordinatesToGeolocation(coords, timestamp);

        Cache.set(GEO_CACHE_KEY, geo);
        Cache.set(GEO_PERMISSION_DENIED_KEY, false);
        GeolocationBus.fire('onAcquire', geo);
        resolve(geo);
      },
      (error) => {
        Cache.set(GEO_PERMISSION_DENIED_KEY, error.code === error.PERMISSION_DENIED);
        GeolocationBus.fire('onError', error);
        reject(new Error(GEOLOCATION_ERRORS[error.code as keyof typeof GEOLOCATION_ERRORS]));
      },
    );
  });
const handleWatchUpdate: PositionCallback = ({ coords, timestamp }) => {
  const geo = coordinatesToGeolocation(coords, timestamp);
  Cache.set(GEO_CACHE_KEY, geo);
  GeolocationBus.fire('onUpdate', geo);
};
const handleWatchError: PositionErrorCallback = (error) => {
  GeolocationBus.fire('onError', error);
};

const useGeolocation = ({
  isEnabled = true,
  watch = false,
  enableHighAccuracy = false,
  maximumAge,
  timeout,
  acquireAddress,
  onAcquire: outerOnAcquire,
}: UseGeolocationArg = {}) => {
  const [geolocation, setGeolocation] = useState<Geolocation | null>(Cache.get(GEO_CACHE_KEY));
  const [isPermissionDenied, setPermissionDenied] = useState<boolean>(Cache.get(GEO_PERMISSION_DENIED_KEY) ?? false);
  const [error, setError] = useState<GeolocationPositionError | null>(null);
  const position = geolocation ? geolocationToLatLng(geolocation) : undefined;
  const { address } = useAddress(acquireAddress ? position : undefined);
  const watchId = useRef<number | null>();

  const onAcquire = useCallback(
    (geolocation: Geolocation) => {
      setGeolocation(geolocation);
      setError(null);
      setPermissionDenied(false);
      outerOnAcquire?.(geolocation);
    },
    [outerOnAcquire],
  );
  const acquire = useCallback(async () => {
    try {
      const geo = await acquireGeolocation();
      onAcquire(geo);
    } catch (e: any) {
      setError(e);
      setPermissionDenied(e.code === e.PERMISSION_DENIED);
    }
  }, [onAcquire]);

  useEffect(() => {
    if (!navigator.geolocation) return;

    if (isEnabled) {
      if (!geolocation) void acquire();

      if (watch && !watchId.current) {
        watchId.current = navigator.geolocation.watchPosition(handleWatchUpdate, handleWatchError, {
          enableHighAccuracy,
          maximumAge,
          timeout,
        });

        return () => {
          if (watchId.current) {
            navigator.geolocation.clearWatch(watchId.current);
            watchId.current = null;
          }
        };
      }
    }
  }, [isEnabled, enableHighAccuracy, maximumAge, setError, timeout, watch, geolocation, acquire]);

  return {
    geolocation,
    position,
    address,
    error,
    acquire,
    isPermissionDenied,
  };
};

export type { Geolocation, UseGeolocationArg };
export { useGeolocation, acquireGeolocation, GeolocationBus };
