import { useCallback, useEffect, useState } from 'react';

import { EventEmitter } from '@swe/shared/tools/event-emitter';

type OnChangePayload = {
  name: string;
  value: any;
};

const Emitter = new EventEmitter<{
  onChange: OnChangePayload;
}>();
const CurrentValues = new Map<string, any>();

type ChangeFunc<VT> = (prev: VT) => VT;
type ChangePlain<VT> = VT;
type ChangeArg<VT> = ChangePlain<VT> | ChangeFunc<VT>;

const useSyncedState = <T>(name: string, initialValue: T) => {
  const [state, innerSetState] = useState<T>(CurrentValues.get(name) ?? initialValue);

  const setState = useCallback(
    (arg: ChangeArg<T>) => {
      innerSetState((prevValue) => {
        const nextState = typeof arg !== 'function' ? arg : (arg as ChangeFunc<T>)(prevValue);
        Emitter.fire('onChange', {
          name,
          value: nextState,
        });
        CurrentValues.set(name, nextState);

        return nextState;
      });
    },
    [name],
  );

  const handleOuterChange = useCallback(
    ({ name: payloadName, value }: OnChangePayload) => {
      if (name === payloadName) {
        innerSetState(value);
      }
    },
    [name],
  );

  useEffect(() => {
    Emitter.on('onChange', handleOuterChange);

    return () => {
      Emitter.off('onChange', handleOuterChange);
    };
  }, [handleOuterChange, name]);

  return [state, setState] as const;
};

export { useSyncedState };
