import { CloseReason, SnackbarKey, SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

import { useCallback, useMemo } from 'react';

import { useBreakpoint } from '@swe/shared/tools/media';

import { useAnimationSettings } from '@swe/shared/ui-kit/theme/provider';

import { useSnackbarAdditionalCtx } from './additional-provider';
import { DEFAULT_COMPONENTS, DEFAULT_PROVIDER_PROPS, DESKTOP_ANCHOR, MOBILE_ANCHOR } from './config';
import styles from './styles.module.scss';
import { SnackbarProviderProps } from './types';

const SnackbarProvider = ({ children, Components = DEFAULT_COMPONENTS }: SnackbarProviderProps) => {
  const { mobile } = useBreakpoint();
  const anchor = useMemo(() => (mobile ? MOBILE_ANCHOR : DESKTOP_ANCHOR), [mobile]);

  const { emitter, paddingTop } = useSnackbarAdditionalCtx();

  const css = useMemo(() => {
    const str = Object.entries({
      top: paddingTop,
    })
      .filter(([, value]) => !!value)
      .reduce((acc, [key, value]) => {
        return `${acc}padding-${key}: ${value};`;
      }, '');

    return `.${styles.snackbarContainerExtend} {${str}}`;
  }, [paddingTop]);

  const onClose = useCallback(
    (event: React.SyntheticEvent<any> | null, reason: CloseReason, key?: SnackbarKey) => {
      emitter.fire('close', {
        reason,
        key,
      });
    },
    [emitter],
  );

  const onEntered = useCallback(
    (node: HTMLElement, isAppearing: boolean, key: SnackbarKey) => {
      emitter.fire('entered', {
        isAppearing,
        key,
      });
    },
    [emitter],
  );
  const onEnter = useCallback(
    (node: HTMLElement, isAppearing: boolean, key: SnackbarKey) => {
      emitter.fire('enter', {
        isAppearing,
        key,
      });
    },
    [emitter],
  );

  const animation = useAnimationSettings();

  return (
    <NotistackSnackbarProvider
      {...DEFAULT_PROVIDER_PROPS}
      transitionDuration={animation.enabled ? undefined : 0}
      anchorOrigin={anchor}
      Components={Components}
      dense={mobile}
      onClose={onClose}
      onEntered={onEntered}
      onEnter={onEnter}
      classes={{
        root: styles.snackbarContainer,
        containerRoot: `${styles.snackbarContainerExtend} ${styles.rootContainer}`,
      }}
    >
      <style dangerouslySetInnerHTML={{ __html: css }} />
      {children}
    </NotistackSnackbarProvider>
  );
};

export { SnackbarProvider };
export default SnackbarProvider;
