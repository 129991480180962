import cn from 'clsx';
import React, { ComponentType, forwardRef } from 'react';

import Button, { ButtonSize } from '@swe/shared/ui-kit/components/button';
import { CloseIcon, IconProps } from '@swe/shared/ui-kit/components/icon';
import {
  ComponentHasChildren,
  ComponentHasClassName,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type ChipsSize = Sizes<'lg' | 'md'>;

export type ChipsProps = {
  icon?: ComponentType<IconProps>;
  rightIcon?: ComponentType<IconProps>;
  onClick?: () => void;
  onClose?: () => void;
  hovered?: boolean;
  pressed?: boolean;
  disabled?: boolean;
  focused?: boolean;
  // non figma state
  active?: boolean;
} & ComponentHasSize<ChipsSize> &
  ComponentHasClassName &
  ComponentHasChildren;

const CHIPS_TO_BUTTON_SIZE: Record<ChipsSize, ButtonSize> = {
  lg: 'sm',
  md: 'xs',
};

export const Chips = forwardRef(
  (
    {
      className,
      size = 'lg',
      children,
      icon: Icon,
      rightIcon: RightIcon,
      onClick,
      onClose,
      hovered,
      pressed,
      disabled,
      focused,
      active,
    }: ChipsProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          styles.root,
          styles[`_size_${size}`],
          {
            [styles._hovered]: hovered,
            [styles._pressed]: pressed || active,
            [styles._disabled]: disabled,
            [styles._focused]: focused,
            [styles._clickable]: !!onClick,
          },
          className,
        )}
        tabIndex={-1}
        onClick={onClick}
      >
        {Icon && <Icon className={styles.icon} />}
        {children && <div className={styles.text}>{children}</div>}
        {RightIcon && <RightIcon className={styles.icon} />}
        {onClose && (
          <div className={styles.closeButtonWrapper}>
            <Button
              className={styles.closeButton}
              variant="link"
              color={active ? 'light' : 'neutral'}
              size={CHIPS_TO_BUTTON_SIZE[size]}
              icon={CloseIcon}
              onClick={onClose}
              disabled={disabled}
              ariaLabel="Close"
            />
          </div>
        )}
      </div>
    );
  },
);

export default Chips;
