import { useState, useEffect, useCallback, useMemo } from 'react';

const useCountdown = (delay: number, id: number, onComplete?: () => void) => {
  const [time, setTime] = useState(delay);

  useEffect(() => {
    setTime(delay);
    const initialDate = new Date().valueOf();

    const tick = () => {
      const timePassed = new Date().valueOf() - initialDate;
      setTime(Math.max(delay - timePassed, 0));
    };

    const intId = setInterval(tick, 1000);

    return () => clearInterval(intId);
  }, [id, delay]);

  useEffect(() => {
    if (onComplete && time === 0) {
      onComplete();
    }
  }, [time, onComplete]);

  return time;
};

const useInterval = (callback: () => void, _delay: number | null | undefined | false, initialPaused = false) => {
  const [paused, setPaused] = useState(initialPaused);
  const delay = paused ? null : _delay;

  useEffect(() => {
    if (typeof delay !== 'number') {
      return;
    }

    const timeID = setInterval(callback, delay);
    return () => clearInterval(timeID);
  }, [callback, delay]);

  const pause = useCallback(() => setPaused(true), []);
  const resume = useCallback(() => setPaused(false), []);

  return useMemo(() => ({ pause, resume }), [pause, resume]);
};

const useIntervalCount = (
  delay: Parameters<typeof useInterval>[1],
  options: {
    from?: number;
    to?: number;
    infinite?: boolean;
  } = {},
) => {
  const { from = 0, to = Number.MAX_SAFE_INTEGER, infinite = true } = options;
  const [count, setCount] = useState(from);
  const [isCountDone, setIsCountDone] = useState(false);
  const increment = useCallback(() => setCount((count) => (count === to ? from : count + 1)), [from, to]);

  useInterval(increment, isCountDone ? null : delay);

  useEffect(() => {
    if (infinite) return;
    if (count === to) setIsCountDone(true);
  }, [count, infinite, to]);

  return count;
};

export { useCountdown, useInterval, useIntervalCount };
