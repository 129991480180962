import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { FullShopFulfillmentType } from 'entities/shop/info';

type SetAccountFulfillmentPreferencesParams = {
  fulfillmentType: FullShopFulfillmentType;
  deliveryAddress?: string | null;
  deliverySecondaryAddress?: string;
  deliveryLatitude?: number | null;
  deliveryLongitude?: number | null;
  preferencesStoreId: number;
  platformOs: PlatformOs;
};

const NAME = '/Account/SetAccountFulfillmentPreferences';
const SetAccountFulfillmentPreferencesEndpoint = createModernEndpoint<
  SetAccountFulfillmentPreferencesParams,
  undefined,
  APIErrorExtended
>(NAME);

export type { SetAccountFulfillmentPreferencesParams };
export { SetAccountFulfillmentPreferencesEndpoint };
