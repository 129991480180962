import { setGlobalHeaders } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { setCookie } from '@swe/shared/tools/cookie';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { addDays } from '@swe/shared/utils/date';
import { defaults, omitBy } from '@swe/shared/utils/object';

import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import { LAST_STORE_COOKIE_NAME } from 'app/config';
import GetShopConfigEndpoint from 'endpoints/shop/get-shop-config';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { mapShopConfigDTOToEntity, SHOP_CONFIG_DEFAULTS, ShopConfig, ShopConfigDTO } from 'entities/shop/config';
import { ShopInfo } from 'entities/shop/info';

const context = createContext<{ storeConfig: ShopConfig; storeInfo: ShopInfo }>(null!);
const ConfigContextProvider = context.Provider;

type StoreConfigProviderProps = ComponentHasChildren<ReactNode | ((config: ShopConfig) => ReactNode)> & {
  storeId: EntityID<number>;
};

const StoreConfigProvider = ({ children, storeId: StoreId }: StoreConfigProviderProps) => {
  setGlobalHeaders({ StoreId });
  const { data: storeConfig } = GetShopConfigEndpoint.useRequest({});
  const { data: storeInfo } = GetShopInfoEndpoint.useRequest();

  useEffect(() => {
    if (storeConfig) {
      setCookie(
        LAST_STORE_COOKIE_NAME,
        { id: storeConfig.id, url: storeConfig.url, routeName: storeConfig.routeName },
        { expires: addDays(new Date(), 365) },
      );
    }
  }, [storeConfig]);

  const value = useMemo(
    () => ({
      storeConfig: {
        ...defaults(
          omitBy(mapShopConfigDTOToEntity(storeConfig as ShopConfigDTO), (val) => val === null),
          SHOP_CONFIG_DEFAULTS,
        ),
      } as ShopConfig,
      storeInfo: storeInfo!,
    }),
    [storeConfig, storeInfo],
  );

  return (
    <ConfigContextProvider value={value}>
      {typeof children === 'function' ? children(value.storeConfig) : children}
    </ConfigContextProvider>
  );
};

const useStoreConfig = () => useContext(context).storeConfig;
const useStoreInfo = () => useContext(context).storeInfo;

export { useStoreConfig, StoreConfigProvider, useStoreInfo };
export default StoreConfigProvider;
