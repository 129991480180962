import { pick } from '@swe/shared/utils/object';

import { StoreDeliveryZone } from 'entities/shop/info';

class DeliveryZone {
  constructor(private readonly _zone: StoreDeliveryZone) {}

  public get settings() {
    return pick(this._zone, ['fee', 'minOrderAmount', 'schedule', 'freeShippingPriceThreshold']);
  }

  public get geoJson() {
    return this._zone.geometry;
  }
}

export { DeliveryZone };
