import { useKeyPress } from '@swe/shared/hooks/use-key-press';
import { usePending } from '@swe/shared/hooks/use-pending';
import { useBreakpoint } from '@swe/shared/tools/media';
import Container from '@swe/shared/ui-kit/components/container';

import { ChatIcon, ChevronDownIcon, ChevronUpIcon, LoaderIcon, PhoneIcon } from '@swe/shared/ui-kit/components/icon';
import { List } from '@swe/shared/ui-kit/components/list';
import { Popover } from '@swe/shared/ui-kit/components/popover';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPhone } from '@swe/shared/utils/phone';
import cx from 'clsx';

import styles from './index.module.scss';

import { ExternalNav } from 'entities/shop/config';

type SupHeaderProps = ComponentHasClassName & {
  tel?: string;
  nav?: ExternalNav;
  onChatClick?: () => void;
};

const SupHeader = ({ className, tel, nav, onChatClick }: SupHeaderProps) => {
  const { desktop } = useBreakpoint();

  const [onChat, pendingChat] = usePending(onChatClick);

  const bindChat = useKeyPress({
    key: 'Enter',
    onKeyDown: onChat,
  });

  if (!desktop || !nav || nav.length === 0) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      <Container>
        <div className={styles.row}>
          {nav.map((item) => {
            return 'href' in item ? (
              <a
                className={styles.navItem}
                href={item.href}
                key={item.href}
              >
                {item.label}
              </a>
            ) : (
              <Popover
                className={styles.navItem}
                placement="bottom-start"
                content={<List<string> items={item.children} />}
                key={item.label}
                tabbable
              >
                {({ isOpen }) => (
                  <>
                    <span tabIndex={0}>{item.label}</span>
                    {isOpen ? <ChevronUpIcon size="sm" /> : <ChevronDownIcon size="sm" />}
                  </>
                )}
              </Popover>
            );
          })}
          <div className={styles.contacts}>
            {onChat && (
              <span
                className={cx(styles.navItem, pendingChat && styles.navItem_pending)}
                tabIndex={0}
                onClick={onChat}
                {...bindChat}
              >
                {pendingChat ? <LoaderIcon size="md" /> : <ChatIcon size="md" />}
                Chat
              </span>
            )}
            {tel && (
              <a
                className={styles.navItem}
                href={`tel:${tel}`}
              >
                <PhoneIcon size="md" />
                Call {formatPhone(tel)}
              </a>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export type { SupHeaderProps };
export { SupHeader };
export default SupHeader;
