import { Badge, ThemedBadgeProps } from '@swe/shared/ui-kit/components/badge';
import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';

import styles from './styles.module.scss';

type ProductBadgesProps = {
  badges: ThemedBadgeProps[];
};

const ProductBadges = ({ badges }: ProductBadgesProps) => {
  return (
    <div className={styles.root}>
      {badges.map((b, idx) => {
        const badgeContent = (
          <Badge
            key={idx}
            size="md"
            {...b}
          />
        );

        return b.themePattern ? (
          <PatternThemeProvider
            key={idx}
            name={b.themePattern}
          >
            {badgeContent}
          </PatternThemeProvider>
        ) : (
          badgeContent
        );
      })}
    </div>
  );
};

export type { ProductBadgesProps };
export { ProductBadges };
export default ProductBadges;
