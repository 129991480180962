import Link from '@swe/shared/providers/router/link';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import styles from './styles.module.scss';

import { buildNamedId } from 'common/router/utils';
import { useBadges } from 'common/use-cases/product-card';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import ProductBadges from 'domains/product/components/product-details/components/badges';
import { TitleSkeleton } from 'domains/product/components/product-details/components/title/skeleton';
import { getProductName, Product, ProductVariant } from 'entities/product/product';

type ProductTitleProps = {
  product: Product;
  variant: ProductVariant;
};

const ProductTitle = ({ product, variant }: ProductTitleProps) => {
  const productName = getProductName(product);
  const badges = useBadges(product, variant, true);
  const { buildCatalogLink } = useCatalogQuery();

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        {badges.length > 0 && <ProductBadges badges={badges} />}
        <div className={styles.manufacturer}>
          {product.category && (
            <Link
              href={buildCatalogLink({
                filters: null,
                subPath: [buildNamedId(product.category.name, product.category.tagId)],
                searchTerm: null,
                page: null,
              })}
            >
              #{product.category?.name}
            </Link>
          )}
          {product.brand?.name && (
            <>
              {' '}
              by{' '}
              <Link
                href={buildCatalogLink({
                  filters: product.brand?.filter ?? null,
                  subPath: null,
                  searchTerm: null,
                  page: null,
                })}
              >
                #{product.brand?.name}
              </Link>
            </>
          )}
        </div>
      </div>
      <h1 className={styles.title}>{productName}</h1>
    </div>
  );
};

const ProductTitleSkeletonized = withSkeleton(ProductTitle, TitleSkeleton, ['product', 'variant']);

export type { ProductTitleProps };
export { ProductTitle, ProductTitleSkeletonized };
export default ProductTitle;
