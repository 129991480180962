import cn from 'clsx';
import { ComponentType, useCallback } from 'react';

import { IconProps } from '@swe/shared/ui-kit/components/icon';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import {
  ComponentHasChildren,
  ComponentHasClassName,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type TagSize = Sizes<'lg' | 'md' | 'sm'>;

export type TagType = 'default' | 'alt';

export type TagProps = {
  icon?: ComponentType<IconProps>;
  rightIcon?: ComponentType<IconProps>;
  onToggle?: (nextActive: boolean) => void;
  active?: boolean;
  hovered?: boolean;
  pressed?: boolean;
  disabled?: boolean;
  focused?: boolean;
  type?: TagType;
  name: string;
} & ComponentHasSize<TagSize> &
  ComponentHasClassName &
  ComponentHasChildren;

export const Tag = ({
  className,
  size = 'lg',
  children,
  icon: Icon,
  rightIcon: RightIcon,
  onToggle,
  active,
  hovered,
  pressed,
  disabled,
  type = 'default',
  focused,
  name,
}: TagProps) => {
  const clickHandler = useCallback(() => onToggle?.(!active), [onToggle, active]);

  return (
    <Locator
      locatorName={name}
      locatorType="tag"
      className={cn(
        styles.root,
        styles[`_size_${size}`],
        {
          [styles._interactive]: !!onToggle,
          [styles._active]: active,
          [styles._hovered]: hovered,
          [styles._pressed]: pressed,
          [styles._disabled]: disabled,
          [styles._focused]: focused,
          [styles[`_type_${type}`]]: type,
        },
        className,
      )}
      tabIndex={-1}
      onClick={onToggle ? clickHandler : undefined}
    >
      {Icon && <Icon className={styles.icon} />}
      {children && (
        <div className={styles.text}>
          <div>{children}</div>
        </div>
      )}
      {RightIcon && <RightIcon className={styles.icon} />}
    </Locator>
  );
};

export default Tag;
