import cx from 'clsx';
import range from 'lodash/range';

import { useMemo } from 'react';

import { ProgressCommonProps } from '@swe/shared/ui-kit/components/progress/types';

import styles from './styles.module.scss';

type ProgressDiscreteProps = ProgressCommonProps & {
  divisions: number;
};

enum Boundary {
  Low = 0.4,
  Medium = 0.8,
  High = 1,
}

enum Color {
  Low = 'low',
  Medium = 'med',
  High = 'high',
}

const COLORS = [
  {
    boundary: Boundary.Low,
    color: Color.Low,
  },
  {
    boundary: Boundary.Medium,
    color: Color.Medium,
  },
  {
    boundary: Boundary.High,
    color: Color.High,
  },
];

const ProgressDiscrete = ({ style, className, value, divisions = 5 }: ProgressDiscreteProps) => {
  const color = useMemo(
    () => COLORS.find(({ boundary }) => value / divisions <= boundary) ?? COLORS[COLORS.length - 1],
    [divisions, value],
  );
  const bars = useMemo(
    () => range(0, divisions).map((barValue) => (barValue + 1) / divisions <= value / divisions),
    [divisions, value],
  );

  return (
    <div
      style={style}
      className={cx(styles.root, styles[`_color_${color.color}`], className)}
    >
      {bars.map((isFilled, idx) => (
        <div
          key={idx}
          className={cx(styles.bar, isFilled && styles.bar_filled)}
        />
      ))}
    </div>
  );
};

export type { ProgressDiscreteProps };
export { ProgressDiscrete };
