import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import Text from '@swe/shared/ui-kit/components/text';

import { ProductSection } from 'common/components/product-section';
import { SectionSkeleton } from 'domains/product/components/product-details/common/section-skeleton';
import { Product } from 'entities/product/product';

type ProductDescriptionProps = {
  product: Product;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProductDescription = ({ product }: ProductDescriptionProps) => {
  if (!product.description) {
    return null;
  }

  return (
    <ProductSection
      title="Product Description"
      expandable
      defaultExpanded={false}
    >
      <Text size="lg">{product.description}</Text>
    </ProductSection>
  );
};

const ProductDescriptionSkeletonized = withSkeleton(ProductDescription, SectionSkeleton, ['product']);

export type { ProductDescriptionProps };
export { ProductDescription, ProductDescriptionSkeletonized };
export default ProductDescription;
