import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = undefined;
type Data = {
  nextTryInSeconds: number;
  code: string | null;
};
const NAME = '/Registration/ResendEmailVerificationCode';
const SignupResendVerifyEmailEndpoint = createModernEndpoint<Params, Data, APIErrorExtended>(NAME);

export default SignupResendVerifyEmailEndpoint;
