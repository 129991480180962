import { ModalDesktopDefault, ModalDesktopDefaultProps } from '@swe/shared/ui-kit/components/modal/desktop/common';

type ModalDesktopProps = { variant: 'default' } & ModalDesktopDefaultProps;

type PropsByVariant = {
  default: ModalDesktopDefaultProps;
};

const ModalDesktop = ({ variant, ...props }: ModalDesktopProps) => {
  switch (variant) {
    case 'default':
      return <ModalDesktopDefault {...props} />;
    default:
      return null;
  }
};

export type { ModalDesktopProps, PropsByVariant };
export { ModalDesktop };
export default ModalDesktop;
