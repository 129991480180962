import { useMemo } from 'react';

import { ModalContextProvider, ModalContextType } from '@swe/shared/ui-kit/components/modal/context';
import { ModalMobileDefault, ModalMobileDefaultProps } from '@swe/shared/ui-kit/components/modal/mobile/common';
import {
  ModalMobileFullscreen,
  ModalMobileFullscreenProps,
} from '@swe/shared/ui-kit/components/modal/mobile/fullscreen';
import { noop } from '@swe/shared/utils/func';

type ModalMobileProps =
  | ({ variant: 'default' } & ModalMobileDefaultProps)
  | ({ variant: 'fullscreen' } & ModalMobileFullscreenProps);

type PropsByVariant = {
  default: ModalMobileDefaultProps;
  fullscreen: ModalMobileFullscreenProps;
};

const ModalMobile = ({ variant, ...props }: ModalMobileProps) => {
  const ctx = useMemo<ModalContextType>(() => ({ setHeaderOffsetBlock: noop }), []);

  switch (variant) {
    case 'default':
      return (
        <ModalContextProvider value={ctx}>
          <ModalMobileDefault {...props} />
        </ModalContextProvider>
      );
    case 'fullscreen':
      return (
        <ModalContextProvider value={ctx}>
          <ModalMobileFullscreen {...props} />
        </ModalContextProvider>
      );
    default:
      return null;
  }
};

export type { ModalMobileProps, PropsByVariant };
export { ModalMobile };
export default ModalMobile;
