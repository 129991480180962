import { useEffect } from 'react';

const useMapListener = (map: any, event: string, listener: any) => {
  useEffect(() => {
    if (map && listener) {
      const gListener = google.maps.event.addListener(map, event, listener);

      return () => {
        google.maps.event.removeListener(gListener);
      };
    }
  }, [map, listener, event]);
};

export { useMapListener };
