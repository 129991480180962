import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { FullShopFulfillmentType } from 'entities/shop/info';

type GuestMedicalSignInParams = {
  firstName?: string;
  lastName?: string;
  email: string;
  dateOfBirth?: DateISOString;
  phone?: string;
  platformOs: PlatformOs;
  fulfillmentType: FullShopFulfillmentType;
};
type Data =
  | undefined
  | {
      nextTryIn: number;
      code?: string;
      id: string;
    };

const NAME = '/GuestAccount/MedicalSignIn';
const GuestMedicalSignInEndpoint = createModernEndpoint<GuestMedicalSignInParams, Data>(NAME);

export { GuestMedicalSignInEndpoint };
export type { GuestMedicalSignInParams };
