import cx from 'clsx';

import React, { useCallback } from 'react';

import { PureButton } from '@swe/shared/ui-kit/components/pure-button';
import { Colors, ComponentHasClassName, Sizes } from '@swe/shared/ui-kit/types/common-props';

import { range } from '@swe/shared/utils/array';

import styles from './styles.module.scss';

type ProgressBarDashedProps = {
  value: number;
  pieces: number;
  fillPrevious?: boolean;
  size?: Sizes<'xs' | 'sm' | 'md'>;
  color?: Colors<'primary' | 'neutral'>;
  onChange?: (next: number) => void;
} & ComponentHasClassName;

const ProgressBarDashed = ({
  size = 'md',
  value,
  pieces,
  fillPrevious,
  color = 'primary',
  className,
  onChange,
}: ProgressBarDashedProps) => {
  const isActive = useCallback(
    (val: number) => (fillPrevious ? val <= value - 1 : val === value),
    [fillPrevious, value],
  );

  return (
    <div className={cx(styles.root, styles[`_color_${color}`], styles[`_size_${size}`], className)}>
      {range(pieces).map((val) => (
        <PureButton
          key={val}
          className={cx(styles.item, isActive(val) && styles.item_active, onChange && styles.item_interactive)}
          onClick={() => onChange?.(val)}
        />
      ))}
    </div>
  );
};

export type { ProgressBarDashedProps };
export { ProgressBarDashed };
export default ProgressBarDashed;
