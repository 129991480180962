import cx from 'clsx';
import { SnackbarContent, closeSnackbar } from 'notistack';
import { ComponentType, forwardRef, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { SnackbarService } from '@swe/shared/providers/snackbar/service';
import { SnackColor, SnackProps } from '@swe/shared/providers/snackbar/types';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Alert } from '@swe/shared/ui-kit/components/alert';
import { CheckIcon, CloseIcon, IconProps } from '@swe/shared/ui-kit/components/icon';

import Loader from '@swe/shared/ui-kit/components/loader';

import { Colors } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

const Snack = forwardRef<HTMLDivElement, SnackProps>(
  (
    {
      id,
      message,
      color,
      icon,
      heading,
      style,
      dismissible = true,
      truncate,
      persist,
      className,
      asyncTask,
      onClick,
      isDangerousMessage,
    },
    ref,
  ) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [complete, setComplete] = useState(false);

    const handleClose = useCallback(
      (e?: SyntheticEvent<HTMLButtonElement, Event>) => {
        e?.stopPropagation();
        closeSnackbar(id);
      },
      [id],
    );
    const handleClick = useCallback(() => {
      if (onClick) {
        onClick();
        handleClose();
      }
    }, [handleClose, onClick]);
    const handleTask = useCallback(async () => {
      if (!asyncTask) return;

      setLoading(true);
      try {
        await asyncTask();
        setComplete(true);
      } catch (e) {
        const err = (e as any)?.message ?? (e as any).toString();

        setError(err);
        SnackbarService.push({
          type: 'danger',
          heading: 'Error',
          message: err,
        });
      } finally {
        setLoading(false);
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    }, [asyncTask, handleClose]);

    useEffect(() => {
      void handleTask();
    }, [handleTask]);

    const snackIcon = useMemo(() => {
      if (error) {
        return CloseIcon as unknown as undefined;
      }

      if (complete) {
        return CheckIcon as unknown as undefined;
      }

      return icon as undefined;
    }, [complete, error, icon]);
    const canBeClosed = asyncTask ? !isLoading : dismissible || persist;

    const { mobile } = useBreakpoint();

    return (
      <SnackbarContent
        ref={ref}
        role="alert"
        className={cx(styles.root, onClick && styles._interactive, className)}
        style={style}
        onClick={handleClick}
      >
        <Alert
          className={styles.alert}
          title={heading as undefined}
          icon={isLoading ? Loader : snackIcon}
          onClose={canBeClosed ? handleClose : undefined}
          truncate={truncate}
          color={color as Colors<'success' | 'danger' | 'warning' | 'neutral'>}
          size={mobile ? 'sm' : 'md'}
        >
          {isDangerousMessage ? <div dangerouslySetInnerHTML={{ __html: message! }} /> : message}
        </Alert>
      </SnackbarContent>
    );
  },
);

const withColor = (Component: ComponentType<SnackProps>, color: SnackColor, defaultIcon: ComponentType<IconProps>) =>
  forwardRef<HTMLDivElement, Omit<SnackProps, 'color'>>(({ icon, ...restProps }, ref) => (
    <Snack
      {...restProps}
      ref={ref}
      color={color}
      icon={icon ?? defaultIcon}
    />
  ));

export { Snack, withColor };
export default Snack;
