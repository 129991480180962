import { DeliveryZone } from './DeliveryZone';

import { ShopInfo } from 'entities/shop/info';

class Store {
  constructor(private readonly store: ShopInfo) {}

  public get data() {
    return this.store;
  }

  public get location() {
    return {
      id: this.store.id,
      position: this.store.location.coords,
    };
  }

  public get deliveryZones() {
    const dz = this.store.deliveryZones ?? [];
    return dz.map((zone) => new DeliveryZone(zone));
  }
}

export { Store };
