import { Link } from '@swe/shared/providers/router/link';
import { Container } from '@swe/shared/ui-kit/components/container';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';

import React from 'react';

import styles from './styles.module.scss';

import { FooterProps, getIcon } from 'common/containers/footer/variants/constants';

const Footer = ({
  externalNav = [],
  // logoSrc,
  // title,
  // logoUrl,
  // slogan,
  socialMedia = [],
  policies = [],
  disclaimers: _disclaimers = [],
  license,
  copyright,
}: FooterProps) => {
  const disclaimers = [..._disclaimers, license].filter(Boolean);
  const theme = useTheme();

  return (
    <PatternThemeProvider
      name="footer"
      className={styles.root}
    >
      {externalNav?.length > 0 && (
        <div className={styles.navWrapper}>
          <Container className={styles.nav}>
            {externalNav.map((navItem) => {
              return (
                <div
                  className={styles.navColumn}
                  key={navItem.label}
                >
                  {'href' in navItem ? (
                    <a
                      className={styles.navColumnHeading}
                      href={navItem.href}
                    >
                      {navItem.label}
                    </a>
                  ) : (
                    <div className={styles.navColumnHeading}>{navItem.label}</div>
                  )}
                  {'children' in navItem
                    ? navItem.children.map((navLink) => (
                        <Link
                          className={styles.navLink}
                          href={navLink.href}
                          key={navLink.href}
                        >
                          {navLink.label}
                        </Link>
                      ))
                    : undefined}
                </div>
              );
            })}
          </Container>
        </div>
      )}
      {disclaimers?.length > 0 && (
        <div className={styles.disclaimersWrapper}>
          <Container className={styles.disclaimers}>
            {disclaimers.map((disclaimer, idx) => (
              <div key={idx}>{disclaimer}</div>
            ))}
          </Container>
        </div>
      )}
      <div className={styles.bottomWrapper}>
        <Container className={styles.bottom}>
          <div className={styles.policies}>
            {policies.map((policy, idx) => (
              <>
                <Link
                  href={policy.href}
                  key={idx}
                >
                  {policy.title}
                </Link>
                {idx < policies.length - 1 && <div className={styles.policyDivider} />}
              </>
            ))}
          </div>
          <div className={styles.socialMedia}>
            {socialMedia.map((sm) => {
              const Icon = getIcon(sm.type, theme.footer.social.variant.switch as 'mono' | 'color');
              return (
                <a
                  href={sm.href}
                  key={sm.href}
                  aria-label={sm.type}
                >
                  <Icon size={parseInt(theme.footer.social.size, 10)} />
                </a>
              );
            })}
          </div>
          <div className={styles.copyright}>{copyright}</div>
        </Container>
      </div>
    </PatternThemeProvider>
  );
};

export { Footer };
export default Footer;
