import { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import { useDeepCompareMemo } from '@swe/shared/hooks/use-deep-compare';
import useRerender from '@swe/shared/hooks/use-rerender';
import { Storage } from '@swe/shared/providers/persist-state/constants';
import { Serializable } from '@swe/shared/types/json';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isEqual } from '@swe/shared/utils/object';

type PersistStateStorageContext = {
  storages: Record<string, Storage>;
};

type PersistStateStorageProviderProps = ComponentHasChildren & Pick<PersistStateStorageContext, 'storages'>;

const persistStateContext = createContext<PersistStateStorageContext>(null!);

const PersistStateStorageProvider = ({ children, storages }: PersistStateStorageProviderProps) => {
  return (
    <persistStateContext.Provider value={useDeepCompareMemo(() => ({ storages }), [storages])}>
      {children}
    </persistStateContext.Provider>
  );
};

const usePersistState = <T extends Serializable = Serializable>(
  storageName: string,
  key: string | null,
  defaultValue: T,
) => {
  const { storages } = useContext(persistStateContext);
  const storage = storages[storageName];
  if (!storage) {
    throw new Error(`Storage '${storageName}' doesn't exist`);
  }

  const storedItem = key !== null ? (storage.getItem(key) as T) : null;
  const value: T = storedItem !== null ? storedItem : defaultValue;
  const valueRef = useRef(value);
  valueRef.current = value;
  const rerender = useRerender();
  const rerenderIfCurrentKey = useCallback(
    ({ key: _key, newValue }: { key: string; newValue: Serializable }) => {
      if (_key === key && !isEqual(valueRef.current, newValue)) {
        rerender();
      }
    },
    [key, rerender],
  );
  useEffect(() => {
    storage.on('change', rerenderIfCurrentKey);
    return () => storage.off('change', rerenderIfCurrentKey);
  }, [storage, rerenderIfCurrentKey]);

  const setValue = useCallback(
    (_newValue: T | ((currentValue: T) => T)) => {
      if (key === null) {
        return;
      }
      let newValue: T;
      if (typeof _newValue === 'function') {
        newValue = _newValue(valueRef.current);
      } else {
        newValue = _newValue;
      }
      if (valueRef.current !== newValue) {
        storage.setItem(key, newValue);
      }
    },
    [key, storage],
  );

  return [value, setValue] as const;
};

export { PersistStateStorageProvider, usePersistState };
