import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Messages } from '@swe/shared/network/transport/signalr/types';
import { WebsocketHub, WebsocketHubEndpoint } from '@swe/shared/network/transport/signalr/WebsocketHub';

type Params = Record<string, string>;

type UseHubOptions<MT, RPT, PT> = {
  type: MT;
  onReceive: (payload: RPT) => void;
  params?: PT;
  isActive?: boolean;
};

const REGISTRY = new Map<WebsocketHubEndpoint, WebsocketHub<any>>();

const createHub = <HubMessages extends Messages>(endpoint: WebsocketHubEndpoint) => {
  return <MessageType extends keyof HubMessages>({
    type,
    onReceive,
    params = {} as Params,
    isActive = true,
  }: UseHubOptions<MessageType, HubMessages[MessageType], Params>) => {
    const finalEndpoint = useMemo(() => {
      const searchParams = new URLSearchParams(params);

      return `${endpoint}?${searchParams.toString()}` as WebsocketHubEndpoint;
    }, [params]);
    const socket = useRef<WebsocketHub<HubMessages> | undefined>(REGISTRY.get(finalEndpoint));

    const unsub = useCallback(() => socket.current?.unsubscribe(type, onReceive), [onReceive, type]);
    const disconnect = useCallback(() => socket.current?.close(), []);

    useEffect(() => {
      if (!socket.current) {
        socket.current = new WebsocketHub<HubMessages>(finalEndpoint, {
          onDestroy: () => REGISTRY.delete(finalEndpoint),
        });
        REGISTRY.set(finalEndpoint, socket.current);
      }
    }, [finalEndpoint]);

    useEffect(() => {
      if (isActive) {
        socket.current?.subscribe(type, onReceive);

        return () => {
          unsub();
        };
      }
    }, [isActive, onReceive, type, unsub]);

    useEffect(() => {
      if (socket.current && !socket.current.isActive && isActive) {
        void socket.current.open();
      }
    }, [isActive]);

    return {
      unsubscribe: unsub,
      disconnect,
    };
  };
};

export { createHub };
