enum FilterKey {
  Category = 'category',
  Carousel = 'category',
  Discount = 'category',
  SubCategory = 'subcategory',
  StrainPrevalence = 'strainPrevalence',
  QuickFilter = 'quickFilter',
}

export { FilterKey };
