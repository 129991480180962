import Button, { ButtonColor } from '@swe/shared/ui-kit/components/button';
import { CartIcon } from '@swe/shared/ui-kit/components/icon';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import { DisableOrdering } from 'common/containers/disable-ordering';
import { useCartQuantity } from 'common/providers/cart/context';

type CartProps = {
  onClick?: () => void;
  active?: boolean;
};

const Cart = ({ onClick, active }: CartProps) => {
  const quantity = useCartQuantity();
  const theme = useTheme();

  return (
    <DisableOrdering>
      <Button
        onClick={onClick}
        icon={CartIcon}
        badge={quantity || ''}
        size="sm"
        color={active ? (theme.header.topButtonActive as ButtonColor) : (theme.header.topButton as ButtonColor)}
        ariaLabel="Cart"
      />
    </DisableOrdering>
  );
};

export { Cart };
