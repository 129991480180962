import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useId } from 'react';
import { FieldValues, useForm, FormProvider, UseFormReturn, DeepPartial, Path } from 'react-hook-form';

import { ObjectSchema } from 'yup';

import { useMounted } from '@swe/shared/hooks/use-mounted';
import { useIsIOS } from '@swe/shared/tools/media';
import { CustomFormContextProvider, useFormContext } from '@swe/shared/ui-kit/components/form/core/context';
import Listener from '@swe/shared/ui-kit/components/form/core/listeners';
import { AugmentedFormContext } from '@swe/shared/ui-kit/components/form/core/types';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

type FormBuilderProps<
  FormValues extends FieldValues,
  ValidationContext extends Record<string, any> = Record<string, any>,
> = {
  autofocus?: boolean | Path<FormValues>;
  initialValues?: DeepPartial<FormValues>;
  validationSchema: ObjectSchema<any>;
  disabled?: boolean;
  ref?: ((node: BuilderContext<FormValues>) => void) | React.RefObject<UseFormReturn<FormValues>>;
  validateOnInit?: boolean;
  id?: string;
  alwaysEmitOnChange?: boolean;
  onSubmit?: (values: FormValues) => void | Promise<void>;
  onChange?: (values: FormValues) => void;
  validationContext?: ValidationContext;
  children: React.ReactNode | ((context: AugmentedFormContext<FormValues>) => React.ReactNode);
  name: string;
} & ComponentHasClassName;

const FormChildren = <ValuesT extends FieldValues>({ render }: { render: FormBuilderProps<ValuesT>['children'] }) => {
  const context = useFormContext<ValuesT>();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{typeof render === 'function' ? render(context) : render}</>;
};

export type BuilderContext<T extends FieldValues> = UseFormReturn<T>;

const _Builder = <ValuesT extends FieldValues, ValidationContext extends Record<string, any> = Record<string, any>>(
  {
    autofocus = true,
    initialValues,
    disabled,
    onSubmit,
    onChange,
    validationSchema,
    children,
    alwaysEmitOnChange = false,
    validateOnInit,
    validationContext,
    name,
    className,
  }: FormBuilderProps<ValuesT, ValidationContext>,
  ref: ((node: BuilderContext<ValuesT>) => void) | React.Ref<UseFormReturn<ValuesT, ValidationContext>>,
) => {
  const formRef = useRef<HTMLFormElement>(null!);
  const methods = useForm<ValuesT, ValidationContext>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: 0,
    context: validationContext ?? ({} as ValidationContext),
  });

  const { handleSubmit } = methods;

  const cbSubmit = useCallback(
    async (value: ValuesT) => {
      try {
        await onSubmit?.({
          ...initialValues,
          ...value,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [onSubmit, initialValues],
  );

  const formId = useId();
  const customContext = useMemo(
    () => ({ validationSchema, initialValues, disabled, formId }),
    [validationSchema, initialValues, disabled, formId],
  );
  const formProps = useMemo(
    () => (onSubmit ? { onSubmit: handleSubmit(cbSubmit) } : {}),
    [cbSubmit, handleSubmit, onSubmit],
  );

  useEffect(() => {
    if (validateOnInit) {
      void methods.trigger();
    }
  }, [methods, validateOnInit]);

  const isIOS = useIsIOS();
  useMounted(() => {
    if (isIOS) {
      return;
    }
    let fieldNameToBeFocused: Path<ValuesT> | undefined;

    if (autofocus === true) {
      const [key] = Object.keys(initialValues ?? {});
      fieldNameToBeFocused = key as Path<ValuesT>;
    } else if (typeof autofocus === 'string') {
      fieldNameToBeFocused = autofocus;
    }

    if (fieldNameToBeFocused) {
      methods.setFocus(fieldNameToBeFocused);
    }
  });

  useImperativeHandle(ref, () => methods);

  return (
    <CustomFormContextProvider value={customContext}>
      <FormProvider {...methods}>
        <Locator
          className={className}
          as="form"
          ref={formRef}
          {...formProps}
          noValidate
          id={formId}
          locatorName={name}
          locatorType="form"
        >
          <FormChildren render={children} />
          {onChange && (
            <Listener.Change<ValuesT>
              onChange={onChange}
              validationSchema={validationSchema}
              alwaysEmitOnChange={alwaysEmitOnChange}
            />
          )}
        </Locator>
      </FormProvider>
    </CustomFormContextProvider>
  );
};

const Builder = forwardRef(_Builder) as <ValuesT extends FieldValues>(
  props: FormBuilderProps<ValuesT> & { ref?: React.Ref<UseFormReturn<ValuesT>> },
) => ReturnType<typeof _Builder>;

export { Builder };
export default Builder;
