const API_BASEPATH = '/_api';

const getApiHost = () => {
  const isApiHostPublic = process.env.NEXT_PUBLIC_IS_PUBLIC_URL === 'true';
  const apiHostFromEnv = process.env.NEXT_PUBLIC_API_HOST ?? '';
  const apiHost = isApiHostPublic ? apiHostFromEnv.concat(API_BASEPATH) : apiHostFromEnv;

  return {
    isPublic: isApiHostPublic,
    apiHost,
    rawHost: apiHostFromEnv,
  };
};

export { getApiHost, API_BASEPATH };
