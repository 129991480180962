import { closeSnackbar, enqueueSnackbar } from 'notistack';

import { PushSnackArg, SnackbarKey } from '@swe/shared/providers/snackbar/types';

const SnackbarService = {
  push: (args: PushSnackArg) => {
    try {
      const { type } = args;
      if (type === 'ghost') {
        const { autoHideDuration, ...restArgs } = args;
        return enqueueSnackbar({
          ...restArgs,
          variant: 'ghost',
          persist: true,
          delay: autoHideDuration,
          autoHideDuration,
        });
      }

      return enqueueSnackbar({ persist: 'asyncTask' in args, ...args, variant: type });
    } catch (e) {
      console.error('SnackbarService has not been initialized yet');
    }
  },
  close: (id: SnackbarKey) => {
    try {
      return closeSnackbar(id);
    } catch (e) {
      console.error('SnackbarService has not been initialized yet');
    }
  },
};

export { SnackbarService };

export type { PushSnackArg };
