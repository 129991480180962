import { useCallback, useState } from 'react';

import { useInitialQuery } from '@swe/shared/hooks/pagination/use-initial-query';
import { QueryParserConfig } from '@swe/shared/hooks/pagination/utils/query-parser';

type UsePaginationConfig = {
  queryParserConfig: QueryParserConfig;
};

const usePagination = ({ queryParserConfig }: UsePaginationConfig) => {
  const initial = useInitialQuery(queryParserConfig);
  const [pagination, setPagination] = useState(initial);

  const onChange = useCallback((page: number, size?: number) => {
    setPagination((prev) => ({ page, pageSize: size ?? prev.pageSize }));
  }, []);

  return {
    pagination,
    onChange,
  };
};

export type { UsePaginationConfig };
export { usePagination };
