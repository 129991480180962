import {
  parse,
  format,
  isToday,
  isTomorrow,
  addDays,
  parseISO,
  isYesterday,
  addMonths,
  isThisYear,
  isAfter,
  isFuture,
  isValid,
  differenceInCalendarDays,
  differenceInDays,
  addMinutes,
  startOfDay,
  formatDistanceToNow,
  formatDistance,
  differenceInHours,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import enUS from 'date-fns/locale/en-US';

export const formatWithLocale = (date: Date, formatStr: DateFormat) => {
  if (Number.isNaN(date.getTime())) {
    console.error('Invalid Date');
    return '';
  }

  try {
    return format(date, formatStr, {
      locale: enUS,
    });
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const parseWithLocale = (dateStr: string, formatStr: string, refDate: Date | number = new Date()) => {
  return parse(dateStr, formatStr, refDate, {
    locale: enUS,
  });
};

type DateValue = Date | number | DateISOString;

type DateFormat =
  | 'MMMM d'
  | 'P'
  | 'M/d'
  | 'p'
  | 'Pp'
  | 'yyyy-MM-dd'
  | 'y'
  | 'MMMM'
  | 'E, d LLL'
  | 'MMMM d, h:mm a'
  | 'dd MMM yyyy';

const parseDate = (_value: DateValue) => (_value instanceof Date ? _value : new Date(_value));

const parseDateOnlyISO = (isoDate: DateISOString) => new Date(`${isoDate}T00:00`);

const tryFormatCloseDate = (value: DateValue, orFormatAs: DateFormat) => {
  const parsedDate = parseDate(value);

  if (isYesterday(parsedDate)) return 'Yesterday';
  if (isToday(parsedDate)) return 'Today';
  if (isTomorrow(parsedDate)) return 'Tomorrow';
  if (isThisYear(parsedDate) && isFuture(parsedDate)) {
    return formatWithLocale(parsedDate, 'MMMM d');
  }

  return formatWithLocale(parsedDate, orFormatAs);
};

const formatDate = (_value: DateValue, format?: DateFormat) => {
  const parsedDate = parseDate(_value);
  if (format) {
    return formatWithLocale(parsedDate, format);
  }
  return tryFormatCloseDate(parsedDate, 'P');
};

const applyOffset = (_value: DateValue, _offsetHours: number | 'local') => {
  const parsedDate = parseDate(_value);
  let offsetHours;
  if (_offsetHours === 'local') {
    offsetHours = parsedDate.getTimezoneOffset() / 60;
  } else {
    offsetHours = _offsetHours;
  }
  return new Date(parsedDate.valueOf() + offsetHours * 60 * 60 * 1000);
};

const utcDate = (_value: DateValue) => {
  const parsedDate = parseDate(_value);

  return addMinutes(parsedDate, parsedDate.getTimezoneOffset());
};

const dayDiff = (offset: number) => {
  const d1 = new Date();
  const d2 = applyOffset(applyOffset(new Date(), 'local'), offset);

  return differenceInCalendarDays(d1, d2);
};

const updateDateTZ = (_value: DateValue, offset: number) => {
  const parsedDate = applyOffset(parseDate(_value), 'local');
  const diff = dayDiff(offset);
  if (diff !== 0) {
    return addDays(parsedDate, diff);
  }
  return parsedDate;
};

export {
  format,
  isToday,
  isTomorrow,
  isAfter,
  parseISO,
  formatDate,
  tryFormatCloseDate,
  addDays,
  addMonths,
  isFuture,
  applyOffset,
  updateDateTZ,
  parseDateOnlyISO,
  isValid,
  differenceInCalendarDays,
  differenceInDays,
  startOfDay,
  utcDate,
  formatDistanceToNow,
  formatDistance,
  differenceInHours,
};
export type { DateValue, DateFormat };
