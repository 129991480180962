import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { Cart } from 'entities/cart/cart';
import { FullShopFulfillmentType, LocationCoords } from 'entities/shop/info';
import { StoreSaleType } from 'entities/shop/sale-type';

type CartCheckoutModel = {
  fulfillmentType?: FullShopFulfillmentType;
  fulfillmentDate?: DateISOString;
  fulfillmentIntervalId?: number | null;
  deliveryAddress?: string;
  secondaryAddress?: string;
  deliveryLocation?: LocationCoords;
  promocodes?: string[];
  loyaltyPoints?: number;
  notes?: string;
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
};

const NAME = '/Cart/Checkout';
export const CheckoutEndpoint = createModernEndpoint<CartCheckoutModel, Cart>(NAME);

export default CheckoutEndpoint;
