import cx from 'clsx';

import { ListItem, ListItemProps } from '@swe/shared/ui-kit/components/list-item';

import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName, ComponentHasSize } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ListProps<VT = string> = ComponentHasClassName &
  ComponentHasSize<'lg' | 'md' | 'sm'> & {
    items: Omit<ListItemProps<VT>, 'size'>[];
    maxHeight?: string;
  };

const List = <VT extends any = string>({ items, className, size, maxHeight }: ListProps<VT>) => {
  return (
    <div className={cx(styles.root, className)}>
      <Scrollable
        style={{ maxHeight }}
        fade={false}
      >
        <Stack
          spacing="none"
          divider
        >
          {items.map((item, index) => (
            <ListItem
              {...item}
              size={size}
              key={index}
            />
          ))}
        </Stack>
      </Scrollable>
    </div>
  );
};

export type { ListProps };
export { List };
export default List;
