/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import cx from 'clsx';
import hash from 'object-hash';

import { useCallback, ReactNode, useMemo } from 'react';

import { Checkbox, CheckboxSizes } from '@swe/shared/ui-kit/components/form/checkbox';
import { BaseCheckboxGroupProps } from '@swe/shared/ui-kit/components/form/checkbox-group/types';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';

import InlineScrollableWrapper, {
  InlineScrollableWrapperProps,
} from '@swe/shared/ui-kit/components/form/wrapper/inline-scrollable';

import styles from './styles.module.scss';

type CheckboxGroupDefaultProps<VT = string> = BaseCheckboxGroupProps<VT> & {
  layout?: 'vertical' | 'horizontal';
  inline?: boolean;
  controls?: InlineScrollableWrapperProps['controls'];
  size?: CheckboxSizes;
  idPrefix?: string;
  start?: ReactNode;
};

const CheckboxGroupDefault = <VT extends any = string>({
  value = [],
  name,
  label,
  layout = 'vertical',
  disabled,
  onBlur,
  onChange,
  inline,
  controls,
  options,
  error,
  size,
  idPrefix: _idPrefix,
  start,
}: CheckboxGroupDefaultProps<VT>) => {
  const idPrefix = useMemo(
    () => (_idPrefix ? `${_idPrefix}${hash(options)}-` : `${hash(options)}-`),
    [_idPrefix, options],
  );
  const handleChange = useCallback(
    ({ value: optionValue, disabled, active }: { value: VT; disabled: boolean; active: boolean }) => {
      if (disabled || !optionValue) return;

      let newValue = [...value];
      if (active) {
        newValue = newValue.filter((v) => v !== optionValue);
      } else {
        newValue = newValue.concat(optionValue);
      }

      onChange?.(newValue);
    },
    [onChange, value],
  );
  const renderOptions = useCallback(
    (o: typeof options) => (
      <div
        id={name}
        className={cx({
          ...(layout === 'vertical' ? { [styles[`_layout_${layout}`]]: layout } : {}),
        })}
        onBlur={onBlur}
      >
        {start}
        {o.map((option, optionIdx) => (
          <Checkbox
            key={`cg-${optionIdx}`}
            name={option.name}
            label={option.label}
            value={value.includes(option.value)}
            disabled={disabled || option.disabled}
            idPrefix={idPrefix}
            size={size}
            onChange={() =>
              handleChange({
                value: option.value,
                disabled: !!option.disabled,
                active: value.includes(option.value),
              })
            }
          />
        ))}
      </div>
    ),
    [name, layout, onBlur, value, disabled, idPrefix, size, handleChange, start],
  );

  return (
    <CommonWrapper
      label={label}
      error={error}
    >
      {inline ? (
        <InlineScrollableWrapper controls={controls ?? 'all'}>{renderOptions(options)}</InlineScrollableWrapper>
      ) : (
        renderOptions(options)
      )}
    </CommonWrapper>
  );
};

export type { CheckboxGroupDefaultProps };
export { CheckboxGroupDefault };
export default CheckboxGroupDefault;
