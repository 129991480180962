import { useCallback, useState } from 'react';

import useMousePosition, { UseMousePositionArgs } from '@swe/shared/hooks/use-mouse-coordinates';

const useElementByMousePosition = <T>(elements: T[], active = true) => {
  const [elementIdx, setElementIdx] = useState(0);

  const handleMouseMove = useCallback(
    ({ position: [x], element }: UseMousePositionArgs) => {
      const imageRelativeWidth = element.clientWidth / elements.length;
      const imagesRelativeXCoordinates = elements
        .map((_, elementIdx) => (elementIdx + 1) * imageRelativeWidth)
        .reverse();
      const maybeCurrentImageIndex = imagesRelativeXCoordinates.findIndex((xCoord) => x >= xCoord);
      const currentImageIndex = maybeCurrentImageIndex === -1 ? 0 : maybeCurrentImageIndex;

      setElementIdx(currentImageIndex);
    },
    [elements],
  );

  const { elementRef } = useMousePosition<HTMLDivElement>(handleMouseMove, active);

  return {
    elementRef,
    element: elements[elementIdx],
    elementIdx,
    setElementIdx,
  };
};

export { useElementByMousePosition };
