import clsx from 'clsx';

import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { useCountdown } from '@swe/shared/hooks/time';
import { usePersistState } from '@swe/shared/providers/persist-state';
import { Link } from '@swe/shared/ui-kit/components/link';
import Text from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';

type ActionWithCountdownProps = PropsWithChildren<{
  name: string;
  onClick: () => Promise<number> | number;
}>;

const getTimeoutKey = (name: string) => `awc_${name}_rct`;

const getTimeoutLeft = (timeoutSetAt: number, timeoutInMilliseconds: number) => {
  if (timeoutInMilliseconds === 0) return 0;

  const currentTime = +new Date();
  const delta = currentTime - timeoutSetAt;
  const timeLeftInMilliseconds = timeoutInMilliseconds - delta;

  return timeLeftInMilliseconds < 0 ? 0 : timeLeftInMilliseconds;
};

const useTimeoutFor = (name: string) => {
  const [timeout, _setTimeout] = usePersistState<{ timeoutSetAt: number; timeout: number } | null>(
    'cookies',
    getTimeoutKey(name),
    null,
  );

  const setTimeout = useCallback(
    (timeout: number) => {
      const timeoutSetAt = +new Date();
      _setTimeout({ timeoutSetAt, timeout });
      return timeoutSetAt;
    },
    [_setTimeout],
  );

  const clearTimeout = useCallback(() => _setTimeout(null), [_setTimeout]);

  return [timeout, setTimeout, clearTimeout] as const;
};

const ActionWithCountdown = ({ name, children, onClick }: ActionWithCountdownProps) => {
  const [id, setId] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [stored, setStored, clearStored] = useTimeoutFor(name);
  const [timeout, setTimeout] = useState(
    getTimeoutLeft(stored?.timeoutSetAt ?? +new Date(), (stored?.timeout ?? 0) * 1000),
  );

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      const timeout = await onClick();

      const timeoutSetAt = setStored(timeout);
      setTimeout(getTimeoutLeft(timeoutSetAt, timeout * 1000));
      setId((id) => id + 1);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [onClick, setStored]);
  const handleComplete = useCallback(() => {
    clearStored();
    setTimeout(0);
  }, [clearStored]);

  const time = useCountdown(timeout, id, handleComplete);
  const formattedTime = useMemo(() => parseInt((time / 1000).toFixed(0), 10), [time]);
  const isDisabled = time > 0 || isLoading;
  return (
    <Text
      className={clsx(styles.root, isDisabled && styles._disabled)}
      size="lg"
    >
      {isDisabled ? (
        <span className={styles.text_disabled}>{children}</span>
      ) : (
        <Link onClick={handleClick}>{children}</Link>
      )}
      {formattedTime > 0 && !Number.isNaN(formattedTime) && (
        <Text
          as="span"
          size="sm"
        >
          {formattedTime}
        </Text>
      )}
    </Text>
  );
};

export { ActionWithCountdown, useTimeoutFor };
export default ActionWithCountdown;
