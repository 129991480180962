import { usePersistState } from '@swe/shared/providers/persist-state';
import { NotificationPermissionUtils, SubscriptionUtils } from '@swe/shared/tools/firebase';
import { consoleLogger } from '@swe/shared/tools/logger/console';
import { CheckCircleIcon } from '@swe/shared/ui-kit/components/icon';
import { ConfirmParams, usePrompt } from '@swe/shared/ui-kit/components/promt';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import { useCallback, useEffect, useMemo } from 'react';

import { firebase, saveDeviceToken } from 'app/firebase';
import { OrderEvents } from 'common/events/order';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { enableNotifications } from 'common/use-cases/pwa/enable-notifications';
import GetShopInfoList from 'endpoints/shop/get-shop-info-list';

import { ShopInfo } from 'entities/shop/info';

const getPromptConfig = (): ConfirmParams => ({
  heading: 'Subscribe to Push Notifications for Order Updates and Exciting News',
  ariaLabel: 'Subscribe to Push Notifications for Order Updates and Exciting News',
  content: (
    <>
      <SectionHeading
        bullet={CheckCircleIcon}
        color="success"
      >
        Your orders status and other account updates
      </SectionHeading>
      <SectionHeading
        bullet={CheckCircleIcon}
        color="success"
      >
        Offers, personalized recommendations, events, and exclusive promotions
      </SectionHeading>
    </>
  ),
  confirmLabel: 'Subscribe',
  cancelLabel: 'Later',
  size: 'md',
});

const mapStoresToRouteMap = (stores: ShopInfo[]) => {
  return Object.fromEntries(stores.map((store) => [store.id, store.routeName || null]));
};

const createKey = (accountId?: number) => `aid-${accountId}-sm-is-shown`;
const useSubscriptionModal = (accountId?: number) => {
  const key = useMemo(() => createKey(accountId), [accountId]);
  const [isShown, setShown] = usePersistState<boolean>('local', accountId ? key : null, false);

  return {
    isShown,
    setShown,
  };
};

const createExternalApiUri = (host: string, basePath = '') => {
  const protocolPrefix = 'https://';

  return new URL(basePath, protocolPrefix.concat(host)).href;
};

const usePushNotifications = () => {
  const { user, isRegistrationComplete } = useCurrentUser();
  const { isShown, setShown } = useSubscriptionModal(user?.accountId);
  const {
    id: storeId,
    externalApiBasePath,
    externalApiHost = '',
    isPushNotificationsEnabled = true,
  } = useStoreConfig();
  const { confirm } = usePrompt();

  const { isGuest, isLoading } = useGuest();
  const [isRouteMapBuildFailed, setRouteMapBuildFailed] = usePersistState<boolean>(
    'local',
    'route-map-build-failed',
    false,
  );

  const askForPermission = useCallback(() => confirm(getPromptConfig()), [confirm]);
  const getRouteMap = useCallback(async () => {
    try {
      const stores = await GetShopInfoList.request(undefined);
      return mapStoresToRouteMap(stores);
    } catch (e) {
      setRouteMapBuildFailed(true);
      return {};
    }
  }, [setRouteMapBuildFailed]);
  const subscribe = useCallback(async () => {
    try {
      const routeMap = await getRouteMap();
      await firebase.makeSubscription(
        storeId,
        routeMap,
        externalApiHost ? createExternalApiUri(externalApiHost, externalApiBasePath) : undefined,
      );
      await saveDeviceToken();
    } catch (e) {
      console.error(e);
    }
  }, [externalApiBasePath, externalApiHost, getRouteMap, storeId]);
  const promptAndSubscribe = useCallback(async () => {
    setShown(true);
    const isPermissionGranted = NotificationPermissionUtils.isPermissionGranted();
    if (isPermissionGranted) {
      await subscribe();
      return;
    }

    const hasPermission = await askForPermission();
    if (hasPermission) {
      await subscribe();
      await enableNotifications();
    }
  }, [askForPermission, setShown, subscribe]);

  const isSubscribedAlready = SubscriptionUtils.hasToken();
  const isPermissionDenied = NotificationPermissionUtils.isPermissionDenied();
  const cantSubscribe =
    !user ||
    isGuest ||
    !isRegistrationComplete ||
    !isPushNotificationsEnabled ||
    isSubscribedAlready ||
    isLoading ||
    isPermissionDenied;

  useEffect(() => {
    if (cantSubscribe || isShown) return;

    if (firebase.isInitialized) {
      void promptAndSubscribe();
      return;
    }

    firebase.on('onInit', promptAndSubscribe);

    return () => {
      firebase.off('onInit', promptAndSubscribe);
    };
  }, [cantSubscribe, isShown, promptAndSubscribe]);
  useEffect(() => {
    if (cantSubscribe) return;

    OrderEvents.on('orderPlaced', promptAndSubscribe);
    return () => {
      OrderEvents.off('orderPlaced', promptAndSubscribe);
    };
  }, [cantSubscribe, promptAndSubscribe]);

  useEffect(() => {
    if (isRouteMapBuildFailed && isPushNotificationsEnabled) {
      firebase
        .deleteSubscription()
        .then(() => {
          void subscribe();
        })
        .catch((e) => consoleLogger.error('Delete subscription failed!', e));
    }
  }, [isPushNotificationsEnabled, isRouteMapBuildFailed, subscribe]);

  return {
    subscribe,
    promptAndSubscribe,
  };
};

export { usePushNotifications };
