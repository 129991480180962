import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

import { Potency, PotencyProps } from 'common/components/product-card/components/potency';
import { Variants, VariantsProps } from 'common/components/product-card/components/variants';

type PropertiesProps = Omit<VariantsProps & PotencyProps, 'className' | 'getQuantity'> & {
  potencyClassName?: string;
  variantsClassName?: string;
} & ComponentHasClassName;

const Properties = ({ className, potencyClassName, variantsClassName, variants, potency }: PropertiesProps) => {
  return (
    <div className={cx(className, styles.root)}>
      <Potency
        className={potencyClassName}
        potency={potency}
      />
      <Variants
        className={variantsClassName}
        variants={variants}
      />
    </div>
  );
};

export type { PropertiesProps };
export { Properties };
