import { useCallback, useRef, useState } from 'react';

const useStateWithPrevious = <DV>(initialValue: DV) => {
  const [state, setState] = useState<DV>(initialValue);
  const previousRef = useRef<DV | undefined>();
  const _setState = useCallback<typeof setState>((nextValue) => {
    setState((prevState) => {
      previousRef.current = prevState;

      return typeof nextValue === 'function' ? (nextValue as any)(prevState) : nextValue;
    });
  }, []);

  return [state, _setState, previousRef] as const;
};

const useStateWithRef = <USV>(initialValue: USV) => {
  const [state, setState] = useState<USV>(initialValue);
  const stateRef = useRef<USV>(initialValue);

  const _setState = useCallback<typeof setState>((nextV) => {
    const nextValue = typeof nextV === 'function' ? (nextV as any)(stateRef.current) : nextV;
    stateRef.current = nextValue;
    setState(nextValue);
  }, []);

  return [state, _setState, stateRef] as const;
};

export { useStateWithPrevious, useStateWithRef };
