import { Middleware } from '@swe/shared/network/transport/swr/transport.types';

import { useCallback } from 'react';

import GetCartEndpoint from 'endpoints/cart/get-cart';

type AddToQueue = <T>(f: (...args: any) => any) => Promise<T>;

const useGetCartRequest = (addToQueue: AddToQueue) => {
  const addQueueMiddleware = useCallback<Middleware>(
    (SWRNext) => (key, fetcher, config) => {
      const fetchWithQueue = fetcher ? (...args: any) => addToQueue<any>(() => fetcher(...args)) : null;

      return SWRNext(key, fetchWithQueue, config);
    },
    [addToQueue],
  );

  const {
    data: cart,
    isLoading,
    isValidating,
    mutate,
  } = GetCartEndpoint.useRequest(undefined, undefined, {
    use: [addQueueMiddleware],
    keepPreviousData: true,
    dedupingInterval: 1000,
  });

  return {
    cart,
    isLoading,
    isValidating,
    mutate,
  };
};

export { useGetCartRequest };
