import { useMounted } from '@swe/shared/hooks/use-mounted';

import { RouteQuery } from '@swe/shared/providers/router/constants';
import { getModalRouteParams, isModalRouteOpened } from '@swe/shared/providers/router/modal';
import { loadExternalGlobalScript } from '@swe/shared/tools/script';

import { isEmpty } from '@swe/shared/utils/other';
import { snakeCase } from '@swe/shared/utils/string';

import { useMemo, useRef } from 'react';

import {
  AEventMeta,
  AEventPayloadByType,
  AEventType,
  AnalyticsAdapter,
  EcomItem,
} from 'common/providers/analytics/constants';
import { useStoreConfig } from 'common/providers/config';

import { ModalRoutes, Routes } from 'common/router/constants';
import { parseCatalogQuery } from 'domains/catalog/use-cases/use-catalog-query';
import { CompilationType } from 'entities/common/compilation';
import { GTMConfig } from 'entities/shop/config';

enum PageType {
  Home = 'shop/home',
  Catalog = 'shop/AllProductsPage',
  CatalogCategory = 'shop/CategoryPage',
  CatalogSubCategory = 'shop/SubCategoryPage',
  CatalogBrand = 'shop/SubBrandPage', // todo
  CatalogCarousel = 'shop/carousel',
  CatalogSearch = 'shop/SearchPage',
  ProductDetails = 'shop/pdp',
  Cart = 'cart',
  Checkout = 'checkout', // /checkout/...
  OrderConfirmation = 'shop/order-confirmation', // todo: doesn't have own page
  DiscountDetails = 'shop/SubSpecialPage',
  DiscountList = 'shop/AllSpecialsPage',
  Locations = 'locations',
  Profile = 'account', // /profile/...
  ProfileReferral = 'account/best-buds',
  ProfileLoyaltyInfo = 'account/loyalty',
  Policies = 'policies', // /policies/...
  AboutUs = 'store/AboutUs',
  Login = 'account/login', // sign in, sign up, password recovery
  Marketing = 'marketing',
  NotFound = '404',
  ServerError = '500',
}

const PAGE_TYPE_MAP: Record<Routes, (query: RouteQuery) => PageType> = {
  [Routes.Home]: () => PageType.Home,
  [Routes.Catalog]: (query) => {
    const catalogQuery = parseCatalogQuery(query);
    if (catalogQuery.page === 'category') {
      return PageType.CatalogCategory;
    }
    if (!isEmpty(catalogQuery.filters)) {
      return PageType.CatalogSubCategory;
    }
    if (catalogQuery.searchTerm) {
      return PageType.CatalogSearch;
    }
    if (catalogQuery.page === 'product') {
      return PageType.ProductDetails;
    }
    if (catalogQuery.page === 'compilation' && catalogQuery.compilationType === CompilationType.DISCOUNT) {
      return PageType.DiscountDetails;
    }
    if (catalogQuery.page === 'compilation' && catalogQuery.compilationType === CompilationType.CAROUSEL) {
      return PageType.CatalogCarousel;
    }
    return PageType.Catalog;
  },
  [Routes.Discounts]: () => PageType.DiscountList,
  [Routes.StoreAbout]: () => PageType.AboutUs,
  [Routes.Cart]: () => PageType.Cart,
  [Routes.Checkout]: () => PageType.Checkout,
  [Routes.CheckoutDocuments]: () => PageType.Checkout,
  [Routes.CheckoutDocumentsCategory]: () => PageType.Checkout,
  [Routes.SignIn]: () => PageType.Login,
  [Routes.SignUp]: () => PageType.Login,
  [Routes.PasswordRecovery]: () => PageType.Login,
  [Routes.Guest]: () => PageType.Login,
  [Routes.Profile]: () => PageType.Profile,
  [Routes.ProfileOrders]: () => PageType.Profile,
  [Routes.ProfileOrderDetails]: () => PageType.OrderConfirmation,
  [Routes.ProfileOrderChat]: () => PageType.Profile,
  [Routes.ProfileContacts]: () => PageType.Profile,
  [Routes.ProfilePhoneUpdate]: () => PageType.Profile,
  [Routes.ProfileBirthdayUpdate]: () => PageType.Profile,
  [Routes.ProfileDocuments]: () => PageType.Profile,
  [Routes.ProfileDocument]: () => PageType.Profile,
  [Routes.ProfileAddDocuments]: () => PageType.Profile,
  [Routes.ProfilePrivacy]: () => PageType.Profile,
  [Routes.ProfilePasswordChange]: () => PageType.Profile,
  [Routes.ProfilePersonalInformation]: () => PageType.Profile,
  [Routes.ProfileNotifications]: () => PageType.Profile,
  [Routes.ProfilePromo]: () => PageType.Profile,
  [Routes.ProfileReferralProgram]: () => PageType.ProfileReferral,
  [Routes.ProfileLoyalty]: () => PageType.ProfileLoyaltyInfo,
  [Routes.Agreements]: () => PageType.Policies,
  [Routes.AgreementsTermsOfService]: () => PageType.Policies,
  [Routes.AgreementsPrivacyPolicy]: () => PageType.Policies,
  [Routes.AgreementsReferrerTermsOfUse]: () => PageType.Policies,
  [Routes.AgreementsReferralTermsOfUse]: () => PageType.Policies,
  [Routes.NotFound]: () => PageType.NotFound,
  [Routes.Reminders]: () => PageType.Profile,
  [Routes.MarketingSubscription]: () => PageType.Marketing,
  [Routes.MarketingSubscriptionSuccess]: () => PageType.Marketing,
  [Routes.MarketingSubscriptionEmailCheck]: () => PageType.Marketing,
  [Routes.MarketingUnsubscribe]: () => PageType.Marketing,
};

const mapRouteToPageType = ({ pathname, query }: AEventMeta['route']) => {
  if (isModalRouteOpened(query, ModalRoutes.ProductDetails)) {
    const { variantId } = getModalRouteParams(query);
    return {
      type: PageType.ProductDetails,
      key: variantId,
    };
  }
  if (isModalRouteOpened(query, ModalRoutes.Locations)) {
    return PageType.Locations;
  }
  if (isModalRouteOpened(query, ModalRoutes.Deals)) {
    return PageType.DiscountList;
  }
  return PAGE_TYPE_MAP[pathname]?.(query) ?? PageType.ServerError;
};

enum EventType {
  APP_INIT = 'dataLayer-initialized',
  PAGE_VIEW = 'page_view',
  ORDER_PLACE = 'purchase',
  PWA_INSTALLED = 'pwa_installed',
  SIGN_UP_POPUP_CLICKED = 'signup_popup_opened',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
}

const EVENT_TYPE_MAP: Partial<Record<AEventType, EventType>> = {
  [AEventType.APP_INIT]: EventType.APP_INIT,
  [AEventType.PAGE_VIEW]: EventType.PAGE_VIEW,
  [AEventType.ORDER_PLACE]: EventType.ORDER_PLACE,
  [AEventType.PWA_INSTALLED]: EventType.PWA_INSTALLED,
  [AEventType.SIGN_UP_POPUP_CLICKED]: EventType.SIGN_UP_POPUP_CLICKED,
  [AEventType.VIEW_ITEM]: EventType.VIEW_ITEM,
  [AEventType.ADD_TO_CART]: EventType.ADD_TO_CART,
  [AEventType.REMOVE_FROM_CART]: EventType.REMOVE_FROM_CART,
  [AEventType.VIEW_CART]: EventType.VIEW_CART,
  [AEventType.BEGIN_CHECKOUT]: EventType.BEGIN_CHECKOUT,
};

const itemCategoryKey = (key: string) => (key === 'itemCategory2' ? 'item_category2' : snakeCase(key));
const castValue = (v: string | number) => `${v}`;

const reduceEcomData =
  <T extends Record<string, any>>(data: T, noQty = false) =>
  (acc: Record<string, any>, key: string) => {
    if (noQty && key === 'quantity') {
      return acc;
    }
    const v = data[key as keyof T];
    return {
      ...acc,
      [itemCategoryKey(key)]: castValue(v),
    };
  };
const cartItemMapper = (data: Record<string, any>) =>
  Object.keys(data)
    .filter((key) => key !== 'value')
    .reduce(reduceEcomData(data), {});

const cartMapper = (data: EcomItem[]) => {
  const value = `${Math.round(data.reduce((acc, { price, quantity }) => acc + price * quantity, 0) * 100) / 100}`;
  return {
    ecommerce: {
      currency: 'USD',
      items: data.map(cartItemMapper),
      value,
    },
  };
};

const PAYLOAD_MAP: Partial<{
  [ET in AEventType]: (payload: AEventPayloadByType[ET]) => any;
}> = {
  [AEventType.ORDER_PLACE]: ({ order: { eventData } = {} }) => {
    return {
      ecommerce: eventData,
    };
  },
  [AEventType.PAGE_VIEW]: (page_details) => {
    return page_details
      ? {
          page_details,
        }
      : {};
  },
  [AEventType.VIEW_ITEM]: (data: AEventPayloadByType[AEventType.VIEW_ITEM]) => {
    return {
      ecommerce: {
        items: [Object.keys(data).reduce(reduceEcomData(data, true), {})],
      },
    };
  },
  [AEventType.ADD_TO_CART]: cartMapper,
  [AEventType.REMOVE_FROM_CART]: cartMapper,
  [AEventType.VIEW_CART]: cartMapper,
  [AEventType.BEGIN_CHECKOUT]: cartMapper,
};

type DefinedGTMConfig = Required<Pick<GTMConfig, 'gaKey'>> & Omit<GTMConfig, 'gaKey'>;

const buildGtmQueryParams = ({ gaKey, gtmAuth, gtmPreview }: DefinedGTMConfig) => {
  const params = new URLSearchParams();

  params.set('id', gaKey);

  if (gtmAuth) {
    params.set('gtm_auth', gtmAuth);
  }

  if (gtmPreview) {
    params.set('gtm_preview', gtmPreview);
  }

  return `?${params.toString()}`;
};

const buildScriptUrl = (url: string, config: DefinedGTMConfig) => {
  return `${url}${buildGtmQueryParams(config)}`;
};

const appendIFrame = (config: DefinedGTMConfig) => {
  if (!config.gaKey) return;

  const noscript = document.createElement('noscript');

  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', buildScriptUrl('https://www.googletagmanager.com/ns.html', config));
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('style', 'display:none; visibility:hidden');

  noscript.append(iframe);
  document.body.prepend(noscript);
};
const loadScript = async (config: DefinedGTMConfig) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
  await loadExternalGlobalScript(
    buildScriptUrl('https://www.googletagmanager.com/gtm.js', config),
    'dataLayer',
    undefined,
    true,
  );
};

const initGTM = async (config: DefinedGTMConfig) => {
  try {
    await loadScript(config);
    appendIFrame(config);
  } catch (e) {
    console.error(e);
  }
};

const useGTMAAdapter = () => {
  const { gaKey, gtmAuth, gtmPreview } = useStoreConfig();
  const wasInitializedRef = useRef(false);

  useMounted(() => {
    if (gaKey && !wasInitializedRef.current) {
      wasInitializedRef.current = true;
      void initGTM({ gaKey, gtmAuth, gtmPreview });
    }
  });

  return useMemo<AnalyticsAdapter>(
    () => ({
      pushEvent: (_type, payload, { store, route }) => {
        const type = EVENT_TYPE_MAP[_type];
        if (!type) {
          return;
        }
        const pageType = mapRouteToPageType(route);
        const isModal = isModalRouteOpened(route.query);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: type,
          ...([
            EventType.PAGE_VIEW,
            EventType.APP_INIT,
            EventType.VIEW_ITEM,
            EventType.ADD_TO_CART,
            EventType.REMOVE_FROM_CART,
          ].includes(type)
            ? {
                page_type: typeof pageType === 'object' ? pageType.type : pageType,
                view_mode: isModal ? 'modal' : 'page',
              }
            : {}),
          site_platform: 'sweed-web',
          store_id: store.id,
          store_name: store.name,
          store_state: store.location.region?.name,
          ...(PAYLOAD_MAP[_type]?.(payload) ?? {}),
        });
      },
    }),
    [],
  );
};

export default useGTMAAdapter;
