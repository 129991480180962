import cn from 'clsx';

import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type PureButtonsProps = ComponentHasClassName &
  ComponentHasChildren & {
    onClick?: () => void;
    tabIndex?: number;
    block?: boolean;
  };

export const PureButton = ({ children, onClick, className, block, tabIndex }: PureButtonsProps) => {
  return (
    <button
      className={cn(styles.root, className, block && styles._block)}
      type="button"
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
