import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { useFormContext, useField } from '@swe/shared/ui-kit/components/form/core/context';
import { getIsYupRequired } from '@swe/shared/ui-kit/components/form/core/utils';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import { merge } from '@swe/shared/utils/object';

type FormValueT = boolean | string | number | object | null | undefined;

type WithFormFieldOptions = {
  passRef?: boolean;
};

const withFormField = <VT extends FormValueT, PT extends FormControl<VT> = FormControl<VT>>(
  Control: React.FunctionComponent<PT>,
  options?: WithFormFieldOptions,
) => {
  const opts: WithFormFieldOptions = merge({ passRef: true }, options);

  return (({
    name,
    error: exError,
    onChange: exOnChange,
    onBlur: exOnblur,
    disabled: exDisabled,
    required: exRequired,
    ...controlProps
  }: PT) => {
    const { validationSchema, disabled: formDisabled } = useFormContext();
    const controller = useController({ name });
    const value = useField(name);
    const {
      field: { onChange, onBlur, ref },
      fieldState: { error },
      formState: { isSubmitting },
    } = controller;

    const reducedOnChange = useCallback(
      (value: any) => {
        onChange(value);
        exOnChange?.(value);
      },
      [onChange, exOnChange],
    );

    const reducedOnBlur = useCallback(() => {
      onBlur();
      exOnblur?.();
    }, [onBlur, exOnblur]);

    const isRequired = getIsYupRequired(validationSchema, name);

    return (
      <Control
        {...(controlProps as PT)}
        ref={opts.passRef ? ref : undefined}
        name={name}
        value={value}
        onChange={reducedOnChange}
        error={exError ?? error?.message}
        onBlur={reducedOnBlur}
        disabled={exDisabled ?? formDisabled ?? isSubmitting}
        required={exRequired ?? isRequired}
      />
    );
  }) as React.FunctionComponent<Omit<PT, 'value' | 'ref'>>;
};

export { withFormField };
export default withFormField;
