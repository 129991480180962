import { createHub } from '@swe/shared/network/transport/signalr';

import { isSSR } from '@swe/shared/utils/environment';

import { ChatMessage } from 'entities/profile/orders';

type Messages = {
  messageStateChanged: ChatMessage;
  connectionError: { arguments: string[] };
};

const useMainHub = createHub<Messages>(
  !isSSR && window.__sw_externalApiHost ? `wss://${window.__sw_externalApiHost}/ws/hub` : '/ws/hub',
);

export { useMainHub };
export default useMainHub;
