import { CloseReason, SnackbarKey } from 'notistack';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLayout } from '@swe/shared/providers/layout';
import { EventEmitter } from '@swe/shared/tools/event-emitter';

import { useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type EmitterT = {
  close: { reason: CloseReason; key?: SnackbarKey };
  entered: { isAppearing: boolean; key: SnackbarKey };
  enter: { isAppearing: boolean; key: SnackbarKey };
  update: { key: SnackbarKey; props: any };
};
const SnackbarCtx = createContext<{
  emitter: EventEmitter<EmitterT>;
  paddingTop: string;
}>(null!);

type SnackbarAdditionalProviderProps = ComponentHasChildren;

const SnackbarAdditionalProvider = ({ children }: SnackbarAdditionalProviderProps) => {
  const emitter = useMemo(() => {
    return new EventEmitter<EmitterT>();
  }, []);

  const [paddingTop, setPaddingTop] = useState('');
  const { headerRect } = useLayout();
  const { subscribe, unsubscribe } = useNearestScrollable();

  const ctx = useMemo(() => {
    return {
      emitter,
      paddingTop,
    };
  }, [emitter, paddingTop]);

  useEffect(() => {
    const handler = () => {
      const offset = headerRect.height;
      setPaddingTop(`${offset}px`);
    };
    subscribe('scrollPosition', handler);

    return () => {
      unsubscribe('scrollPosition', handler);
    };
  }, [headerRect, subscribe, unsubscribe]);

  useEffect(() => {
    const offset = headerRect.height;
    setPaddingTop(`${offset}px`);
  }, [headerRect]);

  return <SnackbarCtx.Provider value={ctx}>{children}</SnackbarCtx.Provider>;
};

const useSnackbarAdditionalCtx = () => useContext(SnackbarCtx);

export { useSnackbarAdditionalCtx, SnackbarAdditionalProvider };
