import {
  IconProps,
  SocialFacebookMonoIcon,
  SocialFacebookColorIcon,
  SocialYoutubeColorIcon,
  SocialYoutubeMonoIcon,
  SocialLinkedinColorIcon,
  SocialLinkedinMonoIcon,
  SocialInstagramColorIcon,
  SocialInstagramMonoIcon,
  SocialXColorIcon,
  SocialXMonoIcon,
} from '@swe/shared/ui-kit/components/icon';

import { ComponentType, ReactNode } from 'react';

import { ShopRoute } from 'common/router/constants';
import { ExternalNav, Policy, SocialMedia, SocialMediaLink } from 'entities/shop/config';

export const getIcon = (sm: SocialMedia, type: 'color' | 'mono' = 'mono') => {
  const SM_TYPE_TO_ICON: Record<SocialMedia, ComponentType<IconProps>> = {
    [SocialMedia.Twitter]: type === 'mono' ? SocialXMonoIcon : SocialXColorIcon,
    [SocialMedia.Facebook]: type === 'mono' ? SocialFacebookMonoIcon : SocialFacebookColorIcon,
    [SocialMedia.Instagram]: type === 'mono' ? SocialInstagramMonoIcon : SocialInstagramColorIcon,
    [SocialMedia.Youtube]: type === 'mono' ? SocialYoutubeMonoIcon : SocialYoutubeColorIcon,
    [SocialMedia.Linkedin]: type === 'mono' ? SocialLinkedinMonoIcon : SocialLinkedinColorIcon,
  };
  return SM_TYPE_TO_ICON[sm];
};

export type FooterProps = {
  logoSrc?: AbsoluteURL;
  logoUrl?: ShopRoute | AbsoluteURL;
  title?: string;
  copyright?: string;
  license?: string;
  disclaimers?: ReactNode[];
  externalNav?: ExternalNav;
  socialMedia?: SocialMediaLink[];
  policies?: Policy[];
  slogan?: string;
};
