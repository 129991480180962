import { Datatable } from '@swe/shared/ui-kit/components/datatable';
import { Column } from '@swe/shared/ui-kit/components/datatable/types';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';

import { ReactNode } from 'react';

import { LaboratoryDataComponentUnit, LaboratoryDataComponentValue } from 'entities/product/product';

enum DataSourceKey {
  Name = 'name',
  Value = 'value',
}

type Source = Record<DataSourceKey, string>;
type TableColumn = Column<Source>;

const mapRawDataToDataSource = (values: LaboratoryDataComponentValue[]) => {
  return values.map((ldcv) => ({
    [DataSourceKey.Name]: ldcv.name,
    [DataSourceKey.Value]:
      ldcv.min && ldcv.max && ldcv.min !== ldcv.max ? `${ldcv.min} - ${ldcv.max}` : (ldcv.max ?? ldcv.min).toString(),
  }));
};

const getColumns = (unitAbbr: LaboratoryDataComponentUnit | null): TableColumn[] => [
  { name: DataSourceKey.Name, heading: unitAbbr ? 'Per package' : '' },
  { name: DataSourceKey.Value, heading: unitAbbr ?? '', hAlign: 'right' },
];

type LaboratoryDataBlockProps = {
  data: LaboratoryDataComponentValue[];
  unitAbbr: LaboratoryDataComponentUnit | null;
  heading?: ReactNode;
  header?: false;
};

const LaboratoryDataBlock = ({ heading, header, data, unitAbbr }: LaboratoryDataBlockProps) => {
  const source = mapRawDataToDataSource(data);
  const columns = getColumns(unitAbbr);

  return (
    <div>
      {header !== false && <SectionHeading>{heading}</SectionHeading>}
      <Datatable
        source={source}
        columns={columns}
      />
    </div>
  );
};

export type { LaboratoryDataBlockProps };
export { LaboratoryDataBlock };
