import cx from 'clsx';
import { ReactNode } from 'react';

import { IconComponent, InfoIcon } from '@swe/shared/ui-kit/components/icon';

import Tooltip from '@swe/shared/ui-kit/components/tooltip';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type FeatureProps = {
  icon?: IconComponent;
  title: string;
  subtitle?: ReactNode;
  info?: ReactNode;
} & ComponentHasClassName;

const Feature = ({ className, icon: Icon, title, subtitle, info }: FeatureProps) => {
  return (
    <div className={cx(className, styles.root)}>
      {info ? (
        <Tooltip
          className={styles.info}
          content={info}
        >
          {Icon ? <Icon /> : <InfoIcon />}
        </Tooltip>
      ) : (
        Icon && <Icon />
      )}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

export type { FeatureProps };
export { Feature };
