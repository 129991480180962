import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { LoyaltyInfo } from 'entities/loyalty/info';

type Params = undefined;
type Data = LoyaltyInfo;
type Error = APIErrorExtended;

const NAME = '/Loyalty/GetLoyaltyInfo';
const GetLoyaltyInfoEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export { GetLoyaltyInfoEndpoint };
