import cn from 'clsx';

import {
  Colors,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type IndicatorSize = Sizes<'md' | 'sm' | 'lg'>;
type IndicatorColor = Colors<'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'neutral'>;

type IndicatorProps = ComponentHasClassName & ComponentHasSize<IndicatorSize> & ComponentHasColor<IndicatorColor>;

const Indicator = ({ className, size = 'md', color = 'primary' }: IndicatorProps) => {
  return <div className={cn(styles.root, styles[`_size_${size}`], styles[`_color_${color}`], className)} />;
};

export { Indicator };
export type { IndicatorColor, IndicatorSize, IndicatorProps };
export default Indicator;
