import useSWR, { mutate as mutateSwr } from 'swr';

import { createSWRKey } from '@swe/shared/network/transport/swr/utils';

import { DEFAULT_SWR_CONFIG, SWR_CLIENT_CACHE } from './transport.constants';
import {
  Factory,
  Endpoint,
  EndpointFactory,
  EndpointMock,
  EndpointMutate,
  EndpointRequest,
  EndpointUseRequest,
  EndpointClearCache,
  FetchFn,
  MockFn,
  SWRConfig,
  EndpointPreload,
  EndpointCache,
  EndpointKey,
} from './transport.types';

const createMock = <Params, Data, MockFunc extends MockFn<Params, Data> = MockFn<Params, Data>>(
  handler: MockFunc,
  timeout = 0,
) => {
  return ((...args) =>
    new Promise((resolve, reject) => {
      try {
        setTimeout(() => resolve(handler?.(...args)), timeout);
      } catch (e) {
        setTimeout(() => reject(e), timeout);
      }
    })) as MockFunc;
};

export const createEndpointFactory: Factory =
  <FetchConfig, EndpointURL = string>(
    _fetchFn: FetchFn<FetchConfig, EndpointURL>,
  ): EndpointFactory<FetchConfig, EndpointURL> =>
  <Params, Data, Error>(
    endpointUrl: EndpointURL,
    defaultFetchConfig: FetchConfig = {} as FetchConfig,
    _endpointSwrConfig: SWRConfig = {},
  ) => {
    let _mock: MockFn<Params, Data> = null;

    const key: EndpointKey<Params> = (params) => createSWRKey(endpointUrl, params);

    const endpointSwrConfig = {
      ...DEFAULT_SWR_CONFIG,
      ..._endpointSwrConfig,
    };

    const useRequest = (params: Params, _fetchConfig: FetchConfig, swrConfig: Omit<SWRConfig, 'keyParams'>) => {
      const requestSwrConfig: SWRConfig<Params> = {
        ...endpointSwrConfig,
        ...swrConfig,
      };

      const fetchFn: FetchFn<FetchConfig, EndpointURL, Params, Data> = _fetchFn;
      const safeParams = params ?? ({} as Params);
      const swrKey = params !== null ? key(endpointSwrConfig.keyParams!(safeParams)) : null;
      const fetchConfig: FetchConfig = {
        ...defaultFetchConfig,
        ..._fetchConfig,
      };

      return useSWR<Data, Error>(
        swrKey,
        () => (_mock ? _mock(safeParams) : fetchFn(endpointUrl, safeParams, fetchConfig).then(([data]) => data)),
        requestSwrConfig,
      );
    };

    const _request = async (params: Params, fetchConfig?: FetchConfig) => {
      const fetchFn: FetchFn<FetchConfig, EndpointURL, Params, Data> = _fetchFn;
      const config: FetchConfig = {
        ...defaultFetchConfig,
        ...fetchConfig,
      };

      return fetchFn(endpointUrl, params ?? ({} as Params), config);
    };

    const request: EndpointRequest<FetchConfig, Params, Data> = async (params, fetchConfig) => {
      if (_mock) {
        return _mock(params);
      }

      const [data] = await _request(params, fetchConfig);
      return data;
    };

    const preload: EndpointPreload<FetchConfig, Params, Data> = async (params, fetchConfig) => {
      const swrKey = key(endpointSwrConfig.keyParams!(params));
      const [data, headers] = await _request(params, fetchConfig);

      return {
        key: swrKey,
        data,
        preload: {
          [swrKey]: data,
        },
        headers,
      };
    };

    const setMock: EndpointMock<Params, Data> = (cb, timeout) => {
      _mock = createMock<Params, Data>(cb, timeout);
    };

    const mutate: EndpointMutate<Data, Params> = (data, params, shouldRevalidate) =>
      mutateSwr(key(endpointSwrConfig.keyParams!(params)), data, shouldRevalidate);

    const cache: EndpointCache<Data, Params> = (params) => {
      const k = key(endpointSwrConfig.keyParams!(params));
      return SWR_CLIENT_CACHE?.get(k);
    };

    const clear: EndpointClearCache<Params> = (params) => {
      const k = key(endpointSwrConfig.keyParams!(params));
      SWR_CLIENT_CACHE?.delete(k);
    };

    const endpoint: Endpoint<FetchConfig, Params, Data, Error> = {
      useRequest: useRequest as EndpointUseRequest<FetchConfig, Params, Data, Error>,
      request,
      preload,
      setMock,
      key,
      mutate,
      cache,
      clear,
    };

    return endpoint;
  };
