import { useResize } from '@swe/shared/hooks';

import { useCallback, useState } from 'react';

import { AppSettings } from '@swe/shop-ui/app/types';

const useClientWidth = (clientWidthFallback: number) => {
  const [clientWidth, _setClientWidth] = useState(clientWidthFallback);
  const setClientWidth = useCallback(() => {
    _setClientWidth(window.innerWidth);
  }, []);
  useResize(setClientWidth, 0);
  return clientWidth;
};

const useSettings = (settings: AppSettings) => {
  if (typeof window === 'undefined') return settings;

  if (!window.__APP_SETTINGS__ && settings) {
    window.__APP_SETTINGS__ = settings;
  }

  return window.__APP_SETTINGS__;
};

export { useClientWidth, useSettings };
