import Form from '@swe/shared/ui-kit/components/form';

import Stack from '@swe/shared/ui-kit/components/stack';

import { AGE_FORM_INITIAL_VALUES, AGE_FORM_NAME, AGE_FORM_VALIDATION_SCHEMA } from './config';

type AgeFormProps = {
  minimalCustomerAge: number;
  onAgeCheckPassed: () => void;
};

const AgeForm = ({ minimalCustomerAge, onAgeCheckPassed }: AgeFormProps) => {
  return (
    <Form.Builder
      name={AGE_FORM_NAME}
      initialValues={AGE_FORM_INITIAL_VALUES}
      validationSchema={AGE_FORM_VALIDATION_SCHEMA}
      validationContext={{ minimalCustomerAge }}
      autofocus={false}
      onSubmit={onAgeCheckPassed}
    >
      <Stack spacing="md">
        <Form.InputDate
          textAlign="left"
          label="Your Birthdate"
          name="birthDate"
          placeholder="Enter date of birth"
          initialScreen="years"
          staticNote={false}
        />
        <Form.SubmitButton block>Confirm</Form.SubmitButton>
      </Stack>
    </Form.Builder>
  );
};

export type { AgeFormProps };
export { AgeForm };
