import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Data = undefined;
type Params = {
  referralData: string;
};

const NAME = '/Referral/Add';
const AddReferralDataEndpoint = createModernEndpoint<Params, Data>(NAME);

export default AddReferralDataEndpoint;
export { AddReferralDataEndpoint };
