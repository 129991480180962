import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { CourierLocation } from 'entities/profile/orders';

type Params = {
  orderId: number;
};
type Data = CourierLocation;
const NAME = '/Orders/GetCourierLocation';
const GetCourierLocation = createModernEndpoint<Params, Data>(
  NAME,
  {},
  {
    refreshInterval: 3000,
  },
);

export type { Params, Data };
export { GetCourierLocation };
