import * as yup from 'yup';

type Locales = { [k in keyof yup.LocaleObject]: yup.LocaleObject[k] & Record<any, yup.Message> };

const LOCALES: Locales = {
  mixed: {
    default: '',
    fileRequired: 'File is required',
    fileSize: ({ maxSize }) => `The file is too large. The maximum file size is ${maxSize} MB`,
    fileExtensions: ({ allowed }) => `The file extension is not valid. Allowed extensions: ${allowed}`,
  },
};

yup.setLocale(LOCALES);

yup.addMethod(yup.mixed, 'file', function validateFile(message = LOCALES.mixed?.fileRequired) {
  return this.test('isValidFile', message, (value: any) => {
    if (!value) return true;

    return value instanceof File;
  });
});

yup.addMethod(
  yup.mixed,
  'fileSize',
  function validateFileSize(maxFileSizeInMb: number, message = LOCALES.mixed?.fileSize) {
    return this.test('isValidSize', function test(value: any) {
      if (!value) return true;

      const { createError } = this;

      if (value.size / 2 ** 20 > maxFileSizeInMb) {
        return createError({
          message: typeof message === 'function' ? message({ maxSize: maxFileSizeInMb }) : message,
        });
      }

      return true;
    });
  },
);

yup.addMethod(
  yup.mixed,
  'fileExtensions',
  function validateFileExtensions(allowedExtensions: string, message = LOCALES.mixed?.fileExtensions) {
    return this.test('isValidExtension', function test(value: any) {
      if (!value) return true;

      const { createError } = this;
      const { type, name } = value;
      const [, ext1] = name.split('.').map((s: string) => s.toLowerCase());
      const [, ext2] = type.split('/');

      if (!allowedExtensions.includes(ext1) && !allowedExtensions.includes(ext2)) {
        return createError({
          message: typeof message === 'function' ? message({ allowed: allowedExtensions }) : message,
        });
      }

      return true;
    });
  },
);

export * from 'yup';
export default yup;
