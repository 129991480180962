import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { Hero } from '@swe/shared/ui-kit/components/hero';
import { Error404Illustration } from '@swe/shared/ui-kit/components/illustration';

import Stack from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import AdditionalProductsCarousel from 'common/containers/additional-products-carousel';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';

type ProductNotFoundProps = ComponentHasClassName;

/*
TODO: Remove isReady fix when Icon/Illustration components ready. Because they are loading in useEffect, it triggers layout jump
 */

const ProductNotFound = ({ className }: ProductNotFoundProps) => {
  const [isReady, setReady] = useToggleable(false);
  const { toCatalog } = useCatalogQuery();
  const handleShopNow = useCallback(() => toCatalog({ filters: null, subPath: null, searchTerm: null }), [toCatalog]);

  return (
    <Stack
      className={cx(styles.root, !isReady && styles._notReady, className)}
      spacing="xxxxl"
    >
      <Hero
        className={styles.hero}
        illustration={<Error404Illustration onLoad={setReady} />}
        title="Not Found"
        content="This product or page does not exist or was removed."
        border={false}
        actions={[{ ariaLabel: 'goToCatalog', children: 'Shop Now', onClick: handleShopNow }]}
      />
      <AdditionalProductsCarousel />
    </Stack>
  );
};

export { ProductNotFound };
