import { createContext, PropsWithChildren, useContext } from 'react';

import { useGeolocation } from '@swe/shared/hooks/use-geolocation';

type GeolocationContextT = ReturnType<typeof useGeolocation>;

const GeolocationContext = createContext<GeolocationContextT>(null!);

type GeolocationProviderOptions = PropsWithChildren & {
  isEnabledByDefault?: boolean;
};

const GeolocationProvider = ({ isEnabledByDefault = false, children }: GeolocationProviderOptions) => {
  const props = useGeolocation({ isEnabled: isEnabledByDefault });

  return <GeolocationContext.Provider value={props}>{children}</GeolocationContext.Provider>;
};
const useGeolocationContext = () => useContext(GeolocationContext);

export { useGeolocationContext, GeolocationProvider };
