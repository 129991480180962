import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

import { useAnimationSettings } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type AnimatedHeightProps = ComponentHasChildren & {
  className?: string;
  expanded?: boolean;
  transition?: boolean;
} & Pick<AnimateHeightProps, 'onHeightAnimationEnd' | 'onHeightAnimationStart'>;

const AnimatedHeight = ({
  className,
  expanded,
  transition,
  onHeightAnimationEnd,
  onHeightAnimationStart,
  children,
}: AnimatedHeightProps) => {
  const animation = useAnimationSettings();
  return (
    <AnimateHeight
      className={className}
      height={expanded ? 'auto' : 0}
      duration={animation.enabled && transition ? 200 : 0}
      animateOpacity
      onHeightAnimationEnd={onHeightAnimationEnd}
      onHeightAnimationStart={onHeightAnimationStart}
    >
      {children}
    </AnimateHeight>
  );
};

export { AnimatedHeight };
export default AnimatedHeight;
