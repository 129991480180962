import { useElementSize } from '@swe/shared/hooks/use-element-size';
import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useLayout } from '@swe/shared/providers/layout';
import Link from '@swe/shared/providers/router/link';
import { useIntl } from '@swe/shared/tools/intl';
import { useBreakpoint } from '@swe/shared/tools/media';
import Button, { ButtonColor } from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';

import { CloseIcon, ChevronLeftIcon, MenuIcon, UserIcon } from '@swe/shared/ui-kit/components/icon';

import ModalMobile from '@swe/shared/ui-kit/components/modal/mobile';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';
import { mergeRefs } from '@swe/shared/ui-kit/utils';
import cx from 'clsx';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Cart } from './containers/cart';

import styles from './styles.module.scss';

import { AppLogo } from 'common/containers/header/components/logo';
import MobileMenu from 'common/containers/header/containers/mobile-menu';
import { SearchContainer } from 'common/containers/header/containers/search';
import { SignupSubheader } from 'common/containers/header/containers/signup-subheader';
import ReferralBanner from 'common/containers/referral-banner';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate, useRouterPathname, useRouterQuery } from 'common/router';
import { Routes, ShopRoute } from 'common/router/constants';

import { useLoyalty } from 'common/use-cases/use-loyalty';
import { getLoyaltyPointsFormatted } from 'entities/loyalty/info';

const useNavigationItems = () => {
  const { desktop } = useBreakpoint();
  const { user } = useCurrentUser();
  const { intl } = useIntl();

  const items = useMemo(
    () => ({
      [Routes.Home]: {
        label: intl.t('pages.home.title', undefined, 'Home'),
        href: Routes.Home,
        exact: true,
      },
      [Routes.Catalog]: {
        label: intl.t('pages.menu.title', undefined, 'Menu'),
        href: {
          pathname: Routes.Catalog,
          query: { all: [] },
        },
        exact: false,
      },
      [Routes.Discounts]: {
        label: intl.t('pages.discounts.title', undefined, 'Discounts'),
        href: Routes.Discounts,
        exact: false,
      },
      [Routes.StoreAbout]: {
        label: intl.t('pages.aboutUs.title', undefined, 'About Us'),
        href: Routes.StoreAbout,
        exact: false,
      },
      [Routes.Profile]: {
        label: 'Profile',
        href: Routes.Profile,
        exact: false,
      },
    }),
    [intl],
  );

  const { isLoading: isLoadingGuest, isGuest } = useGuest();

  return useMemo(
    () =>
      [
        items[Routes.Home],
        items[Routes.Catalog],
        items[Routes.Discounts],
        items[Routes.StoreAbout],
        ...(!desktop && !!user && !isLoadingGuest && !isGuest ? [items[Routes.Profile]] : []),
      ].filter(Boolean),
    [desktop, isGuest, isLoadingGuest, items, user],
  );
};

const Header = () => {
  const theme = useTheme();
  const { mobile, desktop, moreThan } = useBreakpoint();
  const navItems = useNavigationItems();
  const query = useRouterQuery();
  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();
  const { user, hasAuthCookie } = useCurrentUser();
  const [isSearchExpanded, setSearchExpanded] = useState(false);
  const [isMenuVisible, , closeMenu, toggleMenu] = useToggleable(false);
  const { loyalty } = useLoyalty();
  const loyaltyPts = getLoyaltyPointsFormatted(loyalty);
  const { headerRef } = useLayout();
  const isReferralBannerHiddenForCurrentRoute = useMemo(
    () =>
      [Routes.SignUp, Routes.SignIn, Routes.Agreements, Routes.PasswordRecovery, Routes.Profile].some((segment) =>
        pathname.includes(segment),
      ),
    [pathname],
  );

  useEffect(() => {
    closeMenu();
  }, [closeMenu, pathname, query, isSearchExpanded]);

  const handleSearchCollapse = useCallback(() => {
    setSearchExpanded(false);
  }, []);

  const handleSearchExpand = useCallback(() => {
    setSearchExpanded(true);
  }, []);

  const navigateToCart = useCallback(() => navigate(Routes.Cart), [navigate]);

  const navigateToProfile = useCallback(() => navigate(Routes.Profile), [navigate]);

  const rootElRef = useRef<HTMLDivElement | null>(null);
  const { height: headerHeight } = useElementSize(rootElRef);

  const isCurrentRoute = (href: ShopRoute, exact = false) =>
    exact
      ? pathname === (typeof href === 'object' ? href.pathname : href)
      : pathname.startsWith(typeof href === 'object' ? href.pathname! : href);

  const { isGuest } = useGuest();
  const { stickyLoginBarEnabled } = useStoreConfig();

  return (
    <PatternThemeProvider
      ref={mergeRefs([rootElRef, headerRef])}
      name="header"
    >
      <div className={cx(styles.root, isSearchExpanded && styles._search)}>
        <Container>
          <div className={styles.row}>
            <div className={styles.sideRow}>
              {!desktop && isSearchExpanded && (
                <Button
                  size="sm"
                  color={theme.header.topButton as ButtonColor}
                  icon={ChevronLeftIcon}
                  onClick={handleSearchCollapse}
                  ariaLabel="Back"
                />
              )}

              {!(isSearchExpanded && mobile) && <AppLogo />}

              {desktop && (
                <>
                  {navItems.map((item) => {
                    const isCurrent = isCurrentRoute(item.href, item.exact);
                    return (
                      <Link
                        className={cx(styles.menuItem, isCurrent && styles.menuItem_active)}
                        color="ghost"
                        href={item.href}
                        key={item.label}
                      >
                        {item.label}
                      </Link>
                    );
                  })}
                </>
              )}
            </div>

            {!isReferralBannerHiddenForCurrentRoute && moreThan.md && !isSearchExpanded && (
              <ReferralBanner
                className={styles.referral}
                closable
              />
            )}

            <SearchContainer
              className={styles.search}
              headerHeight={headerHeight}
              isExpanded={isSearchExpanded}
              onExpand={handleSearchExpand}
              onCollapse={handleSearchCollapse}
            />

            {(!mobile || !isSearchExpanded) && (
              <div className={styles.userMenu}>
                <Cart
                  onClick={navigateToCart}
                  active={isCurrentRoute(Routes.Cart)}
                />
                {isGuest ? (
                  <Stack
                    direction="row"
                    spacing="xxxs"
                  >
                    <UserIcon />
                    Guest
                  </Stack>
                ) : (
                  (user || hasAuthCookie) &&
                  !mobile && (
                    <Button
                      size="sm"
                      icon={UserIcon}
                      color={
                        isCurrentRoute(Routes.Profile)
                          ? (theme.header.topButtonActive as ButtonColor)
                          : (theme.header.topButton as ButtonColor)
                      }
                      onClick={navigateToProfile}
                      ariaLabel="Profile"
                    >
                      {!mobile && loyaltyPts}
                    </Button>
                  )
                )}
              </div>
            )}

            {!desktop && !isSearchExpanded && (
              <Button
                size="sm"
                className={styles.menuButton}
                icon={isMenuVisible ? CloseIcon : MenuIcon}
                onClick={toggleMenu}
                ariaLabel="Menu"
                color={theme.header.topButton as ButtonColor}
              />
            )}
          </div>
        </Container>

        {theme.header.primaryDivider !== 'off' && (
          <div className={cx(theme.header.primaryDivider === 'brand' ? styles.divider_brand : styles.divider_simple)} />
        )}

        {!desktop && (
          <ModalMobile
            visible={isMenuVisible}
            onClose={closeMenu}
            variant="fullscreen"
            side="right"
            closeButtonPosition="right"
            isHeaderEnabled={false}
            headerHeight={headerHeight}
            ariaLabel="Mobile menu"
            themePattern="mobile-menu"
          >
            <MobileMenu
              items={navItems}
              onItemClick={navigate}
              loyaltyPts={loyaltyPts}
            />
          </ModalMobile>
        )}
      </div>
      {stickyLoginBarEnabled && <SignupSubheader />}
    </PatternThemeProvider>
  );
};

export { Header };
export default Header;
