import { closeSnackbar } from 'notistack';

import {
  SnackbarAdditionalProvider,
  useSnackbarAdditionalCtx,
} from '@swe/shared/providers/snackbar/additional-provider';
import { SnackbarProvider as SnackbarProviderLocal } from '@swe/shared/providers/snackbar/provider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { SnackbarService } from './service';

type SnackbarProps = ComponentHasChildren;

const SnackbarProvider = ({ children }: SnackbarProps) => {
  return (
    <SnackbarAdditionalProvider>
      <SnackbarProviderLocal>{children}</SnackbarProviderLocal>
    </SnackbarAdditionalProvider>
  );
};

export { SnackbarProvider, SnackbarService, useSnackbarAdditionalCtx, closeSnackbar };
export default SnackbarProvider;
