import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { DateFormat, DateValue, formatDate } from '@swe/shared/utils/date';

type DatePresenterProps = {
  value: DateValue;
  format?: DateFormat;
} & ComponentHasClassName;

const DatePresenter = ({ format, value, className }: DatePresenterProps) => (
  <span className={className}>{formatDate(value, format)}</span>
);

export { DatePresenter };
export default DatePresenter;
