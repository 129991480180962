import { SnackbarProviderProps as NotistackSnackbarProviderProps } from 'notistack';

import { CheckIcon, InfoIcon, AlertIcon } from '@swe/shared/ui-kit/components/icon';

import { Ghost } from './components/ghost';
import { Snack, withColor } from './components/snack';
import { SnackComponents } from './types';

const DEFAULT_COMPONENTS: SnackComponents = {
  success: withColor(Snack, 'success', CheckIcon),
  warning: withColor(Snack, 'warning', AlertIcon),
  danger: withColor(Snack, 'danger', AlertIcon),
  neutral: withColor(Snack, 'neutral', InfoIcon),
  ghost: Ghost,
};

const DESKTOP_ANCHOR: NotistackSnackbarProviderProps['anchorOrigin'] = {
  horizontal: 'right',
  vertical: 'top',
};
const MOBILE_ANCHOR: NotistackSnackbarProviderProps['anchorOrigin'] = {
  horizontal: 'center',
  vertical: 'top',
};
const DEFAULT_PROVIDER_PROPS: NotistackSnackbarProviderProps = {
  autoHideDuration: 5000,
  preventDuplicate: true,
  hideIconVariant: true,
  variant: 'neutral',
  maxSnack: 8,
};

export { DEFAULT_COMPONENTS, DEFAULT_PROVIDER_PROPS, DESKTOP_ANCHOR, MOBILE_ANCHOR };
