export const resolveDelay = <T extends string | boolean | number | Record<any, any> | undefined | null | any[]>(
  value: ((resolve: (v: T) => void, reject: (v: any) => void) => void) | T,
  timeout = 1500,
): Promise<T> =>
  new Promise((resolve, reject) => {
    setTimeout(() => (typeof value === 'function' ? value(resolve, reject) : resolve(value)), timeout);
  });

export const wait = (seconds: number) => resolveDelay(true, seconds);

export const waitFor = <T extends any>(predicate: () => T | undefined): Promise<T> => {
  const initialResult = predicate();
  if (initialResult) {
    return Promise.resolve(initialResult);
  }

  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      const result = predicate();
      if (result) {
        clearInterval(intervalId);
        resolve(result);
      }
    }, 500);
  });
};
