import { useCallback, useRef, useState } from 'react';

import { useMounted } from '@swe/shared/hooks/use-mounted';
import { ServiceContainer } from '@swe/shared/tools/google/core/container';
import { GoogleServiceImpl } from '@swe/shared/tools/google/core/types';

const useGoogleService = <Implementation extends GoogleServiceImpl>(
  ServiceContainer: ServiceContainer<Implementation>,
  apiKey: string,
) => {
  const service = useRef(ServiceContainer.getIfExists(apiKey));
  const [isReady, setIsReady] = useState(!!service.current);
  const [error, setError] = useState('');

  const load = useCallback(
    () =>
      new Promise((resolve, reject) => {
        ServiceContainer.getOrCreate(apiKey)
          .then((ps) => {
            service.current = ps;
            resolve(ps);
          })
          .catch((e: any) => {
            setError(e.toString());
            reject(e);
          })
          .finally(() => {
            setIsReady(true);
          });
      }),
    [ServiceContainer, apiKey],
  );

  useMounted(() => {
    return () => {
      service.current = undefined;
    };
  });

  return {
    service,
    load,
    error,
    isReady,
  };
};

export { useGoogleService };
