type DocumentPrototypeId = EntityID<number>;
type DocumentVerificationId = EntityID<number>;
type ImageVerificationId = EntityID<number>;

enum DocumentCategory {
  Identity = 'identity',
  Medical = 'medical',
  Additional = 'additional',
}

enum DocumentCategoryDTO {
  Identity = 'Identity',
  MedicalPermit = 'MedicalPermit',
  AdditionalPermit = 'AdditionalPermit',
}

const documentCategoryToDto = (category: DocumentCategory) =>
  ({
    [DocumentCategory.Identity]: DocumentCategoryDTO.Identity,
    [DocumentCategory.Medical]: DocumentCategoryDTO.MedicalPermit,
    [DocumentCategory.Additional]: DocumentCategoryDTO.AdditionalPermit,
  })[category];

const documentCategoryToLabel = (category: DocumentCategory) =>
  ({
    [DocumentCategory.Identity]: 'Government-issued ID',
    [DocumentCategory.Medical]: 'Medical Documents',
    [DocumentCategory.Additional]: 'Additional Documents',
  })[category];

type DocumentPrototype = NamedEntity<DocumentPrototypeId> & {
  minImagesCount: number;
  maxImagesCount: number;
  canBeScanned?: boolean;
  isBerbixAllowed: boolean;
  isNumberRequired: boolean;
};

enum ImageVerificationStatusId {
  Good = 'Good',
  PoorCropping = 'PoorCropping',
  TooSmall = 'TooSmall',
  TooBlurry = 'TooBlurry',
  WrongDocumentOnTheImage = 'WrongDocumentOnTheImage',
  WrongSide = 'WrongSide',
  DoesNotMatchImageData = 'DoesNotMatchImageData',
  CustomerIsUnderAge = 'CustomerIsUnderAge',
  PotentiallyFraudulent = 'PotentiallyFraudulent',
  ImageUploadFailed = 'ImageUploadFailed',
  WrongDocumentType = 'WrongDocumentType',
  ExpiredDocument = 'ExpiredDocument',
}
type ImageVerificationStatus = NamedEntity<ImageVerificationStatusId>;
type ImageVerification = {
  id: ImageVerificationId;
  status?: ImageVerificationStatus;
  url: string;
};

enum VerificationStatusId {
  None = 'None',
  PendingVerification = 'PendingVerification',
  UserActionRequired = 'UserActionRequired',
  Verified = 'Verified',
  UserAbandonedVerification = 'UserAbandonedVerification',
  DeclinedByManager = 'DeclinedByManager',
}

type DocumentVerificationStatus = NamedEntity<VerificationStatusId>;
type DocumentVerification = {
  id: DocumentVerificationId;
  images: ImageVerification[];
  verificationStatus: DocumentVerificationStatus;
  verificationDocumentType: NamedEntity<number>;
  maskedDocumentNumber: string | null;
  dateOfBirth: string | null;
};

type RequiredDocuments = {
  requiredMedicalPermitTypes: DocumentPrototypeId[];
  requiredAdditionalPermitTypes: DocumentPrototypeId[];
  isRequiredIdentity: boolean;
};

type UploadedDocuments = {
  identities: DocumentVerification[];
  medicalPermits: DocumentVerification[];
  additionalPermits: DocumentVerification[];
};

type DocumentPrototypes = {
  identityTypes: DocumentPrototype[];
  medicalPermitTypes: DocumentPrototype[];
  additionalPermitTypes: DocumentPrototype[];
};

const isImageVerificationValid = (image: ImageVerification) =>
  image.status ? image.status.id === ImageVerificationStatusId.Good : true;

export type {
  DocumentPrototypeId,
  DocumentVerificationId,
  ImageVerificationId,
  DocumentPrototype,
  ImageVerificationStatus,
  ImageVerification,
  DocumentVerificationStatus,
  DocumentVerification,
  RequiredDocuments,
  UploadedDocuments,
  DocumentPrototypes,
};
export {
  DocumentCategory,
  DocumentCategoryDTO,
  ImageVerificationStatusId,
  VerificationStatusId,
  isImageVerificationValid,
  documentCategoryToDto,
  documentCategoryToLabel,
};
