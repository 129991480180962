import { usePreviousDefined } from '@swe/shared/hooks';

import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { useLoyaltySettings } from 'common/use-cases/use-loyalty-settings';
import { GetLoyaltyInfoEndpoint } from 'endpoints/loyalty/get-loyalty-info';
import { getLoyaltyPoints, Requirement } from 'entities/loyalty/info';

// eslint-disable-next-line no-bitwise
const isRequirementNeeded = (flags: number, requirement: Requirement) => requirement === (flags & requirement);

const getRequirements = (flags: number) =>
  [Requirement.Email, Requirement.Phone, Requirement.NotificationSubscription].filter((requirement) =>
    isRequirementNeeded(flags, requirement),
  );

const useLoyalty = (isActive = true) => {
  const { isRegistrationComplete } = useCurrentUser();
  const {
    settings: _settings,
    error: loyaltySettingsError,
    isLoading: isSettingsLoading,
  } = useLoyaltySettings(isActive);
  const settings = usePreviousDefined(_settings);

  const { isGuest, isLoading: isLoadingGuest } = useGuest();

  const {
    data: loyalty,
    error: loyaltyError,
    isLoading: isLoyaltyLoading,
  } = GetLoyaltyInfoEndpoint.useRequest(
    settings?.enabled && isRegistrationComplete && !isLoadingGuest && !isGuest ? undefined : null,
    undefined,
    {
      keepPreviousData: true,
    },
  );

  return {
    isEnabled: settings?.enabled,
    isEnabledAtStore: settings?.useLoyaltyAsDiscount,
    isHidden:
      settings && loyalty
        ? settings.enabled && !settings.useLoyaltyAsDiscount && getLoyaltyPoints(loyalty) === 0
        : undefined,
    isGuest: settings?.enabled && !loyalty,
    loyalty: settings?.enabled ? loyalty : undefined,
    status: loyalty?.status,
    requirements: loyalty?.requirements ? getRequirements(loyalty.requirements) : [],
    settings,
    error: loyaltySettingsError || loyaltyError,
    isLoading: isSettingsLoading || isLoyaltyLoading,
  };
};

export { useLoyalty };
