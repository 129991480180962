import { DEFAULT_PAGE, DEFAULT_PAGINATION } from '@swe/shared/hooks/pagination/config';
import { QueryParserConfig } from '@swe/shared/hooks/pagination/types';
import { RouteQuery } from '@swe/shared/providers/router/constants';
import { Pagination } from '@swe/shared/types/wrappers/pagination';

const parseQuery = (query: RouteQuery, { pageSize, pageKey, pageSizeKey }: QueryParserConfig): Pagination => {
  try {
    return {
      page: query[pageKey] ? parseInt(query[pageKey] as string, 10) : DEFAULT_PAGE,
      pageSize: query[pageSizeKey] ? parseInt(query[pageSizeKey] as string, 10) : pageSize,
    };
  } catch (e) {
    console.error(e);
    return DEFAULT_PAGINATION;
  }
};

export type { QueryParserConfig };
export { parseQuery };
