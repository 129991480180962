import isEqual from 'lodash/isEqual';
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { useBreakpoint } from '@swe/shared/tools/media';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { InputWrapperProps, InputWrapper } from '@swe/shared/ui-kit/components/form/wrapper/input';
import { ChevronDownIcon, ChevronUpIcon } from '@swe/shared/ui-kit/components/icon';
import { List } from '@swe/shared/ui-kit/components/list';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { usePopover } from '@swe/shared/ui-kit/components/popover';

import { getShiftValue, POPOVER_HIDE_PADDING } from '@swe/shared/ui-kit/components/popover/config';
import { Portal } from '@swe/shared/ui-kit/components/portal';

import styles from './styles.module.scss';

export type SelectOption<VT = string> = {
  value: VT;
  label: string;
  disabled?: boolean;
};

export type SelectProps<VT = string> = Omit<InputWrapperProps, 'children' | 'actions'> &
  FormControl<VT> & {
    options: SelectOption<VT>[];
    placeholder?: string;
    note?: ReactNode;
    label?: ReactNode | boolean;
    listMaxHeight?: string;
  };

const _Select = <VT extends any = string>(
  {
    value,
    label,
    disabled,
    error,
    note,
    name,
    className,
    size = 'lg',
    options = [],
    placeholder,
    onChange,
    onBlur,
    icon,
    collapsed,
    staticNote,
    listMaxHeight,
  }: SelectProps<VT>,
  outerRef: ForwardedRef<FormControlRef>,
) => {
  const ref = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const idName = `${name}-${useId()}`;
  const { referenceProps, popoverProps, isOpen, close } = usePopover({
    offset: 'xxs',
    trigger: 'click',
    placement: collapsed ? 'bottom-end' : 'bottom-start',
    sameWidth: !collapsed,
    hidePadding: POPOVER_HIDE_PADDING,
    shiftCrossAxis: true,
  });

  const valueLabel = useMemo(() => {
    if (!value) {
      return undefined;
    }
    return options.find((option) => isEqual(option.value, value))?.label;
  }, [options, value]);

  const changeHandler = useCallback(
    (value: VT) => {
      onChange?.(value);
      close();
    },
    [onChange, close],
  );

  const { mobile } = useBreakpoint();

  useImperativeHandle(outerRef, () => ({
    focus: () => ref.current?.focus(),
  }));

  const isFocused = useRef(false);

  const outerClick = useCallback(
    (e: MouseEvent) => {
      if (
        isFocused.current &&
        e.target &&
        !popoverRef.current?.contains(e.target as Node) &&
        !ref.current?.contains(e.target as Node)
      ) {
        onBlur?.();
        isFocused.current = false;
      }
    },
    [onBlur],
  );

  useEffect(() => {
    document.addEventListener('mousedown', outerClick);
    return () => document.removeEventListener('mousedown', outerClick);
  }, [outerClick]);

  const setRef = useCallback(
    (node: HTMLDivElement | null) => {
      popoverRef.current = node;
      popoverProps.ref(node);
    },
    [popoverProps],
  );

  const setFocus = useCallback(() => {
    isFocused.current = true;
  }, []);

  return (
    <>
      <Locator
        as={InputWrapper}
        locatorName={name}
        locatorType="select"
        className={className}
        name={idName}
        size={size}
        label={label}
        disabled={disabled}
        error={error}
        note={note}
        actions={[{ icon: isOpen ? ChevronUpIcon : ChevronDownIcon, ariaLabel: 'Select switcher' }]}
        fieldProps={referenceProps}
        icon={icon}
        collapsed={collapsed}
        staticNote={staticNote}
      >
        <input
          ref={ref}
          placeholder={placeholder}
          value={valueLabel || ''}
          readOnly
          id={idName}
          name={idName}
          disabled={disabled}
          aria-label="Search option"
          onFocus={setFocus}
        />
      </Locator>
      {isOpen && (
        <Portal>
          <div
            {...popoverProps}
            className={styles.dropdown}
            ref={setRef}
            onFocus={setFocus}
          >
            <List
              maxHeight={listMaxHeight ?? `min(350px, calc(100vh - ${getShiftValue(mobile) * 2}px))`}
              items={options.map((option) => ({
                ...option,
                onPick: changeHandler,
                isActive: isEqual(value, option.value),
              }))}
            />
          </div>
        </Portal>
      )}
    </>
  );
};

const Select = forwardRef(_Select) as <VT>(props: SelectProps<VT>) => ReturnType<typeof _Select>;

export { Select };
export default Select;
