import BrowserStorage from '@swe/shared/providers/persist-state/browser-storage';
import CookieStorage from '@swe/shared/providers/persist-state/cookie-storage';
import { useMemo } from 'react';

const useInitStorages = (_SSRFallback: Record<string, Record<any, string>> = {}) => {
  return useMemo(() => {
    return {
      cookies: new CookieStorage(_SSRFallback.cookies),
      local: new BrowserStorage('local'),
      session: new BrowserStorage('session'),
    };
  }, [_SSRFallback]);
};

export { useInitStorages };
