import { useDocumentHeight } from '@swe/shared/hooks/use-document-height';
import { useBreakpoint } from '@swe/shared/tools/media';
import Backdrop from '@swe/shared/ui-kit/components/backdrop';
import { ButtonColor } from '@swe/shared/ui-kit/components/button';
import { Input } from '@swe/shared/ui-kit/components/form/input';

import { SearchIcon } from '@swe/shared/ui-kit/components/icon';
import Visible from '@swe/shared/ui-kit/components/media/components/visible';
import { Popover } from '@swe/shared/ui-kit/components/popover';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';
import React, { useCallback, useMemo, useState, KeyboardEvent } from 'react';

import { List, ListProps } from './components/list';
import styles from './styles.module.scss';

export type SearchProps = ComponentHasClassName & {
  isLoading: boolean;
  items: ListProps['items'];
  isExpanded: boolean;
  total: number;
  headerHeight: number;

  onActivate(): void;
  onDeactivate(): void;
  onSearch(value: string): void;
  onClickAll: ListProps['onClickAll'];
  onClickItem: ListProps['onClickItem'];
};

export const Search = ({
  onActivate,
  onDeactivate,
  items,
  onSearch,
  isLoading,
  onClickAll,
  onClickItem,
  isExpanded,
  className,
  total,
  headerHeight,
}: SearchProps) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const { desktop, mobile } = useBreakpoint();

  const close = useCallback(() => {
    onDeactivate?.();
    setSearch('');
  }, [onDeactivate]);

  const open = useCallback(() => {
    onActivate?.();
    setSearch((search) => {
      onSearch?.(search);
      return search;
    });
  }, [onActivate, onSearch]);

  const toggle = useCallback((isOpen: boolean) => (isOpen ? open() : close()), [open, close]);

  const _onChange = useCallback(
    (value: string) => {
      setSearch(value);
      onSearch?.(value);
    },
    [setSearch, onSearch],
  );

  const isCollapsed = desktop ? undefined : !isExpanded;

  const _onClickItem = useCallback(
    (data: Parameters<ListProps['onClickItem']>[0]) => {
      onClickItem(data);
      close();
    },
    [close, onClickItem],
  );

  const _onClickAll = useCallback(
    (search: string) => {
      onClickAll(search);
      close();
    },
    [close, onClickAll],
  );

  const { actualHeight } = useDocumentHeight();

  const content = useMemo(
    () =>
      isExpanded &&
      !!search &&
      Array.isArray(items) && (
        <>
          <List
            className={styles.listContainer}
            style={{
              touchAction: 'none',
              willChange: 'top, height',
              top: mobile ? `${headerHeight}px` : 'auto',
              height: mobile ? `calc(${actualHeight}px - ${headerHeight}px)` : 'auto',
            }}
            isLoading={isLoading}
            search={search}
            items={items}
            total={total}
            onClickItem={_onClickItem}
            onClickAll={_onClickAll}
          />
          {mobile && (
            <div
              className={styles.mobileBackdrop}
              style={{
                top: `${headerHeight}px`,
              }}
            />
          )}
        </>
      ),
    [_onClickAll, _onClickItem, actualHeight, headerHeight, isExpanded, isLoading, items, mobile, search, total],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Enter':
          onClickAll?.(search);
          close();
          break;
        case 'Escape':
          close();
          break;
        default:
          if (!isExpanded && (event.code.startsWith('Key') || event.code.startsWith('Digit'))) {
            open();
          }
      }
    },
    [close, isExpanded, onClickAll, open, search],
  );

  return (
    <div className={className}>
      <Visible mobile>{content}</Visible>
      <Popover
        isOpen={!mobile && isExpanded && !!content}
        onToggle={toggle}
        content={content}
        sameWidth={desktop}
        placement={desktop ? 'bottom-end' : 'bottom'}
        trigger="focus"
        block
        offset="xxs"
      >
        <Input
          onIconClick={open}
          collapsed={isCollapsed}
          buttonColor={theme.header.topButton as ButtonColor}
          className={cx(styles.input, isExpanded && styles._active)}
          name="search-products"
          size="md"
          label={false}
          value={search}
          placeholder="Search"
          icon={SearchIcon}
          onChange={_onChange}
          onKeyDown={onKeyDown}
          staticNote={false}
          autofocus={!desktop}
        />
      </Popover>
      <Backdrop
        className={styles.backdrop}
        visible={!mobile && isExpanded && !!content}
        onClick={close}
      />
    </div>
  );
};

export default Search;
