import { useStoreConfig } from 'common/providers/config';

const usePolicies = () => {
  const { policies } = useStoreConfig();

  return {
    registration: (policies || []).filter(({ showAtRegistration }) => showAtRegistration),
    policies,
  };
};

export { usePolicies };
