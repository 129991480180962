import cx from 'clsx';

import { ReactNode, useCallback } from 'react';

import { ComponentHasClassName, ComponentHasSize } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ListItemProps<VT = string> = ComponentHasClassName &
  ComponentHasSize<'lg' | 'md' | 'sm'> & {
    value?: VT;
    label: ReactNode;
    medicalId?: string;
    disabled?: boolean;
    onPick?: (value: VT) => void;
    href?: string;
    isActive?: boolean;
  };

const ListItem = <VT extends any = string>({
  value,
  label,
  disabled,
  onPick,
  isActive,
  className,
  href,
  medicalId,
  size = 'md',
}: ListItemProps<VT>) => {
  const handleClick = useCallback(() => {
    if (value !== undefined) {
      onPick?.(value);
    }
  }, [onPick, value]);

  const Element = href ? 'a' : 'div';

  return (
    <Element
      className={cx(
        styles.root,
        disabled && styles._disabled,
        isActive && styles._active,
        medicalId && styles._medical,
        styles[`_size_${size}`],
        className,
      )}
      href={href}
      onClick={handleClick}
    >
      <div className={styles.text}>{label}</div>
      {medicalId && <div className={styles._medical_value}>{medicalId}</div>}
    </Element>
  );
};

export type { ListItemProps };
export { ListItem };
export default ListItem;
