import { Badge, ThemedBadgeProps } from '@swe/shared/ui-kit/components/badge';
import { PatternThemeProvider } from '@swe/shared/ui-kit/theme/provider';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import styles from './styles.module.scss';

type ProductBadgesProps = ComponentHasClassName & {
  badges: ThemedBadgeProps[];
};

const ProductBadges = ({ className, badges }: ProductBadgesProps) => {
  if (badges.length === 0) {
    return null;
  }

  return (
    <div className={cx(className, styles.root)}>
      {badges.map((badge, idx) => {
        const badgeContent = (
          <Badge
            {...badge}
            key={idx}
            className={styles.badge}
            size="md"
          />
        );

        return badge.themePattern ? (
          <PatternThemeProvider
            key={idx}
            name={badge.themePattern}
          >
            {badgeContent}
          </PatternThemeProvider>
        ) : (
          badgeContent
        );
      })}
    </div>
  );
};

export type { ProductBadgesProps };
export { ProductBadges };
export default ProductBadges;
