import { createContext, useContext, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { Sizes } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';
import { noop } from '@swe/shared/utils/func';

const matchMedia = isSSR
  ? () => ({
      matches: false,
      addListener: noop,
      removeListener: noop,
    })
  : window.matchMedia;

export const useMediaQuery = (mediaQuery?: string | false) => {
  const [isVerified, setIsVerified] = useState<boolean>(() => !!mediaQuery && matchMedia(mediaQuery).matches);

  useEffect(() => {
    if (mediaQuery) {
      const mediaQueryList = matchMedia(mediaQuery);
      setIsVerified(mediaQueryList.matches);
      const documentChangeHandler = () => setIsVerified(mediaQueryList.matches);
      mediaQueryList.addListener(documentChangeHandler);

      return () => {
        mediaQueryList.removeListener(documentChangeHandler);
      };
    }
    setIsVerified(false);
  }, [mediaQuery]);
  return isVerified;
};

type WidthRange = number | [number, number] | false;
const normalizeWidthRange = (range: WidthRange): [number, number] | false => {
  let min = 0;
  let max = Infinity;
  if (range !== false) {
    if (Array.isArray(range)) {
      [min, max] = range;
    } else {
      min = range;
    }
    return [min, max];
  }
  return false;
};

const mediaContext = createContext<{
  clientWidth: number;
  isIOS: boolean;
  isAndroid: boolean;
}>(null!);
export const MediaProvider = mediaContext.Provider;
const useClientWidth = () => useContext(mediaContext).clientWidth;

export const useIsIOS = () => useContext(mediaContext).isIOS;
export const useIsAndroid = () => useContext(mediaContext).isAndroid;

export const useMatchWidthRange = (range: WidthRange) => {
  const clientWidth = useClientWidth();
  const normalizedRange = normalizeWidthRange(range);
  if (normalizedRange) {
    const [min, max] = normalizedRange;
    return min <= clientWidth && clientWidth <= max;
  }
  return false;
};

export type Device = 'mobile' | 'tablet' | 'desktop';
export type ViewportBreakpoint = Sizes<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'>;

export const DEVICES: Device[] = ['mobile', 'tablet', 'desktop'];
export const VIEWPORT_BREAKPOINTS: ViewportBreakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export const DEVICE_TO_BREAKPOINT: Record<Device, ViewportBreakpoint> = {
  mobile: 'xs',
  tablet: 'sm',
  desktop: 'xl',
};

export const useBreakpoint = (
  enabled = true,
): Record<ViewportBreakpoint | Device, boolean> & {
  isUltraNarrow: boolean;
  lessThan: Record<Exclude<ViewportBreakpoint, 'xs'>, boolean>;
  moreThan: Record<Exclude<ViewportBreakpoint, 'xxl'>, boolean>;
} => {
  const {
    media: { breakpoint },
  } = useTheme();
  const bpXS = parseFloat(breakpoint.xs);
  const bpSM = parseFloat(breakpoint.sm);
  const bpMD = parseFloat(breakpoint.md);
  const bpLG = parseFloat(breakpoint.lg);
  const bpXL = parseFloat(breakpoint.xl);
  const bpXXL = parseFloat(breakpoint.xxl);

  const byBreakpoint = {
    xs: useMatchWidthRange(enabled && [bpXS, bpSM - 1]),
    sm: useMatchWidthRange(enabled && [bpSM, bpMD - 1]),
    md: useMatchWidthRange(enabled && [bpMD, bpLG - 1]),
    lg: useMatchWidthRange(enabled && [bpLG, bpXL - 1]),
    xl: useMatchWidthRange(enabled && [bpXL, bpXXL - 1]),
    xxl: useMatchWidthRange(enabled && [bpXXL, Infinity]),
  };
  const lessThan = {
    sm: useMatchWidthRange(enabled && [0, bpSM - 1]),
    md: useMatchWidthRange(enabled && [0, bpMD - 1]),
    lg: useMatchWidthRange(enabled && [0, bpLG - 1]),
    xl: useMatchWidthRange(enabled && [0, bpXL - 1]),
    xxl: useMatchWidthRange(enabled && [0, bpXXL - 1]),
  };
  const moreThan = {
    xs: useMatchWidthRange(enabled && bpSM),
    sm: useMatchWidthRange(enabled && bpMD),
    md: useMatchWidthRange(enabled && bpLG),
    lg: useMatchWidthRange(enabled && bpXL),
    xl: useMatchWidthRange(enabled && bpXXL),
  };

  const byDevice = {
    mobile: byBreakpoint.xs,
    tablet: byBreakpoint.sm || byBreakpoint.md || byBreakpoint.lg,
    desktop: byBreakpoint.xl || byBreakpoint.xxl,
  };

  return {
    ...byBreakpoint,
    ...byDevice,
    moreThan,
    lessThan,
    isUltraNarrow: useMatchWidthRange(enabled && [0, 330]),
  };
};

export const useCurrentBreakpoint = () => {
  const breakpoints = useBreakpoint();
  return VIEWPORT_BREAKPOINTS.find((br) => breakpoints[br])!;
};

export const useDevice = (): Device => {
  const { mobile, tablet } = useBreakpoint();
  return mobile ? 'mobile' : tablet ? 'tablet' : 'desktop';
};

export const useIsTouchEnabled = () => (isSSR ? false : 'ontouchstart' in document.documentElement);
