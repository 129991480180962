import { useCallback, useEffect, useMemo, useState } from 'react';

import { state } from './local-state';

import { useAccountFulfillmentPreferences } from 'common/containers/header/containers/sub-header/use-cases/use-account-fulfillment-preferences';
import { Requirements } from 'common/entities/documents';
import { useDocumentsPrototypes } from 'common/use-cases/documents/use-documents-prototypes';
import { useDocumentsVerifications } from 'common/use-cases/documents/use-documents-verifications';
import { getFulfillmentType } from 'common/use-cases/documents/utils';
import { GetRequiredDocumentsEndpoint } from 'endpoints/profile/documents/get-required-documents';

const useDocumentsRequirements = (isActive = true) => {
  const [manager, setManager] = useState<Requirements | undefined>(state.requirements);
  const { orderType } = useAccountFulfillmentPreferences();

  const {
    verifications,
    isLoading: isVerificationsLoading,
    mutate: mutateVerifications,
  } = useDocumentsVerifications(isActive);
  const { prototypes, isLoading: isPrototypesLoading, mutate: mutatePrototypes } = useDocumentsPrototypes(isActive);
  const {
    data: requiredDocuments,
    isLoading: isRequirementsLoading,
    mutate: mutateRequirements,
  } = GetRequiredDocumentsEndpoint.useRequest(
    isActive
      ? {
          fulfillmentType: getFulfillmentType(orderType),
        }
      : null,
  );

  const mutate = useCallback(async () => {
    await Promise.all([mutateVerifications(), mutatePrototypes(), mutateRequirements()]);
  }, [mutatePrototypes, mutateRequirements, mutateVerifications]);

  useEffect(() => {
    if (requiredDocuments && verifications && prototypes) {
      state.requirements = new Requirements(requiredDocuments, verifications, prototypes);
      setManager(state.requirements);
    }
  }, [requiredDocuments, prototypes, verifications]);

  return {
    requirements: manager,
    uploadedDocuments: verifications,
    prototypes,
    data: requiredDocuments,
    isLoading: isVerificationsLoading || isPrototypesLoading || isRequirementsLoading,
    mutate: useMemo(
      () => ({
        verifications: mutateVerifications,
        prototypes: mutatePrototypes,
        requirements: mutateRequirements,
        all: mutate,
      }),
      [mutate, mutatePrototypes, mutateRequirements, mutateVerifications],
    ),
  };
};

export { useDocumentsRequirements };
