import cx from 'clsx';
import partial from 'lodash/partial';
import { ComponentType, ReactNode, useCallback, useRef, useState, KeyboardEvent } from 'react';

import { ChevronLeftIcon, ChevronRightIcon, IconProps } from '@swe/shared/ui-kit/components/icon';
import { Scrollable, ScrollableMethods, ScrollState } from '@swe/shared/ui-kit/components/scrollable';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { isEnterOrSpacePressed } from '@swe/shared/utils/keyboard';

import styles from './styles.module.scss';

type ControlProps = ComponentHasClassName & {
  active: boolean;
  icon: ComponentType<IconProps>;
  onClick: () => void;
};

const Control = ({ className, active, icon: Icon, onClick }: ControlProps) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (isEnterOrSpacePressed(event)) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <div
      className={cx(styles.control, active && styles.control_active, className)}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      <Icon />
    </div>
  );
};

const isLeftControlVisible = (controls: InlineScrollableWrapperProps['controls']) =>
  ['all', 'backward'].includes(controls);
const isRightControlVisible = (controls: InlineScrollableWrapperProps['controls']) =>
  ['all', 'forward'].includes(controls);

type InlineScrollableWrapperProps = ComponentHasClassName & {
  children: ReactNode;
  controls: 'all' | 'forward' | 'backward' | 'none';
};
const InlineScrollableWrapper = ({ className, children, controls }: InlineScrollableWrapperProps) => {
  const scrollableRef = useRef<ScrollableMethods>(null!);
  const [scrollState, setScrollState] = useState<ScrollState>({ x: undefined, y: undefined });

  const handleScrollBy = useCallback((direction: 'left' | 'right') => {
    const scrollEl = scrollableRef.current.getElement();
    const { scrollLeft } = scrollEl;
    const scrollWidth = scrollEl.getBoundingClientRect().width;
    const newScrollPosition = direction === 'left' ? scrollLeft - scrollWidth : scrollLeft + scrollWidth;

    scrollableRef.current.scroll({
      left: newScrollPosition,
      behavior: 'smooth',
    });
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollLeft = useCallback(partial(handleScrollBy, 'left'), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScrollRight = useCallback(partial(handleScrollBy, 'right'), []);

  return (
    <div className={cx(className, styles.root)}>
      <Control
        className={styles.control_left}
        active={scrollState.x !== undefined && scrollState.x !== 'start' && isLeftControlVisible(controls)}
        icon={ChevronLeftIcon}
        onClick={handleScrollLeft}
      />
      <Scrollable
        ref={scrollableRef}
        hideScrollbar
        fade
        onScrollState={setScrollState}
        direction="horizontal"
      >
        {children}
      </Scrollable>
      <Control
        className={styles.control_right}
        active={scrollState.x !== undefined && scrollState.x !== 'end' && isRightControlVisible(controls)}
        icon={ChevronRightIcon}
        onClick={handleScrollRight}
      />
    </div>
  );
};

export type { InlineScrollableWrapperProps };
export { InlineScrollableWrapper };
export default InlineScrollableWrapper;
