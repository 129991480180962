import cx from 'clsx';

import Dot from '@swe/shared/ui-kit/components/dot';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type PointerProps = ComponentHasClassName & {
  length: number;
  current: number;
  onClick: (index: number) => void;
};

const Pointer = ({ className, length, current, onClick }: PointerProps) => {
  return (
    <div className={cx(styles.root, className)}>
      {new Array(length).fill(null).map((_, index) => (
        <Dot
          index={index}
          key={index}
          active={current === index}
          onClick={onClick}
          size="lg"
        />
      ))}
    </div>
  );
};

export type { PointerProps };
export { Pointer };
export default Pointer;
