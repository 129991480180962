import { isSSR } from '@swe/shared/utils/environment';

import { useCallback } from 'react';

import { useRouterNavigate, useRouterQuery } from 'common/router';

const PHONE_QUERY_FOR_GUEST = 'p';

// backend can't send user phone for guest
const useGuestPassPhone = () => {
  const query = useRouterQuery();
  const navigate = useRouterNavigate();

  const getPhone = useCallback(() => {
    const str = `${query[PHONE_QUERY_FOR_GUEST]}`;
    if (!str) {
      return null;
    }
    if (isSSR) {
      return Buffer.from(str, 'base64').toString();
    }
    return window.atob(str);
  }, [query]);

  const setPhone = useCallback(
    (phone?: string) => {
      if (!phone) {
        return;
      }
      void navigate({
        query: {
          ...query,
          [PHONE_QUERY_FOR_GUEST]: isSSR ? Buffer.from(phone).toString('base64') : window.btoa(phone),
        },
      });
    },
    [navigate, query],
  );
  return { getPhone, setPhone };
};

export { useGuestPassPhone };
