import { Portals } from '@swe/shared/ui-kit/components/portal/types';

const counter: Map<Portals, number> = new Map([
  ['common', 0],
  ['fixedFooter', 0],
]);

const PortalCounter = {
  counter,
  increment: (name: Portals) => {
    const count = counter.get(name) ?? 0;
    counter.set(name, count + 1);

    return count + 1;
  },
  decrement: (name: Portals) => {
    const count = counter.get(name) ?? 0;
    counter.set(name, count - 1);

    return count - 1;
  },
};

export { PortalCounter };
