import { useMemo } from 'react';

import { DEFAULT_PAGINATION } from '@swe/shared/hooks/pagination/config';
import { parseQuery, QueryParserConfig } from '@swe/shared/hooks/pagination/utils/query-parser';
import { useRouterQuery } from '@swe/shared/providers/router';

const useInitialQuery = (config: QueryParserConfig) => {
  const query = useRouterQuery();

  return useMemo(() => (query ? parseQuery(query, config) : DEFAULT_PAGINATION), [config, query]);
};

export { useInitialQuery };
