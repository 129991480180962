import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SWRConfiguration, SWRConfig } from 'swr';

import { DEFAULT_SWR_CONFIGURATION, SWR_CLIENT_CACHE } from '@swe/shared/network/transport/swr/transport.constants';
import { isSSR } from '@swe/shared/utils/environment';

export type EndpointFallback = SWRConfiguration['fallback'];

type EndpointFactoryProviderProps = React.PropsWithChildren<{
  fallback?: EndpointFallback;
  revalidateOnInit?: boolean;
}>;

const context = createContext<{ pauseRevalidateOnFocus: () => void; resumeRevalidateOnFocus: () => void }>(null!);

const CacheControlsProvider = context.Provider;
const useCacheControls = () => useContext(context);

const CacheProvider = ({
  fallback = {},
  children,
  revalidateOnInit: _revalidateOnInit = true,
}: EndpointFactoryProviderProps) => {
  const [revalidateIfStale, setRevalidateIfStale] = useState(_revalidateOnInit);
  useEffect(() => {
    setRevalidateIfStale(true);
  }, []);

  const [revalidateOnFocus, setRevalidateOnFocus] = useState(true);
  const pauseRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(false), []);
  const resumeRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(true), []);

  const ssrFallback = useRef(fallback);

  return (
    <SWRConfig
      value={useMemo(
        () => ({
          ...DEFAULT_SWR_CONFIGURATION,
          revalidateOnFocus,
          revalidateIfStale,
          provider: !isSSR ? () => SWR_CLIENT_CACHE! : undefined,
          fallback: ssrFallback.current,
        }),
        [revalidateOnFocus, revalidateIfStale],
      )}
    >
      <CacheControlsProvider
        value={useMemo(
          () => ({ pauseRevalidateOnFocus, resumeRevalidateOnFocus }),
          [pauseRevalidateOnFocus, resumeRevalidateOnFocus],
        )}
      >
        {children}
      </CacheControlsProvider>
    </SWRConfig>
  );
};

export { useCacheControls, CacheProvider };
export type { EndpointFactoryProviderProps };
export default CacheProvider;
