import { CarDeliveryIcon, ChevronDownIcon, ShopIcon } from '@swe/shared/ui-kit/components/icon';
import { PureButton } from '@swe/shared/ui-kit/components/pure-button';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import { useEffect } from 'react';

import styles from './styles.module.scss';

import { ShopFulfillmentType } from 'entities/shop/info';

type SelectedLocationProps = ComponentHasClassName & {
  address?: string | null;
  storeName?: string | null;
  type: ShopFulfillmentType;
  onClick?: () => void;
  isReady?: boolean;
};

const SelectedLocation = ({ className, type, address, storeName, onClick, isReady }: SelectedLocationProps) => {
  useEffect(() => {
    if (!address && isReady && onClick) onClick();
  }, [address, isReady, onClick]);

  return (
    <PureButton
      className={cx(className, styles.root, isReady && styles._ready)}
      onClick={isReady ? onClick : undefined}
    >
      {type &&
        (type === ShopFulfillmentType.Pickup ? (
          <ShopIcon className={styles.typeIcon} />
        ) : (
          <CarDeliveryIcon className={styles.typeIcon} />
        ))}
      <div className={styles.info}>
        {type && (
          <span className={styles.fulfillmentType}>
            {type === ShopFulfillmentType.Pickup ? 'Pickup at' : 'Delivery Address'}
            <ChevronDownIcon className={styles.downIcon} />
          </span>
        )}
        <span className={styles.address}>
          {type === ShopFulfillmentType.Pickup ? storeName : address || 'Enter your address'}
        </span>
      </div>
    </PureButton>
  );
};

export type { SelectedLocationProps };
export { SelectedLocation };
export default SelectedLocation;
