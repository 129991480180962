import { useCurrentUser } from 'common/providers/user';
import GetLoyaltySettings from 'endpoints/loyalty/get-loyalty-settings';

const useLoyaltySettings = (isActive = true) => {
  const { user } = useCurrentUser();
  const {
    data: settings,
    error,
    isLoading,
  } = GetLoyaltySettings.useRequest(user && isActive ? undefined : null, {}, { dedupingInterval: 60000 * 25 });

  return {
    settings,
    error,
    isLoading,
  };
};

export { useLoyaltySettings };
