import { usePersistState } from '@swe/shared/providers/persist-state';

import { useStoreConfig } from 'common/providers/config';
import { ProductSaleType } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

const SALE_TYPE_STORAGE_KEY = 'Cart/SaleType';
const DEFAULT_SALE_TYPE = ProductSaleType.RECREATIONAL;

type UseSaleTypeReturn =
  | {
      isMedicalMenuEnabled: true;
      saleType: Exclude<StoreSaleType, undefined>;
      setSaleType: (saleType: StoreSaleType) => Promise<void>;
    }
  | {
      isMedicalMenuEnabled: false;
      saleType: undefined;
      setSaleType: undefined;
    };

let initGlobalHeadersSaleType = true;

const getDefaultSaleType = (isMedicalMenuEnabled: boolean) => {
  return isMedicalMenuEnabled ? DEFAULT_SALE_TYPE : null;
};

type RequiredSaleType = Exclude<StoreSaleType, undefined>;

const useSaleType = () => {
  const { medicalMenuEnabled: isMedicalMenuEnabled } = useStoreConfig();
  const defaultValue = getDefaultSaleType(isMedicalMenuEnabled);

  const [saleType, setSaleType] = usePersistState<Exclude<StoreSaleType, undefined> | null>(
    'cookies',
    SALE_TYPE_STORAGE_KEY,
    defaultValue,
  );

  if (saleType !== null && initGlobalHeadersSaleType) {
    initGlobalHeadersSaleType = false;
  }

  if (isMedicalMenuEnabled && saleType === null) {
    setSaleType(DEFAULT_SALE_TYPE);
  }
  if (!isMedicalMenuEnabled && saleType !== null) {
    setSaleType(null);
  }

  return {
    saleType: saleType ?? undefined,
    setSaleType,
    isMedicalMenuEnabled,
  } as UseSaleTypeReturn;
};

const getSaleType = (medicalMenuEnabled: boolean, reqSaleType: StoreSaleType): StoreSaleType => {
  return medicalMenuEnabled ? reqSaleType ?? DEFAULT_SALE_TYPE : undefined;
};

export type { RequiredSaleType };
export { useSaleType, getSaleType, getDefaultSaleType, SALE_TYPE_STORAGE_KEY };
