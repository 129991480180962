import React, { useMemo } from 'react';

import { CacheProvider, EndpointFactoryProviderProps } from '@swe/shared/network/transport/swr';
import { GeolocationProvider } from '@swe/shared/providers/geolocation';
import { HeadProvider, HeadProviderProps } from '@swe/shared/providers/head';
import { LayoutProvider } from '@swe/shared/providers/layout';
import { MediaProvider } from '@swe/shared/tools/media';
import { ErrorBoundary, ErrorBoundaryProps, ErrorBoundaryProvider } from '@swe/shared/ui-kit/components/error-boundary';
import { PortalProvider, PORTALS } from '@swe/shared/ui-kit/components/portal';
import { PromptProvider } from '@swe/shared/ui-kit/components/promt';
import Scrollable from '@swe/shared/ui-kit/components/scrollable';
import ThemeProvider, { ThemeProviderProps } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type SharedProviderProps = ComponentHasChildren & {
  errorFallback?: ErrorBoundaryProps['fallback'];
  onError?: ErrorBoundaryProps['onError'];

  cacheFallback?: EndpointFactoryProviderProps['fallback'];
  revalidateOnInit?: EndpointFactoryProviderProps['revalidateOnInit'];

  getTokens: ThemeProviderProps['getTokens'];
  getClassName: ThemeProviderProps['getClassName'];
  getIconSrc: ThemeProviderProps['getIconSrc'];
  getIllustrationSrc: ThemeProviderProps['getIllustrationSrc'];
  clientWidth: number;
  isIOS?: boolean;
  isAndroid?: boolean;
  head?: HeadProviderProps['head'];
};

const SharedProvider = ({ children, ...props }: SharedProviderProps) => {
  return (
    <ErrorBoundaryProvider
      defaultFallback={props.errorFallback}
      defaultOnError={props.onError}
    >
      <HeadProvider head={props.head}>
        <MediaProvider
          value={useMemo(
            () => ({
              clientWidth: props.clientWidth,
              isIOS: props.isIOS ?? false,
              isAndroid: props.isAndroid ?? false,
            }),
            [props.clientWidth, props.isIOS, props.isAndroid],
          )}
        >
          <ThemeProvider
            getTokens={props.getTokens}
            getClassName={props.getClassName}
            getIconSrc={props.getIconSrc}
            getIllustrationSrc={props.getIllustrationSrc}
          >
            <Scrollable
              isRoot
              fade={false}
            >
              <ErrorBoundary>
                <LayoutProvider>
                  <CacheProvider
                    fallback={props.cacheFallback}
                    revalidateOnInit={props.revalidateOnInit}
                  >
                    <PortalProvider>
                      <GeolocationProvider>
                        <PromptProvider>
                          <ErrorBoundary>{children}</ErrorBoundary>
                          <PORTALS.Common />
                        </PromptProvider>
                      </GeolocationProvider>
                    </PortalProvider>
                  </CacheProvider>
                </LayoutProvider>
              </ErrorBoundary>
            </Scrollable>
          </ThemeProvider>
        </MediaProvider>
      </HeadProvider>
    </ErrorBoundaryProvider>
  );
};

export type { SharedProviderProps };
export { SharedProvider };
export default SharedProvider;
