import Link from '@swe/shared/providers/router/link';
import { Trans } from '@swe/shared/tools/intl/components/trans';
import RowSpacer from '@swe/shared/ui-kit/components/form/row-spacer';

import { Text, TextProps } from '@swe/shared/ui-kit/components/text';

import { useMemo } from 'react';

import { useStoreConfig } from 'common/providers/config';

import { Routes } from 'common/router/constants';
import { usePolicies } from 'common/use-cases/use-policies';
import { Policy } from 'entities/shop/config';

enum AgreementType {
  PrivacyPolicy,
  TermsOfService,
}

const useAvailableAgreements = (): AgreementType[] => {
  const { privacyPolicy, termsOfService } = useStoreConfig();
  return useMemo(() => {
    const res = [];
    if (privacyPolicy) {
      res.push(AgreementType.PrivacyPolicy);
    }
    if (termsOfService) {
      res.push(AgreementType.TermsOfService);
    }
    return res;
  }, [privacyPolicy, termsOfService]) as AgreementType[];
};

const AGREEMENT_LINK: Record<AgreementType, Policy> = {
  [AgreementType.PrivacyPolicy]: {
    title: 'Privacy Policy',
    href: Routes.AgreementsPrivacyPolicy,
  },
  [AgreementType.TermsOfService]: {
    title: 'Terms of Service',
    href: Routes.AgreementsTermsOfService,
  },
};

type AgreementsLinksProps = {
  availableAgreements: AgreementType[];
  spacer?: boolean;
  outerPoliciesLinks: Policy[];
};

const AgreementsLinks = ({ availableAgreements, spacer, outerPoliciesLinks }: AgreementsLinksProps) => {
  let items = outerPoliciesLinks;

  if (!items.length) {
    items = availableAgreements.map((type) => AGREEMENT_LINK[type]);
  }

  return (
    <>
      <span>
        {items.map(({ title, href }, index) => (
          <span key={String(href)}>
            <Link href={href}>{title}</Link>
            {index < items.length - 1 && items.length > 2 && ', '}
            {(index === items.length - 2 || (items.length === 2 && index !== items.length - 1)) && ' and '}
          </span>
        ))}
      </span>
      {spacer && <RowSpacer />}
    </>
  );
};

type AgreementDisclaimerProps = {
  spacer?: boolean;
  type?: 'signIn' | 'signUp' | 'guestSignUp' | 'easySignUp';
} & TextProps<'div'>;

const AgreementDisclaimer = ({ type = 'signIn', spacer = true, ...textProps }: AgreementDisclaimerProps) => {
  const availableAgreements = useAvailableAgreements();
  const { registration } = usePolicies();

  if (availableAgreements.length === 0 && registration.length === 0) {
    return null;
  }

  return (
    <Text {...textProps}>
      <Trans
        id={`common.agreementDisclaimer.${type}`}
        values={{
          0: (
            <AgreementsLinks
              availableAgreements={availableAgreements}
              outerPoliciesLinks={registration}
              spacer={spacer}
            />
          ),
        }}
        defaultMessage="By continuing, you agree to our <0/>"
      />
    </Text>
  );
};

export default AgreementDisclaimer;
export { useAvailableAgreements, AgreementType, AGREEMENT_LINK };
