import { useBreakpoint } from '@swe/shared/tools/media';
import Grid from '@swe/shared/ui-kit/components/grid';
import { Img } from '@swe/shared/ui-kit/components/image';
import Price from '@swe/shared/ui-kit/components/price';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import { Text } from '@swe/shared/ui-kit/components/text';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { getTokenValue } from '@swe/shared/ui-kit/theme/provider/helpers';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

import { Variant } from 'common/components/product-card/components/variants';
import { DisableOrdering } from 'common/containers/disable-ordering';
import { useQuantity } from 'common/providers/cart/use-cases/use-quantity';
import { prepareProductForCard } from 'common/use-cases/product-card';
import { AddProductSkeletonized } from 'domains/product/components/product-details/components/add-to-cart';
import {
  Product,
  ProductVariant,
  getProductName,
  getProductVariantPrice,
  getProductVariant,
} from 'entities/product/product';

type ProductCardListItemProps = {
  product: Product;
  variant?: ProductVariant;
  onProductAdded?: () => void;
} & ComponentHasClassName;

const ProductCardListItem = ({ product, variant: _variant, onProductAdded }: ProductCardListItemProps) => {
  const {
    variant: { name: variantName },
    image,
    hasPriceFrom,
  } = prepareProductForCard(product, _variant);
  const variant = getProductVariant(product, _variant);
  const { getQuantity } = useQuantity(product.id, variant?.id);
  const [price, oldPrice] = getProductVariantPrice(variant, getQuantity());
  const { lessThan } = useBreakpoint();
  const productName = getProductName(product);
  const theme = useTheme();

  return (
    <Grid.Row
      spacing={{ xs: 'sm', sm: 'lg', lg: 'xxl' }}
      hAlign="between"
      vAlign="center"
      wrap
    >
      <Grid.Cell cols={{ xs: 12, md: lessThan.xl ? 6 : 8 }}>
        <Stack
          className={styles.info}
          direction="row"
        >
          <div className={styles.imgWrapper}>
            <Img
              src={image}
              alt={`${product.name} Image`}
              fit="contain"
              ratio={1}
              width={getTokenValue(theme.pdp.stickyTop.product.image.width)}
              className={styles.img}
              emptyImage="placeholder"
            />
          </div>
          <Stack
            direction="column"
            spacing="xxxs"
          >
            <Text
              variant="headline"
              size="xs"
            >
              {productName}
            </Text>
            <Stack
              direction="row"
              spacing="xs"
              className={styles.info}
            >
              {oldPrice && (
                <Text
                  variant="body"
                  size="xl"
                >
                  <Price
                    old={!!oldPrice}
                    value={oldPrice}
                  />
                </Text>
              )}
              <Text
                variant="body"
                size="xl"
              >
                <Price
                  value={price}
                  className={oldPrice || hasPriceFrom ? styles.price_highlighted : undefined}
                  from={hasPriceFrom}
                />
              </Text>
              <Variant
                name={variantName}
                isPacked={variant.isPacked}
              />
            </Stack>
          </Stack>
        </Stack>
      </Grid.Cell>
      <Grid.Cell cols={{ xs: 12, md: lessThan.xl ? 6 : 4 }}>
        <DisableOrdering>
          <div className={styles.action}>
            <AddProductSkeletonized
              product={product}
              variant={variant}
              onProductAdd={onProductAdded}
            />
          </div>
        </DisableOrdering>
      </Grid.Cell>
    </Grid.Row>
  );
};

export type { ProductCardListItemProps };
export { ProductCardListItem };
