import 'vite/modulepreload-polyfill';

import {
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
  reactErrorHandler,
} from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';

import { AppHydrationDataProvider, getAppHydrationData } from './hydration-data-provider';

import { createRoutesDef } from '@swe/shop-ui/app/router';
import GetShopConfigEndpoint from '@swe/shop-ui/endpoints/shop/get-shop-config';
import { ShopConfigDTO } from '@swe/shop-ui/entities/shop/config';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

void (async function hydrate() {
  const context = getAppHydrationData();

  initSentry({
    dsn: 'https://5b90d663b3291dd85d55d7147b011f8b@sentry.kube.sweedpos.com/3',
    environment: context.runtimeConfig.env,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^\/_api\//],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  const storeConfig = context.endpointsFallback?.[GetShopConfigEndpoint.key({})] as ShopConfigDTO;
  const routesDef = createRoutesDef(
    storeConfig && { id: context.storeId, medicalMenuEnabled: storeConfig.medicalMenuEnabled },
  );

  const lazyMatches = matchRoutes(routesDef, window.location, context?.basePath)?.filter((m) => m.route.lazy);
  if (lazyMatches && lazyMatches.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy!();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }

  const router = sentryCreateBrowserRouter(routesDef, { basename: context?.basePath });

  hydrateRoot(
    document.getElementById('app')!,
    <StrictMode>
      <AppHydrationDataProvider value={context}>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </AppHydrationDataProvider>
    </StrictMode>,
    {
      onUncaughtError: reactErrorHandler((error, errorInfo) => {
        // eslint-disable-next-line no-console
        console.warn('Uncaught error', error, errorInfo.componentStack);
      }),
      onCaughtError: reactErrorHandler(),
      onRecoverableError: reactErrorHandler(),
    },
  );
})();
