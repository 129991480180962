import { useProgress } from '@swe/shared/ui-kit/components/carousel-v2/core/use-progress';
import { CarouselProps } from '@swe/shared/ui-kit/components/carousel-v2/types';
import ProgressBarDashed from '@swe/shared/ui-kit/components/carousel/components/dash';
import Pointer from '@swe/shared/ui-kit/components/carousel/components/pointer';

import styles from './styles.module.scss';

type DotsProps = Pick<CarouselProps, 'stepper'> & {
  length: number;
};

const Dots = ({ stepper, length }: DotsProps) => {
  const { selectedIndex, onDotClick } = useProgress();

  switch (stepper) {
    case 'dot':
      return (
        <Pointer
          className={styles.dots}
          length={length}
          current={selectedIndex}
          onClick={onDotClick}
        />
      );
    case 'dash':
      return (
        <ProgressBarDashed
          className={styles.dashes}
          color="neutral"
          size="md"
          value={selectedIndex}
          pieces={length}
          onChange={onDotClick}
        />
      );
    default:
      return null;
  }
};

export type { DotsProps };
export { Dots };
