import { ReactNode } from 'react';

import {
  PropValueByMedia,
  useBreakpointMapValue,
  useViewportBreakpointToValueMap,
} from '@swe/shared/ui-kit/components/media/utils';
import { ComponentHasClassName, ComponentHasStyles } from '@swe/shared/ui-kit/types/common-props';

type VisibleProps = ComponentHasClassName &
  ComponentHasStyles & {
    id?: string | number;
    children: ReactNode;
  } & PropValueByMedia<boolean>;

const Visible = ({ children, className, id, style, ...mediaProps }: VisibleProps) => {
  const visibleMap = useViewportBreakpointToValueMap(mediaProps, false);
  const isVisible = useBreakpointMapValue(visibleMap);

  return isVisible ? (
    <div
      id={id !== undefined ? String(id) : undefined}
      className={className}
      style={style}
    >
      {children}
    </div>
  ) : null;
};

export default Visible;
