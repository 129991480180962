import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { DocumentVerification } from 'entities/documents/documents-v2';

type Params = undefined;
type Data = {
  identities: DocumentVerification[];
  medicalPermits: DocumentVerification[];
  additionalPermits: DocumentVerification[];
};
type Error = APIErrorExtended;

const NAME = '/Documents/Get';
const GetDocumentsEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export type { Data };
export default GetDocumentsEndpoint;
