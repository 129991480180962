import Button from '@swe/shared/ui-kit/components/button';
import { MinusIcon, PlusIcon } from '@swe/shared/ui-kit/components/icon';

type ZoomControlProps = {
  mode: 'in' | 'out';
  onClick?: () => void;
};

const ZoomControl = ({ mode, onClick }: ZoomControlProps) => {
  return (
    <Button
      size="sm"
      color="light"
      icon={mode === 'in' ? PlusIcon : MinusIcon}
      onClick={onClick}
      ariaLabel={mode === 'in' ? 'Zoom in' : 'Zoom out'}
    />
  );
};

export type { ZoomControlProps };
export { ZoomControl };
export default ZoomControl;
