import Link from '@swe/shared/providers/router/link';
import { Img } from '@swe/shared/ui-kit/components/image';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { Text } from '@swe/shared/ui-kit/components/text';

import { useCallback, MouseEvent } from 'react';

import styles from './styles.module.scss';

import { ShopRoute } from 'common/router/constants';
import { Product, ProductVariant } from 'entities/product/product';

type Type = 'product' | 'other';

export type ItemProps<T extends Type = Type> = {
  type: T;
  link: T extends 'product' ? [Product, ProductVariant] : ShopRoute;
  onClick: (args: { type: T; link: T extends 'product' ? [Product, ProductVariant] : ShopRoute }) => void;
  imgSrc?: string;
  name: string;
  caption?: string;
  search?: string;
};

const escapeRegex = (str: string) => {
  return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const Item = <T extends Type>({ imgSrc, type, caption, name, link, search, onClick }: ItemProps<T>) => {
  const _name = name || '';
  let parts = [_name];
  if (search && search.length) {
    const idx = _name.toLowerCase().indexOf(search.toLowerCase());
    if (idx !== -1) {
      parts = _name.split(new RegExp(`(${escapeRegex(search)})`, 'gi'));
    }
  }
  const _onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onClick({ type, link });
    },
    [link, onClick, type],
  );

  const Content = (
    <Locator
      locatorName="item"
      className={styles.link}
      onClick={_onClick}
    >
      {(type === 'product' || imgSrc) && (
        <Img
          width={58}
          className={styles.img}
          alt={`${name} Image`}
          src={imgSrc}
          emptyImage="placeholder"
          ratio={1}
        />
      )}
      <div className={styles.textBlock}>
        <Locator
          as={Text}
          locatorName="title"
          variant="control"
          size="lg"
        >
          {parts.map((item, index) => (
            <span
              key={index}
              className={item.toLowerCase() === search?.toLocaleLowerCase() ? styles.search : undefined}
            >
              {item}
            </span>
          ))}
        </Locator>
        {caption && <Text className={styles.desc}>{caption}</Text>}
      </div>
    </Locator>
  );

  return type === 'other' ? <Link href={link as ShopRoute}>{Content}</Link> : Content;
};

export default Item;
