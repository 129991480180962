import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef, useState } from 'react';

import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';

const usePreviousBy = <ValueT>(value: ValueT, pred: (val: ValueT) => boolean) => {
  const [lastValue, setLastValue] = useState(value);

  useDeepCompareEffect(() => {
    if (pred(value)) {
      setLastValue(value);
    }
  }, [value]);

  return lastValue;
};

const usePreviousTruly = <ValueT>(value: ValueT) => usePreviousBy(cloneDeep(value), (value) => !!value);

const usePreviousDefined = <ValueT>(value: ValueT) => usePreviousBy(cloneDeep(value), (value) => value !== undefined);

const usePreviousAny = <ValueT>(value: ValueT) => usePreviousBy(cloneDeep(value), () => true);

const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { usePreviousBy, usePreviousTruly, usePreviousDefined, usePreviousAny, usePrevious };
