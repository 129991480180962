import { Button } from '@swe/shared/ui-kit/components/button';
import Scrollable from '@swe/shared/ui-kit/components/scrollable';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useMemo } from 'react';

import { AddToCartProps } from 'common/components/add-to-cart';

const QUANTITIES = [2, 3.5, 5, 7];

const buttonStyles = {
  flex: `0 1 ${100 / QUANTITIES.length}%`,
};

type QuantitySuggestionsProps = {
  quantity: number;
  onChange?: (nextQuantity: number) => void;
} & Pick<AddToCartProps, 'availableQuantity'>;

const QuantitySuggestions = ({ quantity, availableQuantity, onChange }: QuantitySuggestionsProps) => {
  const quantities = useMemo(() => {
    const unreachableQtyIndex = QUANTITIES.findIndex((qty) => qty > availableQuantity);
    if (unreachableQtyIndex > -1) {
      return QUANTITIES.slice(0, unreachableQtyIndex);
    }
    return QUANTITIES;
  }, [availableQuantity]);

  return quantities.length ? (
    <Scrollable
      direction="horizontal"
      fade
    >
      <Stack
        direction="row"
        spacing="xxxs"
      >
        {quantities.map((qty) => (
          <Button
            name="addSomeGrams"
            size="sm"
            color={quantity === qty ? 'neutral' : 'light'}
            style={buttonStyles}
            onClick={() => onChange?.(qty)}
          >{`${qty} G`}</Button>
        ))}
      </Stack>
    </Scrollable>
  ) : null;
};

export { QuantitySuggestions as GramsSuggestions };
export default QuantitySuggestions;
