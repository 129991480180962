import { RoutePath } from '@swe/shared/providers/router/constants';
import Link from '@swe/shared/providers/router/link';
import { Badge } from '@swe/shared/ui-kit/components/badge';
import { DatePresenter } from '@swe/shared/ui-kit/components/date-presenter';
import { ShopIcon, CalendarIcon } from '@swe/shared/ui-kit/components/icon';
import Price from '@swe/shared/ui-kit/components/price';
import Stack from '@swe/shared/ui-kit/components/stack';
import { Text } from '@swe/shared/ui-kit/components/text';
import { usePatternTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import { useMemo } from 'react';

import styles from './styles.module.scss';

import { useStoreConfig } from 'common/providers/config';
import { Routes } from 'common/router/constants';
import { getBadgeColor, matchActiveOrderStatusToType } from 'common/utils/order';
import { prepareDeliveryData } from 'domains/profile/use-cases/use-courier-location';
import { prepOrderDate, prepFulfillmentInformation, getOrderNumber } from 'domains/profile/use-cases/use-order-details';
import { Order, OrderProcessStatus } from 'entities/common/orders';
import { getOrderStatusLabel } from 'entities/profile/orders';

const getClassNameByStatus = (status: OrderProcessStatus) => {
  return matchActiveOrderStatusToType(status, {
    active: styles._status_active,
    finished: styles._status_finished,
  });
};

type OrderCardProps = ComponentHasClassName & {
  order: Order;
};

const OrderCard = ({ className, order }: OrderCardProps) => {
  const { timezoneShiftInHrs, id: currentStoreId } = useStoreConfig();
  const orderStatus = order.statusTitle ?? getOrderStatusLabel(order.status);
  const deliveryInfo = prepFulfillmentInformation(
    order,
    prepareDeliveryData(order, order.eta, order.courier?.coordinates),
    timezoneShiftInHrs,
  );
  const dateString = prepOrderDate(order);
  const { className: patternClassName } = usePatternTheme('order-card');

  const scheduleInfoElement = useMemo(() => {
    if (!deliveryInfo || !deliveryInfo.scheduleInfo) {
      return null;
    }

    const [deliveryDay, deliveryTime] = deliveryInfo.scheduleInfo.split(' / ');

    return (
      <Stack
        direction="row"
        spacing="xxs"
      >
        <CalendarIcon />
        <div>
          <Text
            size="lg"
            adaptive
          >
            {deliveryDay}
          </Text>
          <Text
            size="lg"
            className={styles.processStatus}
            adaptive
          >
            {deliveryTime}
          </Text>
        </div>
      </Stack>
    );
  }, [deliveryInfo]);

  return (
    <Link
      className={cx(
        className,
        patternClassName,
        styles.root,
        styles._interactive,
        getClassNameByStatus(order.processStatus),
      )}
      target={order.store?.id !== currentStoreId ? '_blank' : '_self'}
      href={{
        pathname: Routes.ProfileOrderDetails,
        query: { id: String(order.id) },
        basePath: order.store?.id !== currentStoreId ? (order.store?.url as RoutePath) ?? undefined : undefined,
      }}
    >
      <Stack spacing="sm">
        <div className={styles.heading}>
          <div>
            <Text
              variant="headline"
              size="sm"
              adaptive
            >
              Order #{getOrderNumber(order)}
            </Text>
            {!!dateString && (
              <Text
                className={styles.placementDate}
                size="lg"
                adaptive
              >
                <DatePresenter
                  value={dateString}
                  format="MMMM d, h:mm a"
                />
              </Text>
            )}
          </div>
          <div>
            <Text
              variant="headline"
              size="sm"
              adaptive
            >
              <Price value={order.priceTotal} />
            </Text>
            <Text
              className={styles.placementDate}
              size="lg"
              align="right"
            >
              {order.itemsQuantity} {order.itemsQuantity === 1 ? 'item' : 'items'}
            </Text>
          </div>
        </div>
        <div className={styles.footer}>
          {deliveryInfo && (
            <Stack
              direction="row"
              spacing="xs"
              wrap
            >
              <Stack
                direction="row"
                spacing="xxs"
              >
                <ShopIcon />
                <div>
                  <Text
                    size="lg"
                    adaptive
                  >
                    {order.store?.name}
                  </Text>
                  <Text
                    size="lg"
                    className={styles.processStatus}
                    adaptive
                  >
                    {deliveryInfo.type}
                  </Text>
                </div>
              </Stack>
              {scheduleInfoElement}
            </Stack>
          )}
          <Badge
            className={styles.badge}
            size="md"
            color={getBadgeColor(order.processStatus)}
          >
            {orderStatus}
          </Badge>
        </div>
      </Stack>
    </Link>
  );
};

export type { OrderCardProps };
export { OrderCard };
