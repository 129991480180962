import cx from 'clsx';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { Button } from '@swe/shared/ui-kit/components/button';
import { Input } from '@swe/shared/ui-kit/components/form/input';

import { SearchIcon } from '@swe/shared/ui-kit/components/icon';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type Option = {
  label?: ReactNode;
};

type FilterableListProps<OT extends Option> = ComponentHasClassName & {
  options: OT[];
  children: (options: OT[]) => ReactNode;

  searchable?: boolean;
  searchQuery?: string;
  searchPlaceholder?: string;
  search?: (q: string, o: OT) => boolean;
  onSearch?: (query: string) => void;

  expandable?: boolean;
  expanded?: boolean;
  collapsedOptionsVisible?: number;
  onToggle?: (expanded?: boolean) => void;
};
type FilterableListPropsCommon<OT extends Option> = Omit<FilterableListProps<OT>, 'children'>;

const SEARCHABLE_FROM = 15;
const EXPANDABLE_FROM = 8;
const COLLAPSED_OPTIONS_VISIBLE_DEFAULT = 4;
const DEFAULT_SEARCH = <OT extends Option>(query: string, option: OT) => {
  try {
    const q = query.toLowerCase();
    const o = typeof option.label === 'string' ? option.label.toLowerCase() : '';

    return o.includes(q);
  } catch (e) {
    return false;
  }
};

const FilterableList = <OT extends Option>({
  className,
  options,
  children,

  searchable: _searchable,
  searchQuery,
  searchPlaceholder,
  search = DEFAULT_SEARCH,
  onSearch,

  expandable: _expandable,
  expanded,
  collapsedOptionsVisible: _collapsedOptionsVisible,
  onToggle,
}: FilterableListProps<OT>) => {
  const [innerSearchQuery, setInnerSearchQuery] = useState('');
  const [innerExpanded, setInnerExpanded] = useState(false);

  const query = searchQuery ?? innerSearchQuery;
  const isExpanded = query !== '' || (expanded ?? innerExpanded);
  const expandable = _expandable ?? options.length >= EXPANDABLE_FROM;
  const searchable = _searchable ?? options.length >= SEARCHABLE_FROM;
  const collapsedOptionsVisible = _collapsedOptionsVisible ?? COLLAPSED_OPTIONS_VISIBLE_DEFAULT;

  const filteredOptions = useMemo(
    () => (searchable && query ? options.filter((o) => search(query, o)) : options),
    [options, query, search, searchable],
  );
  const isShowMoreButtonVisible = !query && expandable && filteredOptions.length > collapsedOptionsVisible;
  const finalOptions = useMemo(
    () =>
      expandable
        ? filteredOptions.slice(0, isExpanded ? filteredOptions.length : collapsedOptionsVisible)
        : filteredOptions,
    [expandable, filteredOptions, isExpanded, collapsedOptionsVisible],
  );

  const handleQueryChange = useCallback(
    (q: string) => {
      if (typeof searchQuery !== 'undefined') {
        onSearch?.(q);
      } else {
        setInnerSearchQuery(q);
      }
    },
    [onSearch, searchQuery],
  );
  const toggleVisibility = useCallback(() => {
    if (typeof expanded !== 'undefined') {
      onToggle?.(!isExpanded);
    } else {
      setInnerExpanded(!isExpanded);
    }
  }, [expanded, isExpanded, onToggle]);

  return (
    <div className={cx(styles.root, className)}>
      {searchable && (
        <div className={styles.search}>
          <Input
            value={query}
            name="_search"
            size="md"
            onChange={handleQueryChange}
            icon={SearchIcon}
            label={false}
            placeholder={searchPlaceholder}
            staticNote={false}
            ariaLabel={searchPlaceholder ?? 'Search'}
          />
        </div>
      )}
      {children(finalOptions)}
      {isShowMoreButtonVisible && (
        <Text
          size="md"
          className={styles.actions}
        >
          <Button
            variant="link"
            size="xs"
            onClick={toggleVisibility}
            ariaLabel={isExpanded ? 'Show less' : 'Show more'}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        </Text>
      )}
    </div>
  );
};

export type { FilterableListProps, FilterableListPropsCommon };
export { FilterableList };
export default FilterableList;
