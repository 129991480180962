import { isAfter, utcDate } from '@swe/shared/utils/date';

import { GetCourierLocation } from 'endpoints/profile/orders/get-courier-location';
import { Order } from 'entities/common/orders';
import { isInDelivery } from 'entities/profile/orders';

const prepareCourierEta = (driverEta: DateISOString) => utcDate(driverEta);
const prepareOrderEta = (
  scheduleDate: Order['scheduleDate'],
  scheduleInterval: Order['scheduleInterval'] = ['00:00', '00:00'],
) => {
  const dateTimeString = `${scheduleDate}T${scheduleInterval[1]}:00Z`;

  return utcDate(dateTimeString);
};
const isCourierDelayed = (driverEta: Date, orderEta: Date) => {
  return isAfter(driverEta, orderEta);
};

const prepareDeliveryData = (order?: Order, eta?: string, location?: LatLng) => {
  const courierEta = eta ? prepareCourierEta(eta) : null;
  const orderEta = order ? prepareOrderEta(order.scheduleDate, order.scheduleInterval) : null;
  const isDelayed = orderEta && courierEta ? isCourierDelayed(courierEta, orderEta) : false;

  return {
    location: location ?? null,
    eta: courierEta,
    isDelayed,
  };
};

type CourierDeliveryData = {
  location: LatLng | null;
  eta: Date | null;
  isDelayed: boolean;
};

const useCourierLocation = (order?: Order): CourierDeliveryData => {
  const isDelivering = order ? isInDelivery(order.processStatus) : false;
  const { data: driverData } = GetCourierLocation.useRequest(isDelivering ? { orderId: order!.id } : null, undefined, {
    refreshInterval: 5000,
  });

  const eta = driverData?.eta ? `${driverData?.eta}Z` : order?.eta;

  return prepareDeliveryData(order, eta, driverData?.coordinates ?? order?.courier?.coordinates);
};

export type { CourierDeliveryData };

export { useCourierLocation, prepareDeliveryData };
