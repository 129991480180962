import cn from 'clsx';
import {
  createElement,
  ReactNode,
  ElementType,
  HTMLProps,
  forwardRef,
  ForwardedRef,
  LegacyRef,
  useCallback,
  KeyboardEvent,
} from 'react';

import { Colors, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { isEnterOrSpacePressed } from '@swe/shared/utils/keyboard';

import styles from './styles.module.scss';

export type LinkColor = Colors<'neutral' | 'primary' | 'success' | 'warning' | 'danger' | 'ghost'> | 'none';

type AProps = HTMLProps<HTMLAnchorElement>;

export type LinkBaseProps = {
  id?: string;
  children?: ReactNode;
  color?: LinkColor;
  focused?: boolean;
  hovered?: boolean;
  active?: boolean;
  onClick?: (e: any) => void;
} & ComponentHasClassName;

export type LinkProps<P = AProps> = LinkBaseProps & {
  as?: ElementType<P>;
} & Omit<P, keyof LinkBaseProps | 'as'>;

const buildAriaLabel = (children?: string) => {
  if (!children) {
    return `External url that opens new window`;
  }

  return `${children} (Opens new window)`;
};

const _Link = <P extends ComponentHasClassName = AProps>(
  { className, color = 'primary', hovered, focused, active, as, ...asProps }: LinkProps<P>,
  ref: ForwardedRef<any>,
) => {
  const isTargetBlank = 'target' in asProps && (asProps as AProps).target === '_blank';
  const isStringChildren = typeof asProps.children === 'string';
  const hasAriaLabel = 'aria-label' in asProps;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isEnterOrSpacePressed(e)) {
        asProps.onClick?.(e);
      }
    },
    [asProps],
  );

  return createElement(as ?? 'a', {
    className: cn(
      styles.root,
      color !== 'none' && styles[`_color_${color}`],
      {
        [styles._hovered]: hovered,
        [styles._active]: active,
      },
      className,
    ),
    ...(asProps as unknown as P),
    tabIndex: 0,
    onKeyDown: handleKeyDown,
    rel:
      'rel' in asProps && !!(asProps as AProps).rel
        ? (asProps as AProps).rel
        : isTargetBlank
          ? 'external noopener noreferrer'
          : undefined,
    'aria-label': hasAriaLabel
      ? (asProps as AProps)['aria-label']
      : isTargetBlank
        ? buildAriaLabel(isStringChildren ? (asProps.children as string) : undefined)
        : undefined,
    ref,
  });
};

export const Link = forwardRef(_Link) as <P = AProps>(
  props: LinkProps<P> & { ref?: React.ForwardedRef<any> | LegacyRef<any> },
) => ReturnType<typeof _Link>;

export default Link;
