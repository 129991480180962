import cx from 'clsx';
import React, { useCallback, useMemo, useState, ReactNode } from 'react';

import Text from '@swe/shared/ui-kit/components/text';

import styles from './styles.module.scss';
import { Column } from './types';

type RowProps<DataItem> = {
  row: DataItem;
  rowIdx: number;
  columns: Column<DataItem>[];
  onRowClick?: (this: void, row: DataItem) => void | false;
};

const Row = <DataItem,>({ row, rowIdx, columns, onRowClick }: RowProps<DataItem>) => {
  const isRowHoverable = useMemo(() => columns.some((column) => column.clickable !== false), [columns]);
  const [isRowHovered, setIsRowHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (onRowClick && isRowHoverable) {
      setIsRowHovered(true);
    }
  }, [isRowHoverable, onRowClick]);

  const handleMouseLeave = useCallback(() => {
    if (onRowClick && isRowHoverable) {
      setIsRowHovered(false);
    }
  }, [isRowHoverable, onRowClick]);

  const columnsContentRaw = useMemo(() => {
    return columns.map(({ name, render }) => {
      return render ? render({ value: row[name], row, rowIdx, isRowHovered }) : row[name];
    });
  }, [columns, row, rowIdx, isRowHovered]);

  const columnsContent = useMemo(() => {
    return columnsContentRaw.map((columnContent) =>
      Array.isArray(columnContent)
        ? columnContent.reduce<(ReactNode | [ReactNode, number])[]>((acc, item) => {
            const [content, count] = Array.isArray(item) ? item : [item, 1];
            const contents = Array(count).fill(content);
            return acc.concat(contents);
          }, [])
        : columnContent,
    );
  }, [columnsContentRaw]);

  const maxRowSpan = Math.max(...columnsContent.map((item) => (Array.isArray(item) ? item.length : 1)));

  const columnsContentToRowSpansMap = useMemo(() => {
    return columnsContentRaw.map((colContent) => {
      if (!Array.isArray(colContent)) {
        return [maxRowSpan];
      }
      return colContent
        .reduce<(number | undefined)[]>((acc, item) => {
          const [, count] = Array.isArray(item) ? item : [item, 1];
          const rowSpans = Array(count).fill(count, 0, 1);
          return acc.concat(rowSpans as (number | undefined)[]);
        }, [])
        .concat(Array(maxRowSpan).fill(1))
        .slice(0, maxRowSpan);
    });
  }, [columnsContentRaw, maxRowSpan]);

  return (
    <>
      {Array(maxRowSpan)
        .fill(null)
        .map((_, trIdx) => {
          return (
            <tr
              key={trIdx}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={cx(styles.row, {
                [styles.row_hovered]: isRowHovered,
              })}
            >
              {columns.map(({ name, hAlign = 'left', vAlign, clickable = true, className }, idx) => {
                const colContent = columnsContent[idx];
                const content = Array.isArray(colContent) ? colContent[trIdx] : colContent;
                const rowSpan = columnsContentToRowSpansMap[idx][trIdx];
                const isCellClickable = clickable && onRowClick;

                return (
                  rowSpan && (
                    <td
                      key={name}
                      className={cx(styles.cell, className, {
                        [styles[`cell_h-align_${hAlign}`]]: hAlign,
                        ...(vAlign ? { [styles[`cell_v-align_${vAlign}`]]: vAlign } : {}),
                        [styles.cell_clickable]: isCellClickable,
                      })}
                      rowSpan={rowSpan > 1 ? rowSpan : undefined}
                    >
                      <Text
                        variant="body"
                        size="lg"
                        align={hAlign}
                      >
                        {content}
                      </Text>
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}
    </>
  );
};

export type { RowProps };
export { Row };
