import { useIsAndroid, useIsIOS } from '@swe/shared/tools/media';

import { useIsPwa } from 'common/providers/pwa/use-is-pwa';

const usePlatformOs = () => {
  const isInStandaloneMode = useIsPwa();
  const isIOS = useIsIOS();
  const isAndroid = useIsAndroid();
  const os = isIOS ? 'Ios' : isAndroid ? 'Android' : '';
  return { platformOs: [isInStandaloneMode ? 'pwa' : 'web', os].filter(Boolean).join('') as PlatformOs };
};

type PlatformOs = 'web' | 'webIos' | 'webAndroid' | 'pwaIos' | 'pwaAndroid' | 'pwa';

export { usePlatformOs };

export type { PlatformOs };
