import { useHead } from '@swe/shared/providers/head';

type PageMetaProps = {
  title: string;
  description?: string;
  keywords?: string[];
  links?: {
    rel: string;
    href: string;
  }[];
};

export const PageMeta = ({ title, description, keywords, links }: PageMetaProps) => {
  const Head = useHead();

  return (
    <Head>
      <title>{title}</title>
      {description && (
        <meta
          name="description"
          content={description}
        />
      )}
      {keywords && (
        <meta
          name="keywords"
          content={keywords.join(', ')}
        />
      )}
      {links &&
        links.map((link, index) => (
          <link
            key={index}
            {...link}
          />
        ))}
    </Head>
  );
};
