import cn from 'clsx';

import { ComponentHasClassName, ComponentHasSize, Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type DotSize = Sizes<'md' | 'lg'>;

type DotProps = ComponentHasClassName & {
  index: number;
  active?: boolean;
  disabled?: boolean;
  onClick?: (index: number) => void;
} & ComponentHasSize<DotSize>;

const Dot = ({ index, className, size = 'md', active, disabled, onClick }: DotProps) => {
  return (
    <button
      className={cn(
        styles.root,
        styles[`_size_${size}`],
        disabled && styles._disabled,
        active && styles._active,
        className,
      )}
      tabIndex={0}
      disabled={disabled}
      onClick={() => onClick?.(index)}
    >
      {index}
    </button>
  );
};

export { Dot };
export type { DotSize, DotProps };
export default Dot;
