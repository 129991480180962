import { FCMService, InitConfig } from '@swe/shared/tools/firebase';

const FIREBASE_CONFIG: InitConfig = {
  apiKey: 'AIzaSyDFidbnpP88HbKHCpKw-hChqLsvzxIVnCY',
  authDomain: 'sd-technologies-inc.firebaseapp.com',
  projectId: 'sd-technologies-inc',
  storageBucket: 'sd-technologies-inc.appspot.com',
  messagingSenderId: '83047725472',
  appId: '1:83047725472:web:1cc966c29a31dd900b4cc5',
  measurementId: 'G-J6S83F31DX',
};

const firebase = new FCMService(FIREBASE_CONFIG);

export { firebase, FIREBASE_CONFIG };
