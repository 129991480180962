import cx from 'clsx';

import React, { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { mergeRefs } from 'react-merge-refs';

import { useWasMounted } from '@swe/shared/hooks/use-mounted';
import BoxRatio from '@swe/shared/ui-kit/components/box-ratio';
import placeholderIcon from '@swe/shared/ui-kit/components/image/assets/placeholder.svg';
import SVG from '@swe/shared/ui-kit/components/svg';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';

import styles from './styles.module.scss';

const isProbablyCached = (src: ImgProps['src']) => {
  if (!src || isSSR) return true;

  const image = new Image();
  image.loading = 'lazy';
  image.src = src;

  return image.complete;
};

type ImgProps = ComponentHasClassName & {
  src?: string | null;
  alt: string;
  title?: ReactNode;
  onLoad?: () => void;
  loading?: boolean;
  fit?: 'cover' | 'contain';
  lazy?: boolean;
  emptyImage?: 'alt' | 'placeholder';
} & ({ width: number; ratio: number } | { width?: number; ratio?: undefined });

const isImageDataSrc = (src: string) => src.startsWith('data:image');
const widthPresets = [320, 640, 960, 1280, 2560, 3840, 5120, 7680];
const getWidthPreset = (width: number) => {
  const maxWidth = 7680;
  const closestPreset = widthPresets.filter((preset) => width <= preset)[0];
  return closestPreset ?? maxWidth;
};

const Img = forwardRef<HTMLImageElement, ImgProps>(
  (
    { lazy = true, className, ratio, emptyImage = 'alt', src, alt, onLoad, fit = 'cover', title, loading, width },
    ref,
  ) => {
    const finalSrc = useMemo(() => {
      let finalSrc = src;
      if (src && !isImageDataSrc(src)) {
        finalSrc = src.replace('sweedpos.s3.amazonaws.com', 'static.sweedpos.com');
        if (width) {
          finalSrc = `${finalSrc}?${new URLSearchParams({ width: String(getWidthPreset(width)) })}`;
        }
      }
      return finalSrc;
    }, [src, width]);
    const _ref = useRef<HTMLImageElement>(null!);
    const [isLoaded, setIsLoaded] = useState(isProbablyCached(src));
    const [isCached, setIsCached] = useState(isProbablyCached(src));
    const [isError, setIsError] = useState(!src);
    const wasMounted = useWasMounted();

    const handleLoad = useCallback(() => {
      onLoad?.();
      if (!isCached) {
        setIsLoaded(!!src);
      }
    }, [onLoad, src, isCached]);

    const handleError = useCallback(() => setIsError(true), []);

    useEffect(() => {
      setIsError(!src);
    }, [src]);

    useEffect(() => {
      if (_ref.current && (_ref.current.complete || _ref.current.naturalHeight + _ref.current.naturalWidth > 0)) {
        if (onLoad) {
          setTimeout(onLoad);
        }
        setIsCached(true);
      }
    }, [onLoad, src]);

    const isDataUrl = (src || '').startsWith('data:');
    const roundedWidth = width ? Math.ceil(width) : undefined;

    const Image = useMemo(
      () => (
        <img
          className={styles.image}
          ref={mergeRefs([ref, _ref])}
          width={ratio && roundedWidth ? roundedWidth : undefined}
          height={ratio && roundedWidth ? roundedWidth / ratio : undefined}
          alt={alt}
          src={finalSrc ?? undefined}
          draggable={false}
          onLoad={handleLoad}
          onError={handleError}
          loading={lazy ? 'lazy' : 'eager'}
        />
      ),
      [ref, alt, handleLoad, lazy, handleError, roundedWidth, ratio, finalSrc],
    );

    const classNameCompound = cx(
      styles.root,
      className,
      ratio && styles._ratio,
      loading && styles._loading,
      (!lazy || wasMounted) && (isLoaded || isDataUrl) && styles._loaded,
      (!lazy || wasMounted) && isCached && styles._cached,
      isError && styles._error,
      !!ratio && styles[`_fit_${fit}`],
    );

    return ratio ? (
      <BoxRatio
        className={classNameCompound}
        ratio={ratio}
      >
        {Image}
        <div
          role={src ? undefined : 'img'}
          aria-label={src ? undefined : alt}
          className={styles.placeholder}
        >
          {emptyImage === 'alt' && (title ?? alt)}
          {emptyImage === 'placeholder' && (
            <SVG
              src={placeholderIcon}
              role="presentation"
              width="70%"
              height="70%"
              className={styles.placeholderImage}
            />
          )}
        </div>
      </BoxRatio>
    ) : (
      <div className={classNameCompound}>{Image}</div>
    );
  },
);

export { Img };
export type { ImgProps };
export default Img;
