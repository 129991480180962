import { EndpointRequest } from '@swe/shared/network/transport/swr/transport.types';
import { SnackbarService } from '@swe/shared/providers/snackbar';

import { showCode } from 'entities/common/show-code';

const resend = async <T1 = any, T2 = any, T3 extends { nextTryInSeconds: number; code?: string | null } = any>(
  request: EndpointRequest<T1, T2, T3>,
  { params, config }: { params: T2; config?: T1 },
) => {
  let nextTryInSeconds = 0;
  try {
    const res = await request(params, { ...config, notifyWithSnackbar: false } as T1);
    showCode(res);
    nextTryInSeconds = res.nextTryInSeconds;
  } catch (e) {
    const errorData = e as { details?: { NextTryInSeconds?: string }; message: string };
    if (errorData.details?.NextTryInSeconds) {
      nextTryInSeconds = parseInt(errorData.details.NextTryInSeconds, 10);
      SnackbarService.push({
        type: 'warning',
        message: errorData.message,
      });
    }
  }

  return nextTryInSeconds;
};

export { resend };
