import { get } from '@swe/shared/utils/object';

const LOADED_EXTERNAL_SCRIPTS: Record<string, Promise<any>> = {};

const loadExternalGlobalScript = <T = any>(
  src: string,
  globalName: string,
  initCb?: (sdk: T) => Promise<void> | void,
  async?: boolean,
): Promise<T> => {
  if (src in LOADED_EXTERNAL_SCRIPTS) {
    return LOADED_EXTERNAL_SCRIPTS[src];
  }

  LOADED_EXTERNAL_SCRIPTS[src] = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    if (typeof async !== 'undefined') {
      script.async = async;
    }
    script.onload = async () => {
      const sdk = get(window, globalName) as T;
      await initCb?.(sdk);
      resolve(get(window, globalName) as T);
    };
    script.onerror = () => {
      delete LOADED_EXTERNAL_SCRIPTS[src];
      reject(new Error(`External script (${src}) was not loaded`));
    };
    document.body.appendChild(script);
  });

  return LOADED_EXTERNAL_SCRIPTS[src];
};

export { loadExternalGlobalScript };
