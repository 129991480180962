import { usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback } from 'react';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

const useMedicalPurchaseConfirmation = () => {
  const { confirm } = usePrompt();
  const navigate = useRouterNavigate();

  const handleConfirm = useCallback(async () => {
    const res = await confirm({
      cancelLabel: 'Cancel',
      confirmLabel: 'Add medical ID',
      content: 'State-issued medical ID required for medical purchase',
      heading: 'Patients only',
      ariaLabel: 'Patients only',
    });
    if (res) {
      await navigate(
        {
          pathname: Routes.ProfileAddDocuments,
          query: {
            type: 'MedicalPermit',
            r: Routes.Cart,
          },
        },
        { replace: true },
      );
    }
  }, [confirm, navigate]);

  return {
    confirmMedicalPurchase: handleConfirm,
  };
};

export { useMedicalPurchaseConfirmation };
