import cx from 'clsx';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import InputWrapper, { InputWrapperProps } from '@swe/shared/ui-kit/components/form/wrapper/input';

import { CloseIcon, DocumentsIcon, ImageIcon, UploadIcon } from '@swe/shared/ui-kit/components/icon';

import styles from './styles.module.scss';

enum InputFileAccept {
  Image = '.png, .jpg, .jpeg, .heic, .heif, .gif',
}

type StoredFile = {
  name: string;
  src?: string;
};

type InputFileProps = Omit<InputWrapperProps, 'children' | 'actions' | 'icon' | 'collapsed'> &
  Omit<FormControl<File | null>, 'value'> & {
    placeholder?: string;
    accept?: InputFileAccept;
    value: File | StoredFile | null;
  };

type InputFileValue = File | StoredFile | null;

const isFile = (v: InputFileValue): v is File => !!v && v instanceof File;
const isFileLikeSource = (v: InputFileValue): v is StoredFile => !isFile(v);

const InputFile = ({
  value,
  label,
  disabled,
  error,
  note,
  name,
  className,
  size = 'lg',
  placeholder,
  required,
  onChange,
  onBlur,
  accept,
}: InputFileProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const valueLabel = useMemo(() => {
    return value?.name;
  }, [value]);

  const imagePreview = useMemo(() => {
    if (value instanceof File) {
      const isImage = value.type?.startsWith('image/');
      if (isImage) {
        return URL.createObjectURL(value);
      }
    } else if (value && 'src' in value && value.src !== undefined) {
      // const isImage = /\.(jpg|jpeg|png|svg)/.test(value.src);
      // if (isImage) {
      //   return value.src;
      // }

      return value.src;
    }
    return undefined;
  }, [value]);

  const changeHandler = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange?.(ev.currentTarget.files?.[0] ?? null);
    },
    [onChange],
  );

  const clearHandler = useCallback(() => {
    onChange?.(null);
  }, [onChange]);

  const chooseFileHandler = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const typedIcon = useMemo(() => {
    switch (accept) {
      case InputFileAccept.Image:
        return ImageIcon;
      default:
        return DocumentsIcon;
    }
  }, [accept]);

  return (
    <InputWrapper
      className={cx(className, styles.root)}
      name={name}
      size={size}
      label={label}
      required={required}
      disabled={disabled}
      error={error}
      note={note}
      actions={
        value ? [{ icon: CloseIcon, onClick: clearHandler }] : [{ icon: UploadIcon, onClick: chooseFileHandler }]
      }
      icon={imagePreview ?? typedIcon}
    >
      {valueLabel ? (
        <div className={styles.value}>{valueLabel}</div>
      ) : (
        <div className={styles.placeholder}>{placeholder ?? 'Choose file'}</div>
      )}
      <input
        key={value?.name}
        ref={inputRef}
        accept={accept}
        className={styles.input}
        type="file"
        onChange={changeHandler}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
      />
    </InputWrapper>
  );
};

export type { InputFileProps, InputFileValue, StoredFile };
export { InputFile, InputFileAccept, isFile, isFileLikeSource };
export default InputFile;
