import { yup } from '@swe/shared/ui-kit/components/form';

const PASSWORD_MATCHING_NOTE =
  'Password must contain 8 characters. Use one uppercase, one lowercase, one numeric, and one special character';

const PASSWORD_MATCHING_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!“#$%&‘()*+,-./\\:;<=>?@[\]^_`{|}~])[A-Za-z\d!“#$%&‘()*+,-./\\:;<=>?@[\]^_`{|}~]{8,}$/;

const MEDICAL_CARD_ID_FLORIDA_PREFIX = 'P';
const MEDICAL_CARD_ID_REQUIRED_NOTE = 'Patient ID is required';
const MEDICAL_CARD_ID_INVALID = 'Invalid patient ID';
const MEDICAL_CARD_ID_MATCHING_NOTE = 'Patient ID must contain only letters and digits';
const MEDICAL_CARD_NUMBER_ID_REGEXP = /^[A-Za-z0-9]+$/;
const MEDICAL_CARD_NUMBER_ID_LENGTH_REGEXP = /^[A-Za-z0-9]{1,16}$/;

const getMinimalCustomerAgeDate = (minimalCustomerAge: number): Date => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - minimalCustomerAge);
  return currentDate;
};

const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

const MIN_DATE_OF_BIRTH = new Date(TODAY);
MIN_DATE_OF_BIRTH.setFullYear(MIN_DATE_OF_BIRTH.getFullYear() - 120);

type BirthdaySchemaOptions = {
  minimalAgeError?: (minAge: number) => string;
  requiredError?: string;
};

const BASE_BIRTHDAY_SCHEMA = ({
  minimalAgeError = (minAge: number) => `You must be at least ${minAge} to sign up`,
}: Pick<BirthdaySchemaOptions, 'minimalAgeError'> = {}) =>
  yup
    .date()
    .nullable()
    .transform((value) => {
      return value instanceof Date && !Number.isNaN(value.getTime()) ? value : null;
    })
    .when(['$minimalCustomerAge'], ([minimalCustomerAge], schema) =>
      schema.max(getMinimalCustomerAgeDate(minimalCustomerAge), minimalAgeError(minimalCustomerAge)),
    );

const BIRTHDAY_SCHEMA = ({
  minimalAgeError,
  requiredError = 'Date of birth is required',
}: BirthdaySchemaOptions = {}) => {
  return BASE_BIRTHDAY_SCHEMA({ minimalAgeError }).required(requiredError);
};

const PATIENT_ID_SCHEMA = () =>
  yup
    .string()
    .trim()
    .when(
      ['$isPatientIdSignUpRequired', '$isFloridaStateShop'],
      ([$isPatientIdSignUpRequired, $isFloridaStateShop], schema) => {
        if ($isPatientIdSignUpRequired) {
          if ($isFloridaStateShop) {
            return schema
              .test({
                name: 'is-valid-medical-card-number-id',
                test(value) {
                  if (value?.startsWith(MEDICAL_CARD_ID_FLORIDA_PREFIX) && value.length < 2) {
                    return this.createError({
                      message: MEDICAL_CARD_ID_REQUIRED_NOTE,
                    });
                  }
                  if (value?.startsWith(MEDICAL_CARD_ID_FLORIDA_PREFIX) && value.length < 8) {
                    return this.createError({
                      message: MEDICAL_CARD_ID_INVALID,
                    });
                  }
                  return true;
                },
              })
              .required(MEDICAL_CARD_ID_REQUIRED_NOTE);
          }
          return schema
            .required(MEDICAL_CARD_ID_REQUIRED_NOTE)
            .matches(MEDICAL_CARD_NUMBER_ID_REGEXP, MEDICAL_CARD_ID_MATCHING_NOTE)
            .matches(MEDICAL_CARD_NUMBER_ID_LENGTH_REGEXP, MEDICAL_CARD_ID_INVALID);
        }
        return schema;
      },
    );

export {
  PASSWORD_MATCHING_REGEXP,
  PASSWORD_MATCHING_NOTE,
  BIRTHDAY_SCHEMA,
  BASE_BIRTHDAY_SCHEMA,
  TODAY,
  MIN_DATE_OF_BIRTH,
  PATIENT_ID_SCHEMA,
};
