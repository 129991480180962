import cn from 'clsx';
import { ComponentProps, ComponentType, ReactNode, SyntheticEvent, useMemo } from 'react';

import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import Button, { ButtonColor, ButtonSize } from '@swe/shared/ui-kit/components/button';

import RowSpacer from '@swe/shared/ui-kit/components/form/row-spacer';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import { IconProps } from '@swe/shared/ui-kit/components/icon';
import {
  ComponentHasChildren,
  ComponentHasClassName,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type Action = {
  icon: ComponentType<IconProps>;
  onClick?: (e: SyntheticEvent) => void;
  visible?: boolean;
  ariaLabel?: string;
  node?: ReactNode;
};

type InputWrapperSize = Sizes<'lg' | 'md'>;

const SIZE_TO_BUTTON_SIZE: Record<InputWrapperSize, ButtonSize> = {
  lg: 'sm',
  md: 'sm',
};

type InputWrapperProps = Omit<FormControl<unknown>, 'value' | 'onChange' | 'ref'> &
  ComponentHasChildren & {
    icon?: ComponentType<IconProps> | string;
    onIconClick?: () => void;
    note?: ReactNode;
    label?: ReactNode | false;
    collapsed?: boolean;
    actions?: Action[];
    fieldProps?: ComponentProps<'div'>;
    controlType?: 'input' | 'textarea';
    staticNote?: boolean;
    fieldWrapper?: (input: ReactNode) => ReactNode;
    buttonColor?: ButtonColor;
    buttonAriaLabel?: string;
    textAlign?: 'left' | 'center' | 'right';
  } & ComponentHasClassName &
  ComponentHasSize<InputWrapperSize>;

const InputWrapper = ({
  children,
  icon: Icon,
  onIconClick,
  error,
  note,
  label,
  name,
  disabled,
  collapsed,
  className,
  required,
  actions = [],
  size = 'md',
  fieldProps,
  fieldWrapper,
  controlType = 'input',
  staticNote = true,
  buttonColor = 'ghost',
  buttonAriaLabel,
  textAlign = 'left',
}: InputWrapperProps) => {
  const field = useMemo(() => {
    return (
      <div
        className={styles.field}
        {...fieldProps}
      >
        {Icon &&
          (typeof Icon === 'string' ? (
            <img
              alt="file preview"
              src={Icon}
              className={styles.icon}
              onClick={onIconClick}
            />
          ) : (
            <Icon
              className={styles.icon}
              onClick={onIconClick}
            />
          ))}
        <div className={styles.control}>{children}</div>
        {actions
          .filter((action) => action.visible !== false)
          .map((action, index) => (
            <div
              className={styles.actionWrapper}
              key={index}
            >
              <Button
                className={styles.action}
                icon={action.icon}
                onClick={action.onClick}
                color="ghost"
                size={SIZE_TO_BUTTON_SIZE[size]}
                disabled={disabled}
                tabIndex={-1}
                ariaLabel={action.ariaLabel as string}
              >
                {action.node}
              </Button>
            </div>
          ))}
      </div>
    );
  }, [Icon, actions, children, disabled, fieldProps, onIconClick, size]);

  if (collapsed) {
    return (
      <div
        className={className}
        {...fieldProps}
      >
        <Button
          color={buttonColor}
          onClick={onIconClick}
          icon={typeof Icon !== 'string' ? Icon : undefined}
          size={SIZE_TO_BUTTON_SIZE[size]}
          ariaLabel={buttonAriaLabel || `${name}_open`}
        />
      </div>
    );
  }

  const _fieldWrapper = typeof fieldWrapper === 'function' ? fieldWrapper(field) : field;

  return (
    <div
      className={cn(className, styles[`_size_${size}`], {
        [styles._disabled]: disabled,
        [styles._invalid]: !!error,
        [styles._textarea]: controlType === 'textarea',
        [styles[`_textAlign_${textAlign}`]]: textAlign,
      })}
      onPointerDown={(e) => {
        if (
          document.activeElement &&
          e.target !== document.activeElement &&
          e.currentTarget.contains(document.activeElement)
        ) {
          e.preventDefault();
        }
      }}
      onClick={(e) => {
        const inputEl = e.currentTarget.querySelector<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        if (inputEl && inputEl !== document.activeElement) {
          inputEl.focus();
        }
      }}
    >
      <div className={styles.main}>
        {label !== false && (
          <label
            className={styles.label}
            htmlFor={name}
          >
            {label} {required && '*'}
          </label>
        )}
        {_fieldWrapper}
      </div>
      <AnimatedHeight expanded={!!(staticNote || error || note)}>
        {error || note ? <div className={styles.message}>{error || note}</div> : <RowSpacer />}
      </AnimatedHeight>
    </div>
  );
};

export type { InputWrapperProps };
export { InputWrapper };
export default InputWrapper;
