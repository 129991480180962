import { useCallback } from 'react';

import { GetQuantityFunc } from 'common/components/product-card/types';
import { AddToCartMeta, useCartActions, useCartStash, useCartState } from 'common/providers/cart';
import { useQuantity } from 'common/providers/cart/use-cases/use-quantity';
import { CartItemDTO } from 'entities/cart/cart';
import { getProductVariant, Product, ProductVariant } from 'entities/product/product';

const useAddProductToCart = (
  product: Product,
  _variant?: ProductVariant | ProductVariant['id'],
  _getQuantity?: GetQuantityFunc,
) => {
  const { addToCart: onAdd, removeFromCart: onRemove } = useCartActions();
  const { addToStash: _updateStash, getStashQuantity } = useCartStash();
  const variant = getProductVariant(product, _variant);
  const { isValidating, isLoading } = useCartState();
  const { getQuantity: cartGetQuantity } = useQuantity(product.id, variant.id);

  const isCartValidating = isValidating || isLoading;
  const getQuantity = _getQuantity ?? cartGetQuantity;
  const quantity = getQuantity(variant.id);
  const quantitiesByVariant = product.variants.map((variant) => getQuantity(variant.id));
  const totalQuantity = quantitiesByVariant.reduce((total, qty) => total + qty, 0);
  const qtyStep = variant.qtyStepSize;

  let availableQty = Math.floor((variant.availableQty ?? 0) / qtyStep) * qtyStep;
  if (availableQty < 1) availableQty = 0;

  const addToCart = (qty: number, meta?: AddToCartMeta) =>
    onAdd?.(
      {
        product: {
          ...product,
          variants: [variant],
        },
        variantId: variant.id,
        qty,
        reservedQty: 1,
        availableQty: 2,
      },
      meta,
    );

  const removeFromCart = (qty: number, meta?: AddToCartMeta) =>
    onRemove?.(
      {
        product: {
          ...product,
          variants: [variant],
        },
        variantId: variant.id,
        qty,
        reservedQty: 1,
        availableQty: 1,
      } as CartItemDTO,
      meta,
    );

  const stashQuantity = getStashQuantity?.(variant.id);
  const updateStash = useCallback((qty = 1) => _updateStash?.(variant.id, qty), [_updateStash, variant]);

  return {
    addToCart,
    removeFromCart,
    quantity,
    quantitiesByVariant,
    totalQuantity,
    updateStash,
    stashQuantity,
    isCartValidating,
    availableQuantity: availableQty,
    qtyStep,
  };
};

export { useAddProductToCart };
