import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

type Manufacturer = { category?: string; brand?: string };

// The variable name 'isAddToCartPopup' should be used for search in the project.
// After the UI/UX designers finalize the tokens, this variable needs to be removed.
type ProductTitleProps = {
  name: string;
  isAddToCartPopup?: boolean;
} & Manufacturer &
  ComponentHasClassName;

const ProductTitle = ({ className, name, category, brand, isAddToCartPopup = false }: ProductTitleProps) => {
  return (
    <div className={cx(className, styles.root)}>
      {category && (
        <div className={cx(styles.manufacturer, isAddToCartPopup && styles.isAddToCartPopup)}>
          {category}
          {brand && <> by {brand}</>}
        </div>
      )}
      <h2 className={cx(styles.name, isAddToCartPopup && styles.isAddToCartPopup)}>{name}</h2>
    </div>
  );
};

export type { Manufacturer, ProductTitleProps };
export { ProductTitle };
