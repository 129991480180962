import { createContext, RefObject, useContext } from 'react';

import { DEFAULT_RECT, Rect, useElementSizeWithRef } from '@swe/shared/hooks/use-element-size';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type Elements = 'header';

type LayoutContextT = { [key in Elements as `${Elements}Rect`]: Rect } & {
  [key in Elements as `${Elements}Ref`]: RefObject<HTMLElement> | undefined;
};

const LayoutContext = createContext<LayoutContextT>({
  headerRect: DEFAULT_RECT,
  headerRef: undefined!,
});

const _useLayout = (): LayoutContextT => {
  const [headerRect, headerRef] = useElementSizeWithRef();

  return {
    headerRect,
    headerRef,
  };
};

const LayoutProvider = ({ children }: ComponentHasChildren) => {
  const context = _useLayout();

  return <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>;
};

const useLayout = () => useContext(LayoutContext);

export { useLayout, LayoutProvider };
