import { useBreakpoint } from '@swe/shared/tools/media';
import Button from '@swe/shared/ui-kit/components/button';
import Stack from '@swe/shared/ui-kit/components/stack';

import cx from 'clsx';

import { forwardRef, useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import { AddToCart, AddToCartProps } from 'common/components/add-to-cart';

const TEXT = {
  UpdateQuantity: 'Update Quantity',
  AddToCart: 'Add to Cart',
  Remove: 'Remove',
  InCart: 'In Cart',
  OutOfStock: 'Out of Stock',
};

type OnUpdateActionType = 'add' | 'remove';
type OnUpdateAction = {
  action: OnUpdateActionType;
  qty: number;
};

type AddToCartCombinedProps = {
  initialQuantity: number;
  quantity: number;
  qtyStep: number;
} & {
  onChange?: (nextQuantity: number) => void;
  onAdd: (quantity: number) => void;
  onRemove: (quantity: number) => void;
  onUpdate?: (action: OnUpdateAction) => any | Promise<any>;
} & Pick<AddToCartProps, 'availableQuantity'>;

const AddToCartCombined = forwardRef<HTMLDivElement, AddToCartCombinedProps>(
  ({ initialQuantity, quantity, availableQuantity, qtyStep, onChange, onUpdate, onAdd, onRemove }, ref) => {
    const { isUltraNarrow } = useBreakpoint();
    const action = useMemo<OnUpdateAction | undefined>(() => {
      if (availableQuantity <= 0) {
        return undefined;
      }

      if (quantity < initialQuantity) {
        return {
          action: 'remove',
          qty: initialQuantity - quantity,
        };
      }

      if (quantity > initialQuantity) {
        return {
          action: 'add',
          qty: quantity - initialQuantity,
        };
      }

      return undefined;
    }, [availableQuantity, initialQuantity, quantity]);

    const renderAction = useCallback(
      (action?: OnUpdateAction) => {
        if (availableQuantity <= 0) {
          return TEXT.OutOfStock;
        }

        switch (action?.action) {
          case 'add':
            return initialQuantity === 0 ? TEXT.AddToCart : TEXT.UpdateQuantity;
          case 'remove':
            return action.qty === initialQuantity ? TEXT.Remove : TEXT.UpdateQuantity;
          default:
            return TEXT.InCart;
        }
      },
      [availableQuantity, initialQuantity],
    );
    const handleAdd = useCallback(() => {
      const minQty = quantity + qtyStep < 1 ? 1 : quantity + qtyStep;
      const nextQty = Math.min(minQty, availableQuantity);
      onChange?.(nextQty);
      return nextQty;
    }, [availableQuantity, onChange, quantity, qtyStep]);
    const handleRemove = useCallback(() => {
      const nextQty = quantity - qtyStep < 1 ? 0 : quantity - qtyStep;
      onChange?.(nextQty);
      return nextQty;
    }, [onChange, quantity, qtyStep]);
    const handleUpdate = useCallback(() => {
      if (action) {
        if (onUpdate) {
          onUpdate?.(action);
        }

        switch (action.action) {
          case 'add':
            return onAdd(action.qty);
          case 'remove':
            return onRemove(action.qty);
        }
      }
    }, [action, onAdd, onRemove, onUpdate]);

    return (
      <Stack
        ref={ref}
        direction="row"
        wrap={isUltraNarrow}
      >
        {availableQuantity > 0 && (
          <AddToCart
            className={cx(styles.quantor, !isUltraNarrow && styles.quantor_constrained)}
            filled
            color="light"
            availableQuantity={availableQuantity}
            quantity={quantity}
            minQuantity={initialQuantity === 0 ? 1 : 0}
            onAdd={handleAdd}
            onRemove={handleRemove}
            counterOnly
          />
        )}
        <Button
          name={renderAction(action)}
          key={initialQuantity}
          className={cx(styles.add, availableQuantity > 0 && !isUltraNarrow && styles.add_constrained)}
          block
          disabled={!action}
          onClick={handleUpdate}
        >
          {renderAction(action)}
        </Button>
      </Stack>
    );
  },
);

export type { AddToCartCombinedProps, OnUpdateAction, OnUpdateActionType };
export { AddToCartCombined };
