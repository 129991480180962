import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { Cart } from 'entities/cart/cart';
import { StoreSaleType } from 'entities/shop/sale-type';

type CartEditParams = {
  isResident?: boolean;
  saleType?: StoreSaleType;
  platformOs?: PlatformOs;
  patientId?: number | null;
  flushPatientId?: boolean | null;
};

const NAME = '/Cart/Edit';
const CartEditEndpoint = createModernEndpoint<CartEditParams, Cart>(NAME);

export default CartEditEndpoint;
export { CartEditEndpoint };
export type { CartEditParams };
