import Clamp from 'react-multiline-clamp';

import Link from '@swe/shared/ui-kit/components/link';
import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { MAX_LINES } from './constants';

type CroppedTextProps = ComponentHasClassName &
  ComponentHasChildren & {
    truncatedLines: number;
    maxLines?: number;
    expandable?: boolean;
  };

const ShowMore = ({ toggle }: any) => (
  <span
    onClick={(ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      toggle();
    }}
  >
    <Link>Show more</Link>
  </span>
);

const CroppedText = ({
  className,
  children,
  truncatedLines,
  maxLines = MAX_LINES,
  expandable = true,
}: CroppedTextProps) => {
  return (
    <div className={className}>
      <Clamp
        lines={truncatedLines}
        maxLines={maxLines}
        withToggle={expandable}
        showMoreElement={ShowMore}
        showLessElement={() => null}
      >
        {children}
      </Clamp>
    </div>
  );
};

export default CroppedText;
