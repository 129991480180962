import { StateAbbreviation } from '@swe/shared/ui-kit/types/common-props';

import { useCallback, useMemo } from 'react';

import { isMatchingState } from 'common/entities/get-region-abbreviation';
import { useStoreInfo } from 'common/providers/config';

const useMatchState = (matchState: StateAbbreviation | StateAbbreviation[]) => {
  const { location } = useStoreInfo();

  const isMatchesStoreState = useCallback(
    (state: StateAbbreviation) => {
      // Even though the location is required, we still need to check if the region is available'
      // because I saw myself it was null in some cases in some shops
      if (!location?.region?.name) {
        return false;
      }

      return isMatchingState(location.region.name, state);
    },
    [location.region.name],
  );

  return useMemo(
    () => (Array.isArray(matchState) ? matchState.some(isMatchesStoreState) : isMatchesStoreState(matchState)),
    [isMatchesStoreState, matchState],
  );
};

export { useMatchState };
