import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { AuthErrorCode } from 'endpoints/authorization/errors';
import { SignInPayload } from 'entities/authorization/sign-in';

type Params = SignInPayload;
type Data = boolean;
type Error = APIErrorExtended<AuthErrorCode.WRONG_PASSWORD>;

const NAME = '/Account/SignIn';
const SignInEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default SignInEndpoint;
