import cx from 'clsx';

import { useCallback, useRef } from 'react';

import { BaseRadioGroupProps } from '@swe/shared/ui-kit/components/form/radio-group/types';

import { ComponentStacked } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type RadioGroupSectionProps<VT> = BaseRadioGroupProps<VT> & ComponentStacked;

const getId = (index: number) => `section_option_${index}`;

const RadioGroupSection = <VT,>({ className, value, options, onChange, onBlur }: RadioGroupSectionProps<VT>) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (code: string, value: VT) => {
      if (code === 'Space' || code === 'Enter') {
        onChange?.(value);
      }
    },
    [onChange],
  );

  return (
    <div
      ref={rootRef}
      className={cx(className, styles.root)}
      onBlur={onBlur}
    >
      {options.map((o, oIdx) => (
        <div
          id={getId(oIdx)}
          key={oIdx}
          className={cx(styles.option, o.value === value && styles.option_active)}
          tabIndex={0}
          onClick={() => onChange?.(o.value)}
          onKeyDown={(e) => handleKeyDown(e.code, o.value)}
        >
          <div className={styles.text}>{o.label}</div>
        </div>
      ))}
    </div>
  );
};

export type { RadioGroupSectionProps };
export { RadioGroupSection };
export default RadioGroupSection;
