import { StateAbbreviation, StateName, StateToAbbreviation } from '@swe/shared/ui-kit/types/common-props';

const STATE_TO_ABBREVIATION: StateToAbbreviation = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

// TODO keep region as StateName
const getRegionAbbreviation = (region: string): StateAbbreviation => {
  if (region in STATE_TO_ABBREVIATION) {
    return STATE_TO_ABBREVIATION[region as StateName];
  }

  const words = region.split(' ');
  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase() as StateAbbreviation;
  }

  return words
    .map((p) => p[0])
    .join('')
    .toUpperCase() as StateAbbreviation;
};

// TODO keep stateName as StateName
const isMatchingState = (stateName: string, state: StateAbbreviation): boolean =>
  getRegionAbbreviation(stateName as StateName) === state;

export { getRegionAbbreviation, isMatchingState, STATE_TO_ABBREVIATION };
