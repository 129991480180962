import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { useGoogleMapContext } from '@swe/shared/ui-kit/components/google-map/context';
import { createOverlay } from '@swe/shared/ui-kit/components/google-map/core/overlay';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

type OverlayProps = ComponentHasChildren & {
  position: google.maps.LatLngLiteral;
  pane?: keyof google.maps.MapPanes;
  zIndex?: number;
};

const Overlay = ({ position, pane = 'floatPane', zIndex, children }: OverlayProps) => {
  const { map } = useGoogleMapContext();

  const container = useMemo(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';

    return div;
  }, []);

  const overlay = useMemo(() => createOverlay(container, pane, position), [container, pane, position]);

  useEffect(() => {
    if (!map) return;

    overlay?.setMap(map);

    return () => {
      overlay?.setMap(null);
    };
  }, [map, overlay]);

  useEffect(() => {
    container.style.zIndex = `${zIndex}`;
  }, [zIndex, container]);

  return createPortal(children, container);
};

export type { OverlayProps };
export { Overlay };
export default Overlay;
