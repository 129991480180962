import { Link } from '@swe/shared/providers/router/link';
import { Container } from '@swe/shared/ui-kit/components/container';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';
import cx from 'clsx';

import React from 'react';

import styles from './styles.module.scss';

import { FooterProps, getIcon } from 'common/containers/footer/variants/constants';

const Footer = ({
  externalNav = [],
  logoSrc,
  title,
  logoUrl,
  slogan,
  socialMedia = [],
  policies = [],
  disclaimers = [],
  license,
  copyright,
}: FooterProps) => {
  const theme = useTheme();
  return (
    <PatternThemeProvider
      name="footer"
      className={cx(styles.root, externalNav.length === 0 && styles._withoutNav)}
    >
      <Container className={styles.container}>
        <div className={styles.lead}>
          {logoSrc &&
            (logoUrl ? (
              <Link href={logoUrl}>
                <img
                  className={styles.logo}
                  src={logoSrc}
                  alt={title}
                />
              </Link>
            ) : (
              <img
                className={styles.logo}
                src={logoSrc}
                alt={title}
              />
            ))}
          {slogan && <div className={styles.slogan}>{slogan}</div>}
          {socialMedia.length > 0 && (
            <div className={styles.socialLinks}>
              {socialMedia.map((sm) => {
                const Icon = getIcon(sm.type, theme.footer.social.variant.switch as 'mono' | 'color');
                return (
                  <a
                    href={sm.href}
                    key={sm.href}
                    aria-label={sm.type}
                  >
                    <Icon size={parseInt(theme.footer.social.size, 10)} />
                  </a>
                );
              })}
            </div>
          )}
        </div>

        {externalNav.length > 0 && (
          <div className={styles.nav}>
            {externalNav.length > 0 && (
              <div className={styles.nav}>
                {externalNav.map((navItem) => {
                  return (
                    <div
                      className={styles.navColumn}
                      key={navItem.label}
                    >
                      {'href' in navItem ? (
                        <a
                          className={styles.navColumnHeading}
                          href={navItem.href}
                        >
                          {navItem.label}
                        </a>
                      ) : (
                        <div className={styles.navColumnHeading}>{navItem.label}</div>
                      )}
                      {'children' in navItem
                        ? navItem.children.map((navLink) => (
                            <Link
                              className={styles.navLink}
                              href={navLink.href}
                              key={navLink.href}
                            >
                              {navLink.label}
                            </Link>
                          ))
                        : undefined}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className={styles.small}>
          {disclaimers.map((disclaimer, idx) => (
            <div
              className={styles.disclaimer}
              key={idx}
            >
              {disclaimer}
            </div>
          ))}
          <div className={styles.policies}>
            {policies.length > 0 &&
              policies.map((policy, idx) => (
                <Link
                  target="_blank"
                  href={policy.href}
                  key={idx}
                >
                  {policy.title}
                </Link>
              ))}
          </div>
          {license && <div>{license}</div>}
          {copyright && <div>{copyright}</div>}
        </div>
      </Container>
    </PatternThemeProvider>
  );
};

export { Footer };
export default Footer;
