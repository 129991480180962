import { SnackbarService } from '@swe/shared/providers/snackbar';
import { difference } from '@swe/shared/utils/array';

import { useEffect, useRef } from 'react';

import { useRouterNavigate, useRouterPathname } from 'common/router';

import { Routes } from 'common/router/constants';
import { useSaleType } from 'common/use-cases/use-sale-type';

type CartItemPartial = { product: { id: ProductId } };

type UseNotificationAffectedItems = {
  isLoadingCart: boolean;
  unavailableItems: CartItemPartial[];
};

const itemMapper = ({ product }: CartItemPartial) => product.id;

const useNotificationAffectedItems = ({ isLoadingCart, unavailableItems }: UseNotificationAffectedItems) => {
  const isReady = useRef(false);
  const prevUnavailableItems = useRef(unavailableItems.map(itemMapper));
  const { saleType } = useSaleType();
  const prevSaleType = useRef(saleType);
  const navigate = useRouterNavigate();
  const pathname = useRouterPathname();

  useEffect(() => {
    if (!isLoadingCart && !isReady.current) {
      isReady.current = !isLoadingCart;
    }

    if (isReady.current && pathname !== Routes.Cart) {
      const unavailableItemsProducts = unavailableItems.map(itemMapper);

      const diff =
        difference(prevUnavailableItems.current, unavailableItemsProducts).length +
        difference(unavailableItemsProducts, prevUnavailableItems.current).length;

      if (diff > 0 && prevSaleType.current !== saleType) {
        SnackbarService.push({
          type: 'warning',
          message: `${diff} products at your cart has been affected`,
          onClick: () => navigate(Routes.Cart),
          autoHideDuration: 2000,
        });
      }
    }

    prevUnavailableItems.current = unavailableItems.map(itemMapper);
    prevSaleType.current = saleType;
  }, [isLoadingCart, navigate, saleType, unavailableItems, pathname]);

  return {};
};

export { useNotificationAffectedItems };
