import { AnalyticsMeta } from 'common/entities/product-analitycs';
import { CartEditParams } from 'endpoints/cart/edit';
import { Cart, CartItemDTO } from 'entities/cart/cart';
import { Product, ProductVariant } from 'entities/product/product';

enum ProductAvailability {
  Available,
  OutOfStock,
  OnlyMedical,
  OnlyRecreation,
}

type Stash = {
  [productId: ProductVariant['id']]: number | undefined;
};

type CartItemWithAvailability = CartItemDTO & {
  availability: ProductAvailability;
};

type CartViewItem = {
  qty: number;
  unavailable: string | false;
  product: Product;
  variantId: EntityID;
  onRemoveAll?: () => void;
};

type CartActions = {
  addToCart: (item: CartItemDTO, meta?: AddToCartMeta) => Promise<void>;
  removeFromCart: (item: CartItemDTO, meta?: AddToCartMeta) => void;
};

type CartUtils = {
  mutate: (cart?: Cart, options?: any) => Promise<Cart | undefined>;
  update: (data: Pick<CartEditParams, 'isResident' | 'patientId' | 'flushPatientId'>) => Promise<boolean>;
  setLocalAvailableItemsQuantity: (qty: number) => void;
  setSkipNextCartUpdate: (skip: boolean) => void;
};

type CartState = {
  isLoading: boolean;
  isLoadingUpdate: boolean;
  isValidating: boolean;
  isLoadingAdd: boolean;
  isLoadingRemove: boolean;
};

type CartMeta = {
  isVirtual: boolean;
  isEmpty: boolean;
  hasMedicalProducts: boolean;
  isOutOfLimit: boolean;
};

type CartStashActions = {
  addToStash: (variantId: ProductVariant['id'], quantity: number) => void;
  removeFromStash: (variantId: ProductVariant['id'], quantity: number) => void;
  getStashQuantity: (variantId: ProductVariant['id']) => number | undefined;
};

type CartPrice = {
  total: number;
  totalBeforePromo: number;
};

type CartItemsWithAvailability = {
  availableItems: CartItemWithAvailability[];
  unavailableItems: CartItemWithAvailability[];
};

type AddToCartMeta = AnalyticsMeta;

export type {
  Stash,
  CartItemWithAvailability,
  CartActions,
  CartUtils,
  CartState,
  CartMeta,
  CartStashActions,
  CartPrice,
  CartViewItem,
  CartItemsWithAvailability,
  AddToCartMeta,
};
export { ProductAvailability };
