import cx from 'clsx';

import {
  Children,
  ComponentProps,
  ComponentPropsWithRef,
  ElementType,
  forwardRef,
  Fragment,
  ReactElement,
} from 'react';

import { useCurrentBreakpoint } from '@swe/shared/tools/media';
import Box, { BoxProps } from '@swe/shared/ui-kit/components/box';
import Divider from '@swe/shared/ui-kit/components/divider';
import {
  PropValueByMediaWithShorthand,
  useViewportBreakpointToValueMap,
} from '@swe/shared/ui-kit/components/media/utils';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { Spacing } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type Direction = 'column' | 'row';

export type StackProps<T extends ElementType> = BoxProps<T> & {
  spacing?: PropValueByMediaWithShorthand<Spacing>;
  direction?: PropValueByMediaWithShorthand<Direction>;
  divider?: boolean | string;
  flex?: 1 | 0 | string;
  wrap?: boolean;
};

const _Stack = forwardRef(
  <T extends ElementType = 'div'>(
    { className, style, spacing, direction, divider, children, wrap, flex, ...boxProps }: StackProps<T>,
    ref: ComponentPropsWithRef<T>,
  ) => {
    const theme = useTheme();
    const bp = useCurrentBreakpoint();

    const spacingsByBreakpoints = useViewportBreakpointToValueMap<Spacing>(spacing, 'sm');
    const spacingVal = theme.spacing.scale[spacingsByBreakpoints[bp]];

    const directionByBreakpoints = useViewportBreakpointToValueMap<Direction>(direction, 'column');
    const directionVal = directionByBreakpoints[bp];

    const childrenArr = Children.toArray(children);

    return (
      <Box
        {...boxProps}
        ref={ref}
        className={cx(styles.root, className, {
          [styles._wrap]: wrap,
          [styles._overWidth]: directionVal === 'row' && !wrap,
        })}
        style={{ ...style, '--sw-stack-direction': directionVal, '--sw-stack-spacing': spacingVal, flex }}
      >
        {divider && childrenArr.length > 1
          ? childrenArr.map((child, index) => {
              return (
                <Fragment key={index}>
                  {child}
                  {index < childrenArr.length - 1 && (
                    <Divider
                      key={index}
                      caption={typeof divider === 'string' ? divider : undefined}
                    />
                  )}
                </Fragment>
              );
            })
          : children}
      </Box>
    );
  },
);
_Stack.displayName = 'Stack';

type ProperlyTypedStack = <T extends ElementType = 'div'>(
  props: StackProps<T> & { ref?: ComponentProps<typeof _Stack>['ref'] },
) => ReactElement;

const Stack = _Stack as ProperlyTypedStack;

export { Stack };
export default Stack;
