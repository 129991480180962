import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import { forwardRef } from 'react';

import { AddToCartCombined } from 'common/components/product-card/components/add-to-cart-combined';
import { useAddProductToCart } from 'common/use-cases/use-add-product-to-cart';
import { AddToCartSkeleton } from 'domains/product/components/product-details/components/add-to-cart/skeleton';
import { Product, ProductVariant } from 'entities/product/product';

type AddToCartProps = {
  product: Product;
  variant: ProductVariant;
  onProductAdd?: () => void;
};

const AddProduct = forwardRef<HTMLDivElement, AddToCartProps>(({ product, variant, onProductAdd }, ref) => {
  const {
    quantity: realQuantity,
    stashQuantity,
    availableQuantity,
    addToCart,
    updateStash,
    removeFromCart,
    qtyStep,
  } = useAddProductToCart(product, variant);

  const quantity = stashQuantity ?? (realQuantity === 0 ? 1 : realQuantity);

  return (
    <AddToCartCombined
      ref={ref}
      initialQuantity={realQuantity}
      quantity={quantity}
      availableQuantity={availableQuantity}
      qtyStep={qtyStep}
      onChange={updateStash}
      onAdd={addToCart}
      onRemove={removeFromCart}
      onUpdate={onProductAdd}
    />
  );
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AddProductSkeletonized = withSkeleton(AddProduct, AddToCartSkeleton, ['product', 'variant'], true);

export type { AddToCartProps };
export { AddProduct, AddProductSkeletonized };
export default AddProduct;
