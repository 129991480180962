import { ProductSaleType } from 'entities/product/product';

const SALE_TYPE_TO_LABEL: Record<ProductSaleType, string> = {
  [ProductSaleType.RECREATIONAL]: 'Recreational Purchase',
  [ProductSaleType.MEDICAL]: 'Medical Purchase',
  [ProductSaleType.BOTH]: 'Medical and Recreational Purchase',
};

const DISABLE_ONLINE_ORDERING_SUB_CODE = 1072;
const SUBCODE_SHOW_AS_WARNING = [DISABLE_ONLINE_ORDERING_SUB_CODE];

export { SALE_TYPE_TO_LABEL, DISABLE_ONLINE_ORDERING_SUB_CODE, SUBCODE_SHOW_AS_WARNING };
