import React, { forwardRef, SyntheticEvent, useCallback, useImperativeHandle, useRef } from 'react';

import TextareaAutosize from 'react-textarea-autosize';

import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { InputWrapper, InputWrapperProps } from '@swe/shared/ui-kit/components/form/wrapper/input';
import { Locator } from '@swe/shared/ui-kit/components/locator';

export type TextareaProps = FormControl<string> &
  Omit<InputWrapperProps, 'children' | 'actions'> & {
    minRows?: number;
    maxRows?: number;
    placeholder?: string;
    maxLength?: number;
  };

const Textarea = forwardRef<FormControlRef, TextareaProps>(
  (
    {
      value,
      onChange,
      minRows,
      maxRows,
      label,
      name,
      size,
      error,
      note,
      disabled,
      className,
      placeholder,
      onFocus,
      onBlur,
      staticNote,
      maxLength,
    },
    outerRef,
  ) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const _onChange = useCallback(
      ({ currentTarget }: SyntheticEvent<HTMLTextAreaElement>) => onChange?.(currentTarget.value),
      [onChange],
    );

    useImperativeHandle(outerRef, () => ({
      focus: () => ref.current?.focus(),
    }));

    return (
      <InputWrapper
        name={name}
        size={size}
        error={error}
        note={note}
        label={label}
        disabled={disabled}
        className={className}
        controlType="textarea"
        staticNote={staticNote}
      >
        <Locator
          as={TextareaAutosize}
          locatorName={name}
          locatorType="textarea"
          ref={ref}
          value={value}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          onChange={_onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
        />
      </InputWrapper>
    );
  },
);

export { Textarea };
export default Textarea;
