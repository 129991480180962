import { useCallback } from 'react';

import { useMounted } from '@swe/shared/hooks/use-mounted';
import { useSyncedState } from '@swe/shared/hooks/use-synced-state';
import { throttle } from '@swe/shared/ui-kit/utils/throttle';
import { isSSR } from '@swe/shared/utils/environment';

const HUNDRED_VH_CSS_VARIABLE = '--hundred-vh';
const VH_CSS_VARIABLE = '--vh';

const setGlobalHeightCssVariable = () => {
  const windowHeight = window.innerHeight;
  document.documentElement.style.setProperty(HUNDRED_VH_CSS_VARIABLE, isSSR ? '100vh' : `${windowHeight}px`);
  document.documentElement.style.setProperty(VH_CSS_VARIABLE, isSSR ? '1vh' : `${windowHeight / 100}px`);
};

let isUseDocumentHeightVariableListenerSetUp = false;
const useDocumentHeightVariable = (clearVariableOnUnmount = false) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleViewportChanges = useCallback(
    throttle(() => {
      setGlobalHeightCssVariable();
    }, 250),
    [],
  );

  useMounted(() => {
    if (!isSSR && !isUseDocumentHeightVariableListenerSetUp) {
      setGlobalHeightCssVariable();
      isUseDocumentHeightVariableListenerSetUp = true;
      window.addEventListener('resize', handleViewportChanges);
      window.visualViewport?.addEventListener('resize', handleViewportChanges);
      window.visualViewport?.addEventListener('scroll', handleViewportChanges);

      return () => {
        isUseDocumentHeightVariableListenerSetUp = false;
        window.removeEventListener('resize', handleViewportChanges);
        window.visualViewport?.removeEventListener('resize', handleViewportChanges);
        window.visualViewport?.removeEventListener('scroll', handleViewportChanges);

        if (clearVariableOnUnmount) {
          document.documentElement.style.removeProperty(HUNDRED_VH_CSS_VARIABLE);
          document.documentElement.style.removeProperty(VH_CSS_VARIABLE);
        }
      };
    }
  });
};

let isUseDocumentHeightListenerSetUp = false;
const useDocumentHeight = () => {
  const defValue = typeof window === 'undefined' ? 0 : window.visualViewport?.height || window.innerHeight;
  const [actualHeight, setActualHeight] = useSyncedState<number>('actualHeightRef', defValue);
  const [previousHeight, setPreviousHeight] = useSyncedState<number>('previousHeight', 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle(() => {
      setActualHeight((previousHeight) => {
        setPreviousHeight(previousHeight);

        return window.visualViewport?.height || window.innerHeight;
      });
    }, 250),
    [setActualHeight, setPreviousHeight],
  );

  useMounted(() => {
    if (!isSSR && !isUseDocumentHeightListenerSetUp) {
      isUseDocumentHeightListenerSetUp = true;
      window.addEventListener('resize', handleResize);
      window.visualViewport?.addEventListener('resize', handleResize);
      window.visualViewport?.addEventListener('scroll', handleResize);

      return () => {
        isUseDocumentHeightListenerSetUp = false;
        window.removeEventListener('resize', handleResize);
        window.visualViewport?.removeEventListener('resize', handleResize);
        window.visualViewport?.removeEventListener('scroll', handleResize);
      };
    }
  });

  return {
    actualHeight,
    previousHeight,
  };
};

export {
  HUNDRED_VH_CSS_VARIABLE,
  VH_CSS_VARIABLE,
  useDocumentHeight,
  useDocumentHeightVariable,
  setGlobalHeightCssVariable,
};
