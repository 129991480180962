import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import Button from '@swe/shared/ui-kit/components/button';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

const ActionRequiredNotifier = () => {
  const { user } = useCurrentUser();
  const navigate = useRouterNavigate();
  const isProfileDocumentsRoute = useMatchRoute(Routes.ProfileDocuments);

  const handleClick = useCallback(() => {
    void navigate(Routes.ProfileDocuments);
  }, [navigate]);

  if (!user || !user.isActionRequired) return null;

  return (
    <div className={styles.root}>
      <Stack
        className={styles.stack}
        direction="row"
      >
        <Text
          variant="control"
          size="xl"
        >
          Action Required
        </Text>
        {!isProfileDocumentsRoute && (
          <Button
            size="sm"
            name="view"
            onClick={handleClick}
          >
            View
          </Button>
        )}
      </Stack>
    </div>
  );
};

export { ActionRequiredNotifier };
