import { useBreakpoint } from '@swe/shared/tools/media';
import { Button } from '@swe/shared/ui-kit/components/button';
import Loader from '@swe/shared/ui-kit/components/loader';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { Visible } from '@swe/shared/ui-kit/components/media';
import { Scrollable } from '@swe/shared/ui-kit/components/scrollable';
import Stack from '@swe/shared/ui-kit/components/stack';
import { Tag } from '@swe/shared/ui-kit/components/tag';
import { Text } from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName, ComponentHasStyles } from '@swe/shared/ui-kit/types/common-props';
import cx from 'clsx';

import React, { useCallback, useMemo, SyntheticEvent } from 'react';

import styles from './styles.module.scss';

import { CatalogEmptyState } from 'common/components/catalog-empty-state';
import { Item, ItemProps } from 'common/containers/header/containers/search/components/search/components/item';

type AllButtonProps = ComponentHasClassName & {
  count?: number;
  block?: boolean;

  onClick(): void;
};

const AllButton = ({ count, className, block, onClick }: AllButtonProps) => {
  const { mobile } = useBreakpoint();
  const _onClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement, Event>) => {
      event.preventDefault();
      onClick();
    },
    [onClick],
  );
  return (
    <Button
      color="primary"
      size={mobile ? 'lg' : 'sm'}
      className={className}
      block={block}
      onClick={_onClick}
      name="all-products"
    >
      <div className={styles.allBtnContent}>
        All Products
        <Tag
          name="count"
          className={styles.tag}
          size="sm"
          active
        >
          {count}
        </Tag>
      </div>
    </Button>
  );
};

export type ListProps = {
  items?: Omit<ItemProps, 'onClick'>[];
  search: string;
  isLoading?: boolean;
  total: number;

  onClickItem: ItemProps['onClick'];
  onClickAll(search: string): void;
} & ComponentHasClassName &
  ComponentHasStyles;

const List = ({ items = [], total, className, search, isLoading, onClickItem, onClickAll, style }: ListProps) => {
  const _onClickAll = useCallback(() => {
    onClickAll(search);
  }, [onClickAll, search]);

  const loading = useMemo(() => {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }, []);

  let content = null;
  switch (true) {
    case isLoading: {
      content = loading;
      break;
    }
    case !items.length: {
      content = <CatalogEmptyState />;
      break;
    }
    default:
      content = (
        <>
          <Visible
            desktop
            tablet
          >
            <div className={styles.header}>
              <Text
                variant="headline"
                size="sm"
              >
                Search Results
              </Text>
              <AllButton
                count={total}
                onClick={_onClickAll}
              />
            </div>
          </Visible>
          <Locator
            as={Scrollable}
            locatorName="search-suggest-list"
            className={styles.list}
          >
            <Stack divider>
              {items.map((data, index) => (
                <Item
                  key={index}
                  search={search}
                  {...data}
                  onClick={onClickItem}
                />
              ))}
            </Stack>
          </Locator>
          <Visible mobile>
            <div className={styles.footerMobile}>
              <AllButton
                count={total}
                block
                onClick={_onClickAll}
              />
            </div>
          </Visible>
        </>
      );
  }

  return (
    <div
      className={cx(styles.root, className)}
      style={style}
    >
      {content}
    </div>
  );
};

export default List;
export { List };
