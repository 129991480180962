import { usePaginated } from '@swe/shared/hooks/pagination';
import { useNearestScrollable } from '@swe/shared/ui-kit/components/scrollable';

import { useCallback, useMemo } from 'react';

import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { Routes } from 'common/router/constants';

import GetOrderHistoryEndpoint from 'endpoints/profile/orders/get-history';
import { RegistrationStep } from 'entities/authorization/user';
import { isOrderNotCompleted } from 'entities/common/orders';

const useOrdersHistory = () => {
  const { user } = useCurrentUser();
  const { isGuest } = useGuest();
  const { scroll } = useNearestScrollable();
  const isDisabled = isGuest || user?.registrationStepName !== RegistrationStep.COMPLETED;

  const scrollToTop = useCallback(() => scroll({ top: 0 }), [scroll]);
  const {
    data: orders,
    pagination,
    isLoading,
    buildPageLink,
    onPaginationChange,
    pagesCount,
    total,
    error,
  } = usePaginated({
    endpoint: GetOrderHistoryEndpoint,
    params: isDisabled ? null : {},
    onPaginationChanged: scrollToTop,
    linkBuilderConfig: {
      pathname: Routes.ProfileOrders,
    },
  });

  const lastNonCompleted = useMemo(() => orders?.list.find(isOrderNotCompleted), [orders?.list]);

  return {
    orders,
    pagination,
    total,
    pagesCount,
    onPaginationChange,
    buildPageLink,
    lastNonCompleted,
    isLoading,
    error,
  };
};

export { useOrdersHistory };
