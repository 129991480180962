import { useCallback, useEffect, useState } from 'react';

import { useGoogleApiContext } from '@swe/shared/providers/google-api';
import GeocodingServiceContainer from '@swe/shared/tools/google/geocoding';
import { useGoogleService } from '@swe/shared/tools/google/hooks/use-google-service';

const useAddress = (location: google.maps.LatLngLiteral | undefined, apiKey?: string) => {
  const { apiKey: providedKey } = useGoogleApiContext();
  const {
    service,
    load,
    isReady,
    error: serviceError,
  } = useGoogleService(GeocodingServiceContainer, apiKey ?? providedKey);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const tryFindAddress = useCallback(
    async (location: google.maps.LatLngLiteral) => {
      setLoading(true);

      if (!service.current) {
        await load();
      }

      try {
        const address = await service.current!.getAddressByLocation(location);
        setAddress(address);
        setError('');
      } catch (e: any) {
        setAddress(undefined);
        setError(e.toString());
      } finally {
        setLoading(false);
      }
    },
    [load, service],
  );

  useEffect(() => {
    if (location) {
      void tryFindAddress(location);
    } else {
      setAddress(undefined);
      setError('');
    }
  }, [tryFindAddress, location]);

  return {
    location,
    address,
    error: error ?? serviceError,
    isLoading,
    isInitializing: !isReady,
  };
};

const useLocation = (address: string | undefined | null, apiKey?: string) => {
  const { apiKey: providedKey } = useGoogleApiContext();
  const {
    service,
    load,
    isReady,
    error: serviceError,
  } = useGoogleService(GeocodingServiceContainer, apiKey ?? providedKey);
  const [location, setLocation] = useState<google.maps.LatLngLiteral | undefined>(undefined);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const tryFindLocation = useCallback(
    async (address: string) => {
      setLoading(true);

      if (!service.current) {
        await load();
      }

      try {
        const location = await service.current!.getLocationByAddress(address);
        setLocation(location);
        setError('');
      } catch (e: any) {
        setLocation(undefined);
        setError(e.toString());
      } finally {
        setLoading(false);
      }
    },
    [load, service],
  );

  useEffect(() => {
    if (address) {
      void tryFindLocation(address);
    } else {
      setLocation(undefined);
      setError('');
    }
  }, [tryFindLocation, address]);

  return {
    address,
    location,
    error: error ?? serviceError,
    isLoading,
    isInitializing: !isReady,
  };
};

const useGeocodingService = (apiKey?: string) => {
  const { apiKey: providedKey } = useGoogleApiContext();

  return useGoogleService(GeocodingServiceContainer, apiKey ?? providedKey);
};

export { useAddress, useLocation, useGeocodingService };
