import { SubscriptionUtils } from '@swe/shared/tools/firebase';

import { firebase } from './firebase';

import SaveDeviceTokenEndpoint from '@swe/shop-ui/endpoints/profile/notifications/save-device-token';

const saveDeviceToken = async () => {
  if (!SubscriptionUtils.hasToken()) return;
  await SaveDeviceTokenEndpoint.request({
    applicationId: firebase.appId!,
    deviceToken: SubscriptionUtils.getToken()!,
  });
};

const trySaveDeviceToken = async () => {
  try {
    await saveDeviceToken();
  } catch (e) {
    console.error(e);
  }
};

export { saveDeviceToken, trySaveDeviceToken };
