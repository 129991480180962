import Overlay, { OverlayProps } from '@swe/shared/ui-kit/components/google-map/components/overlay';
import { UserIcon } from '@swe/shared/ui-kit/components/icon';

import Pin from '@swe/shared/ui-kit/components/pin';

type UserMarkerProps = Omit<OverlayProps, 'children'>;

const UserMarker = ({ position, zIndex }: UserMarkerProps) => {
  return (
    <Overlay
      position={position}
      zIndex={zIndex}
    >
      <Pin
        color="success"
        selfAligned
        icon={UserIcon}
      />
    </Overlay>
  );
};

export type { UserMarkerProps };
export { UserMarker };
export default UserMarker;
