import { setErrorHandler } from '@swe/shared/network/endpoint-factories/modern/fetcher';
import { useCacheControls } from '@swe/shared/network/transport/swr';
import { SWRResponseExtended } from '@swe/shared/network/transport/swr/transport.types';
import { RouteQuery } from '@swe/shared/providers/router/constants';
import { isMatchRouter } from '@swe/shared/providers/router/helpers';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { getCookie } from '@swe/shared/tools/cookie';
import { loadExternalGlobalScript } from '@swe/shared/tools/script';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';

import { omit } from '@swe/shared/utils/object';

import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { trySaveDeviceToken } from 'app/firebase';
import { useSaleTypeConfirmation } from 'common/containers/sale-type-confirmation';
import { useStoreConfig } from 'common/providers/config';

import { useGuest } from 'common/providers/guest';
import { useRouterAsPath, useRouterNavigate, useRouterPathname, useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import GetCurrentUser, { GET_CURRENT_USER_ENDPOINT_NAME } from 'endpoints/authorization/get-current-user';
import SignInEndpoint from 'endpoints/authorization/sign-in';
import SignInExternal from 'endpoints/authorization/sign-in-external';
import SignOutEndpoint from 'endpoints/authorization/sign-out';
import { SignInPayload } from 'entities/authorization/sign-in';
import { RegistrationStep, User } from 'entities/authorization/user';
import { OAuthProvider } from 'entities/shop/config';

const FB_SCOPE = ['public_profile', 'email'];
const G_TOKEN_KEY = '__sw-g-token';
const YAHOO_API_POSTFIX = '/yahoo';
const API = '/_api';

type OnTokenReceivedCallback = (token: string, firstName?: string, lastName?: string) => void;
type OnErrorCallback = (err: any) => void;
type OAuthMethod = (
  id: EntityID<string>,
  onTokenReceived: OnTokenReceivedCallback,
  onError: OnErrorCallback,
  baseApiUrl?: string,
) => void;
type OAuthLoginMethod = (
  provider: OAuthProvider,
  onReceive?: OnTokenReceivedCallback,
  onReject?: OnErrorCallback,
) => void;

const O_AUTH_LOADERS = {
  [OAuthProvider.Google]: () =>
    loadExternalGlobalScript<typeof window.google>('https://accounts.google.com/gsi/client', 'google'),
  [OAuthProvider.Facebook]: async (id: string) => {
    await loadExternalGlobalScript<typeof window.FB>('https://connect.facebook.net/en_US/sdk.js', 'FB', (sdk) => {
      sdk.init({
        appId: id,
        cookie: true,
        version: 'v15.0',
        xfbml: true,
      });
    });
  },
  [OAuthProvider.Apple]: (id: string) =>
    loadExternalGlobalScript<typeof window.AppleID>(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      'AppleID',
      (sdk) => {
        sdk.auth.init({
          clientId: id,
          scope: 'name email',
          redirectURI: window.location.origin,
          usePopup: true,
        });
      },
    ),
  [OAuthProvider.Yahoo]: () => Promise.resolve(),
};

const O_AUTH_LOGIN: Record<OAuthProvider, OAuthMethod> = {
  [OAuthProvider.Google]: (id, onReceive, onError) => {
    window.google.accounts.oauth2
      .initTokenClient({
        client_id: id,
        scope: 'profile email',
        callback: ({ access_token }) => {
          localStorage.setItem(G_TOKEN_KEY, access_token);
          onReceive(access_token);
        },
        error_callback: onError,
      })
      .requestAccessToken();
  },
  [OAuthProvider.Facebook]: (id, onReceive, onError) => {
    window.FB.login(
      (response) => {
        const token = response.authResponse?.accessToken;
        if (token) {
          onReceive(token);
        } else {
          onError(new Error('Something went wrong'));
        }
      },
      { scope: FB_SCOPE.join(','), auth_type: 'reauthorize' },
    );
  },
  [OAuthProvider.Apple]: (id, onReceive, onError) => {
    window.AppleID.auth
      .signIn()
      .then(({ authorization, user }) => {
        const firstName = user?.name.firstName;
        const lastName = user?.name.lastName;
        onReceive(authorization.id_token, firstName, lastName);
      })
      .catch(onError);
  },
  [OAuthProvider.Yahoo]: (id, onReceive, onError, redirectUri) => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const baseLocation = `${window.location.origin}${window.location.pathname}`;

    if (code) {
      onReceive(code);
    } else {
      const authUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${id}&response_type=code&redirect_uri=${redirectUri}${YAHOO_API_POSTFIX}&state=${baseLocation}&scope=openid%20email%20profile`;
      window.location.href = authUrl;
    }
  },
};

const O_AUTH_LOGOUT: Record<OAuthProvider, () => Promise<void>> = {
  [OAuthProvider.Google]: () => {
    return new Promise((resolve) => {
      const gToken = localStorage.getItem(G_TOKEN_KEY);
      if (gToken) {
        window.google.accounts.oauth2.revoke(gToken, resolve);
        localStorage.removeItem(G_TOKEN_KEY);
      } else {
        resolve();
      }
    });
  },
  [OAuthProvider.Facebook]: () => {
    return new Promise((resolve) => {
      window.FB.getLoginStatus(async ({ authResponse }) => {
        if (authResponse) {
          await Promise.all(
            FB_SCOPE.map(
              (scope) =>
                new Promise((resolve) => {
                  FB.api(`/${authResponse.userID}/permissions/${scope}`, 'delete', {}, resolve);
                }),
            ),
          );
          window.FB.logout(() => resolve());
        } else {
          resolve();
        }
      });
    });
  },
  [OAuthProvider.Apple]: () => Promise.resolve(),
  [OAuthProvider.Yahoo]: () => Promise.resolve(),
};

type UserContextT = {
  user?: User;
  login: (payload: SignInPayload, redirectToInitialURL?: boolean) => Promise<void>;
  oAuthLogin: OAuthLoginMethod;
  logout: (isByUser?: boolean, noRedirect?: boolean) => Promise<void>;
  revalidate: SWRResponseExtended<User>['mutate'];
  isRegistrationComplete?: boolean;
  redirectUnauthorized: (replace?: boolean, query?: RouteQuery) => Promise<boolean>;
  isLoggedIn: boolean;
  toSignIn: (replace?: boolean, toSignUp?: boolean) => Promise<boolean>;
  isLoaded: boolean;
  returnURL: Routes | null;
  setReturnURL: (url: Routes | null) => void;
  hasAuthCookie: boolean;
};

const context = createContext<UserContextT>(null!);

const UserContextProvider = context.Provider;

const useCurrentUser = () => useContext(context);

type UserProviderProps = ComponentHasChildren & {
  hasAuthCookie?: boolean;
};

const UserProvider = ({ children, hasAuthCookie: _hasAuthCookie }: UserProviderProps) => {
  const hasAuthCookie = !!(isSSR ? _hasAuthCookie : getCookie('UserLoginCookie'));
  const asPath = useRouterAsPath();
  const query = useRouterQuery();
  const pathname = useRouterPathname();
  const navigate = useRouterNavigate();
  const [returnURL, setReturnURL] = useState<Routes | null>(
    ((Array.isArray(query.r) ? query.r[0] : query.r) as Routes) ?? Routes.Home,
  );

  useEffect(() => {
    setReturnURL(((Array.isArray(query.r) ? query.r[0] : query.r) as Routes) ?? Routes.Home);
  }, [query.r]);

  const { oAuth, baseApiUrl } = useStoreConfig();
  const {
    data: user,
    mutate: mutateCurrentUser,
    error: userError,
    isLoading,
  } = GetCurrentUser.useRequest(undefined, undefined, {
    revalidateOnReconnect: hasAuthCookie,
    revalidateIfStale: hasAuthCookie,
    revalidateOnMount: hasAuthCookie,
  });
  const { pauseRevalidateOnFocus, resumeRevalidateOnFocus } = useCacheControls();
  const { confirmSaleType } = useSaleTypeConfirmation();

  const { isGuest, setIsGuestUser } = useGuest();

  const isLoggedIn = !isLoading && !!user;
  if (isSSR) {
    setIsGuestUser(user?.isGuest);
  }

  useEffect(() => {
    if (!isLoading) {
      setIsGuestUser(!!user?.isGuest);
    }
  }, [isLoading, setIsGuestUser, user?.isGuest]);

  const revalidateSensitiveData = useCallback(
    (clearUser?: boolean) => (clearUser ? mutateCurrentUser(null!) : mutateCurrentUser()),
    [mutateCurrentUser],
  );
  const isRegistrationNotComplete = useRef(false);
  isRegistrationNotComplete.current = !!user && user.registrationStepName !== RegistrationStep.COMPLETED;

  const isRegistrationComplete = useRef(false);
  isRegistrationComplete.current = !!user && user.registrationStepName === RegistrationStep.COMPLETED;

  const _logout = useCallback(
    async (isByUser = true) => {
      if (isByUser || isRegistrationComplete.current) {
        await SignOutEndpoint.request(null);
        await revalidateSensitiveData(true);
        if (!isByUser) {
          SnackbarService.push({ type: 'warning', message: 'You were logged out' });
        }
      }
    },
    [revalidateSensitiveData],
  );

  const toSignIn = useCallback(
    async (replace = true, toSignUp = false, _query: RouteQuery = {}) => {
      let r = query.r || asPath;
      if (user && isGuest) {
        if (isMatchRouter(Routes.Checkout, pathname)) {
          r = Routes.Cart;
        }
        if (toSignUp) {
          await _logout(true);
        }
      }

      return navigate(
        {
          pathname: toSignUp ? Routes.SignUp : Routes.SignIn,
          query: [Routes.PasswordRecovery].includes(pathname) ? query : { ...query, ..._query, r },
        },
        { replace },
      );
    },
    [query, asPath, user, isGuest, navigate, pathname, _logout],
  );

  const redirectUnauthorized = useCallback(
    async (replace = true, query?: RouteQuery) => {
      if (isSSR) {
        return true;
      }
      if (isGuest || isRegistrationComplete.current || [Routes.SignIn, Routes.SignUp].includes(pathname)) {
        return false;
      }
      await toSignIn(replace, isRegistrationNotComplete.current, query);
      return true;
    },
    [pathname, toSignIn, isGuest],
  );

  const returnToInitialURL = useCallback(async () => {
    if (isRegistrationComplete.current) {
      await navigate(returnURL || Routes.Home, { replace: true });
    } else if (isRegistrationNotComplete.current) {
      await navigate(
        {
          pathname: Routes.SignUp,
          query: omit(query, 'code'), // Removes the 'code' parameter after performing external OAuth authentication through Yahoo
        },
        { replace: true },
      );
    }
  }, [query, navigate, returnURL]);

  const additionalAction = useCallback(async () => {
    const user = await revalidateSensitiveData();
    if (user?.registrationStepName === RegistrationStep.COMPLETED) {
      await trySaveDeviceToken();
    }
    if (user) {
      void confirmSaleType(user.lastSaleType);
    }
  }, [confirmSaleType, revalidateSensitiveData]);

  const login = useCallback(
    async (payload: SignInPayload, redirectToInitialURL = true) => {
      await SignInEndpoint.request(payload);
      await additionalAction();
      if (redirectToInitialURL) {
        await returnToInitialURL();
      }
    },
    [additionalAction, returnToInitialURL],
  );

  const oAuthLogin = useCallback<OAuthLoginMethod>(
    (provider, onResolve, onReject) => {
      const providerConfig = oAuth[provider];
      if (!providerConfig.enabled) {
        return;
      }
      const baseUrl = baseApiUrl || window.location.origin;
      const redirectUrl = `${baseUrl}${baseApiUrl ? '' : API}${YAHOO_API_POSTFIX}`;
      const onSuccess = async (token: string, firstName?: string, lastName?: string) => {
        setTimeout(() => resumeRevalidateOnFocus, 500);
        try {
          await SignInExternal.request(
            {
              externalAuthenticationProvider: provider,
              externalServiceToken: token,
              redirectUrl,
              firstName,
              lastName,
            },
            { notifyWithSnackbar: false },
          );
          await additionalAction();
          await returnToInitialURL();
          onResolve?.(token);
        } catch (err: any) {
          SnackbarService.push({ type: 'danger', heading: `${provider} Auth Error`, message: err?.message });
          onReject?.(err);
        }
      };
      const onError = (err: any) => {
        setTimeout(() => resumeRevalidateOnFocus, 500);
        SnackbarService.push({ type: 'danger', heading: `${provider} Auth Error`, message: err?.message });
        onReject?.(err);
      };

      pauseRevalidateOnFocus();
      O_AUTH_LOGIN[provider](providerConfig.id, onSuccess, onError, baseUrl);
    },
    [additionalAction, oAuth, pauseRevalidateOnFocus, resumeRevalidateOnFocus, returnToInitialURL, baseApiUrl],
  );

  const logout = useCallback(
    async (isByUser?: boolean, noRedirect = false) => {
      if (!user) {
        return;
      }

      await _logout(isByUser);

      if (!noRedirect) {
        void redirectUnauthorized();
      }
    },
    [_logout, redirectUnauthorized, user],
  );

  useEffect(() => {
    setErrorHandler(async (err: any) => {
      if (typeof err === 'object' && err.code === 401 && user) {
        await logout(err.endpoint === GET_CURRENT_USER_ENDPOINT_NAME);
        return true;
      }
      return false;
    });
  }, [isLoading, logout, user]);

  const ctx = useMemo<UserContextT>(
    () => ({
      user,
      isRegistrationComplete: isRegistrationComplete.current,
      login,
      oAuthLogin,
      logout,
      revalidate: mutateCurrentUser,
      redirectUnauthorized,
      toSignIn,
      // SWR thinks that if there was an error during request, then there is no data and isLoading will always be true, while request are running.
      isLoaded: userError || !hasAuthCookie ? true : !isLoading,
      returnURL,
      isLoggedIn,
      setReturnURL,
      hasAuthCookie: ((isSSR || isLoading) && hasAuthCookie) || !!user,
    }),
    [
      user,
      login,
      oAuthLogin,
      logout,
      mutateCurrentUser,
      redirectUnauthorized,
      toSignIn,
      userError,
      hasAuthCookie,
      isLoading,
      returnURL,
      isLoggedIn,
    ],
  );

  return <UserContextProvider value={ctx}>{children}</UserContextProvider>;
};

type Session = {
  UserLoginCookie?: string;
  Session?: string;
};

const readSessionFromCookies = () => ({
  UserLoginCookie: (getCookie('UserLoginCookie') as string) ?? undefined,
  Session: (getCookie('Session') as string) ?? undefined,
});
const useSession = () => {
  const [session, setSession] = useState<Session>(readSessionFromCookies());
  const retake = useCallback(() => {
    setSession(readSessionFromCookies());
  }, []);

  return {
    session,
    retake,
  };
};

export type { UserContextT };
export { useCurrentUser, UserProvider, O_AUTH_LOADERS, O_AUTH_LOGOUT, useSession };
export default UserProvider;
