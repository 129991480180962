import { BIRTHDAY_SCHEMA } from '@swe/shared/tools/validation/constants';
import yup from '@swe/shared/tools/yup';

import { AgeFormInitialValues } from 'common/containers/age-restriction-modal/components/age-form/types';

const AGE_FORM_NAME = 'neutral-age-restriction-form';

const AGE_FORM_VALIDATION_SCHEMA = yup.object().shape({
  birthDate: BIRTHDAY_SCHEMA({
    minimalAgeError: (minAge) => `You should be ${minAge}+ y.o.`,
    requiredError: 'Please enter your birthdate',
  }),
});

const AGE_FORM_INITIAL_VALUES: AgeFormInitialValues = {
  birthDate: undefined,
};

export { AGE_FORM_NAME, AGE_FORM_VALIDATION_SCHEMA, AGE_FORM_INITIAL_VALUES };
