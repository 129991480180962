import cn from 'clsx';
import { CSSProperties, ReactNode } from 'react';

import { useCurrentBreakpoint } from '@swe/shared/tools/media';
import { ColOffset, ColOrder, ColVisible, ColWidth } from '@swe/shared/ui-kit/components/grid/types';
import Visible from '@swe/shared/ui-kit/components/media/components/visible';
import {
  PropValueByMediaWithShorthand,
  useViewportBreakpointToValueMap,
} from '@swe/shared/ui-kit/components/media/utils';
import { ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type CellProps = ComponentHasStyling & {
  id?: string | number;
  cols?: PropValueByMediaWithShorthand<ColWidth>;
  offset?: PropValueByMediaWithShorthand<ColOffset>;
  order?: PropValueByMediaWithShorthand<ColOrder>;
  visible?: PropValueByMediaWithShorthand<ColVisible>;
  children?: ReactNode;
  overflow?: boolean;
};

export const Cell = ({ id, cols, offset, order, visible, children, className, style, overflow }: CellProps) => {
  const bp = useCurrentBreakpoint();

  const widthsMap = useViewportBreakpointToValueMap<ColWidth>(cols, 'auto');
  const offsetMap = useViewportBreakpointToValueMap<ColOffset>(offset, 0);
  const orderMap = useViewportBreakpointToValueMap<ColOrder>(order, 'unset');
  const widthVal = widthsMap[bp];
  const offsetVal = offsetMap[bp];
  const orderVal = orderMap[bp];
  const cssVars = {
    '--sw-grid-cell-width': typeof widthVal === 'number' ? widthVal : undefined,
    '--sw-grid-cell-offset': typeof offsetVal === 'number' ? offsetVal : undefined,
    '--sw-grid-cell-order': orderVal,
  } as CSSProperties;

  const modsClassNames = [
    typeof widthVal === 'string' && styles[`_width_${widthVal}`],
    typeof offsetVal === 'string' && styles[`_width_${offsetVal}`],
  ];

  return (
    <Visible
      id={id}
      className={cn(styles.root, modsClassNames, className, overflow === false && styles[`_overflow_${overflow}`])}
      style={{
        ...cssVars,
        ...style,
      }}
      xs={typeof visible === 'boolean' ? visible : true}
      {...(typeof visible === 'object' ? visible : {})}
    >
      {children}
    </Visible>
  );
};

export default Cell;
