import { useMemo } from 'react';

import { useStoresFiltered } from 'common/use-cases/use-stores';
import GetDeliveryZones from 'endpoints/shop/get-delivery-zones';
import { LocationCoords, ShopInfo } from 'entities/shop/info';

type UseDeliveryArg = {
  storeId?: ShopInfo['id'];
  location?: LocationCoords;
  address?: string;
  addressExtra?: string;
};

const useDeliveryByLocation = ({ location, address, addressExtra = '' }: UseDeliveryArg) => {
  const hasData = !!address && !!location;
  const { getById } = useStoresFiltered({ location });
  const { data: deliveryZonesAvailable, isLoading } = GetDeliveryZones.useRequest(
    hasData ? { address, secondaryAddress: addressExtra, coordinates: location } : null,
  );
  const availableStores = useMemo<ShopInfo[]>(
    () => deliveryZonesAvailable?.map(({ storeId }) => getById(storeId)).filter(Boolean) as ShopInfo[],
    [deliveryZonesAvailable, getById],
  );

  return {
    deliveryStore: availableStores?.[0],
    deliveryZone: deliveryZonesAvailable?.[0],
    availableStores,
    isLoading,
  };
};

export { useDeliveryByLocation };
