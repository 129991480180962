import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

import merge from 'lodash/merge';

import { Messages } from '@swe/shared/network/transport/signalr/types';
import { IS_DEV } from '@swe/shared/tools/env';
import { range } from '@swe/shared/utils/array';

type WebsocketHubConfig = {
  autoDestructionTimeoutIfNoSubscribers: number | false;
  timeoutWithoutMessages: number;
  onDestroy?: () => void;
};

type Endpoint = string;
type WSProtocol = 'ws://' | 'wss://';
type Host = `${string}/`;
type WebsocketHubEndpoint = `/${Endpoint}` | `${WSProtocol}${Host}${Endpoint}`;
type WebsocketSubscriber<M extends Messages, KT extends keyof M = keyof M> = {
  event: KT;
  receiver: (...args: any) => void;
};

const DEFAULT_WS_CONFIG: WebsocketHubConfig = {
  autoDestructionTimeoutIfNoSubscribers: 500,
  timeoutWithoutMessages: 999999,
};

class WebsocketHub<M extends Messages> {
  private _connection!: HubConnection;

  private _subscribers: WebsocketSubscriber<M>[] = [];

  private _isDestroyed = false;

  private _isActive = false;

  private _config!: WebsocketHubConfig;

  private _destroyTaskTimeoutId?: any;

  private static RETRY_DELAYS = range(500).map((_, index) => Math.round(index * 1.2 * 1000));

  private static AUTO_DESTRUCTION_TIMEOUT = 1000 * 3;

  constructor(
    private readonly endpoint: WebsocketHubEndpoint,
    config?: Partial<WebsocketHubConfig>,
  ) {
    const isLocalHost = typeof window !== 'undefined' ? window.location.href.includes('localhost') : false;
    const builder = new HubConnectionBuilder()
      .withUrl(endpoint, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: !isLocalHost,
      })
      .withAutomaticReconnect(WebsocketHub.RETRY_DELAYS);
    const cfg = merge({}, DEFAULT_WS_CONFIG, config);

    this._connection = builder.build();
    this._connection.serverTimeoutInMilliseconds = cfg.timeoutWithoutMessages;
    this._config = cfg;
  }

  private throwIfDestroyed() {
    if (this._isDestroyed) throw new Error('Connection is destroyed!');
  }

  private throwIfActive() {
    if (this.isActive) throw new Error('Connection has been already established!');
  }

  private throwIfInactive() {
    if (!this.isActive) throw new Error('Connection has been already disabled!');
  }

  public get connection() {
    return this._connection;
  }

  public get hasSubscribers() {
    return this._subscribers.length > 0;
  }

  public get isActive() {
    return this._isActive;
  }

  public async open() {
    this.throwIfDestroyed();
    this.throwIfActive();
    await this._connection.start();
    this._isActive = true;

    return this;
  }

  public async close() {
    this.throwIfDestroyed();
    this.throwIfInactive();
    await this._connection.stop();
    this._isActive = false;

    return this;
  }

  public subscribe<MT extends keyof M>(event: MT, receiver: (payload: M[MT]) => void) {
    this.throwIfDestroyed();

    if (this._destroyTaskTimeoutId) {
      clearTimeout(this._destroyTaskTimeoutId);
      this._destroyTaskTimeoutId = null;
    }

    this._connection.on(event as string, receiver);
    this._subscribers.push({ event, receiver });

    if (IS_DEV) {
      console.info(`[WEBSOCKET][${this.endpoint}]: Subscribe on ${event as string}`);
    }

    return this;
  }

  public unsubscribe<MT extends keyof M>(type: MT, receiver: (payload: M[MT]) => void) {
    this.throwIfDestroyed();

    this._connection.off(type as string, receiver);
    this._subscribers = this._subscribers.filter((s) => s.receiver !== receiver);

    if (IS_DEV) {
      console.info(`[WEBSOCKET][${this.endpoint}]: Unsubscribe from ${type as string}`);
    }

    if (!this.hasSubscribers && this._config.autoDestructionTimeoutIfNoSubscribers !== false) {
      this._destroyTaskTimeoutId = setTimeout(async () => {
        await this.close();
      }, this._config.autoDestructionTimeoutIfNoSubscribers);
    }

    return this;
  }

  public async destroy() {
    try {
      if (this.hasSubscribers) {
        this._subscribers.forEach((sub) => this._connection.off(sub.event as string, sub.receiver));
      }

      if (this.isActive) {
        await this.close();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this._subscribers = [];
      this._connection = null!;
      this._isActive = false;
      this._isDestroyed = true;
      this._config.onDestroy?.();
    }
  }
}

export type { WebsocketSubscriber, WebsocketHubEndpoint, WebsocketHubConfig };
export { WebsocketHub };
