import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLilius } from 'use-lilius';

import { Button } from '@swe/shared/ui-kit/components/button';

import { ChevronLeftIcon, ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';
import { Scrollable, ScrollableMethods } from '@swe/shared/ui-kit/components/scrollable';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { range, chunk } from '@swe/shared/utils/array';
import { formatDate, isValid } from '@swe/shared/utils/date';

import styles from './styles.module.scss';

enum WeekDayAbbr {
  Sunday = 'Su',
  Monday = 'Mo',
  Tuesday = 'Tu',
  Wednesday = 'We',
  Thursday = 'Th',
  Friday = 'Fr',
  Saturday = 'Sa',
}

const GridWeekDays = () => (
  <div className={styles.row7}>
    {Object.values(WeekDayAbbr).map((day) => (
      <Button
        key={day}
        color="primary"
        size="xs"
        disabled
        variant="link"
        name="day"
      >
        {day}
      </Button>
    ))}
  </div>
);

enum Screens {
  Days = 'days',
  Months = 'months',
  Years = 'years',
}
type ScreensValues = `${Screens}`;

type CalendarGridProps = {
  data: unknown[][];
  screen: Screens.Days | Screens.Months;
  format: (item: any) => string;
  isSelected: (item: any) => boolean;
  isDisabled?: (item: any) => boolean;
  isAlt?: (item: any) => boolean;
  onClick: (item: any) => void;
};

const GridCalendar = ({ data, screen, format, isSelected, onClick, isDisabled, isAlt }: CalendarGridProps) => {
  return (
    <Stack spacing={screen === Screens.Days ? 'xxxs' : 'sm'}>
      {data.map((row, ri) => (
        <Stack
          key={ri}
          direction="row"
          spacing={screen === Screens.Days ? 'xxxs' : 'xxs'}
          className={screen === Screens.Days ? styles.row7 : styles.row3}
        >
          {row.map((item, ci) => (
            <Button
              color={isSelected(item) ? 'primary' : isAlt?.(item) ? 'ghost' : 'light'}
              size="xs"
              key={`${ri}${ci}`}
              onClick={() => onClick(item)}
              name={screen === Screens.Days ? 'Day' : 'Month'}
              disabled={isDisabled?.(item)}
            >
              {format(item)}
            </Button>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

type GridDaysProps = {
  calendar: Date[][][];
  isSelected: (date: Date) => boolean;
  onSelect: (date: Date) => void;
  isDisabled: (date: Date) => boolean;
  isAlt: (date: Date) => boolean;
};
const GridDays = ({ calendar, isSelected, onSelect, isDisabled, isAlt }: GridDaysProps) => {
  return (
    <GridCalendar
      data={calendar[0]}
      screen={Screens.Days}
      format={useCallback((date: Date) => `${date.getDate()}`, [])}
      isSelected={isSelected}
      isDisabled={isDisabled}
      onClick={onSelect}
      isAlt={isAlt}
    />
  );
};

type ControlsProps = {
  viewing: Date;
  onNext: () => void;
  onPrev: () => void;
  onClickYear: () => void;
  onClickMoth: () => void;
  screen: ScreensValues;
  isDisabledNext?: boolean;
  isDisabledPrev?: boolean;
};
const Controls = ({
  viewing,
  onNext,
  onPrev,
  screen,
  isDisabledNext,
  isDisabledPrev,
  onClickYear,
  onClickMoth,
}: ControlsProps) => {
  return (
    <Stack
      spacing="xxs"
      wrap={false}
      direction="row"
    >
      <Button
        color="ghost"
        size="xs"
        icon={ChevronLeftIcon}
        ariaLabel="Prev"
        onClick={onPrev}
        disabled={isDisabledPrev}
      />
      {screen === Screens.Days && (
        <Button
          size="xs"
          color="light"
          block
          onClick={onClickMoth}
          name={screen}
        >
          {formatDate(viewing, 'MMMM')}
        </Button>
      )}
      <Button
        size="xs"
        color="light"
        block
        onClick={onClickYear}
        name={screen}
      >
        {formatDate(viewing, 'y')}
      </Button>
      <Button
        color="ghost"
        size="xs"
        icon={ChevronRightIcon}
        ariaLabel="Next"
        onClick={onNext}
        disabled={isDisabledNext}
      />
    </Stack>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const prepMonths = months.map((title, value) => ({
  title,
  value,
}));

type GridMonthsProps = {
  onSelect: (month: number) => void;
  isSelected: (month: number) => boolean;
  isDisabled: (month: number) => boolean;
};
const GridMonths = ({ onSelect, isSelected, isDisabled }: GridMonthsProps) => {
  return (
    <GridCalendar
      data={chunk(prepMonths, 3)}
      screen={Screens.Months}
      format={useCallback(({ title }) => title, [])}
      isSelected={useCallback(({ value }) => isSelected(value), [isSelected])}
      isDisabled={useCallback(({ value }: { value: number }) => isDisabled(value), [isDisabled])}
      onClick={useCallback(({ value }) => onSelect(value), [onSelect])}
    />
  );
};

type GridYearsProps = {
  onSelect: (year: number) => void;
  isSelected: (year: number) => boolean;
  max: number;
  min: number;
};
const GridYears = ({ onSelect, isSelected, max, min }: GridYearsProps) => {
  const years = range(max, min - 1);
  const selectedRef = useRef<HTMLButtonElement | null>(null);

  const scrollableRef = useRef<ScrollableMethods>(null);
  const contRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (selectedRef.current && scrollableRef.current) {
        scrollableRef.current.scrollTo(selectedRef.current);
      }
    }, 0);
  }, []);

  return (
    <Scrollable
      className={styles.years}
      ref={scrollableRef}
    >
      <Stack
        spacing="sm"
        ref={contRef}
      >
        {years.map((year) => (
          <Button
            key={year}
            block
            size="xs"
            color={isSelected(year) ? 'primary' : 'ghost'}
            onClick={() => onSelect(year)}
            ref={(node) => {
              if (isSelected(year)) {
                selectedRef.current = node;
              }
            }}
            name={`${year}`}
          >
            {year}
          </Button>
        ))}
      </Stack>
    </Scrollable>
  );
};

type CalendarProps = {
  value: Date;
  onChange: (date: Date) => void;
  max?: Date;
  min?: Date;
  onChangeDay?: (date: Date) => void;
  initialScreen?: `${Screens}`;
} & ComponentHasClassName;

const Calendar = ({ value, onChange, max, min, onChangeDay, initialScreen = Screens.Days }: CalendarProps) => {
  const {
    calendar,
    viewing,
    viewNextMonth,
    viewNextYear,
    viewPreviousMonth,
    viewPreviousYear,
    select,
    isSelected,
    selected,
    clearTime,
    setViewing,
  } = useLilius();
  const [screen, setScreen] = useState<`${Screens}`>(initialScreen);

  const next = useCallback(() => {
    return screen === Screens.Days ? viewNextMonth() : viewNextYear();
  }, [screen, viewNextMonth, viewNextYear]);

  const isDisabledMonth = useCallback(
    (month: number) => {
      return (
        !!(max && max.getMonth() < month && viewing.getFullYear() >= max.getFullYear()) ||
        !!(min && min.getMonth() > month && viewing.getFullYear() <= min.getFullYear())
      );
    },
    [max, min, viewing],
  );

  const isDisabledYear = useCallback(
    (year: number) => {
      return (max && max.getFullYear() < year) || (min && min.getFullYear() > year);
    },
    [max, min],
  );

  const prev = useCallback(() => {
    if (screen === Screens.Days) {
      viewPreviousMonth();
      return;
    }
    viewPreviousYear();
  }, [screen, viewPreviousMonth, viewPreviousYear]);

  const _onChange = useCallback(
    (date: Date) => {
      onChange(clearTime(date));
    },
    [clearTime, onChange],
  );

  const _onChangeDay = useCallback(
    (date: Date) => {
      const value = clearTime(date);
      onChangeDay?.(value);
      _onChange(value);
    },
    [_onChange, clearTime, onChangeDay],
  );

  useEffect(() => {
    if (isValid(value)) {
      setViewing(clearTime(value));
      select(clearTime(value), true);
    }
  }, [clearTime, select, setViewing, value]);

  useEffect(() => {
    if (initialScreen) {
      setScreen(initialScreen);
    }
  }, [initialScreen]);

  const isSelectedMonth = useCallback(
    (month: number) => {
      return selected.some((date) => date.getMonth() === month);
    },
    [selected],
  );

  const onSelectMonth = useCallback(
    (month: number) => {
      const date = new Date(isValid(selected[0]) ? selected[0] : new Date());
      date.setMonth(month);
      date.setFullYear(viewing.getFullYear());
      setViewing(date);
      setScreen(Screens.Days);
      _onChange(date);
    },
    [_onChange, selected, setViewing, viewing],
  );

  const isSelectedYear = useCallback(
    (year: number) => {
      return viewing.getFullYear() === year;
    },
    [viewing],
  );

  const onSelectYear = useCallback(
    (month: number) => {
      const date = new Date(isValid(selected[0]) ? selected[0] : new Date());
      date.setFullYear(month);
      setViewing(date);
      setScreen(Screens.Months);
      _onChange(date);
    },
    [_onChange, selected, setViewing],
  );

  const isDisabledDay = useCallback(
    (day: Date) => {
      return !!(max && day > max) || !!(min && day < min);
    },
    [max, min],
  );

  const curDate = new Date();
  const yearMin = (min && min.getFullYear()) || curDate.getFullYear() - 120;
  const yearMax = (max && max.getFullYear()) || curDate.getFullYear();

  const isDisabledNext = useMemo(() => {
    if (screen === Screens.Days) {
      return isDisabledMonth(viewing.getMonth() + 1);
    }
    return isDisabledYear(viewing.getFullYear() + 1);
  }, [isDisabledMonth, isDisabledYear, screen, viewing]);

  const isDisabledPrev = useMemo(() => {
    if (screen === Screens.Days) {
      return isDisabledMonth(viewing.getMonth() - 1);
    }
    return isDisabledYear(viewing.getFullYear() - 1);
  }, [isDisabledMonth, isDisabledYear, screen, viewing]);

  const isAlt = useCallback(
    (date: Date) => {
      return date.getMonth() !== viewing.getMonth();
    },
    [viewing],
  );
  const toMonths = useCallback(() => {
    setScreen(Screens.Months);
  }, []);

  const toYears = useCallback(() => {
    setScreen(Screens.Years);
  }, []);

  return (
    <Stack
      spacing="sm"
      className={styles.root}
    >
      {screen !== Screens.Years && (
        <Controls
          viewing={viewing}
          screen={screen}
          onNext={next}
          onPrev={prev}
          isDisabledNext={isDisabledNext}
          isDisabledPrev={isDisabledPrev}
          onClickMoth={toMonths}
          onClickYear={toYears}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          justifySelf: 'stretch',
          alignSelf: 'stretch',
          flexGrow: 1,
        }}
      >
        {screen === Screens.Days && (
          <Stack spacing="sm">
            <GridWeekDays />
            <GridDays
              calendar={calendar}
              isSelected={isSelected}
              isDisabled={isDisabledDay}
              onSelect={_onChangeDay}
              isAlt={isAlt}
            />
          </Stack>
        )}
        {screen === Screens.Months && (
          <GridMonths
            isSelected={isSelectedMonth}
            onSelect={onSelectMonth}
            isDisabled={isDisabledMonth}
          />
        )}
        {screen === Screens.Years && (
          <GridYears
            isSelected={isSelectedYear}
            onSelect={onSelectYear}
            min={yearMin}
            max={yearMax}
          />
        )}
      </div>
    </Stack>
  );
};

export type { CalendarProps };
export { Calendar };
