import { KeyboardEvent } from 'react';

import { partialRight } from '@swe/shared/utils/func';

type FunctionalKey =
  | 'Enter'
  | 'Shift'
  | 'Space'
  | 'Backspace'
  | 'Meta'
  | 'Control'
  | 'Alt'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight';

type Modificator = 'Shift' | 'Control' | 'Alt' | 'Meta';

type Key = `${string}` | FunctionalKey;

const MOD_MAP = {
  Shift: 'shiftKey' as const,
  Control: 'ctrlKey' as const,
  Alt: 'altKey' as const,
  Meta: 'metaKey' as const,
};

type IsKeyPressedKeyArg = {
  key: Key | Key[];
  mod?: Modificator;
};

const isKeyPressed = (event: KeyboardEvent, { key, mod }: IsKeyPressedKeyArg) => {
  const isKeyDown =
    typeof key === 'string'
      ? event.key === key || event.code === key
      : key.includes(event.key) || key.includes(event.code);
  const isModDown = typeof mod !== 'undefined' ? event[MOD_MAP[mod]] : undefined;

  return typeof isModDown !== 'undefined' ? isKeyDown && isModDown : isKeyDown;
};

const isEnterOrSpacePressed = partialRight(isKeyPressed, { key: ['Space', 'Enter'] });

export { isKeyPressed, isEnterOrSpacePressed };
