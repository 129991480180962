import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { PlatformOs } from 'common/use-cases/use-platform-os';
import { AeroPayPaymentContext } from 'domains/checkout/types/aeropay';
import { Cart } from 'entities/cart/cart';
import { StoreSaleType } from 'entities/shop/sale-type';

enum ConfirmApiErrorCode {
  UnknownError = 'UnknownError',
  Unauthorized = 401,
  NotFound = 'NotFound',
  OrderRecreated = 'OrderRecreated',
  ConfirmCartAndOrderItemsAreDifferent = 'ConfirmCartAndOrderItemsAreDifferent',
}

type Params = {
  saleType?: StoreSaleType;
  platformOs: PlatformOs;
  paymentContext: AeroPayPaymentContext | undefined;
};

type ConfirmEndpointError = APIErrorExtended<ConfirmApiErrorCode>;

const NAME = '/Cart/Confirm';
const ConfirmEndpoint = createModernEndpoint<Params, Cart, ConfirmEndpointError>(NAME);

export { ConfirmEndpoint, ConfirmApiErrorCode };
export type { ConfirmEndpointError };
