import { KeyboardEventHandler, KeyboardEvent, useCallback, useEffect, SyntheticEvent } from 'react';

enum Modifier {
  Alt = 'Alt',
  AltGraph = 'AltGraph',
  CapsLock = 'CapsLock',
  Control = 'Control',
  Fn = 'Fn',
  FnLock = 'FnLock',
  Hyper = 'Hyper',
  Meta = 'Meta',
  NumLock = 'NumLock',
  OS = 'OS',
  ScrollLock = 'ScrollLock',
  Shift = 'Shift',
  Super = 'Super',
  Symbol = 'Symbol',
  SymbolLock = 'SymbolLock',
}

type Options = {
  key: string;
  modifiers?: Modifier[];
  global?: boolean;
  onKeyUp?: (e: SyntheticEvent) => void;
  onKeyDown?: (e: SyntheticEvent) => void;
  prevent?: boolean;
};

const hasModifiers = <ET extends KeyboardEvent>(event: ET, modifiers: Modifier[]) =>
  modifiers.map((modifier) => event.getModifierState(modifier)).every(Boolean);

const isPressed = <ET extends KeyboardEvent>(event: ET, key: string, modifiers?: Modifier[]) =>
  key === event.key && (modifiers ? hasModifiers(event, modifiers) : true);

const useKeyPress = <ET extends Element>({ key, modifiers, global, onKeyUp, onKeyDown, prevent }: Options) => {
  const preventFn = useCallback(
    (event: KeyboardEvent<ET>) => {
      if (prevent) {
        event.preventDefault();
      }
    },
    [prevent],
  );
  const keyDownHandler = useCallback<KeyboardEventHandler<ET>>(
    (event: KeyboardEvent<ET>) => {
      if (isPressed(event, key, modifiers)) {
        preventFn(event);
        onKeyDown?.(event);
      }
    },
    [key, modifiers, onKeyDown, preventFn],
  );
  const keyUpHandler = useCallback<KeyboardEventHandler<ET>>(
    (event: KeyboardEvent<ET>) => {
      if (isPressed(event, key, modifiers)) {
        preventFn(event);
        onKeyUp?.(event);
      }
    },
    [key, modifiers, onKeyUp, preventFn],
  );

  useEffect(() => {
    if (global) {
      window.addEventListener('keydown', keyDownHandler as any);
      window.addEventListener('keyup', keyUpHandler as any);

      return () => {
        window.removeEventListener('keydown', keyDownHandler as any);
        window.removeEventListener('keyup', keyUpHandler as any);
      };
    }
  }, [global, keyDownHandler, keyUpHandler]);

  return global
    ? undefined
    : {
        onKeyDown: onKeyDown ? keyDownHandler : undefined,
        onKeyUp: onKeyUp ? keyUpHandler : undefined,
      };
};

export { useKeyPress, Modifier };
