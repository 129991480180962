import cx from 'clsx';

import { useTransition, a } from 'react-spring';

import { useAnimationSettings } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasClassName, ComponentHasStyles } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

const OPACITY_VALUE = 0.5;

type BackdropProps = ComponentHasClassName &
  ComponentHasStyles & {
    visible?: boolean;
    onClick?: () => void;
  };

const Backdrop = ({ className, visible, onClick, style }: BackdropProps) => {
  const { enabled: isAnimationEnabled } = useAnimationSettings();

  const transition = useTransition(Boolean(visible), {
    from: {
      opacity: isAnimationEnabled ? 0 : OPACITY_VALUE,
    },
    enter: { opacity: OPACITY_VALUE },
    leave: { opacity: 0 },
  });

  return transition(
    ({ opacity }, visible) =>
      visible && (
        <a.div
          className={cx(className, styles.root)}
          onClick={onClick}
          style={{
            ...style,
            opacity,
          }}
        />
      ),
  );
};

export default Backdrop;
