import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { PaginatedRequest, PaginatedResponse } from '@swe/shared/types/wrappers/pagination';

import { Order } from 'entities/common/orders';

type Params = PaginatedRequest & {
  fromDate?: DateISOString;
  toDate?: DateISOString;
  orderStatusIds?: number[];
};
type Data = PaginatedResponse<Order>;
type Error = APIErrorExtended;

const NAME = '/Orders/GetOrdersList';
const GetOrderHistoryEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default GetOrderHistoryEndpoint;
