import createEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Cart } from 'entities/cart/cart';

type Params = undefined;

const NAME = '/Cart/Get';
const GetCartEndpoint = createEndpoint<Params, Cart>(NAME, {
  notifyWithSnackbar: false,
});

export default GetCartEndpoint;
export { GetCartEndpoint };
