import cx from 'clsx';

import Grid from '@swe/shared/ui-kit/components/grid';
import { LoaderIcon, IconSize } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

export type LoaderColor = 'default' | 'inherit';
export type LoaderSize = IconSize;

export type LoaderProps = ComponentHasClassName & {
  size?: LoaderSize;
  color?: LoaderColor;
  center?: boolean;
  centered?: boolean; // TODO: Remove
};

export const Loader = ({ className, color = 'default', centered, center, size = 'md' }: LoaderProps) => {
  const LoaderComponent = (
    <LoaderIcon
      className={cx(styles.root, centered && styles._centered, styles[`_color_${color}`], className)}
      size={size}
    />
  );

  return center ? (
    <Grid.Row
      hAlign="center"
      vAlign="center"
    >
      <Grid.Cell cols="content">{LoaderComponent}</Grid.Cell>
    </Grid.Row>
  ) : (
    LoaderComponent
  );
};

export default Loader;
