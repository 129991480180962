import { isSSR } from '@swe/shared/utils/environment';

class LogManager {
  error(err: Error) {
    console.error(`[${isSSR ? 'server' : 'client'} error]: `, err);
  }

  action(/* name: string */) {
    // do nothing for now
  }
}

const logManager = new LogManager();

export { logManager };
