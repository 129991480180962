import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type Data = undefined;
type Params = {
  referralData: string;
};

const NAME = '/Referral/Attach';
const AttachReferralByDataEndpoint = createModernEndpoint<Params, Data>(NAME);

export default AttachReferralByDataEndpoint;
export { AttachReferralByDataEndpoint };
