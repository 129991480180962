import Link from '@swe/shared/providers/router/link';
import { Button } from '@swe/shared/ui-kit/components/button';
import Container from '@swe/shared/ui-kit/components/container';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';

import styles from './styles.module.scss';

import { useStoreConfig, useStoreInfo } from 'common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { useRouterPathname } from 'common/router';
import { Routes } from 'common/router/constants';

const MedicalCardAssistance = () => {
  const { medicalCardAssistanceLink } = useStoreConfig();
  const { user } = useCurrentUser();
  const pathname = useRouterPathname();

  const { location } = useStoreInfo();
  const isRightPage = [Routes.Catalog, Routes.Home, Routes.Discounts].includes(pathname);
  const isVisible = medicalCardAssistanceLink && isRightPage && !user;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Container size="md">
        <SectionHeading size="lg">Get Your {location?.region?.name} Medical Marijuana Card</SectionHeading>
        <div className={styles.content}>
          Are you ready to apply for a medical marijuana card in {location?.region?.name}? Do you need more information
          on qualifying conditions, purchase limits, and fees? We are here to help.
          <br />
          <br />
          Follow the link to request assistance applying for your card, renew, and more.
        </div>
        <Link
          className={styles.link}
          href={medicalCardAssistanceLink}
        >
          <Button
            ariaLabel="Medical Card Assistance"
            size="sm"
          >
            Medical Card Assistance
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export { MedicalCardAssistance };
