import { useIntl } from '@swe/shared/tools/intl';
import { useBreakpoint } from '@swe/shared/tools/media';
import Button, { ButtonColor } from '@swe/shared/ui-kit/components/button';

import Container from '@swe/shared/ui-kit/components/container';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { useStoreConfig } from 'common/providers/config';

import { useGuest } from 'common/providers/guest';
import { useCurrentUser } from 'common/providers/user';
import { useRouterPathname } from 'common/router';
import { Routes } from 'common/router/constants';

const SignupSubheader = () => {
  const theme = useTheme();
  const { user, toSignIn: _toSignIn, hasAuthCookie } = useCurrentUser();
  const pathname = useRouterPathname();
  const { isSignupPopupEnabled, signupPopupTitle } = useStoreConfig();
  const { mobile } = useBreakpoint();

  const toSignUp = useCallback(() => _toSignIn(false, true), [_toSignIn]);
  const toSignIn = useCallback(() => _toSignIn(false, false), [_toSignIn]);
  const { intl } = useIntl();
  const headingText = mobile
    ? intl.t('common.signupSubheader.headingTextMobile', undefined, 'Sign up or log in to see exclusive deals')
    : intl.t('common.signupSubheader.headingText', undefined, 'Sign up or log in to see exclusive deals');

  const heading = isSignupPopupEnabled ? signupPopupTitle : headingText;
  const { isGuest, isLoading: isLoadingGuest } = useGuest();

  if (
    ((hasAuthCookie || user) && !isLoadingGuest && !isGuest) ||
    [Routes.SignIn, Routes.SignUp].includes(pathname as Routes)
  )
    return null;

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.row}>
          <div className={styles.text}>{heading}</div>
          <div className={styles.controls}>
            <Button
              className={styles.button}
              name="sign-up-btn"
              color={theme.header.loginButton as ButtonColor}
              size="sm"
              onClick={toSignIn}
            >
              Log In
            </Button>
            <Button
              className={styles.button}
              name="sign-up-btn"
              color={theme.header.signupButton as ButtonColor}
              size="sm"
              onClick={toSignUp}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </Container>

      {theme.header.secondaryDivider !== 'off' && <div className={styles.divider} />}
    </div>
  );
};

export { SignupSubheader };
