import Link from '@swe/shared/providers/router/link';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

import { useStoreConfig } from 'common/providers/config';
import { usePWA } from 'common/providers/pwa';
import { Routes } from 'common/router/constants';

import { getLogoAlt } from 'entities/common/seo';

type AppLogoProps = ComponentHasClassName & {
  link?: boolean;
};

const AppLogo = ({ className, link = true }: AppLogoProps) => {
  const { logo, title, logoUrl } = useStoreConfig();

  const { isInStandaloneMode } = usePWA();
  const finalLogoUrl = isInStandaloneMode ? Routes.Home : logoUrl || Routes.Home;
  const Logo = (
    <img
      className={styles.logo}
      src={logo}
      alt={getLogoAlt(title ?? '')}
    />
  );

  if (!logo) return null;

  return link !== false ? (
    <Link
      href={finalLogoUrl}
      className={cx(className, styles.root)}
    >
      {Logo}
    </Link>
  ) : (
    Logo
  );
};

export { AppLogo };
