import createEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { Cart } from 'entities/cart/cart';
import { StoreSaleType } from 'entities/shop/sale-type';

type AddToCartManyEndpointParams = {
  saleType?: StoreSaleType;
  variants: {
    variantId: EntityID<number>;
    qty: number;
  }[];
};

type Error = APIErrorExtended;

const NAME = '/Cart/AddProducts';
const AddToCartManyEndpoint = createEndpoint<AddToCartManyEndpointParams, Cart>(NAME);

export type { AddToCartManyEndpointParams };
export { AddToCartManyEndpoint };
export type { Error };
