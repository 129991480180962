import React from 'react';

import { Hero, HeroProps } from '@swe/shared/ui-kit/components/hero';
import { Error404Illustration } from '@swe/shared/ui-kit/components/illustration';

type Error404Props = {
  actions?: HeroProps['actions'];
};

const Error404 = ({ actions }: Error404Props) => {
  return (
    <Hero
      illustration={<Error404Illustration />}
      title="Not found"
      content={
        <span>
          The requested URL was not found on this server. <br /> That’s all we know.
        </span>
      }
      actions={actions}
      border={false}
    />
  );
};

export type { Error404Props };
export { Error404 };
