import cn from 'clsx';
import { forwardRef, ReactNode } from 'react';

import { ViewportBreakpoint } from '@swe/shared/tools/media';
import { ComponentHasClassName, Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type ContainerSize = Sizes<'sm' | 'md'> | 'none';

type ContainerProps = {
  children?: ReactNode;
  size?: ContainerSize;
  paddings?: boolean;
  maxWidth?: ViewportBreakpoint;
} & ComponentHasClassName;

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, size, paddings, maxWidth }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          styles.root,
          size && styles[`_size_${size}`],
          paddings === false && styles._paddings_disabled,
          maxWidth && styles[`_max_${maxWidth}`],
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

export type { ContainerProps };
export { Container };
export default Container;
