import hash from 'object-hash';

import { getApiHost } from '@swe/shared/configs/api';
import { FETCH_DEFAULT_CONFIG } from '@swe/shared/network/endpoint-factories/modern/constants';
import { createCustomError } from '@swe/shared/network/endpoint-factories/modern/errors';
import { APIError, ApiErrorCode, FetchConfig } from '@swe/shared/network/endpoint-factories/modern/types';
import { FetchFn } from '@swe/shared/network/transport/swr/transport.types';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { IS_DEV } from '@swe/shared/tools/env';
import { isSSR } from '@swe/shared/utils/environment';
import { capitalize } from '@swe/shared/utils/string';

enum XSweedHeader {
  Cookie = 'X-Cookie',
  SetCookie = 'X-Set-Cookie',
  TraceId = 'x-sweed-trace-id',
  ClientTraceId = 'TraceId',
  IsSSR = 'SSR',
  StoreId = 'StoreId',
}

type GlobalHeaders = Partial<{
  [XSweedHeader.StoreId]: number;
}>;

let globalHeaders: GlobalHeaders = {};

const setGlobalHeaders = (headers: GlobalHeaders) => {
  if (!isSSR) {
    globalHeaders = Object.assign(globalHeaders, headers);
  }
};
const getGlobalHeaders = () => globalHeaders;

const removeGlobalHeaders = (key: keyof GlobalHeaders) => {
  delete globalHeaders[key];
};

type ErrorHandler = (err: any) => Promise<boolean> | boolean;

let errorHandler: ErrorHandler | undefined;

const setErrorHandler = (handler: ErrorHandler) => {
  errorHandler = handler;
};

const generateTraceId = () => hash({ datetime: new Date().toISOString(), num: Math.random() * 10000 });

const createFormData = (params: Record<string, any>) => {
  const entries = Object.entries(params);
  const formData = new FormData();

  entries.forEach(([key, value]) => {
    const cKey = capitalize(key);

    if (Array.isArray(value) && value[0] instanceof File) {
      value.forEach((file) => formData.append(cKey, file));
    } else {
      formData.append(cKey, value);
    }
  });

  return formData;
};

const getEndpoint = (name: string) => {
  const { isPublic } = getApiHost();
  const apiPrefx = '/_api';
  if (isSSR) {
    return isPublic ? `${apiPrefx}${name}` : name;
  }

  return `${window.__sw_externalApiBasePath ?? apiPrefx}${name}`;
};

const getBase = () => {
  const { isPublic, rawHost } = getApiHost();
  if (isSSR) {
    return rawHost;
  }

  return !isPublic && window.__sw_externalApiHost ? `https://${window.__sw_externalApiHost}` : window.location.origin;
};

const FETCH_FN: FetchFn<FetchConfig> = async (name, params = {}, _config = {} as FetchConfig) => {
  // TODO: We should reconsider this approach, because it relies on API rewrite from shop-ui package
  const ENDPOINT_URL = new URL(getEndpoint(name), getBase());

  const config: RequestInit & FetchConfig = {
    ...FETCH_DEFAULT_CONFIG,
    ..._config,
    credentials: 'same-origin',
    headers: {
      ...(globalHeaders as Record<string, string>),
      [XSweedHeader.ClientTraceId]: generateTraceId(),
      [XSweedHeader.IsSSR]: String(isSSR),
      ...(!isSSR ? { [XSweedHeader.Cookie]: document.cookie } : {}),
      accept: '*/*',
      ...(_config.asFormData ? {} : { 'Content-Type': 'application/json' }),
      ..._config.headers,
    },
  };

  if (config.method?.toUpperCase() === 'GET') {
    if (params && typeof params === 'object') {
      ENDPOINT_URL.search = new URLSearchParams(params).toString();
    }
  } else {
    config.body = config.asFormData ? createFormData(params) : JSON.stringify(params);
  }

  try {
    const response = await fetch(ENDPOINT_URL, config);

    if (!isSSR && response.headers.has(XSweedHeader.SetCookie)) {
      response.headers
        .get(XSweedHeader.SetCookie)!
        .replace(/,\s([a-zA-Z])/g, '|||$1')
        .split('|||')
        .forEach((cookie) => {
          document.cookie = cookie;
        });
    }

    if (!response.ok) {
      const traceId = response.headers.get(XSweedHeader.TraceId) || undefined;
      let apiError: APIError;

      if (response.status === 401) {
        apiError = {
          code: ApiErrorCode.Unauthorized,
          message: 'User is unauthorized',
        };
      } else {
        try {
          const apiErrorResponse = (await response.json()) as APIError;

          apiError = apiErrorResponse.code
            ? apiErrorResponse
            : {
                code: ApiErrorCode.UnknownError,
                message: 'Response body is not parsed properly',
                details: apiErrorResponse,
              };
        } catch (err: any) {
          apiError = {
            code: ApiErrorCode.UnknownError,
            message: 'Response body is not parsed',
            details: err,
          };
        }
      }
      throw createCustomError({
        endpoint: name,
        traceId,
        ...apiError,
      });
    }

    try {
      const data = await response.json();
      return [data, response.headers];
    } catch (e) {
      return [null, response.headers];
    }
  } catch (err: any) {
    const isConnectionIssue = err instanceof TypeError;

    if (!isConnectionIssue && !(errorHandler && (await errorHandler(err))) && !isSSR && config.notifyWithSnackbar) {
      SnackbarService.push(
        IS_DEV
          ? {
              type: 'danger',
              heading: 'API Error',
              message: `${err.endpoint}: ${err.code} ${err.message}`,
            }
          : {
              type: 'danger',
              message: err.message,
            },
      );
    }

    throw err;
  }
};

export { setGlobalHeaders, getGlobalHeaders, setErrorHandler, FETCH_FN, removeGlobalHeaders, XSweedHeader };
export type { GlobalHeaders };
