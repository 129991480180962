import { Prototypes } from 'common/entities/documents/Prototypes';
import { createRequirement, Requirement, RequirementStatus } from 'common/entities/documents/Requirement';
import { Verifications } from 'common/entities/documents/Verifications';
import { DocumentCategory, documentCategoryToLabel, RequiredDocuments } from 'entities/documents/documents-v2';

type RequirementsMap = { [category in DocumentCategory]: Requirement<category> };

class Requirements {
  readonly requirements: RequirementsMap;

  constructor(requirements: RequiredDocuments, verifications: Verifications, prototypes: Prototypes) {
    this.requirements = {
      [DocumentCategory.Identity]: createRequirement(
        DocumentCategory.Identity,
        requirements,
        prototypes,
        verifications,
      ),
      [DocumentCategory.Medical]: createRequirement(DocumentCategory.Medical, requirements, prototypes, verifications),
      [DocumentCategory.Additional]: createRequirement(
        DocumentCategory.Additional,
        requirements,
        prototypes,
        verifications,
      ),
    };
  }

  get isFulfilled() {
    const categories = Object.values(this.requirements);
    return categories.every((category) => category.status === RequirementStatus.Fulfilled);
  }

  get isNothingRequired() {
    const categories = Object.values(this.requirements);
    return categories.every((category) => category.requiredDocuments.length === 0);
  }

  byCategory(category: DocumentCategory) {
    return this.requirements[category];
  }

  get requiredDocumentsCategories() {
    return Object.entries(this.requirements).reduce((requiredDocumentsString, requirementsEntry) => {
      const [category, requirements] = requirementsEntry;
      if (requirements.requiredDocuments.length > 0) {
        const separator = requiredDocumentsString === '' ? '' : ', ';
        const categoryName = documentCategoryToLabel(category as DocumentCategory);

        return requiredDocumentsString.concat(`${separator}${categoryName}`);
      }
      return requiredDocumentsString;
    }, '');
  }

  get nonEmptyRequirements() {
    return Object.values(this.requirements).filter((req) => !req.isEmpty) as Requirement<DocumentCategory>[];
  }
}

export type { RequirementsMap };
export { Requirements, RequirementStatus };
