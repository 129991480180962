import { usePersistState } from '@swe/shared/providers/persist-state';

import { useMatchRoute } from '@swe/shared/providers/router/helpers';
import { Link } from '@swe/shared/providers/router/link';
import { Container } from '@swe/shared/ui-kit/components/container';
import { CloseIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

import { useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import { useStoreConfig } from 'common/providers/config';
import { Routes } from 'common/router/constants';

const SignUpNotifier = () => {
  const { isSignupNotifierEnabled, signupNotifierFaqLink } = useStoreConfig();
  const isMatchingRoute = useMatchRoute([Routes.SignIn, Routes.SignUp]);
  const [isShown, setIsShown] = usePersistState<boolean>('local', 'isSignUpNotifierShown', !isSignupNotifierEnabled);
  const [isVisible, setVisible] = useState(false);

  const handleClose = useCallback(() => {
    setIsShown(true);
  }, [setIsShown]);

  useEffect(() => {
    setVisible(!isShown);
  }, [isShown]);

  if (!isSignupNotifierEnabled || !isMatchingRoute || !isVisible) {
    return null;
  }

  return (
    <Container size="sm">
      <SectionHeading
        size="md"
        subHeading={
          <Stack
            className={styles.text}
            spacing="xxs"
          >
            <Text
              as="p"
              variant="body"
              size="sm"
            >
              Please{' '}
              <Link
                className={styles.link}
                href={Routes.SignUp}
              >
                Create a New Account
              </Link>{' '}
              to Keep Your Loyalty Points and Continue Shopping.
            </Text>
            <Text
              as="p"
              variant="body"
              size="sm"
            >
              Please Sign Up for a new account using your existing email to access your loyalty points and complete your
              purchase.
            </Text>
            {signupNotifierFaqLink && (
              <Text
                as="p"
                variant="body"
                size="sm"
              >
                Still having trouble? Click{' '}
                <Link
                  className={styles.link}
                  href={signupNotifierFaqLink as AbsoluteURL}
                >
                  here
                </Link>{' '}
                for FAQs and Support
              </Text>
            )}
          </Stack>
        }
        button={{ icon: CloseIcon, onClick: handleClose }}
      >
        To Our Returning Customers
      </SectionHeading>
    </Container>
  );
};

export { SignUpNotifier };
