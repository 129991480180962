import { Provider, createContext, useContext } from 'react';

type ReturnType<ContextType extends Record<string, unknown>, ContextName extends string> = {
  [key in `${ContextName}ContextProvider`]: Provider<ContextType>;
} & { [key in `use${ContextName}Context`]: () => ContextType };

const createReactContext = <
  ContextType extends Record<string, unknown> = Record<string, unknown>,
  ContextName extends string = string,
>(
  ctxName: ContextName,
  ctxInitialValues: ContextType,
): ReturnType<ContextType, ContextName> => {
  const Context = createContext<ContextType>(ctxInitialValues);
  const { Provider } = Context;
  const useContextHook = () => useContext(Context);

  return {
    [`${ctxName}ContextProvider`]: Provider,
    [`use${ctxName}Context`]: useContextHook,
  } as ReturnType<ContextType, ContextName>;
};

export { createReactContext };
