import { useCallback, useMemo } from 'react';

import Button, { ButtonProps } from '@swe/shared/ui-kit/components/button';
import { useFormContext } from '@swe/shared/ui-kit/components/form/core/context';
import { AugmentedFormContext, FieldValues } from '@swe/shared/ui-kit/components/form/core/types';

type FormButtonProps<FormValues extends FieldValues> = Omit<
  ButtonProps,
  'type' | 'onClick' | 'disabled' | 'pending'
> & {
  onClick?: (context: AugmentedFormContext<FormValues>) => void;
  disableWhileProcessing?: boolean;
  pendingWhileProcessing?: boolean;
  disabled?: boolean | ((context: AugmentedFormContext<FormValues>) => boolean);
  pending?: boolean | ((context: AugmentedFormContext<FormValues>) => boolean);
};

const FormButton = <FormValues extends FieldValues>({
  disableWhileProcessing = true,
  pendingWhileProcessing = false,
  onClick,
  disabled,
  pending,
  ...props
}: FormButtonProps<FormValues>) => {
  const context = useFormContext<FormValues>();
  const {
    formState: { isSubmitting },
  } = context;

  const isDisabled = useMemo(() => {
    const isOuterDisabled = typeof disabled === 'function' ? disabled(context) : disabled;

    return disableWhileProcessing ? isOuterDisabled ?? isSubmitting : isOuterDisabled;
  }, [context, disableWhileProcessing, disabled, isSubmitting]);
  const isPending = useMemo(() => {
    const isOuterPending = typeof pending === 'function' ? pending(context) : pending;

    return pendingWhileProcessing ? isOuterPending ?? isSubmitting : isOuterPending;
  }, [context, isSubmitting, pending, pendingWhileProcessing]);

  const handleClick = useCallback(() => onClick?.(context), [context, onClick]);

  return (
    <Button
      {...(props as ButtonProps)}
      type="button"
      onClick={handleClick}
      disabled={isDisabled}
      pending={isPending}
    />
  );
};

export { FormButton };
export default FormButton;
