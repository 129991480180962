import useGTMAAdapter from 'common/providers/analytics/adapters/gtm';
import { PosthogConfig, usePosthogAdapter } from 'common/providers/analytics/adapters/posthog';
import useSweedAAdapter from 'common/providers/analytics/adapters/sweed';

type AnalyticsAdaptersConfig = {
  posthog?: PosthogConfig;
};

const useAdapters = (config?: AnalyticsAdaptersConfig) => {
  const sweedAdapter = useSweedAAdapter();
  const gtmAdapter = useGTMAAdapter();
  const posthogAdapter = usePosthogAdapter(config?.posthog);

  return [sweedAdapter, gtmAdapter, posthogAdapter];
};

export { useAdapters };
export type { AnalyticsAdaptersConfig };
