import loaderIcon from './assets/loader.svg';

import makeIcon, { IconProps, makeIcon2 } from './make-icon';

export type { IconSize, IconProps } from './make-icon';
export type IconComponent = React.ComponentType<IconProps>;

export const LoaderIcon = makeIcon(loaderIcon); // ?

export const TwoAcceptIcon = makeIcon2('2accept');
export const AccessibilityIcon = makeIcon2('accessibility');
export const AeropayIcon = makeIcon2('aeropay');
export const AlertIcon = makeIcon2('alert');
export const AtmIcon = makeIcon2('atm');
export const AttachIcon = makeIcon2('attach');
export const BarsIcon = makeIcon2('bars');
export const BookmarkIcon = makeIcon2('bookmark');
export const CalendarIcon = makeIcon2('calendar');
export const CameraIcon = makeIcon2('camera');
export const CannabisIcon = makeIcon2('cannabis');
export const CanpayIcon = makeIcon2('canpay');
export const CarDeliveryIcon = makeIcon2('car-delivery');
export const CarIcon = makeIcon2('car');
export const CardIcon = makeIcon2('card');
export const CartAddIcon = makeIcon2('cart-add');
export const CartIcon = makeIcon2('cart');
export const CashIcon = makeIcon2('cash');
export const ChatIcon = makeIcon2('chat');
export const CheckCircleIcon = makeIcon2('check-circle');
export const CheckDoubleIcon = makeIcon2('check-double');
export const CheckIcon = makeIcon2('check');
export const ChevronDownIcon = makeIcon2('chevron-down');
export const ChevronLeftIcon = makeIcon2('chevron-left');
export const ChevronRightIcon = makeIcon2('chevron-right');
export const ChevronUpIcon = makeIcon2('chevron-up');
export const ClockIcon = makeIcon2('clock');
export const CloseIcon = makeIcon2('close');
export const CopyIcon = makeIcon2('copy');
export const CrownIcon = makeIcon2('crown');
export const DecreasingIcon = makeIcon2('decreasing');
export const DeliveryIcon = makeIcon2('delivery');
export const DiscountLabelIcon = makeIcon2('discount-label');
export const DiscountIcon = makeIcon2('discount');
export const DocumentsIcon = makeIcon2('documents');
export const DropletIcon = makeIcon2('droplet');
export const EditIcon = makeIcon2('edit');
export const EffectCreativeIcon = makeIcon2('effect-creative');
export const EffectEuphoricIcon = makeIcon2('effect-euphoric');
export const EffectHappyIcon = makeIcon2('effect-happy');
export const EffectSadIcon = makeIcon2('effect-sad');
export const EffectsIcon = makeIcon2('effects');
export const EmailIcon = makeIcon2('email');
export const ExpandIcon = makeIcon2('expand');
export const EyeOffIcon = makeIcon2('eye-off');
export const EyeIcon = makeIcon2('eye');
export const FavoriteIcon = makeIcon2('favorite');
export const FilterIcon = makeIcon2('filter');
export const FlavorsIcon = makeIcon2('flavors');
export const FlowerIcon = makeIcon2('flower');
export const GiftIcon = makeIcon2('gift');
export const HashIcon = makeIcon2('hash');
export const HomeIcon = makeIcon2('home');
export const IdIcon = makeIcon2('id');
export const ImageIcon = makeIcon2('image');
export const IncreasingIcon = makeIcon2('increasing');
export const InfoIcon = makeIcon2('info');
export const JarIcon = makeIcon2('jar');
export const LabelIcon = makeIcon2('label');
export const LeafIcon = makeIcon2('leaf');
export const LoginIcon = makeIcon2('login');
export const LogoutIcon = makeIcon2('logout');
export const LongArrowDownRightIcon = makeIcon2('long-arrow-down-right');
export const MapIcon = makeIcon2('map');
export const MedicalIcon = makeIcon2('medical');
export const MenuIcon = makeIcon2('menu');
export const MinusIcon = makeIcon2('minus');
export const MoreIcon = makeIcon2('more');
export const NotificationsIcon = makeIcon2('notifications');
export const OrangeSliceIcon = makeIcon2('orange-slice');
export const PackSizeIcon = makeIcon2('pack-size');
export const PackageSizeIcon = makeIcon2('package-size');
export const PackageIcon = makeIcon2('package');
export const ParkingStreetIcon = makeIcon2('parking-street');
export const ParkingIcon = makeIcon2('parking');
export const PercentIcon = makeIcon2('percent');
export const PetIcon = makeIcon2('pet');
export const PhoneIcon = makeIcon2('phone');
export const PinIcon = makeIcon2('pin');
export const PlusIcon = makeIcon2('plus');
export const PrivacyIcon = makeIcon2('privacy');
export const QrIcon = makeIcon2('qr');
export const QuestionIcon = makeIcon2('question');
export const ReceiptIcon = makeIcon2('receipt');
export const RefreshIcon = makeIcon2('refresh');
export const ReloadIcon = makeIcon2('reload');
export const RetryIcon = makeIcon2('retry');
export const SearchIcon = makeIcon2('search');
export const SettingsIcon = makeIcon2('settings');
export const ShareIcon = makeIcon2('share');
export const ShopIcon = makeIcon2('shop');
export const SocialAppleColorIcon = makeIcon2('social-apple-color');
export const SocialAppleMonoIcon = makeIcon2('social-apple-mono');
export const SocialFacebookColorIcon = makeIcon2('social-facebook-color');
export const SocialFacebookMonoIcon = makeIcon2('social-facebook-mono');
export const SocialGoogleColorIcon = makeIcon2('social-google-color');
export const SocialGoogleMonoIcon = makeIcon2('social-google-mono');
export const SocialInstagramColorIcon = makeIcon2('social-instagram-color');
export const SocialInstagramMonoIcon = makeIcon2('social-instagram-mono');
export const SocialLinkedinColorIcon = makeIcon2('social-linkedin-color');
export const SocialLinkedinMonoIcon = makeIcon2('social-linkedin-mono');
export const SocialYahooColorIcon = makeIcon2('social-yahoo-color');
export const SocialYahooMonoIcon = makeIcon2('social-yahoo-mono');
export const SocialXColorIcon = makeIcon2('social-x-color');
export const SocialXMonoIcon = makeIcon2('social-x-mono');
export const SocialYoutubeColorIcon = makeIcon2('social-youtube-color');
export const SocialYoutubeMonoIcon = makeIcon2('social-youtube-mono');
export const SortIcon = makeIcon2('sort');
export const StarSolidIcon = makeIcon2('star-solid');
export const StarStrokeIcon = makeIcon2('star-stroke');
export const TerpeneCaryophylleneIcon = makeIcon2('terpene-caryophyllene');
export const TerpeneLimoneneIcon = makeIcon2('terpene-limonene');
export const TerpeneLinaloolIcon = makeIcon2('terpene-linalool');
export const TerpeneMyrceneIcon = makeIcon2('terpene-myrcene');
export const TerpeneOtherIcon = makeIcon2('terpene-other');
export const TerpenePineneAbIcon = makeIcon2('terpene-pinene-ab');
export const TerpensIcon = makeIcon2('terpens');
export const ThumbUpIcon = makeIcon2('thumb-up');
export const TierIcon = makeIcon2('tier');
export const TimerIcon = makeIcon2('timer');
export const TrashIcon = makeIcon2('trash');
export const UnitSizeIcon = makeIcon2('unit-size');
export const UnitsIcon = makeIcon2('units');
export const UploadIcon = makeIcon2('upload');
export const UserIcon = makeIcon2('user');
export const ViewGridIcon = makeIcon2('view-grid');
export const ViewListIcon = makeIcon2('view-list');
export const ZoomInIcon = makeIcon2('zoom-in');
export const ZoomOutIcon = makeIcon2('zoom-out');
export const StrainPrevalenceCbdIcon = makeIcon2('strain-prevalence-CBD');
export const StrainPrevalenceHybridIcon = makeIcon2('strain-prevalence-hybrid');
export const StrainPrevalenceIndicaIcon = makeIcon2('strain-prevalence-indica');
export const StrainPrevalenceSativaIcon = makeIcon2('strain-prevalence-sativa');
