import ceil from 'lodash/ceil';
import round from 'lodash/round';

const getOrdinalSuffix = (num = 0): string => {
  const j = num % 10;
  const k = num % 100;
  switch (true) {
    case j === 1 && k !== 11:
      return 'st';
    case j === 2 && k !== 12:
      return 'nd';
    case j === 3 && k !== 13:
      return 'rd';
    default:
      return 'th';
  }
};

const formatOrdinalNumber = (num = 0): string => {
  return num.toFixed() + getOrdinalSuffix(num);
};

const getFractionalPartSize = (num: number): number => {
  const [, val] = num.toString().split('.');
  return val?.length ?? 0;
};

export { formatOrdinalNumber, round, ceil, getFractionalPartSize };
