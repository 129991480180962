import cx from 'clsx';
import { ComponentType, FC, forwardRef, SVGProps } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { useAnimationSettings, useTheme } from '@swe/shared/ui-kit/theme/provider';
import { ComponentHasChildren, ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type SkeletonProps = ComponentHasClassName &
  ComponentHasChildren &
  Pick<IContentLoaderProps, 'uniqueKey' | 'width' | 'height'>;

const Skeleton = ({ className, width = '100%', height = '100px', children, ...props }: SkeletonProps) => {
  const { skeleton } = useTheme();
  const { enabled } = useAnimationSettings();

  return (
    <ContentLoader
      className={cx(className, styles.root)}
      width={width}
      height={height}
      style={{ width: '100% ' }}
      backgroundColor={skeleton.backgroundColor}
      foregroundColor={skeleton.foregroundColor}
      animate={enabled}
      {...props}
    >
      {children}
    </ContentLoader>
  );
};

const Bone = (props: SVGProps<SVGRectElement>) => <rect {...props} />;

type RequiredAndUndefined<T> = T | undefined;
type MakeRequiredAndUndefined<CT extends AnyObject, RK extends keyof CT> = {
  [key in RK]: RequiredAndUndefined<CT[key]>;
} & Omit<CT, RK>;

const withSkeleton = <ComponentProps extends AnyObject, RequiredKeys extends keyof ComponentProps>(
  Component: ComponentType<ComponentProps>,
  Skeleton: FC<ComponentProps>,
  requiredDataKeys: RequiredKeys[],
  passRef = false,
) => {
  return forwardRef<HTMLElement, any>(function ComponentWithSkeleton(
    props: MakeRequiredAndUndefined<ComponentProps, RequiredKeys>,
    ref,
  ) {
    // eslint-disable-next-line react/destructuring-assignment
    if (requiredDataKeys.some((key) => typeof props[key] === 'undefined')) {
      return <Skeleton {...(props as unknown as ComponentProps)} />;
    }

    return (
      <Component
        {...(props as unknown as ComponentProps)}
        {...(passRef ? { ref } : {})}
      />
    );
  });
};

export type { SkeletonProps };
export { Skeleton, Bone, withSkeleton };
export default Skeleton;
