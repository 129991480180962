import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useDeepCompareMemo } from '@swe/shared/hooks/use-deep-compare';
import { useMounted } from '@swe/shared/hooks/use-mounted';
import { CommonQueryParams, Route, RoutePath, RouteQuery, RouteUrl } from '@swe/shared/providers/router/constants';
import { EventEmitterImpl } from '@swe/shared/tools/event-emitter';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { uniqueId } from '@swe/shared/utils/other';

type RouteTransitionOptions = {
  scroll?: boolean;
  replace?: boolean;
};

type RouterHistoryState<R extends RoutePath> = {
  url: RouteUrl<R>;
  key: string;
};

type HistoryControls<R extends RoutePath> = {
  history: RouterHistoryState<R>[];
  index: number;
  length: number;
  hasPrevious: boolean;
  prevState: RouterHistoryState<R> | undefined;
  currentState: RouterHistoryState<R>;
  pushState: (url: RouteUrl<R>) => string;
  replaceState: (url: RouteUrl<R>) => string;
};

const useRouterHistoryControls = <R extends RoutePath>(router: RouterImpl<R>): HistoryControls<R> => {
  const initialKey = useMemo(() => uniqueId(), []);
  useEffect(() => {
    window.history.replaceState(
      {
        ...window.history.state,
        __swKey: initialKey,
      },
      '',
    );
  }, [initialKey]);
  const historyRef = useRef<RouterHistoryState<R>[]>([
    {
      url: {
        pathname: router.pathname,
        query: router.query,
      },
      key: initialKey,
    },
  ]);

  const indexRef = useRef(0);

  const pushState = useCallback((url: RouteUrl<R>) => {
    const key = uniqueId();
    indexRef.current += 1;
    historyRef.current = [
      ...historyRef.current.slice(0, indexRef.current),
      {
        url,
        key,
      },
    ];
    return key;
  }, []);

  const replaceState = useCallback((url: RouteUrl<R>) => {
    const key = historyRef.current[indexRef.current].key;
    historyRef.current[indexRef.current] = {
      url,
      key,
    };
    return key;
  }, []);

  useMounted(() => {
    const popStateHandler = ({ state }: PopStateEvent) => {
      indexRef.current = historyRef.current.findIndex(({ key }) => key === state.__swKey);
    };

    window.addEventListener('popstate', popStateHandler);

    return () => {
      window.removeEventListener('popstate', popStateHandler);
    };
  });

  const history = historyRef.current;
  const index = indexRef.current;
  const currentState = history[index];

  return useMemo(
    () => ({
      history,
      index,
      length: history.length,
      hasPrevious: index > 0,
      prevState: history[index - 1],
      currentState,
      pushState,
      replaceState,
      initialKey,
    }),
    [history, index, currentState, pushState, replaceState, initialKey],
  );
};

type RouterNavigate<R extends RoutePath> = {
  (delta: number): Promise<boolean>;
  (url: Route<R>, options?: RouteTransitionOptions): Promise<boolean>;
};

type RouterEventsPayload = {
  routeChangeStart: string;
  routeChangeComplete: unknown;
};

type RouterEventsEmitter = EventEmitterImpl<RouterEventsPayload>;

type RouterImpl<R extends RoutePath> = {
  pathname: R;
  query: RouteQuery;
  navigate: RouterNavigate<R>;
  asPath: string;
  basePath: string;

  buildHref: (route: Route<R>) => string;
  normalizeRoute: (route: Route<R>) => Required<RouteUrl<R>>;

  events: Pick<RouterEventsEmitter, 'on' | 'off'>;
};

type RouterHelpers<R extends RoutePath> = Pick<RouterImpl<R>, 'buildHref' | 'normalizeRoute'>;

const RouterContext = createContext<any>(null!);
const useRouter = <R extends RoutePath>() => useContext<RouterHelpers<R>>(RouterContext);

const RouterHistoryContext = createContext<any>(null!);
const useRouterHistory = <R extends RoutePath>() =>
  useContext<Omit<HistoryControls<R>, 'pushState' | 'replaceState'>>(RouterHistoryContext);

const RouterNavigateContext = createContext<any>(null!);
const useRouterNavigate = <R extends RoutePath>() => useContext<RouterNavigate<R>>(RouterNavigateContext);

const RouterPathnameContext = createContext<any>(null!);
const useRouterPathname = <R extends RoutePath>() => useContext<R>(RouterPathnameContext);

const RouterAsPathContext = createContext<string>(null!);
const useRouterAsPath = () => useContext(RouterAsPathContext);

const RouterQueryContext = createContext<RouteQuery>(null!);
const useRouterQuery = () => useContext<RouteQuery>(RouterQueryContext);

const RouterEventsContext = createContext<any>(null!);
const useRouterEvents = () => useContext<RouterEventsEmitter>(RouterEventsContext);

type RouterProviderProps<R extends RoutePath> = ComponentHasChildren & {
  router: RouterImpl<R>;
};
const RouterProvider = <R extends RoutePath>({ children, router }: RouterProviderProps<R>) => {
  const history = useRouterHistoryControls(router);
  const { pushState, replaceState } = history;
  const { navigate: _navigate, normalizeRoute, pathname, query, asPath, buildHref, events } = router;

  const decorator = query[CommonQueryParams.URLDecorator];
  useEffect(() => {
    if (typeof decorator === 'string' && decorator !== window.location.pathname) {
      window.history.replaceState(window.history.state, '', decorator);
    }
  }, [decorator]);

  const methodsRef = useRef({
    _navigate,
    normalizeRoute,
    pushState,
    replaceState,
    buildHref,
  });
  methodsRef.current = {
    _navigate,
    normalizeRoute,
    pushState,
    replaceState,
    buildHref,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const navigate: RouterNavigate<R> = useCallback(async (url: Route<R> | number, options) => {
    const { _navigate, normalizeRoute, pushState, replaceState } = methodsRef.current;
    if (typeof url === 'number') {
      return _navigate(url);
    }
    const historyKey = (options?.replace === true ? replaceState : pushState)(normalizeRoute(url));
    const result = await _navigate(url, options);
    window.history.replaceState(
      {
        ...window.history.state,
        __swKey: historyKey,
      },
      '',
    );
    return result;
  }, []);

  const helpers = useMemo(
    () => ({
      normalizeRoute: (route: Route<R>) => methodsRef.current.normalizeRoute(route),
      buildHref: (route: Route<R>) => methodsRef.current.buildHref(route),
    }),
    [],
  );
  return (
    <RouterHistoryContext.Provider value={history}>
      <RouterQueryContext.Provider value={useDeepCompareMemo(() => query, [query])}>
        <RouterAsPathContext.Provider value={asPath}>
          <RouterPathnameContext.Provider value={pathname}>
            <RouterNavigateContext.Provider value={navigate as RouterNavigate<R>}>
              <RouterEventsContext.Provider value={events}>
                <RouterContext.Provider value={helpers as RouterHelpers<R>}>{children}</RouterContext.Provider>
              </RouterEventsContext.Provider>
            </RouterNavigateContext.Provider>
          </RouterPathnameContext.Provider>
        </RouterAsPathContext.Provider>
      </RouterQueryContext.Provider>
    </RouterHistoryContext.Provider>
  );
};

export {
  RouterProvider,
  useRouter,
  useRouterNavigate,
  useRouterPathname,
  useRouterQuery,
  useRouterAsPath,
  useRouterHistory,
  useRouterEvents,
};
export type {
  RouterImpl,
  RouteTransitionOptions,
  RouterNavigate,
  RouterHelpers,
  RouterEventsEmitter,
  RouterEventsPayload,
};
