import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { FullShopFulfillmentType } from 'entities/shop/info';

const NAME = '/Account/GetAccountFulfillmentPreferences';
const GetAccountFulfillmentPreferencesEndpoint = createModernEndpoint<
  undefined,
  {
    fulfillmentType: FullShopFulfillmentType;
    accountId: number;
    storeId: number;
    deliveryAddress: string;
    deliverySecondaryAddress: string;
    deliveryLatitude: number;
    deliveryLongitude: number;
  },
  APIErrorExtended
>(NAME);

export { GetAccountFulfillmentPreferencesEndpoint };
