import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { ShopInfo } from 'entities/shop/info';

type Data = ShopInfo[];
type Params = undefined;

const NAME = '/Store/GetStoreInfoList';
export const GetShopInfoListEndpoint = createModernEndpoint<Params, Data>(NAME);

export default GetShopInfoListEndpoint;
