import { CarouselScrollable } from '@swe/shared/ui-kit/components/carousel';
import { useBreakpointValue } from '@swe/shared/ui-kit/components/media/utils';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import styles from './styles.module.scss';

import { CarouselProductsProps } from './types';

import { ProductCardConnected } from 'common/containers/product-card';

import { Skeleton } from 'domains/shop/components/carousel-products/skeleton';

const CarouselProducts = ({
  className,
  name,
  total,
  products = [],
  extendToView,
  onViewAll,
  headingLevel = 2,
  maxNotLazyItems = 0,
  id,
  analyticalItemListName,
}: CarouselProductsProps) => {
  const width = useBreakpointValue({ xs: 167, sm: 242 }, 167);
  if (products.length === 0) return null;

  return (
    <CarouselScrollable
      title={name}
      className={className}
      link={onViewAll ? { children: `View all${total ? ` ${total}` : ''}`, onClick: onViewAll } : undefined}
      spacing={{ xs: 'xxs', sm: 'sm' }}
      extendToView={extendToView}
      headingLevel={headingLevel}
    >
      {products.map((product, index) => (
        <ProductCardConnected
          key={product.variants[0].id}
          layout="vertical"
          className={styles.card}
          width={width}
          product={product}
          lazy={index >= maxNotLazyItems}
          index={index}
          analyticalItemListId={`carousel${id ? `_${id}` : ''} `}
          analyticalItemListName={analyticalItemListName ?? name}
        />
      ))}
    </CarouselScrollable>
  );
};

const CarouselProductsSkeletonized = withSkeleton(CarouselProducts, Skeleton, ['products']);

export type { CarouselProductsProps };
export { CarouselProducts, CarouselProductsSkeletonized };
