import { formatIntegerPrice } from '@swe/shared/utils/price';

type ReferralProgram = {
  enabled: boolean;
  showInEcommerce: boolean;
  referrerDiscountType: ReferralDiscountType;
  referrerDiscountName: string;
  referrerDiscountAmount: number;
  referrerCodeExpirationInDays: number;
  referrerPromocodeMinApplyAmount: number;
  referralDiscountType: ReferralDiscountType;
  referralDiscountName: string;
  referralDiscountAmount: number;
  referralMinInvoiceAmount: number;
  referralMinInvoiceCount: number;
  referralCodeExpirationInDays: number;
  referralPromocodeMinApplyAmount: number;
  terms: string;
};

enum ReferralDiscountType {
  PERCENT_DISCOUNT = 'PercentDiscount',
  DOLLAR_OFF_DISCOUNT = 'DollarOffDiscount',
}

type ReferrerData = {
  url: string;
  code: string;
};

type ReferralStatus = {
  currentInvoiceCount: number;
  referralDiscountAmount: number;
  referralDiscountType: ReferralDiscountType;
  referralMinInvoiceAmount: number;
  referralMinInvoiceCount: number;
  terms: string;
};

const getDiscountAmountLabel = (discountType?: ReferralDiscountType, discountAmount = 0): string => {
  switch (discountType) {
    case ReferralDiscountType.DOLLAR_OFF_DISCOUNT:
      return formatIntegerPrice(discountAmount);
    case ReferralDiscountType.PERCENT_DISCOUNT:
      return `${discountAmount}%`;
    default:
      return '';
  }
};

export type { ReferralProgram, ReferrerData, ReferralStatus };
export { getDiscountAmountLabel };
