import cn from 'clsx';
import { ReactNode, useCallback } from 'react';

import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import CommonWrapper from '@swe/shared/ui-kit/components/form/wrapper/common';
import { Locator } from '@swe/shared/ui-kit/components/locator';
import { ComponentHasClassName, ComponentHasSize, Sizes } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type RadioboxSizes = Sizes<'md' | 'sm'>;

type RadioboxProps = ComponentHasClassName &
  FormControl<boolean> & {
    label?: ReactNode;
    hovered?: boolean;
    pressed?: boolean;
    focused?: boolean;
  } & ComponentHasSize<RadioboxSizes>;

const Radiobox = ({
  name,
  label,
  onChange,
  value = false,
  onBlur,
  error,
  disabled,
  className,
  hovered,
  pressed,
  focused,
  size = 'md',
}: RadioboxProps) => {
  const onChangeHandler = useCallback(() => onChange?.(!value), [onChange, value]);

  return (
    <CommonWrapper
      error={error}
      className={cn(
        className,
        {
          [styles._checked]: value,
          [styles._disabled]: disabled,
          [styles._hovered]: hovered,
          [styles._focused]: focused,
          [styles._pressed]: pressed,
        },
        size === 'sm' && styles._size_sm,
      )}
    >
      <label className={styles.label}>
        <div className={styles.radio}>
          <span className={styles.baseline}>{name}</span>
          <Locator
            as="input"
            locatorName={name}
            locatorType="radio"
            className={styles.input}
            type="radio"
            checked={value}
            name={name}
            id={name}
            disabled={disabled}
            onChange={onChangeHandler}
            onBlur={onBlur}
          />
          <div className={styles.checkIcon} />
        </div>
        {label && <div>{label}</div>}
      </label>
    </CommonWrapper>
  );
};

export type { RadioboxProps, RadioboxSizes };
export { Radiobox };
export default Radiobox;
