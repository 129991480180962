import { createPrototype, DocumentPrototype } from 'common/entities/documents/DocumentPrototype';
import { DocumentCategory, DocumentPrototypeId, DocumentPrototypes } from 'entities/documents/documents-v2';

type PrototypesMap = Record<DocumentCategory, DocumentPrototype[]>;

class Prototypes {
  readonly prototypes: PrototypesMap;

  private static parsePrototypes(prototypes: DocumentPrototypes) {
    return {
      [DocumentCategory.Identity]: prototypes.identityTypes.map(createPrototype),
      [DocumentCategory.Medical]: prototypes.medicalPermitTypes.map(createPrototype),
      [DocumentCategory.Additional]: prototypes.additionalPermitTypes.map(createPrototype),
    };
  }

  constructor(prototypes: DocumentPrototypes) {
    this.prototypes = Prototypes.parsePrototypes(prototypes);
  }

  getPrototypes(category: DocumentCategory) {
    return this.prototypes[category];
  }

  getPrototype(category: DocumentCategory, id: DocumentPrototypeId) {
    return this.prototypes[category].find((prototype) => prototype.data.id === id);
  }
}

export type { PrototypesMap };
export { Prototypes };
