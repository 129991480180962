import cx from 'clsx';
import { ComponentProps, ReactNode, useCallback, useRef, useState } from 'react';

import { useElementSize } from '@swe/shared/hooks/use-element-size';
import { useBreakpoint } from '@swe/shared/tools/media';
import { ChevronLeftIcon, ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';
import Scrollable, { ScrollableMethods, ScrollState } from '@swe/shared/ui-kit/components/scrollable';
import { HeadingLevel, SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import Stack, { StackProps } from '@swe/shared/ui-kit/components/stack';
import { ComponentHasStyling } from '@swe/shared/ui-kit/types/common-props';

import styles from './styles.module.scss';

type CarouselScrollableProps = ComponentHasStyling &
  Pick<StackProps<any>, 'extendToView'> & {
    children: ReactNode;
    title?: ReactNode;
    link?: { children: string; onClick: () => void };
    spacing?: ComponentProps<typeof Stack>['spacing'];
    headingLevel?: HeadingLevel;
    shadowSupport?: boolean;
  };

const CarouselScrollable = ({
  className,
  style,
  children,
  title,
  link,
  extendToView = true,
  spacing,
  headingLevel = 2,
  shadowSupport = true,
}: CarouselScrollableProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { mobile } = useBreakpoint();
  const scrollRef = useRef<ScrollableMethods>(null!);
  const [scrollState, setScrollState] = useState<ScrollState>({ x: 'start', y: 'start' });
  const { width } = useElementSize(rootRef);

  const scrollForward = useCallback(() => {
    scrollRef.current.getElement().scrollBy({
      left: width,
      behavior: 'smooth',
    });
  }, [width, scrollRef]);
  const scrollBackward = useCallback(() => {
    scrollRef.current.getElement().scrollBy({
      left: -1 * width,
      behavior: 'smooth',
    });
  }, [width, scrollRef]);

  return (
    <div
      ref={rootRef}
      className={cx(className, shadowSupport && styles._shadowSupport)}
      style={style}
    >
      {title && (
        <SectionHeading
          className={styles.toolbar}
          size={mobile ? 'md' : 'lg'}
          level={headingLevel}
          link={link}
          button={
            scrollState.x !== undefined && !mobile
              ? [
                  {
                    disabled: scrollState.x === 'start',
                    onClick: scrollBackward,
                    icon: ChevronLeftIcon,
                    ariaLabel: 'Previous',
                  },
                  {
                    disabled: scrollState.x === 'end',
                    onClick: scrollForward,
                    icon: ChevronRightIcon,
                    ariaLabel: 'Next',
                  },
                ]
              : undefined
          }
        >
          {title}
        </SectionHeading>
      )}
      <Scrollable
        ref={scrollRef}
        onScrollState={setScrollState}
        className={styles.track}
        fade={false}
        direction="horizontal"
        hideScrollbar
        extendToView={extendToView}
      >
        <Stack
          direction="row"
          spacing={spacing}
        >
          {children}
        </Stack>
      </Scrollable>
    </div>
  );
};

export type { CarouselScrollableProps };
export { CarouselScrollable };
export default CarouselScrollable;
