import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

import { Order } from 'entities/common/orders';
import { StoreSaleType } from 'entities/shop/sale-type';

type Params = {
  saleType?: StoreSaleType;
  id: number;
};

const NAME = '/Orders/Get';
const GetOrderEndpoint = createModernEndpoint<Params, Order>(NAME);

export { GetOrderEndpoint };
export default GetOrderEndpoint;
