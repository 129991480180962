import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = undefined;
type Data = undefined;
type Error = APIErrorExtended;

const NAME = '/Registration/DoBackstep';
const SignupBackstepEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export type { Params };
export default SignupBackstepEndpoint;
