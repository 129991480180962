import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { AuthErrorCode } from 'endpoints/authorization/errors';

type Params = {
  phoneNumber: string;
};
type Data = {
  success: boolean;
  code: string | null;
  nextTryInSeconds: number;
};
type Error = APIErrorExtended<AuthErrorCode.EXISTING_EMAIL>;

const NAME = '/Registration/SetPhone';
const SignupBindPhoneEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default SignupBindPhoneEndpoint;
