import { QueryParserConfig } from '@swe/shared/hooks/pagination/types';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_QUERY_KEY = 'page';
const DEFAULT_PAGE_SIZE_QUERY_KEY = 'size';
const DEFAULT_PAGINATION = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};
const DEFAULT_QUERY_PARSER_CONFIG: QueryParserConfig = {
  pageKey: DEFAULT_PAGE_QUERY_KEY,
  pageSizeKey: DEFAULT_PAGE_SIZE_QUERY_KEY,
  pageSize: DEFAULT_PAGE_SIZE,
};

export {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_QUERY_KEY,
  DEFAULT_PAGE_SIZE_QUERY_KEY,
  DEFAULT_PAGINATION,
  DEFAULT_QUERY_PARSER_CONFIG,
};
