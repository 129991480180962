import get from 'lodash/get';
import isNaN from 'lodash/isNaN';

import { EventEmitter } from '@swe/shared/tools/event-emitter';

enum Language {
  En = 'en',
}

type Languages = Language | Language[];
interface Messages {
  [x: string]: Messages | string;
}
type Dict = Partial<Record<Language, Messages>>;
type Values = Record<string, unknown>;

const defaultLocale = Language.En;
const defaultLanguages = [Language.En];

const DEFAULT_PARAMS = { language: defaultLocale, languages: defaultLanguages };

type OnTransMissingHandler = (id: string) => any;

type IntlInitParams = {
  language?: Language;
  languages?: Language[];
  dictionary?: Dict;
  onTransMissing?: OnTransMissingHandler;
};

type Events = {
  change: Language;
};

class IntlCore extends EventEmitter<Events> {
  private _dictionary: Dict = {};

  private _language: Language;

  private _languages: Language[];

  private readonly _onTransMissing: OnTransMissingHandler = () => {
    // console.error(`[INTL]: Translation with id:${id} is missing!`);
    return undefined;
  };

  constructor({ language = defaultLocale, languages = defaultLanguages, dictionary, onTransMissing }: IntlInitParams) {
    super();
    this._language = language;
    this._languages = languages;

    if (dictionary) {
      this._dictionary = dictionary;
    }
    if (onTransMissing) {
      this._onTransMissing = onTransMissing;
    }
  }

  get language() {
    return this._language;
  }

  get languages() {
    return this._languages;
  }

  get dictionary() {
    return this._dictionary;
  }

  get messages() {
    return this.dictionary[this.language];
  }

  get isDictionaryEmpty() {
    return Object.keys(this.dictionary).length === 0;
  }

  get isMessagesEmpty() {
    return !this.messages;
  }

  get isDefaultLocale() {
    return this.language === defaultLocale;
  }

  load(dict: Dict): void;

  load(language: Language, messages: Messages): void;
  load(langOrDict: Language | Dict, messages?: Messages) {
    if (typeof langOrDict === 'object' && !!messages) {
      this._dictionary = langOrDict as Dict;
    } else {
      this._dictionary[langOrDict as Language] = messages;
    }
    this.fire('change', this.language);
  }

  activate(language: Language) {
    this._language = language;
    this.fire('change', language);
  }

  loadAndActivate(language: Language, messages: Messages) {
    this._dictionary[language] = messages;
    this._language = language;
    this.fire('change', language);
  }

  format(template: string, values: Values) {
    let result = template;

    Object.keys(values).forEach((key) => {
      if (isNaN(+key)) {
        if (typeof values[key] !== 'string') {
          console.error(`[INTL]: Template named value must be type of string! (key:${key})`);
          return;
        }

        result = result.replace(`{${key}}`, values[key] as string);
      }
    });

    return result;
  }

  t(id: string, values: Values = {}, defaultMessage = '') {
    const messageFromDict = get(this.messages, id);
    if (!messageFromDict) {
      this._onTransMissing(id);
    }

    // if (messageFromDict && typeof messageFromDict !== 'string') {
    //   console.error('Wrong translation key used: ', id);
    // }

    const resultMessage = typeof messageFromDict === 'string' ? messageFromDict : defaultMessage;
    return this.format(resultMessage, values);
  }
}

const setup = (params: IntlInitParams = DEFAULT_PARAMS) => new IntlCore(params);

export { IntlCore, Language, defaultLanguages, defaultLocale, setup };
export type { IntlInitParams, Dict, Messages, Values, Languages, OnTransMissingHandler };
