import { createContainer } from '@swe/shared/tools/google/core/container';
import { GoogleJSApiLoader } from '@swe/shared/tools/google/core/loader';
import { GoogleServiceImpl } from '@swe/shared/tools/google/core/types';

type GeocodingServiceImpl = GoogleServiceImpl<{
  getAddressByLocation: (location: google.maps.LatLngLiteral) => Promise<string>;
  getLocationByAddress: (address: string) => Promise<google.maps.LatLngLiteral>;
}>;

class GeocodingService extends GoogleJSApiLoader implements GeocodingServiceImpl {
  private geocoderService!: google.maps.Geocoder;

  constructor(private readonly apiKey: string) {
    super(apiKey);
  }

  public async init() {
    if (!window.google?.maps?.places) {
      await this.load();
    }

    this.geocoderService = new google.maps.Geocoder();
    this.isLoaded = true;

    return this;
  }

  public destroy() {
    this.isLoaded = false;
  }

  async getAddressByLocation(location: google.maps.LatLngLiteral) {
    const response = await this.geocoderService.geocode({ location });

    return response.results[0].formatted_address;
  }

  async getLocationByAddress(address: string) {
    const response = await this.geocoderService.geocode({ address });
    const { location } = response.results[0].geometry;

    return {
      lat: location.lat(),
      lng: location.lng(),
    };
  }
}

const GeocodingServiceContainer = createContainer<GeocodingServiceImpl>(GeocodingService);
export type { GeocodingServiceImpl };
export default GeocodingServiceContainer;
