import Skeleton from '@swe/shared/ui-kit/components/skeleton';
// import { range } from '@swe/shared/utils/array';

import { SkeletonSectionHeader } from 'domains/product/components/product-details/common/skeleteon-section-header';

// const MARGIN = 16;
// const HEIGHT = 72;
// const COUNT = 3;
// const TOP = 56;

const AttributesSkeleton = () => {
  // const PER_ROW = 1;
  // const WIDTH = 100 / PER_ROW;

  return (
    <Skeleton
      uniqueKey="product_attributes"
      height={28 + 24}
    >
      <SkeletonSectionHeader />
    </Skeleton>
  );

  // return (
  //   <Skeleton
  //     uniqueKey="product_attributes"
  //     height={TOP + HEIGHT * Math.ceil(COUNT / PER_ROW) + MARGIN * (Math.ceil(COUNT / PER_ROW) - 1) + 24}
  //   >
  //     <SkeletonSectionHeader />
  //     {range(COUNT).map((v, idx) => {
  //       const width = (MARGIN * (PER_ROW - 1)) / PER_ROW;
  //       const k = idx % PER_ROW;
  //       return (
  //         <Bone
  //           key={idx}
  //           x={`calc(${WIDTH * k}% - ${width * k}px + ${MARGIN * k}px)`}
  //           y={TOP + Math.floor(idx / PER_ROW) * HEIGHT + Math.floor(idx / PER_ROW) * MARGIN}
  //           rx={2}
  //           ry={2}
  //           width={`calc(${WIDTH}% - ${width}px)`}
  //           height={HEIGHT}
  //         />
  //       );
  //     })}
  //   </Skeleton>
  // );
};

export { AttributesSkeleton };
