import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  code: string;
};
type Data = {
  success: boolean;
};
const NAME = '/Registration/VerifyPhone';
const SignupVerifyPhoneEndpoint = createModernEndpoint<Params, Data, APIErrorExtended>(NAME);

export default SignupVerifyPhoneEndpoint;
