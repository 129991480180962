import { createContext, useContext } from 'react';

import { AppSettings } from '@swe/shop-ui/app/types';

const AppHydrationDataContext = createContext<AppSettings>(null!);
const AppHydrationDataProvider = AppHydrationDataContext.Provider;
const useAppHydrationData = () => useContext(AppHydrationDataContext);
const getAppHydrationData = (): AppSettings => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.__APP_HYDRATION_DATA__;
};

export { AppHydrationDataProvider, useAppHydrationData, getAppHydrationData };
