import { Document } from 'common/entities/documents/Document';
import { DocumentVerificationImpl } from 'common/entities/documents/DocumentVerification';
import { Prototypes } from 'common/entities/documents/Prototypes';
import { Verifications } from 'common/entities/documents/Verifications';
import { DocumentCategory, RequiredDocuments } from 'entities/documents/documents-v2';

enum RequirementStatus {
  RequiresUpload = 'upload',
  RequiresAction = 'action',
  Fulfilled = 'fulfilled',
}

class Requirement<Cat extends DocumentCategory> {
  constructor(
    readonly category: Cat,
    readonly requiredDocuments: Document[],
  ) {}

  get status(): RequirementStatus {
    if (this.requiredDocuments.some((r) => r.isActionRequired)) {
      return RequirementStatus.RequiresAction;
    }

    if (this.requiredDocuments.length === 0 || this.requiredDocuments.some((r) => r.isFulfilled)) {
      return RequirementStatus.Fulfilled;
    }

    return RequirementStatus.RequiresUpload;
  }

  get isEmpty() {
    return this.requiredDocuments.length === 0;
  }

  get documentNamesString() {
    return this.requiredDocuments.map((d) => d.prototype.data.name).join(', ');
  }

  findRequiredDocumentByPrototypeId(prototypeId: number) {
    return this.requiredDocuments.find((rd) => rd.prototype.data.id === prototypeId);
  }

  findVerificationByPrototypeId(prototypeId: number) {
    return this.findRequiredDocumentByPrototypeId(prototypeId)?.verifications[0];
  }

  findPrototypeByPrototypeId(prototypeId: number) {
    return this.findRequiredDocumentByPrototypeId(prototypeId)?.prototype;
  }
}

const getRequiredPrototypes = (
  category: DocumentCategory,
  requiredDocuments: RequiredDocuments,
  prototypes: Prototypes,
) => {
  switch (category) {
    case DocumentCategory.Identity:
      if (requiredDocuments.isRequiredIdentity) {
        return prototypes.getPrototypes(category);
      }

      return [];
    case DocumentCategory.Medical:
      return requiredDocuments.requiredMedicalPermitTypes.map((id) => prototypes.getPrototype(category, id)!);
    case DocumentCategory.Additional:
      return requiredDocuments.requiredAdditionalPermitTypes.map((id) => prototypes.getPrototype(category, id)!);
  }
};

const createRequirement = <Category extends DocumentCategory>(
  category: Category,
  requiredDocuments: RequiredDocuments,
  prototypes: Prototypes,
  verifications: Verifications,
) => {
  const requiredPrototypes = getRequiredPrototypes(category, requiredDocuments, prototypes);

  return new Requirement<Category>(
    category,
    requiredPrototypes.map(
      (prototype) =>
        new Document(
          category,
          prototype,
          verifications.getVerificationsByCategory(category) as DocumentVerificationImpl[],
        ),
    ),
  );
};

export { RequirementStatus, Requirement, createRequirement };
