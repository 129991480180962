import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { DocumentType } from 'entities/documents/documents';

type Params = undefined;
type Data = {
  identityTypes: DocumentType[];
  medicalPermitTypes: DocumentType[];
  additionalPermitTypes: DocumentType[];
};
type Error = APIErrorExtended;

const NAME = '/Documents/GetTypes';
const GetDocumentTypesEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export type { Data };
export default GetDocumentTypesEndpoint;
