import { RefObject, useEffect } from 'react';

export const useMutationObserver = (
  observable: HTMLElement | RefObject<HTMLElement> | null,
  callback: (record: MutationRecord[]) => void,
  options: MutationObserverInit,
) => {
  useEffect(() => {
    const el: HTMLElement | null = observable && 'current' in observable ? observable.current : observable;
    if (!el) return;

    const observer = new MutationObserver(callback);

    observer.observe(el, options);

    return () => {
      observer.disconnect();
    };
  }, [observable, callback, options]);
};
