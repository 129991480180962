import { useEffect, useRef } from 'react';

const useOnlyUpdateEffect = (fn: (...args: any) => any, deps: any[]) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export { useOnlyUpdateEffect };
