import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  data: string;
};
type Data = null;
type Error = APIErrorExtended;

const NAME = '/Registration/EasySignUp';
const SignupEasySignUpEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default SignupEasySignUpEndpoint;
