import Image from '@swe/shared/ui-kit/components/image';

import styles from './styles.module.scss';

const ProductImageCarouselSkeleton = () => (
  <Image
    className={styles.root}
    alt="Product Image Placeholder"
    src={undefined}
    ratio={1}
    width={412}
    emptyImage="placeholder"
  />
);

export { ProductImageCarouselSkeleton };
