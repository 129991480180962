import { ConfirmParams, usePrompt } from '@swe/shared/ui-kit/components/promt';

import { useCallback } from 'react';

import { RequiredSaleType, useSaleType } from 'common/use-cases/use-sale-type';
import { ProductSaleType } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

const STORE_SALE_TYPE_LABEL: Record<RequiredSaleType, string> = {
  Medical: 'Medical',
  Recreational: 'Adult Use',
};

const SSL_TO_PSL: Record<RequiredSaleType, Exclude<ProductSaleType, ProductSaleType.BOTH>> = {
  Medical: ProductSaleType.MEDICAL,
  Recreational: ProductSaleType.RECREATIONAL,
};

const getConfirmationProps = (currentSaleType: RequiredSaleType, previousSaleType: RequiredSaleType): ConfirmParams => {
  return {
    heading: 'Purchase Type',
    ariaLabel: 'Purchase Type',
    content: (
      <div>
        Previous order was placed as {STORE_SALE_TYPE_LABEL[previousSaleType]}. <br /> Do you want to switch to{' '}
        {STORE_SALE_TYPE_LABEL[previousSaleType]} mode?
      </div>
    ),
    confirmLabel: `Switch to ${STORE_SALE_TYPE_LABEL[previousSaleType]}`,
    cancelLabel: `Stay ${STORE_SALE_TYPE_LABEL[currentSaleType]}`,
  };
};

const useSaleTypeConfirmation = () => {
  const { saleType, setSaleType } = useSaleType();
  const { confirm } = usePrompt();

  const confirmPreviousSaleType = useCallback(
    async (currentSaleType: StoreSaleType, previousSaleType: StoreSaleType) => {
      if (!currentSaleType || !previousSaleType) return currentSaleType as RequiredSaleType;

      const confirmed = await confirm(getConfirmationProps(currentSaleType, previousSaleType));
      if (confirmed) {
        return previousSaleType as RequiredSaleType;
      }

      return confirmed ? (previousSaleType as RequiredSaleType) : (currentSaleType as RequiredSaleType);
    },
    [confirm],
  );
  const confirmSaleType = useCallback(
    async (lastSaleType: StoreSaleType | null) => {
      if (!lastSaleType || lastSaleType === saleType) return;
      const confirmed = await confirmPreviousSaleType(saleType, lastSaleType);
      const nextProductSaleType = SSL_TO_PSL[confirmed];
      await setSaleType?.(nextProductSaleType);
    },
    [confirmPreviousSaleType, saleType, setSaleType],
  );

  return {
    confirmSaleType,
  };
};

export { useSaleTypeConfirmation };
