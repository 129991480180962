import {
  CheckboxGroupDefault,
  CheckboxGroupDefaultProps,
} from '@swe/shared/ui-kit/components/form/checkbox-group/variants/check';
import {
  CheckboxGroupTag,
  CheckboxGroupTagProps,
} from '@swe/shared/ui-kit/components/form/checkbox-group/variants/tag';

type CheckboxGroupVariantsProps<VT = string> =
  | ({
      variant: 'check';
    } & CheckboxGroupDefaultProps<VT>)
  | ({
      variant: 'tag';
    } & CheckboxGroupTagProps<VT>);

const CheckboxGroupVariants = <VT,>({ variant = 'check', ...props }: CheckboxGroupVariantsProps<VT>) => {
  switch (variant) {
    default:
    case 'check':
      return <CheckboxGroupDefault<VT> {...props} />;
    case 'tag':
      return <CheckboxGroupTag<VT> {...props} />;
  }
};

export type { CheckboxGroupTagProps, CheckboxGroupDefaultProps, CheckboxGroupVariantsProps };
export { CheckboxGroupVariants };
export default CheckboxGroupVariants;
