import { useBreakpoint } from '@swe/shared/tools/media';
import Badge from '@swe/shared/ui-kit/components/badge';
import { CarouselScrollable } from '@swe/shared/ui-kit/components/carousel';
import { CartIcon } from '@swe/shared/ui-kit/components/icon';
import Price from '@swe/shared/ui-kit/components/price';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { isEnterOrSpacePressed } from '@swe/shared/utils/keyboard';
import cx from 'clsx';

import { useCallback, KeyboardEvent } from 'react';

import styles from './styles.module.scss';

import { ProductVariantsSkeleton } from 'common/components/variants/skeleton';
import { useQuantities } from 'common/providers/cart/use-cases/use-quantities';
import { getProductVariantPrice, Product, ProductVariant } from 'entities/product/product';

type VariantProps = ComponentHasClassName & {
  product: Product;
  variant: ProductVariant;
  selected: boolean;
  quantity: number;
  onSelect?: (variant: ProductVariant) => void;
};

const Variant = ({ className, product, variant, selected, quantity, onSelect }: VariantProps) => {
  const [price, oldPrice] = getProductVariantPrice(variant, quantity);

  const { variants } = product;
  const isMultipleVariant = variants.length > 1;
  const hasManyPromos = variant.promos && variant.promos.length > 1;
  const hasPriceFrom = hasManyPromos || variant.promos?.[0]?.isConditional;

  const handleSelect = useCallback(() => {
    onSelect?.(variant);
  }, [onSelect, variant]);
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (isEnterOrSpacePressed(event)) {
        onSelect?.(variant);
      }
    },
    [onSelect, variant],
  );

  return (
    <div
      className={cx(
        className,
        styles.variant,
        selected && styles.variant_selected,
        onSelect && isMultipleVariant && styles.variant_interactive,
      )}
      tabIndex={0}
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
    >
      {!!quantity && quantity > 0 && (
        <Badge
          className={styles.badge}
          color="primary"
          size="md"
          icon={CartIcon}
        >
          {quantity}
        </Badge>
      )}
      <div className={styles.variantName}>
        {variant.name}
        {!variant.isPacked && ' · by weight'}
      </div>
      {(variant.availableQty || 0) <= 0 ? (
        <div className={styles.outOfStock}>Out of stock</div>
      ) : (
        <div>
          {oldPrice && (
            <div className={styles.variantPriceOld}>
              <Price value={oldPrice} />
            </div>
          )}
          {hasManyPromos && <div className={styles.deal}>Deals</div>}
          <div
            className={cx(styles.variantPrice, {
              [styles.variantPrice_discounted]: oldPrice || hasPriceFrom,
            })}
          >
            <Price
              value={price}
              from={hasPriceFrom}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type ProductVariantsProps = ComponentHasClassName & {
  product: Product;
  selected?: ProductVariant['id'];
  onSelect: (next: ProductVariant) => void;
};

const ProductVariants = ({ className, selected, onSelect, product }: ProductVariantsProps) => {
  const { mobile } = useBreakpoint();
  const { variants } = product;
  const isMultipleVariant = variants.length > 1;
  const { quantities } = useQuantities(product.id);

  const isVariantSelected = useCallback(
    (variant: ProductVariant) => (isMultipleVariant ? variant.id === selected : false),
    [isMultipleVariant, selected],
  );

  const Variants = (
    <div className={styles.variants}>
      {variants.map((variant, idx) => (
        <Variant
          key={idx}
          product={product}
          variant={variant}
          quantity={quantities.map[variant.id]}
          selected={isVariantSelected(variant)}
          onSelect={onSelect}
        />
      ))}
    </div>
  );

  if (mobile) {
    return (
      <CarouselScrollable
        className={className}
        shadowSupport={false}
      >
        {Variants}
      </CarouselScrollable>
    );
  }

  return Variants;
};

const ProductVariantSkeletonized = withSkeleton(ProductVariants, ProductVariantsSkeleton, ['product']);

export type { ProductVariantsProps };
export { ProductVariants, ProductVariantSkeletonized };
export default ProductVariants;
